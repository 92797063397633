"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDirections;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _react2 = _interopRequireWildcard(require("react"));
var _theme = _interopRequireDefault(require("../../theme"));
var _components = require("./components");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelDirections(_ref) {
  var _data$hotelInformatio;
  var {
    data,
    apiKey,
    t
  } = _ref;
  var hotelMapRef = (0, _react2.useRef)(null);
  var {
    coordinates,
    directions,
    brand
  } = (_data$hotelInformatio = data === null || data === void 0 ? void 0 : data.hotelInformation) !== null && _data$hotelInformatio !== void 0 ? _data$hotelInformatio : '';
  var latitude = coordinates === null || coordinates === void 0 ? void 0 : coordinates.latitude;
  var longitude = coordinates === null || coordinates === void 0 ? void 0 : coordinates.longitude;
  var fillColor = getFillColorMarker();
  (0, _react2.useEffect)(() => {
    var loader = new _jsApiLoader.Loader({
      apiKey,
      version: 'weekly',
      libraries: []
    });
    loader.load().then(() => {
      if (hotelMapRef !== null && hotelMapRef !== void 0 && hotelMapRef.current && latitude && longitude) {
        var _google = window.google;
        var hotelMap = new _google.maps.Map(hotelMapRef.current, {
          center: {
            lat: latitude,
            lng: longitude
          },
          zoom: 13
        });
        new _google.maps.Marker({
          position: {
            lat: latitude,
            lng: longitude
          },
          map: hotelMap,
          icon: getMarkerIconByBrand()
        });
      }
    });
  }, [brand, latitude, longitude]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    mb: "2xl",
    children: [(0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, hotelDirectionsWrapperStyle), {}, {
      sx: {
        '@media print': {
          display: 'none'
        }
      },
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        w: "full",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, hotelDirectionsHeadingStyles), {}, {
          "data-testid": "hotelDirections-label",
          children: t('booking.confirmation.hotelDirections')
        })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, mapBoxStyles), {}, {
          sx: {
            '@media print': {
              display: 'none'
            }
          },
          children: (0, _jsxRuntime.jsx)(_components.HotelMap, {
            latitude: latitude,
            longitude: longitude,
            brand: brand,
            apiKey: apiKey,
            fillColor: fillColor
          })
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, hotelDirectionsStyle), {}, {
          "data-testid": "hotelDirections-directions",
          dangerouslySetInnerHTML: {
            __html: directions
          }
        }))]
      })
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      display: "none",
      sx: {
        '@media print': {
          display: 'block'
        }
      },
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        fontSize: "md",
        fontWeight: "semibold",
        children: t('booking.confirmation.hotelDirections')
      }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, mapBoxStyles), {}, {
        display: "flex",
        children: (0, _jsxRuntime.jsx)("img", {
          src: "https://maps.googleapis.com/maps/api/staticmap?center=".concat(latitude, ",").concat(longitude, "&zoom=13&size=814x400&markers=color:0x").concat(fillColor.substring(1), "%7C").concat(latitude, ",").concat(longitude, "&scale=2&key=").concat(apiKey),
          alt: 'Google Maps'
        })
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, hotelDirectionsStyle), {}, {
        dangerouslySetInnerHTML: {
          __html: directions
        }
      }))]
    })]
  });
  function getFillColorMarker() {
    var fillColor = _theme.default.colors.btnSecondaryEnabled;
    if ((brand === null || brand === void 0 ? void 0 : brand.toLowerCase()) === 'hub') {
      fillColor = _theme.default.colors.hubPrimary;
    }
    if ((brand === null || brand === void 0 ? void 0 : brand.toLowerCase()) === 'zip') {
      fillColor = _theme.default.colors.zipPrimary;
    }
    return fillColor;
  }
  function getMarkerIconByBrand() {
    return _objectSpread(_objectSpread({}, defaultPinStyles), {}, {
      fillColor,
      anchor: new google.maps.Point(15, 30)
    });
  }
}
var MapPinPath = 'M45.0585 21C45.0585 32.598 22.8232 63.5 22.8232 63.5C22.8232 63.5 0.587891 32.598 0.587891 21C0.587891 9.40202 10.543 0 22.8232 0C35.1034 0 45.0585 9.40202 45.0585 21Z';
var defaultPinStyles = {
  path: MapPinPath,
  fillOpacity: 1,
  strokeWeight: 2,
  strokeColor: _theme.default.colors.baseWhite,
  rotation: 0,
  scale: 0.5
};
var mapBoxStyles = {
  h: {
    xl: '25rem',
    mobile: '9.75rem'
  },
  borderRadius: '8px',
  mb: 'lg'
};
var hotelDirectionsHeadingStyles = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4',
  mb: 'lg'
};
var hotelDirectionsStyle = {
  fontWeight: 'normal',
  fontSize: 'md',
  color: 'darkGrey1'
};
var hotelDirectionsWrapperStyle = {
  w: 'full',
  border: '1px solid var(--chakra-colors-lightGrey1)',
  borderRadius: '3px',
  p: 'lg'
};