"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _availability = require("./availability");
Object.keys(_availability).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _availability[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _availability[key];
    }
  });
});
var _booking = require("./booking");
Object.keys(_booking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _booking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _booking[key];
    }
  });
});
var _hotelInventory = require("./hotelInventory");
Object.keys(_hotelInventory).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelInventory[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelInventory[key];
    }
  });
});
var _roomTypeInformation = require("./roomTypeInformation");
Object.keys(_roomTypeInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roomTypeInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _roomTypeInformation[key];
    }
  });
});
var _staticHotelInformation = require("./staticHotelInformation");
Object.keys(_staticHotelInformation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _staticHotelInformation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _staticHotelInformation[key];
    }
  });
});
var _availabilityBusinessBooker = require("./availabilityBusinessBooker");
Object.keys(_availabilityBusinessBooker).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _availabilityBusinessBooker[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _availabilityBusinessBooker[key];
    }
  });
});
var _availabilityCcui = require("./availabilityCcui");
Object.keys(_availabilityCcui).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _availabilityCcui[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _availabilityCcui[key];
    }
  });
});