"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ExtrasSection;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ExtrasSection(_ref) {
  var {
    extrasDetailsList,
    addOrRemoveExtrasRoom,
    selectedExtrasList,
    selectedRoom
  } = _ref;
  var baseDataTestId = 'ExtrasSection';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var extrasDetailsListSorted = extrasDetailsList === null || extrasDetailsList === void 0 ? void 0 : extrasDetailsList.sort((firstItem, secondItem) => {
    var _firstItem$order, _secondItem$order;
    return ((_firstItem$order = firstItem === null || firstItem === void 0 ? void 0 : firstItem.order) !== null && _firstItem$order !== void 0 ? _firstItem$order : 0) - ((_secondItem$order = secondItem === null || secondItem === void 0 ? void 0 : secondItem.order) !== null && _secondItem$order !== void 0 ? _secondItem$order : 0);
  });
  var countSelectedExtras = (extrasList, extrasId) => {
    var count = 0;
    extrasList === null || extrasList === void 0 || extrasList.forEach(room => {
      var _room$packagesList;
      if (room !== null && room !== void 0 && (_room$packagesList = room.packagesList) !== null && _room$packagesList !== void 0 && _room$packagesList.includes(extrasId)) {
        count++;
      }
    });
    return count;
  };
  var totalInventoryEarlyCheckIn = countSelectedExtras(selectedExtrasList, _api.ExtrasId.EARLY_CHECK_IN);
  var totalInventoryLateCheckOut = countSelectedExtras(selectedExtrasList, _api.ExtrasId.LATE_CHECK_OUT);
  var checkInventoryAvailability = extrasItemParam => {
    var {
      available,
      id
    } = extrasItemParam;
    if (id === _api.ExtrasId.EARLY_CHECK_IN) {
      return available > totalInventoryEarlyCheckIn;
    }
    if (id === _api.ExtrasId.LATE_CHECK_OUT) {
      return available > totalInventoryLateCheckOut;
    }
    return false;
  };
  var getExtrasNotificationLabel = extrasItem => {
    var {
      available,
      id
    } = extrasItem;
    var extrasLabelsCheck = "".concat(id === _api.ExtrasId.EARLY_CHECK_IN ? 'Early check-in' : 'Late check-out');
    var minRoomsExtrasLabel = "".concat(extrasLabelsCheck, " is only available for up to ").concat(available, " of your rooms.");
    var maxExtrasLabel = "".concat(extrasLabelsCheck, " is only available for up to ").concat(available, " of your rooms. Please remove ").concat(extrasLabelsCheck.toLocaleLowerCase(), " from one of you other rooms to enable you to add it here.");
    var checkedSelectedInventory = id === _api.ExtrasId.EARLY_CHECK_IN ? totalInventoryEarlyCheckIn : id === _api.ExtrasId.LATE_CHECK_OUT ? totalInventoryLateCheckOut : -1;
    if (available && selectedExtrasList) {
      if (available === checkedSelectedInventory) {
        return maxExtrasLabel;
      } else if (available < (selectedExtrasList === null || selectedExtrasList === void 0 ? void 0 : selectedExtrasList.length)) {
        return minRoomsExtrasLabel;
      }
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, wrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, extrasTitleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Heading-Title'),
      children: t('upsell.extras.heading')
    })), extrasDetailsListSorted === null || extrasDetailsListSorted === void 0 ? void 0 : extrasDetailsListSorted.map(extrasItem => {
      var _selectedExtrasList$s;
      var isRemovable = selectedExtrasList === null || selectedExtrasList === void 0 || (_selectedExtrasList$s = selectedExtrasList[selectedRoom]) === null || _selectedExtrasList$s === void 0 || (_selectedExtrasList$s = _selectedExtrasList$s.packagesList) === null || _selectedExtrasList$s === void 0 ? void 0 : _selectedExtrasList$s.includes(extrasItem === null || extrasItem === void 0 ? void 0 : extrasItem.id);
      return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
          maxWidth: "full",
          variant: "info",
          status: "info",
          title: '',
          description: getExtrasNotificationLabel(extrasItem),
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          wrapperStyles: {
            mt: 'xl',
            mb: 'xl'
          }
        }), (0, _react2.createElement)(_molecules.ExtrasItemComponent, _objectSpread(_objectSpread({}, extrasItem), {}, {
          addOrRemoveExtrasRoom: addOrRemoveExtrasRoom,
          key: "Room-".concat(selectedRoom, "-").concat(extrasItem.id),
          isRemovable: isRemovable,
          isAvailable: checkInventoryAvailability(extrasItem)
        }))]
      });
    })]
  }));
}
var extrasTitleStyle = {
  fontWeight: 'semibold',
  fontSize: {
    mobile: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    mobile: '3',
    sm: '4'
  },
  color: 'darkGrey1'
};
var wrapperStyle = {
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 2
};