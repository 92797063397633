"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHotelAvailability = useHotelAvailability;
exports.useHotelAvailabilityBB = useHotelAvailabilityBB;
exports.useHotelAvailabilityCCUI = useHotelAvailabilityCCUI;
exports.useHotelRatesInformationBB = useHotelRatesInformationBB;
exports.useHotelRatesInformationCCUI = useHotelRatesInformationCCUI;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _getters = require("../getters");
var _auth = require("../getters/auth");
var _cookies = require("../helpers/cookies");
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useRequest = require("./use-request");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function useHotelAvailability(hotelId, hotelBrand, arrival, departure, rooms, channel, empOfferCodes, accessToken) {
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var queryEnabled = arrival !== undefined && departure !== undefined;
  var {
    isLoading,
    isError,
    data,
    error
  } = (0, _useRequest.useQueryRequest)((0, _getters.getHotelAvailabilityQueryKey)(language, country, hotelBrand, hotelId, arrival, departure, rooms, empOfferCodes), _api.HOTEL_AVAILABILITY_QUERY, {
    hotelId,
    arrival,
    departure,
    rooms,
    brand: hotelBrand === null || hotelBrand === void 0 ? void 0 : hotelBrand.toLowerCase(),
    language,
    country,
    bookingChannel: {
      channel,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      subchannel: _api.BOOKING_SUBCHANNEL.WEB
    },
    channel,
    ratePlanCodes: empOfferCodes,
    promotionCode: (0, _cookies.getCookie)(_api.PROMO_CODE_COOKIE)
  }, {
    enabled: !!queryEnabled
  }, accessToken);
  return {
    isLoading,
    isError,
    data,
    error
  };
}
function useHotelAvailabilityBB(hotelId, hotelBrand, arrival, departure, rooms, channel, accessToken) {
  var _userData, _data$hotelAvailabili;
  var userData = {
    profile: {
      accessLevel: '',
      companyId: '',
      employeeId: '',
      isBusiness: '',
      sessionId: ''
    },
    operaCompanyId: ''
  };
  if (accessToken) userData = (0, _auth.getLoggedInUserInfo)(accessToken);
  var companyId = (_userData = userData) === null || _userData === void 0 ? void 0 : _userData.operaCompanyId;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var queryEnabled = arrival !== undefined && departure !== undefined;
  var {
    data,
    isLoading,
    isError,
    error
  } = (0, _useRequest.useQueryRequest)(['hotelAvailabilityBB', language, country, hotelBrand.toLowerCase(), hotelId, companyId, arrival, departure, JSON.stringify(rooms), channel], _api.HOTEL_AVAILABILITY_BB_QUERY, {
    hotelId,
    arrival,
    departure,
    rooms,
    brand: hotelBrand.toLowerCase(),
    language,
    country,
    companyId,
    bookingChannel: {
      channel: channel,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      subchannel: _api.BOOKING_SUBCHANNEL.WEB
    }
  }, {
    enabled: !!queryEnabled
  }, accessToken);
  var roomRates = data === null || data === void 0 || (_data$hotelAvailabili = data.hotelAvailability) === null || _data$hotelAvailabili === void 0 ? void 0 : _data$hotelAvailabili.roomRates;
  if ((roomRates === null || roomRates === void 0 ? void 0 : roomRates.length) > 0) {
    var filteredRatePlanCodes = getFilteredRatePlans(roomRates);
    var filteredRoomRates = roomRates.filter(_ref => {
      var {
        ratePlanCode
      } = _ref;
      return filteredRatePlanCodes.includes(ratePlanCode);
    });
    data.hotelAvailability.roomRates = filteredRoomRates;
  }
  return {
    isLoading,
    isError,
    data,
    error
  };
}
function useHotelAvailabilityCCUI(hotelId, hotelBrand, arrival, departure, rooms, channel, queryEnabled, companyId) {
  var _data$hotelAvailabili2;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var {
    data,
    isLoading,
    isError,
    error
  } = (0, _useRequest.useQueryRequest)(['hotelAvailabilityCCUI', language, country, hotelBrand.toLowerCase(), hotelId, companyId, arrival, departure, JSON.stringify(rooms), channel].filter(Boolean), _api.HOTEL_AVAILABILITY_CCUI_QUERY, {
    hotelId,
    arrival,
    departure,
    rooms,
    brand: hotelBrand.toLowerCase(),
    language,
    country,
    companyId,
    bookingChannel: {
      channel: channel,
      language: language === null || language === void 0 ? void 0 : language.toUpperCase(),
      subchannel: _api.BOOKING_SUBCHANNEL.WEB
    }
  }, {
    enabled: !!queryEnabled
  });
  if (data !== null && data !== void 0 && (_data$hotelAvailabili2 = data.hotelAvailability) !== null && _data$hotelAvailabili2 !== void 0 && _data$hotelAvailabili2.roomRates) {
    data.hotelAvailability.roomRates = data.hotelAvailability.roomRates.filter(rate => rate.ratePlanCode !== _api.RatePlanCodes.BUSIFLEX);
  }
  return {
    isLoading,
    isError,
    data,
    error
  };
}
function useHotelRatesInformationBB(hotelId, hotelBrand, dataHotelAvailability, channel, accessToken) {
  var _dataHotelAvailabilit;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var ratePlanCodes = dataHotelAvailability === null || dataHotelAvailability === void 0 || (_dataHotelAvailabilit = dataHotelAvailability.hotelAvailability) === null || _dataHotelAvailabilit === void 0 ? void 0 : _dataHotelAvailabilit.roomRates.map(roomRate => roomRate.ratePlanCode);
  var {
    data: dataRatesInformation,
    isLoading,
    isError,
    error
  } = (0, _useRequest.useQueryRequest)(['ratesInformationBB', language, country, hotelBrand.toLowerCase(), hotelId, channel, ratePlanCodes], _api.RATE_INFORMATION_BB_QUERY, {
    hotelId,
    brand: hotelBrand === null || hotelBrand === void 0 ? void 0 : hotelBrand.toLowerCase(),
    language,
    country,
    channel,
    ratePlans: ratePlanCodes === null || ratePlanCodes === void 0 ? void 0 : ratePlanCodes.toString()
  }, undefined, accessToken);
  var hotelData = _objectSpread(_objectSpread({}, dataHotelAvailability), dataRatesInformation);
  return {
    isLoading,
    isError,
    data: hotelData,
    error
  };
}
function useHotelRatesInformationCCUI(hotelId, hotelBrand, dataHotelAvailability, channel, queryEnabled) {
  var _dataHotelAvailabilit2;
  var {
    country,
    language
  } = (0, _useCustomLocale.default)();
  var ratePlanCodes = dataHotelAvailability === null || dataHotelAvailability === void 0 || (_dataHotelAvailabilit2 = dataHotelAvailability.hotelAvailability) === null || _dataHotelAvailabilit2 === void 0 ? void 0 : _dataHotelAvailabilit2.roomRates.map(roomRate => roomRate.ratePlanCode);
  var {
    data: dataRatesInformation,
    isLoading,
    isError,
    error
  } = (0, _useRequest.useQueryRequest)(['ratesInformationCCUI', language, country, hotelBrand.toLowerCase(), hotelId, channel, ratePlanCodes], _api.RATE_INFORMATION_CCUI_QUERY, {
    hotelId,
    brand: hotelBrand === null || hotelBrand === void 0 ? void 0 : hotelBrand.toLowerCase(),
    language,
    country,
    channel,
    ratePlans: ratePlanCodes === null || ratePlanCodes === void 0 ? void 0 : ratePlanCodes.toString()
  }, {
    enabled: queryEnabled
  });
  var hotelData = _objectSpread(_objectSpread({}, dataHotelAvailability), dataRatesInformation);
  return {
    isLoading,
    isError,
    data: hotelData,
    error
  };
}
var getFilteredRatePlans = roomRates => {
  var ratePlanCodes = roomRates.map(roomRate => roomRate.ratePlanCode);
  var isHavingBusAndFlexRate = Array.isArray(ratePlanCodes) && ratePlanCodes.indexOf(_api.RatePlanCodes.BUSIFLEX) > -1 && ratePlanCodes.indexOf(_api.RatePlanCodes.FLEXRATE) > -1;
  return isHavingBusAndFlexRate ? ratePlanCodes.filter(planCode => planCode !== _api.RatePlanCodes.FLEXRATE) : ratePlanCodes;
};