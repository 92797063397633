"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FT_PI_WEB_PUSH_NOTIFICATIONS = exports.FT_PI_RECAPTCHA_REGISTER = exports.FT_PI_PRE_CHECK_IN_LABEL = exports.FT_PI_PRE_CHECK_IN = exports.FT_PI_META_CLASS_RATE_SEARCH = exports.FT_PI_HREFLANG_ATTRIBUTE = exports.FT_PI_GROUP_BOOKING_FORM = exports.FT_PI_GDP_SINGLE_BOOKING = exports.FT_PI_GDP_MULTI_BOOKING = exports.FT_PI_GDP_BILLING_ADDRESS = exports.FT_PI_ENABLE_PAYPAL = exports.FT_PI_ENABLE_PAYMENT_REDESIGN = exports.FT_PI_BREAKFAST_PROMO_CODE = exports.FT_PI_BB_CCUI_TRIP_ADVISOR = exports.FT_PI_ANCILLARIES_EXTRAS = exports.FT_PI_AMEND_GUEST_ADDRESS = exports.FT_PI_ADDITIONAL_INFORMATION = exports.FT_IB_ENABLED = exports.FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = exports.FT_CCUI_PRE_CHECK_IN_LABEL = exports.FT_CCUI_GDP_SINGLE_BOOKING = exports.FT_CCUI_GDP_MULTI_BOOKING = exports.FT_CCUI_GDP_BILLING_ADDRESS = exports.FT_CCUI_CHANGE_PAYMENT_METHOD = exports.FT_CCUI_AMEND_GUEST_ADDRESS = exports.FT_CCUI_ADDITIONAL_INFORMATION = exports.FT_BB_GROUP_BOOKING_FORM = exports.FT_BB_ENABLE_PAYPAL = exports.FT_BB_ENABLE_PAYMENT_REDESIGN = void 0;
var FT_PI_ENABLE_PAYPAL = exports.FT_PI_ENABLE_PAYPAL = 'kill_switch_pi_enable-paypal';
var FT_BB_ENABLE_PAYPAL = exports.FT_BB_ENABLE_PAYPAL = 'kill_switch_bb_enable-paypal';
var FT_PI_ENABLE_PAYMENT_REDESIGN = exports.FT_PI_ENABLE_PAYMENT_REDESIGN = 'release_pi_payment_redesign';
var FT_BB_ENABLE_PAYMENT_REDESIGN = exports.FT_BB_ENABLE_PAYMENT_REDESIGN = 'release_bb_payment_redesign';
var FT_PI_AMEND_GUEST_ADDRESS = exports.FT_PI_AMEND_GUEST_ADDRESS = 'release_pi_amend_guest_address';
var FT_CCUI_AMEND_GUEST_ADDRESS = exports.FT_CCUI_AMEND_GUEST_ADDRESS = 'release_ccui_amend_guest_address';
var FT_PI_PRE_CHECK_IN_LABEL = exports.FT_PI_PRE_CHECK_IN_LABEL = 'release_pi_pre_check_in_label';
var FT_CCUI_PRE_CHECK_IN_LABEL = exports.FT_CCUI_PRE_CHECK_IN_LABEL = 'release_ccui_pre_check_in_label';
var FT_PI_PRE_CHECK_IN = exports.FT_PI_PRE_CHECK_IN = 'release_pi_pre_check_in';
var FT_PI_GDP_BILLING_ADDRESS = exports.FT_PI_GDP_BILLING_ADDRESS = 'release_pi_gdp_billing_address';
var FT_CCUI_GDP_BILLING_ADDRESS = exports.FT_CCUI_GDP_BILLING_ADDRESS = 'release_ccui_indigo_gdp-billing-address';
var FT_PI_GDP_MULTI_BOOKING = exports.FT_PI_GDP_MULTI_BOOKING = 'release_pi_gdp_multi_booking';
var FT_CCUI_GDP_MULTI_BOOKING = exports.FT_CCUI_GDP_MULTI_BOOKING = 'release_ccui_indigo_gdp-multi-booking';
var FT_PI_GDP_SINGLE_BOOKING = exports.FT_PI_GDP_SINGLE_BOOKING = 'release_pi_gdp_single_booking';
var FT_CCUI_GDP_SINGLE_BOOKING = exports.FT_CCUI_GDP_SINGLE_BOOKING = 'release_ccui_indigo_gdp-single-booking';
var FT_PI_ADDITIONAL_INFORMATION = exports.FT_PI_ADDITIONAL_INFORMATION = 'release_pi_additional_information';
var FT_CCUI_ADDITIONAL_INFORMATION = exports.FT_CCUI_ADDITIONAL_INFORMATION = 'release_ccui_additional_information';
var FT_IB_ENABLED = exports.FT_IB_ENABLED = 'release_ib_enabled';
var FT_PI_RECAPTCHA_REGISTER = exports.FT_PI_RECAPTCHA_REGISTER = 'kill_switch_pi_recaptcha_register_page';
var FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = exports.FT_CCUI_PRE_POPULATE_BILLING_ADDRESS = 'release-pre-populate-different-billing-address-ccui';
var FT_PI_BB_CCUI_TRIP_ADVISOR = exports.FT_PI_BB_CCUI_TRIP_ADVISOR = 'release_pi_bb_ccui_white_trip_advisor_review';
var FT_PI_GROUP_BOOKING_FORM = exports.FT_PI_GROUP_BOOKING_FORM = 'release_pi_group_booking_form';
var FT_BB_GROUP_BOOKING_FORM = exports.FT_BB_GROUP_BOOKING_FORM = 'release_bb_group_booking_form';
var FT_PI_HREFLANG_ATTRIBUTE = exports.FT_PI_HREFLANG_ATTRIBUTE = 'release_pi_hreflang_attribute';
var FT_PI_ANCILLARIES_EXTRAS = exports.FT_PI_ANCILLARIES_EXTRAS = 'release_pi_ancillaries_extras_display';
var FT_PI_WEB_PUSH_NOTIFICATIONS = exports.FT_PI_WEB_PUSH_NOTIFICATIONS = 'release_pi_web_push_notifications';
var FT_PI_BREAKFAST_PROMO_CODE = exports.FT_PI_BREAKFAST_PROMO_CODE = 'release_pi_breakfast_promo_code';
var FT_CCUI_CHANGE_PAYMENT_METHOD = exports.FT_CCUI_CHANGE_PAYMENT_METHOD = 'release_ccui_change_payment_method';
var FT_PI_META_CLASS_RATE_SEARCH = exports.FT_PI_META_CLASS_RATE_SEARCH = 'kill_switch_pi_meta_search';