"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _ChangeLogTable = _interopRequireDefault(require("./ChangeLogTable.component"));
var _tableConfig = require("./tableConfig");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getChangesLogResults(_x, _x2, _x3, _x4, _x5) {
  return _getChangesLogResults.apply(this, arguments);
}
function _getChangesLogResults() {
  _getChangesLogResults = (0, _asyncToGenerator2.default)(function* (queryClient, hotelId, selectedReservationId, limit, offset) {
    var queryResult = yield queryClient.fetchQuery(_objectSpread({
      queryKey: ['retrieveChangesLog', hotelId, selectedReservationId, limit, offset],
      queryFn: () => (0, _utils.graphQLRequest)(_api.RETRIEVE_CHANGES_LOG, {
        hotelId,
        reservationId: selectedReservationId,
        limit,
        offset
      })
    }, {
      gcTime: 0
    }));
    var results = queryResult.retrieveChangesLog.activityLog;
    return results;
  });
  return _getChangesLogResults.apply(this, arguments);
}
var defaultChangeLogResults = {
  activityLog: [],
  hasMore: false,
  limit: 20,
  offset: 0,
  totalPages: 0,
  totalResults: 0,
  isLoadingChangeLog: false,
  isErrorChangeLog: false
};
function ChangeLog(_ref) {
  var {
    showChangeLogModal,
    setShowChangeLogModal,
    bookingReference
  } = _ref;
  var baseDataTestId = 'ChangeLog';
  var [hotelId, setHotelId] = (0, _react2.useState)('');
  var [reservationIdList, setReservationIdList] = (0, _react2.useState)([]);
  var [selectedReservationId, setSelectedReservationId] = (0, _react2.useState)('');
  var [changeLogResults, setChangeLogResults] = (0, _react2.useState)(defaultChangeLogResults);
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var queryClient = (0, _reactQuery.useQueryClient)();
  var isValidReservation = !!hotelId && (reservationIdList === null || reservationIdList === void 0 ? void 0 : reservationIdList.length) && !!selectedReservationId;
  var isMultiRoomReservatoin = (reservationIdList === null || reservationIdList === void 0 ? void 0 : reservationIdList.length) > 1;
  var {
    data: bookingData,
    isLoading: isLoadingBookingData,
    isError: isErrorBookingData
  } = (0, _utils.useQueryRequest)(['getBookingConfirmationAuthenticated', bookingReference, language, country], _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED, {
    language,
    country,
    bookingReference
  }, {
    enabled: !!idTokenCookie
  }, idTokenCookie, true);
  var enableChangesLogRequest = Boolean(!isLoadingBookingData && !isErrorBookingData && isValidReservation);
  var fetchChangeLogs = (offset, limit) => {
    setChangeLogResults(() => _objectSpread(_objectSpread({}, changeLogResults), {}, {
      isLoadingChangeLog: true
    }));
    getChangesLogResults(queryClient, hotelId, selectedReservationId, limit, offset).then(results => {
      if (results) {
        var latestResults = _objectSpread(_objectSpread({}, results), {}, {
          activityLog: [...changeLogResults.activityLog, ...results.activityLog],
          isLoadingChangeLog: false,
          isErrorChangeLog: false
        });
        setChangeLogResults(() => _objectSpread({}, latestResults));
      }
    }).catch(() => {
      setChangeLogResults(() => _objectSpread(_objectSpread({}, changeLogResults), {}, {
        isErrorChangeLog: true,
        isLoadingChangeLog: false
      }));
    });
  };
  (0, _react2.useEffect)(() => {
    if (enableChangesLogRequest) {
      fetchChangeLogs(changeLogResults.offset, changeLogResults.limit);
    }
  }, [hotelId, selectedReservationId, enableChangesLogRequest]);
  (0, _react2.useEffect)(() => {
    var _bookingData$bookingC, _bookingData$bookingC2;
    var hotelId = bookingData === null || bookingData === void 0 || (_bookingData$bookingC = bookingData.bookingConfirmationAuthenticated) === null || _bookingData$bookingC === void 0 ? void 0 : _bookingData$bookingC.hotelId;
    var reservationIdList = bookingData === null || bookingData === void 0 || (_bookingData$bookingC2 = bookingData.bookingConfirmationAuthenticated) === null || _bookingData$bookingC2 === void 0 || (_bookingData$bookingC2 = _bookingData$bookingC2.reservationByIdList) === null || _bookingData$bookingC2 === void 0 ? void 0 : _bookingData$bookingC2.map(x => x.reservationId);
    var firstReservationId = reservationIdList !== null && reservationIdList !== void 0 && reservationIdList.length ? reservationIdList[0] : '';
    setHotelId(hotelId !== null && hotelId !== void 0 ? hotelId : '');
    setReservationIdList(reservationIdList !== null && reservationIdList !== void 0 ? reservationIdList : []);
    setSelectedReservationId(firstReservationId);
  }, [bookingData]);
  var handleDropdown = option => {
    var _ref2;
    setChangeLogResults(defaultChangeLogResults);
    setSelectedReservationId((_ref2 = option === null || option === void 0 ? void 0 : option.id) !== null && _ref2 !== void 0 ? _ref2 : '');
  };
  var modalHeader = (0, _jsxRuntime.jsxs)(_react.HStack, {
    gap: 4,
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerTitleStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ModalTitle'),
      children: t('ccui.changeLogModal.title')
    })), isMultiRoomReservatoin && (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
      dataTestId: (0, _utils.formatDataTestId)(baseDataTestId, 'MultiRoomDropdown'),
      dropdownStyles: dropdownStyles,
      matchWidth: true,
      selectedId: selectedReservationId,
      onChange: handleDropdown,
      options: reservationIdList.map((reservationId, index) => ({
        label: "".concat(t('ccui.changeLogModal.multiRoomDropdown.room').replace('[roomNumber]', String(index + 1))),
        id: reservationId
      }))
    })]
  });
  var variantProps = {
    title: t('ccui.changeLogModal.title'),
    modalHeight: modalHeight,
    header: modalHeader,
    showDelimiter: false
  };
  var renderModalBody = () => {
    if (isLoadingBookingData || changeLogResults.isLoadingChangeLog) {
      return (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, gridStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
          loadingText: t('ccui.changeLogModal.loading')
        })
      }));
    }
    if (isErrorBookingData || changeLogResults.isErrorChangeLog) {
      return (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, gridStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, messageTitleStyles), {}, {
          children: t('ccui.changeLogModal.errorHeading')
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, errorMessageDescriptionStyles), {}, {
          children: t('ccui.changeLogModal.errorDescription')
        }))]
      }));
    }
    var rows = changeLogResults.activityLog;
    if (!(rows !== null && rows !== void 0 && rows.length)) {
      return (0, _jsxRuntime.jsx)(_react.Grid, _objectSpread(_objectSpread({}, gridStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, messageTitleStyles), {}, {
          children: t('ccui.changeLogModal.noResults')
        }))
      }));
    }
    var columns = (0, _tableConfig.getTableColumns)(t);
    return (0, _jsxRuntime.jsx)(_ChangeLogTable.default, {
      columns: columns,
      rows: rows
    });
  };
  var handleLoadMore = () => {
    fetchChangeLogs(changeLogResults.offset, changeLogResults.limit);
  };
  return (0, _jsxRuntime.jsxs)(_atoms.ModalVariants, {
    isOpen: showChangeLogModal,
    onClose: () => {
      setShowChangeLogModal(false);
    },
    variant: "data",
    variantProps: variantProps,
    updatedWidth: modalWidth,
    dataTestId: baseDataTestId,
    children: [renderModalBody(), changeLogResults.hasMore && !changeLogResults.isLoadingChangeLog && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, loadMoreContainerStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Button, {
        color: "btnSecondaryEnabled",
        variant: "tertiary",
        size: "md",
        onClick: handleLoadMore,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'LoadMoreButton'),
        children: t('ccui.changeLogModal.loadMore')
      })
    }))]
  });
}
var modalWidth = {
  lg: '1224px',
  xl: '1308px'
};
var modalHeight = {
  lg: '984px'
};
var headerTitleStyle = {
  color: 'darkGrey1',
  justify: 'center',
  justifyContent: 'flex-start',
  fontSize: 'lg',
  lineHeight: '3',
  fontFamily: 'header',
  padding: 'var(--chakra-sizes-3-5) 0',
  margin: 0
};
var dropdownStyles = {
  wrapperStyles: {
    width: '288px'
  },
  menuButtonStyles: {
    borderColor: 'var(--chakra-colors-lightGrey1)'
  },
  menuListStyles: {
    maxHeight: '200px'
  }
};
var loadMoreContainerStyle = {
  textAlign: 'center',
  my: 'lg'
};
var gridStyles = {
  height: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  justifyItems: 'center'
};
var messageTitleStyles = {
  color: 'darkGrey1',
  fontSize: '3xl',
  lineHeight: '5',
  fontWeight: 'semibold',
  mb: 'var(--chakra-space-4)'
};
var errorMessageDescriptionStyles = {
  color: 'darkGrey1',
  fontSize: 'lg',
  lineHeight: '3',
  fontWeight: 'normal'
};
var _default = exports.default = ChangeLog;