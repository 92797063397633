"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.I18N_LANGUAGES = exports.I18N_FALLBACK_LANG = exports.I18N_DEFAULT_NS = void 0;
exports.getOptions = getOptions;
exports.useTranslation = useTranslation;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _i18next = require("i18next");
var _initReactI18next = require("react-i18next/initReactI18next");
var _index = require("../server/index");
var I18N_FALLBACK_LANG = exports.I18N_FALLBACK_LANG = 'en';
var I18N_LANGUAGES = exports.I18N_LANGUAGES = [I18N_FALLBACK_LANG, 'de'];
var I18N_DEFAULT_NS = exports.I18N_DEFAULT_NS = 'common';
function getOptions() {
  var lng = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : I18N_FALLBACK_LANG;
  var ns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : I18N_DEFAULT_NS;
  return {
    supportedLngs: I18N_LANGUAGES,
    fallbackLng: I18N_FALLBACK_LANG,
    lng: lng,
    fallbackNS: I18N_DEFAULT_NS,
    defaultNS: I18N_DEFAULT_NS,
    ns: ns
  };
}
var initI18next = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (lng, ns) {
    var resources = {
      [lng]: {
        [ns]: yield (0, _index.getInnBusinessHeaderLabels)(lng)
      }
    };
    var i18nInstance = (0, _i18next.createInstance)({
      resources: resources
    });
    yield i18nInstance.use(_initReactI18next.initReactI18next).init(getOptions(lng, ns));
    return i18nInstance;
  });
  return function initI18next(_x, _x2) {
    return _ref.apply(this, arguments);
  };
}();
function useTranslation() {
  return _useTranslation.apply(this, arguments);
}
function _useTranslation() {
  _useTranslation = (0, _asyncToGenerator2.default)(function* () {
    var lng = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : I18N_FALLBACK_LANG;
    var ns = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : I18N_DEFAULT_NS;
    var i18nextInstance = yield initI18next(lng, ns);
    var t = i18nextInstance.getFixedT(lng, ns);
    return {
      t: t,
      i18n: i18nextInstance
    };
  });
  return _useTranslation.apply(this, arguments);
}