"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgHandheldShower = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 22,
  height: 22,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M11.317 1.78a6.05 6.05 0 0 1 1.422 2.23l8.681 10.648-.786.787c.845.956 1.301 2.056 1.36 3.282L22 19v3h-1v-3c0-1.068-.354-2.01-1.074-2.846l-.61.608-7.557-7.557-.045.044a2.75 2.75 0 0 1-3.751.129l-.138-.129-3.977-3.977a2.752 2.752 0 0 1-.25-3.6C5.47-.83 9.242-.294 11.317 1.781Zm1.06 6.63 6.938 6.937.76-.758L13.07 5.99a4.802 4.802 0 0 1-.693 2.42ZM6.238 9.423c.27.155.362.5.206.768L5.32 12.14a.562.562 0 1 1-.974-.563L5.47 9.63a.562.562 0 0 1 .768-.206Zm-2.832.268c.22.22.22.576 0 .796l-1.591 1.59a.562.562 0 1 1-.796-.795l1.591-1.59c.22-.22.576-.22.796 0Zm.847-7.196-.046.09c-.283.604-.2 1.334.24 1.862l.108.118 3.977 3.977a1.75 1.75 0 0 0 1.784.426l.15-.057.136-.067-6.349-6.349Zm-.579 4.364a.563.563 0 0 1-.206.768L1.52 8.752a.563.563 0 0 1-.563-.974l1.949-1.125a.563.563 0 0 1 .768.206Zm1.26-5.098 6.402 6.403c1.19-1.587.926-4.024-.726-5.676C8.957.835 6.52.572 4.934 1.76Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgHandheldShower;