"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgElectricVehicleCharging = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 18,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9 0a2 2 0 0 1 2 2v6h.5a2.5 2.5 0 0 1 2.495 2.336L14 10.5V15a1 1 0 0 0 1.993.117L16 15V6h-.5a.5.5 0 0 1-.492-.41L15 5.5v-2a.5.5 0 0 1 .992-.09L16 3.5V5h1V3.5a.5.5 0 0 1 .41-.492L17.5 3a.5.5 0 0 1 .492.41L18 3.5v2a.5.5 0 0 1-.41.492L17.5 6H17v9a2 2 0 0 1-3.995.15L13 15v-4.5a1.5 1.5 0 0 0-1.355-1.493L11.5 9H11v10h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V2a2 2 0 0 1 2-2h6Zm0 1H3a1 1 0 0 0-.993.883L2 2v17h8V2a1 1 0 0 0-.883-.993L9 1ZM6.724 9.053a.5.5 0 0 1 .256.587l-.033.084L5.31 13H7.5a.5.5 0 0 1 .478.646l-.03.078-2 4a.5.5 0 0 1-.928-.364l.033-.084L6.69 14H4.5a.5.5 0 0 1-.478-.646l.03-.078 2-4a.5.5 0 0 1 .672-.223ZM8 2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4Zm0 1H4v3h4V3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgElectricVehicleCharging;