"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.firstError = firstError;
exports.getSearchResultsURLQuery = exports.getSearchQueryUrl = exports.getOpeningSoonHotels = exports.getCurrentResultsNumber = exports.formatDateSearchQueryUrl = void 0;
exports.hotelOpensSoon = hotelOpensSoon;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var getSearchResultsURLQuery = (multiSearchParams, searchSuggestions, query) => {
  var _geometry;
  var {
    properties,
    places
  } = searchSuggestions;
  var items = [...properties, ...places];
  var searchedItem = items.find(item => item.suggestion === multiSearchParams.location);
  var placeId = searchedItem === null || searchedItem === void 0 ? void 0 : searchedItem.placeId;
  var coordinates = searchedItem === null || searchedItem === void 0 || (_geometry = searchedItem.geometry) === null || _geometry === void 0 ? void 0 : _geometry.coordinates;
  var newQuery = _objectSpread({}, query);
  if (placeId) {
    newQuery = _objectSpread(_objectSpread({}, newQuery), {}, {
      PLACEID: placeId
    });
  } else if (coordinates !== null && coordinates !== void 0 && coordinates.length || !placeId) {
    var item = places[0];
    newQuery = _objectSpread(_objectSpread({}, newQuery), {}, {
      PLACEID: item.placeId,
      'searchModel.searchTerm': item.suggestion
    });
  }
  return newQuery;
};
exports.getSearchResultsURLQuery = getSearchResultsURLQuery;
var formatDateSearchQueryUrl = (key, value) => {
  if (!value) {
    return value;
  }
  switch (key) {
    case 'ARRdd':
    case 'ARRmm':
      return ('0' + value).slice(-2);
    default:
      return value;
  }
};
exports.formatDateSearchQueryUrl = formatDateSearchQueryUrl;
var getSearchQueryUrl = (router, ignoreKeys, formatter) => {
  var queryParams = router.query;
  var pathObject = {};
  var _loop = function _loop(_key) {
    var foundInIgnoredKeys = ignoreKeys === null || ignoreKeys === void 0 ? void 0 : ignoreKeys.find(el => el === _key);
    if (!foundInIgnoredKeys) {
      if (formatter) {
        pathObject[_key] = formatter(_key, queryParams[_key]);
      } else {
        pathObject[_key] = queryParams[_key];
      }
    }
  };
  for (var _key in queryParams) {
    _loop(_key);
  }
  var searchQueryUrl = Object.entries(pathObject).map(_ref => {
    var [key, value] = _ref;
    return "".concat(key, "=").concat(value);
  }).join('&');
  return searchQueryUrl;
};
exports.getSearchQueryUrl = getSearchQueryUrl;
function hotelOpensSoon(hotelOpeningDate, multiSearchParams) {
  if (!hotelOpeningDate) return false;
  try {
    return (0, _differenceInDays.default)(new Date(hotelOpeningDate), new Date("".concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalMonth, "/").concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalDay, "/").concat(multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.arrivalYear))) > 0;
  } catch (e) {
    return false;
  }
}
var getOpeningSoonHotels = (hotels, multiSearchParams) => hotels === null || hotels === void 0 ? void 0 : hotels.filter(hotel => {
  var _hotel$hotelInformati;
  if (hotel !== null && hotel !== void 0 && (_hotel$hotelInformati = hotel.hotelInformation) !== null && _hotel$hotelInformati !== void 0 && _hotel$hotelInformati.hotelOpeningDate) {
    try {
      var _hotel$hotelInformati2;
      return hotelOpensSoon(hotel === null || hotel === void 0 || (_hotel$hotelInformati2 = hotel.hotelInformation) === null || _hotel$hotelInformati2 === void 0 ? void 0 : _hotel$hotelInformati2.hotelOpeningDate, multiSearchParams);
    } catch (e) {
      return false;
    }
  }
  return false;
});
exports.getOpeningSoonHotels = getOpeningSoonHotels;
function firstError(errors) {
  var _errors$response;
  return errors === null || errors === void 0 || (_errors$response = errors.response) === null || _errors$response === void 0 ? void 0 : _errors$response.errors[0];
}
var getCurrentResultsNumber = (results, items, total) => {
  if (results.length < 10 && items.length > total - results.length || total - results.length === items.length) {
    return 0;
  }
  return results.length;
};
exports.getCurrentResultsNumber = getCurrentResultsNumber;