"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentTypeContainer;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _payment = require("../../payment");
var _jsxRuntime = require("react/jsx-runtime");
var baseTestId = 'paymentTypeContainer';
function PaymentTypeContainer(_ref) {
  var _query$reservationId, _formatedData$payment;
  var {
    selectedPaymentDetail,
    selectedPaymentType,
    onPaymentTypeClick,
    disabledOptions,
    hiddenPaymentMethodTypes,
    initialPaymentType,
    styles
  } = _ref;
  var router = (0, _router.useRouter)();
  var {
    query
  } = router;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var basketRef = (_query$reservationId = query.reservationId) !== null && _query$reservationId !== void 0 ? _query$reservationId : query.basketReference;
  var {
    data,
    isError,
    error,
    isLoading
  } = (0, _utils.useQueryRequest)(['getPaymentMethodsCCUI', language, country, basketRef], _api.GET_PAYMENT_TYPE_OPTS_QUERY_CCUI, {
    basketReference: basketRef,
    language,
    country
  });
  var formatedData = {
    paymentMethods: data === null || data === void 0 ? void 0 : data.paymentCcuiMethods
  };
  var paymentOptions = (0, _react2.useMemo)(() => {
    var _JSON$parse;
    return (_JSON$parse = JSON.parse(JSON.stringify((formatedData === null || formatedData === void 0 ? void 0 : formatedData.paymentMethods) || []))) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.reduce((previous, current) => {
      var _ref2;
      (_ref2 = current.paymentOptions || []) === null || _ref2 === void 0 || _ref2.forEach(option => {
        var _previous;
        var existing = (_previous = previous) === null || _previous === void 0 ? void 0 : _previous.find(c => (c === null || c === void 0 ? void 0 : c.type) === (option === null || option === void 0 ? void 0 : option.type));
        if (existing) {
          existing.enabled = existing.enabled || option.enabled;
        } else {
          previous = [...previous, option];
        }
      });
      return previous;
    }, []);
  }, [formatedData === null || formatedData === void 0 ? void 0 : formatedData.paymentMethods]);
  !isLoading && (formatedData === null || formatedData === void 0 || (_formatedData$payment = formatedData.paymentMethods) === null || _formatedData$payment === void 0 ? void 0 : _formatedData$payment.forEach(method => {
    var _method$acceptedCardT;
    (_method$acceptedCardT = method.acceptedCardTypes) === null || _method$acceptedCardT === void 0 || _method$acceptedCardT.forEach(cardType => {
      cardType && (cardType.logoSrc = (0, _utils.formatAssetsUrl)(cardType === null || cardType === void 0 ? void 0 : cardType.logoSrc));
    });
    method.paymentOptionsAvailable = paymentOptions;
  }));
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (data) return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "".concat(baseTestId, "_withData"),
    children: (0, _jsxRuntime.jsx)(_payment.PaymentType, {
      data: formatedData,
      isLoading,
      isError,
      error,
      t,
      selectedPaymentDetail: selectedPaymentDetail,
      selectedPaymentType: selectedPaymentType,
      onPaymentTypeClick: onPaymentTypeClick,
      disabledOptions: disabledOptions,
      hiddenPaymentMethodTypes: hiddenPaymentMethodTypes,
      variant: _api.Area.CCUI,
      styles: styles,
      initialPaymentType: initialPaymentType
    })
  });else return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "".concat(baseTestId, "_noData")
  });
}