"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PRECHECKIN_BOOKING_INFORMATION = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var GET_PRECHECKIN_BOOKING_INFORMATION = exports.GET_PRECHECKIN_BOOKING_INFORMATION = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query bookingConfirmation($basketReference: String!, $language: String!, $country: String!) {\n    bookingConfirmation(basketReference: $basketReference, language: $language, country: $country) {\n      reservationByIdList {\n        reservationId\n        reservationStatus\n        preCheckInStatus\n        billing {\n          address {\n            addressLine1\n            addressLine2\n            addressLine3\n            addressLine4\n            cityName\n            postalCode\n            countryCode\n            country\n            addressType\n          }\n          title\n          telephone\n          firstName\n          lastName\n          email\n        }\n        reservationGuestList {\n          givenName\n          surName\n          email\n          nameTitle\n          additionalDetails {\n            dob\n            passportNumber\n            nationality\n          }\n          address {\n            addressType\n            addressLine1\n            addressLine2\n            addressLine4\n            addressType\n            cityName\n            countryCode\n            postalCode\n          }\n          profileId\n        }\n        reservationBooker {\n          email\n          title\n          firstName\n          lastName\n          mobile\n          landline\n        }\n        gdsReferenceNumber\n        roomStay {\n          arrivalDate\n          departureDate\n          childrenNumber\n          adultsNumber\n          roomType\n          roomExtraInfo {\n            roomName\n          }\n        }\n        additionalGuestInfo {\n          purposeOfStay\n        }\n      }\n      hotelId\n      hotelName\n      bookingFlowId\n      rateMessage\n      bookingReference\n      basketReference\n    }\n  }\n"])));