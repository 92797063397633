"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgBritishFlagRegular = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("rect", {
    x: -0.001,
    y: 4.003,
    width: 24,
    height: 16,
    rx: 1.714,
    fill: "#fff"
  }), (0, _jsxRuntime.jsx)("mask", {
    id: "british-flag-regular_svg__a",
    style: {
      maskType: 'alpha'
    },
    maskUnits: "userSpaceOnUse",
    x: -1,
    y: 4,
    width: 25,
    height: 17,
    children: (0, _jsxRuntime.jsx)("rect", {
      x: -0.001,
      y: 4.003,
      width: 24,
      height: 16,
      rx: 1.714,
      fill: "#fff"
    })
  }), (0, _jsxRuntime.jsxs)("g", {
    mask: "url(#british-flag-regular_svg__a)",
    children: [(0, _jsxRuntime.jsx)("path", {
      fill: "#0A17A7",
      d: "M-.001 4.003h24v16.134h-24z"
    }), (0, _jsxRuntime.jsx)("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M-1.1 2.457 9.142 8.96V2.928h5.715v6.031l10.242-6.502 1.278 1.784-8.098 5.14H24v5.379h-5.72l8.098 5.14-1.278 1.784-10.242-6.502v6.031H9.142v-6.031L-1.1 21.684-2.378 19.9l8.098-5.14H0V9.382h5.72L-2.378 4.24-1.1 2.457Z",
      fill: "#fff"
    }), (0, _jsxRuntime.jsx)("path", {
      d: "M16 9.112 26.856 2.39M17.153 15.054l9.732 6.173M6.861 9.094-3.29 2.655M7.962 14.979-3.29 22.002",
      stroke: "#DB1F35",
      strokeWidth: 0.571,
      strokeLinecap: "round"
    }), (0, _jsxRuntime.jsx)("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M-.001 13.684h10.286v6.454h3.428v-6.454H24v-3.227H13.713V4.003h-3.428v6.454H-.001v3.227Z",
      fill: "#E6273E"
    })]
  })]
}));
var _default = exports.default = SvgBritishFlagRegular;