"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserContextProvider = UserContextProvider;
exports.UserDataContext = void 0;
exports.useUserData = useUserData;
var _react = _interopRequireWildcard(require("react"));
var _getters = require("../getters");
var _helpers = require("../helpers");
var _hooks = require("../hooks");
var _noop = _interopRequireDefault(require("../utils/noop"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var UserDataContext = exports.UserDataContext = _react.default.createContext({
  isLoggedIn: false,
  setIsLoggedIn: _noop.default
});
function useUserData() {
  return (0, _react.useContext)(UserDataContext);
}
function UserContextProvider(_ref) {
  var _email;
  var {
    children
  } = _ref;
  var {
    country
  } = (0, _hooks.useCustomLocale)();
  var idTokenCookie = '';
  var email = '';
  idTokenCookie = (0, _getters.getAuthCookie)();
  ({
    email
  } = (0, _getters.decodeIdToken)(idTokenCookie));
  var defaultState = !!((_email = email) !== null && _email !== void 0 && _email.length);
  var [isLoggedIn, setIsLoggedIn] = (0, _react.useState)(defaultState);
  var value = (0, _react.useMemo)(() => ({
    isLoggedIn,
    setIsLoggedIn
  }), [isLoggedIn]);
  (0, _react.useEffect)(() => {
    var intervalId = setInterval(() => {
      var idToken = (0, _getters.getAuthCookie)();
      if (!idToken) {
        setIsLoggedIn(false);
      }
      if (idToken && !isLoggedIn) {
        var {
          email: userEmail
        } = (0, _getters.decodeIdToken)(idToken);
        if (userEmail !== null && userEmail !== void 0 && userEmail.length) {
          setIsLoggedIn(true);
        }
      } else if (idToken && isLoggedIn) {
        var {
          exp: expData
        } = (0, _getters.decodeIdToken)(idToken);
        var currentTimeInSeconds = new Date().getTime() / 1000;
        if (expData && currentTimeInSeconds > expData) {
          (0, _helpers.deleteCookie)(_getters.ID_TOKEN_COOKIE);
        }
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [isLoggedIn, country]);
  return (0, _jsxRuntime.jsx)(UserDataContext.Provider, {
    value: value,
    children: children
  });
}