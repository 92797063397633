"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESERVATION_STATUS = exports.RESERVATION_SOURCE_PMS = exports.PurposeOfStayAnalytics = exports.PI_FAVICON = exports.MAX_NIGHTS_CCUI = exports.CITYTAX_PACKAGE = exports.CCUI_LOCALE_COOKIE = exports.BB_LIVE_ASSIST = exports.BB_CO_BROWSE = exports.AMEND_COOKIE_NAME = exports.AMEND_COOKIE_EXPIRY_MINUTES = void 0;
var CITYTAX_PACKAGE = exports.CITYTAX_PACKAGE = 'CITYTAX';
var CCUI_LOCALE_COOKIE = exports.CCUI_LOCALE_COOKIE = 'CCUI_LOCALE';
var RESERVATION_STATUS = exports.RESERVATION_STATUS = {
  CANCELLED: 'CANCELLED',
  UPCOMING: 'UPCOMING'
};
var RESERVATION_SOURCE_PMS = exports.RESERVATION_SOURCE_PMS = {
  OPERA: 'OPERA',
  BART: 'BART'
};
var PurposeOfStayAnalytics = exports.PurposeOfStayAnalytics = {
  LEISURE: 'leisure',
  BUSINESS: 'business'
};
var PI_FAVICON = exports.PI_FAVICON = '/content/dam/pi/websites/desktop/icons/favicons/favicon.ico';
var MAX_NIGHTS_CCUI = exports.MAX_NIGHTS_CCUI = 364;
var AMEND_COOKIE_NAME = exports.AMEND_COOKIE_NAME = 'bb-amend';
var AMEND_COOKIE_EXPIRY_MINUTES = exports.AMEND_COOKIE_EXPIRY_MINUTES = 30;
var BB_LIVE_ASSIST = exports.BB_LIVE_ASSIST = '/etc/clientlibs/pi/resources/js/live-assist.js';
var BB_CO_BROWSE = exports.BB_CO_BROWSE = '/etc/clientlibs/pi/resources/js/co-browse.js';