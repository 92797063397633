"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgDoubleBed32 = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 28,
  height: 18,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M25 18h-1l-.5-2h-19L4 18H3l-.5-2H0V9.667c0-.843.39-1.594 1-2.082V1.333C1 .597 1.597 0 2.333 0h23.334C26.403 0 27 .597 27 1.333v6.253c.61.488 1 1.239 1 2.08V16h-2.5l-.5 2Zm.333-10H2.667c-.872 0-1.588.67-1.66 1.523L1 9.667V15h26V9.667c0-.872-.67-1.588-1.523-1.66L25.333 8Zm.334-7H2.333a.333.333 0 0 0-.326.266L2 1.333V7h1V4.333C3 3.597 3.597 3 4.333 3h7.334C12.403 3 13 3.597 13 4.333V7h2V4.333C15 3.597 15.597 3 16.333 3h7.334C24.403 3 25 3.597 25 4.333V7h1V1.333a.333.333 0 0 0-.266-.326L25.667 1Zm-14 3H4.333a.333.333 0 0 0-.326.266L4 4.333V7h8V4.333a.333.333 0 0 0-.266-.326L11.667 4Zm12 0h-7.334a.333.333 0 0 0-.326.266L16 4.333V7h8V4.333a.333.333 0 0 0-.266-.326L23.667 4Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgDoubleBed32;