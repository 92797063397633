"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserType = exports.PaymentType = exports.PaymentStatus = exports.Channel = exports.CardStatus = exports.BartBookingChannel = void 0;
var BartBookingChannel = exports.BartBookingChannel = function (BartBookingChannel) {
  BartBookingChannel["Cbt"] = "CBT";
  BartBookingChannel["Mobile"] = "MOBILE";
  BartBookingChannel["Web"] = "WEB";
  BartBookingChannel["WebDe"] = "WEB_DE";
  return BartBookingChannel;
}({});
var CardStatus = exports.CardStatus = function (CardStatus) {
  CardStatus["Cancelled"] = "CANCELLED";
  CardStatus["Current"] = "CURRENT";
  CardStatus["NotActivated"] = "NOT_ACTIVATED";
  CardStatus["Pending"] = "PENDING";
  CardStatus["StopHot"] = "STOP_HOT";
  return CardStatus;
}({});
var Channel = exports.Channel = function (Channel) {
  Channel["Bb"] = "BB";
  Channel["Ccui"] = "CCUI";
  Channel["Distr"] = "DISTR";
  Channel["Employee"] = "EMPLOYEE";
  Channel["Pi"] = "PI";
  return Channel;
}({});
var PaymentStatus = exports.PaymentStatus = function (PaymentStatus) {
  PaymentStatus["NotRequired"] = "NOT_REQUIRED";
  PaymentStatus["PaymentRequired"] = "PAYMENT_REQUIRED";
  return PaymentStatus;
}({});
var PaymentType = exports.PaymentType = function (PaymentType) {
  PaymentType["AccountCompany"] = "ACCOUNT_COMPANY";
  PaymentType["Piba"] = "PIBA";
  PaymentType["ReserveWithoutCard"] = "RESERVE_WITHOUT_CARD";
  return PaymentType;
}({});
var UserType = exports.UserType = function (UserType) {
  UserType["Agent"] = "AGENT";
  UserType["Business"] = "BUSINESS";
  UserType["Leisure"] = "LEISURE";
  return UserType;
}({});