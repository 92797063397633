"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _molecules = require("@whitbread-eos/molecules");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PreCheckInFormBookingDetails(_ref) {
  var {
    control,
    formField,
    errors,
    getValues
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, boxStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headingStyles), {}, {
      children: t('precheckin.bookingdetails')
    })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, subHeadingStyles), {}, {
      children: t('precheckin.bookingdetails.description')
    })), (0, _jsxRuntime.jsx)(_molecules.BookingDetails, {
      formField: formField,
      control: control,
      errors: errors,
      getValues: getValues
    })]
  }));
}
var _default = exports.default = PreCheckInFormBookingDetails;
var boxStyle = {
  pos: 'relative',
  mt: '2xl',
  pb: '10em'
};
var subHeadingStyles = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '2',
  color: 'darkGrey2',
  mt: 'lg'
};
var headingStyles = {
  fontFamily: 'header',
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '3',
  mb: '2xl',
  color: 'darkGrey1'
};