"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AgentMemo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AgentMemo() {
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    isAgentMemoOpen,
    closeAgentMemo,
    agentMemoState: {
      reservationId,
      variant
    },
    agentMemoData,
    agentMemoCount,
    user
  } = (0, _utils.useAgentMemo)();
  var isPageVariant = variant === _api.MemoModalVariants.PAGE;
  var isCardVariant = variant === _api.MemoModalVariants.CARD;
  var baseDataTestId = 'AgentMemo';
  var [showAddMemo, setShowAddMemo] = (0, _react2.useState)(false);
  var handleCloseAgentMemo = () => {
    setShowAddMemo(false);
    closeAgentMemo();
  };
  var addMemo = extraStyles => {
    return showAddMemo ? (0, _jsxRuntime.jsx)(_molecules.AddMemo, {
      basketReference: reservationId,
      user: user,
      onSave: () => setShowAddMemo(false)
    }) : (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      size: "full",
      variant: "tertiary",
      onClick: () => setShowAddMemo(true),
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AddMemoButton')
    }, extraStyles || {}), {}, {
      children: t('ccui.agentMemo.addMemo')
    }));
  };
  var content = data => {
    if (data !== null && data !== void 0 && data.memos) {
      return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, contentStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Content'),
        children: data.memos.map(memo => (0, _jsxRuntime.jsx)(_molecules.AgentMemoCard, {
          memo: memo
        }, "AgentMemoCard-".concat(memo.ids[0].memoIds.join(''))))
      }));
    }
    return null;
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [isPageVariant && (0, _jsxRuntime.jsxs)(_react.Drawer, {
      isOpen: isAgentMemoOpen,
      placement: "right",
      size: "md",
      autoFocus: false,
      preserveScrollBarGap: true,
      returnFocusOnClose: false,
      trapFocus: false,
      onClose: handleCloseAgentMemo,
      children: [(0, _jsxRuntime.jsx)(_react.DrawerOverlay, {}), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'PageVariant')
      }, dialogContentStyle), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.DrawerContent, _objectSpread(_objectSpread({}, drawerContentStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.DrawerCloseButton, {}), (0, _jsxRuntime.jsx)(_react.DrawerHeader, {
            children: agentMemoCount ? t('ccui.agentMemo.agentMemoCount', {
              count: agentMemoCount
            }) : t('ccui.agentMemo.agentMemo')
          }), (0, _jsxRuntime.jsxs)(_react.DrawerBody, {
            children: [addMemo(), content(agentMemoData)]
          })]
        }))
      }))]
    }), isCardVariant && (0, _jsxRuntime.jsxs)(_react.Modal, {
      preserveScrollBarGap: true,
      isCentered: false,
      scrollBehavior: "inside",
      isOpen: isAgentMemoOpen,
      onClose: handleCloseAgentMemo,
      size: "md",
      children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {}), (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CardVariant')
      }, modalContentStyle), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.ModalHeader, _objectSpread(_objectSpread({}, modalHeaderStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CardVariant-ModalHeader'),
          children: [agentMemoCount ? t('ccui.agentMemo.agentMemoCount', {
            count: agentMemoCount
          }) : t('ccui.agentMemo.agentMemo'), (0, _jsxRuntime.jsx)(_react.ModalCloseButton, _objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CardVariant-ModalCloseButton')
          }, modalCloseButtonStyle))]
        })), (0, _jsxRuntime.jsxs)(_react.ModalBody, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CardVariant-ModalBody')
        }, modalBodyStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Box, {
            overflow: "auto",
            maxH: "calc(100vh - 4rem)",
            children: addMemo(addMemoButtonStyle)
          }), content(agentMemoData)]
        }))]
      }))]
    })]
  });
}
var dialogContentStyle = {
  position: 'absolute',
  top: '72px'
};
var drawerContentStyle = {
  pb: 0
};
var modalBodyStyle = {
  overflow: 'auto',
  my: 'var(--chakra-space-3xl)',
  p: 0
};
var modalContentStyle = {
  position: 'absolute',
  top: '46px',
  maxW: 'auto',
  maxH: '100vh',
  overflow: 'auto',
  width: {
    sm: 'var(--chakra-space-breakpoint-sm)'
  },
  height: {
    sm: 'auto'
  },
  borderRadius: {
    sm: 0
  },
  boxShadow: '0 0.3rem 0.3rem 0 var(--chakra-colors-darkGrey2)',
  my: 0,
  p: 'var(--chakra-space-lg)',
  pb: 0
};
var modalHeaderStyle = {
  minH: 'var(--chakra-space-xxl)',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  p: 0,
  fontWeight: 'var(--chakra-fontWeights-bold)'
};
var addMemoButtonStyle = {
  my: 0
};
var modalCloseButtonStyle = {
  h: 'var(--chakra-space-xl)',
  w: 'var(--chakra-space-xl)',
  position: 'static',
  alignSelf: 'flex-end',
  fontSize: 'xs',
  lineHeight: '3',
  fontWeight: 'var(--chakra-fontWeights-normal)',
  color: 'var(--chakra-colors-darkGrey1)',
  _focus: {
    boxShadow: 'none'
  },
  _hover: {
    bgColor: 'transparent'
  },
  _active: {
    bgColor: 'transparent'
  }
};
var contentStyle = {
  marginTop: '3xl'
};