"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SR_FORMAT = exports.PROMO_CODE_COOKIE = void 0;
var SR_FORMAT = exports.SR_FORMAT = {
  LATLONG: 'LATLONG',
  MANAGEDPLACEID: 'MANAGEDPLACEID',
  PLACEID: 'PLACEID',
  EN_DIST: '40',
  DIST: '50'
};
var PROMO_CODE_COOKIE = exports.PROMO_CODE_COOKIE = 'activePromotionCode';