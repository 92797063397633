"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgChargedParkingGb = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 20,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M19 0a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h18Zm0 1H1v18h18V1ZM7.5 4c2.61 0 4 1.39 4 4 0 2.53-1.307 3.915-3.766 3.996L7.5 12H5v5H4V4h3.5Zm7.35 5.793c.619 0 1.109.172 1.47.518.321.306.5.704.535 1.194l.007.187h-.933l-.006-.144a1.047 1.047 0 0 0-.304-.68c-.207-.203-.49-.303-.852-.303-.342 0-.618.125-.83.375-.181.215-.285.488-.31.817l-.007.17.044 1.27h1.557v.766h-1.533l.04 1.074-.007.209c-.02.337-.093.622-.216.854l-.08.133h3.578L17 17h-5.04v-.767h.377l.063-.022c.101-.05.186-.164.254-.341.065-.17.106-.373.123-.607l.009-.182-.04-1.118h-.805v-.767h.781l-.044-1.27.008-.21c.04-.55.236-.998.59-1.342.4-.387.924-.581 1.575-.581ZM7.5 5H5v6h2.5c2.057 0 3-.943 3-3 0-1.986-.879-2.934-2.791-2.997L7.5 5Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgChargedParkingGb;