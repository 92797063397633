"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPlace = void 0;
var _api = require("@whitbread-eos/api");
var _getters = require("./getters");
var getPlace = (multiSearchParams, language) => {
  var _multiSearchParams$co;
  var place = {
    location: multiSearchParams !== null && multiSearchParams !== void 0 && multiSearchParams.placeId ? multiSearchParams.placeId : (_multiSearchParams$co = multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.coordinates) !== null && _multiSearchParams$co !== void 0 ? _multiSearchParams$co : '',
    locationFormat: multiSearchParams !== null && multiSearchParams !== void 0 && multiSearchParams.placeId ? 'PLACEID' : 'LATLONG',
    radius: language === _api.LanguageEnum.ENGLISH ? 30 : 50,
    radiusUnit: (0, _getters.getDistanceUnitBasedOnLocale)(language !== null && language !== void 0 ? language : _api.LanguageEnum.ENGLISH)
  };
  return place;
};
exports.getPlace = getPlace;