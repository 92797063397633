"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = MealItem;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function MealItem(_ref) {
  var {
    currency,
    numberNights,
    description,
    imageUrl,
    freeBreakfastOption,
    price,
    title,
    allergyInfoSrc,
    totalPrice,
    allergyInfoLabel,
    prefixDataTestId,
    controller,
    currentLanguage = 'en',
    isForEntireStay = false,
    showFreeFoodKids,
    hasPromoMeal = false
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  return (0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, wrapperMealItemStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerImageStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Image-Wrapper'),
      children: imageUrl && (0, _jsxRuntime.jsx)(_image.default, {
        fill: true,
        style: {
          objectFit: 'cover'
        },
        alt: t('upsell.menus.iconAltText'),
        src: imageUrl,
        loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
      })
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerDescriptionStyle), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
        flexDir: "column",
        lineHeight: "2",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Title'),
          children: title
        })), !hasPromoMeal && price && numberNights && currency && totalPrice && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTextStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Price'),
          children: renderPrice(currentLanguage, currency, price, totalPrice, numberNights)
        })), hasPromoMeal && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, priceTextStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Price'),
          children: t('config.experiments.breakfastPromoCode.priceText')
        })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, descriptionTextStyle), {}, {
          pt: "xs",
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Description'),
          dangerouslySetInnerHTML: {
            __html: (0, _utils.formatInnerHTMLAssetUrls)(description !== null && description !== void 0 ? description : '')
          },
          className: "formatLinks"
        }))]
      }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerInfoStyle), {}, {
        children: [freeBreakfastOption && showFreeFoodKids && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, freeFoodKidsStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'FreeFoodKids'),
          children: t('upsell.label.promoText.freeKidsMeal')
        })), hasPromoMeal && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, freeFoodKidsStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'FreeBreakfastPromo'),
          children: t('config.experiments.breakfastPromoCode.promotionText')
        })), allergyInfoSrc && allergyInfoLabel && (0, _jsxRuntime.jsx)(_react.Link, {
          href: allergyInfoSrc,
          isExternal: true,
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, allergyTextStyle(freeBreakfastOption)), {}, {
            "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Allergy'),
            children: allergyInfoLabel
          }))
        })]
      }))]
    })), (0, _jsxRuntime.jsx)(_react.Box, {
      py: "md",
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, delimiterStyle))
    }), (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, controllerContainerStyle), {}, {
      children: controller
    }))]
  }));
  function renderPrice(currentLanguage, currency, price, totalPrice, numberNights) {
    var formattedCurrency = (0, _utils.formatCurrency)(currency);
    var nightLabel = numberNights === 1 ? t('upsell.label.night') : t('upsell.label.nights');
    var pricePerNight = (0, _utils.formatPrice)(formattedCurrency, price.toFixed(2), currentLanguage);
    var totalPricePerStay = (0, _utils.formatPrice)(formattedCurrency, totalPrice.toFixed(2), currentLanguage);
    if (isForEntireStay) {
      return "".concat(pricePerNight, " ").concat(t('upsell.label.per.adult'), "/").concat(t('upsell.label.day'), " (").concat(totalPricePerStay, " ").concat(t('upsell.label.for'), " ").concat(numberNights, " ").concat(nightLabel, " ").concat(t('upsell.label.for'), " ").concat(t('upsell.label.all'), " ").concat(t('hoteldetails.bookingsummary.rooms'), ")");
    } else {
      return "".concat(pricePerNight, " ").concat(t('upsell.label.per.adult'), "/").concat(t('upsell.label.day'), " (").concat(totalPricePerStay, " ").concat(t('upsell.label.for'), " ").concat(numberNights, " ").concat(nightLabel, ")");
    }
  }
}
var allergyTextStyle = freeBreakfastOption => {
  return {
    textDecoration: 'underline',
    lineHeight: '2',
    color: 'btnSecondaryEnabled',
    fontWeight: 'normal',
    fontSize: 'sm',
    pt: {
      mobile: freeBreakfastOption ? '' : 'sm',
      md: 0
    }
  };
};
var containerInfoStyle = {
  direction: {
    mobile: 'column',
    md: 'row'
  },
  align: {
    mobile: 'flex-start',
    md: 'center'
  },
  mt: {
    mobile: 'sm',
    xs: 'sm',
    sm: 'sm',
    md: 'md'
  },
  justify: 'flex-start',
  flexWrap: 'wrap',
  rowGap: 'sm',
  columnGap: 'md'
};
var containerDescriptionStyle = {
  pl: {
    mobile: 0,
    md: 'lg'
  },
  pr: {
    mobile: 0,
    md: '3xl'
  },
  flexDir: 'column',
  justifyContent: 'space-between'
};
var freeFoodKidsStyle = {
  bgColor: 'tooltipInfo',
  fontSize: 'xs',
  lineHeight: '2',
  color: 'darkGrey1',
  fontWeight: 'medium',
  px: 'sm',
  py: 'xs'
};
var descriptionTextStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  color: 'darkGrey2'
};
var priceTextStyle = _objectSpread(_objectSpread({}, descriptionTextStyle), {}, {
  pt: {
    mobile: 0,
    xs: 'xs'
  },
  fontWeight: 'semibold',
  color: 'darkGrey1'
});
var titleStyle = {
  as: 'h4',
  pt: {
    mobile: 'md',
    md: '0'
  },
  fontSize: 'xl',
  lineHeight: '3',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var controllerContainerStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  pl: {
    mobile: 0,
    md: 'md'
  }
};
var containerImageStyle = {
  w: {
    mobile: '100%',
    md: '9.875rem'
  },
  h: {
    mobile: '8.75rem',
    xs: '10.938rem',
    sm: '17.875rem',
    md: '8.25rem'
  },
  pos: 'relative'
};
var delimiterStyle = {
  w: {
    mobile: 'full',
    md: '1px'
  },
  h: {
    mobile: '1px',
    md: 'full'
  },
  bgColor: 'var(--chakra-colors-lightGrey3)',
  alignSelf: 'center'
};
var wrapperMealItemStyle = {
  templateColumns: {
    mobile: 'auto',
    md: 'auto 1fr auto auto'
  },
  templateRows: {
    mobile: 'auto 1fr auto auto',
    md: 'auto '
  },
  border: '1px solid var(--chakra-colors-lightGrey3)',
  p: 'md'
};