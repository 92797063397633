"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hasValidCharacters = hasValidCharacters;
exports.isAlphabetic = isAlphabetic;
exports.isDateValid = isDateValid;
exports.isEmailValid = isEmailValid;
exports.isJsonValid = isJsonValid;
exports.isNumber = isNumber;
exports.isSameDate = isSameDate;
exports.isStringValid = isStringValid;
exports.isUrl = isUrl;
exports.logicalAndOperator = logicalAndOperator;
exports.logicalOrOperator = logicalOrOperator;
exports.ternaryCondition = ternaryCondition;
var _parse = _interopRequireDefault(require("date-fns/parse"));
function isStringValid(item) {
  return item !== null && item !== undefined && item !== '';
}
function isDateValid(day, month, year) {
  var date = (0, _parse.default)("".concat(day, " ").concat(month, " ").concat(year), 'dd MM yyyy', new Date());
  return !isNaN(date.getTime());
}
function isSameDate(firstDate, secondDate) {
  if (!firstDate || !secondDate) return false;
  return firstDate.getDate() === secondDate.getDate() && firstDate.getMonth() === secondDate.getMonth() && firstDate.getFullYear() === secondDate.getFullYear();
}
function isNumber(val) {
  return !isNaN(Number(val));
}
function isAlphabetic(val) {
  return /^[-A-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']+-?[\sA-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']*$/g.test(val);
}
function hasValidCharacters(val) {
  return /^[-A-Za-z0-9À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']+-?[\sA-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']*$/g.test(val);
}
function isJsonValid(str) {
  try {
    var obj = JSON.parse(str);
    return obj && typeof obj === 'object';
  } catch (e) {
    return false;
  }
}
function ternaryCondition(condition, exprIfTrue, exprIfFalse) {
  return condition ? exprIfTrue : exprIfFalse;
}
function logicalAndOperator() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  return args === null || args === void 0 ? void 0 : args.reduce((acc, current) => acc && current, true);
}
function logicalOrOperator() {
  for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    args[_key2] = arguments[_key2];
  }
  return args === null || args === void 0 ? void 0 : args.reduce((acc, current) => acc || current, false);
}
function isEmailValid(email) {
  var regex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  var isValidLength = email.length <= 50;
  return regex.test(email) && isValidLength;
}
function isUrl(url) {
  if (url) {
    try {
      return Boolean(new URL(url));
    } catch (_unused) {
      return false;
    }
  }
  return false;
}