"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AutocompleteLocation;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _react2 = require("react");
var _icons = require("../../assets/icons");
var _Autocomplete = _interopRequireDefault(require("../Autocomplete"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AutocompleteLocation(_ref) {
  var {
    items,
    inputSelectedValue,
    hasClearIcon,
    hasListDivider,
    openListOnFocus,
    disableInternalFilter,
    isRequired,
    onChange,
    onSelectOption,
    onInputChange,
    onClearInput,
    onBlurInput,
    onFocusInput,
    autocompleteStyles,
    inputPlaceholder,
    dataTestId,
    showElements
  } = _ref;
  var [inputHasFocus, setInputHasFocus] = (0, _react2.useState)(false);
  var {
    inputStyles,
    wrapperStyles
  } = getAutocompleteLocationStyles(autocompleteStyles);
  var autoCompleteProps = {
    items,
    onChange,
    onSelectOption,
    onInputChange,
    onBlurInput,
    onFocusInput,
    setInputHasFocus,
    inputPlaceholder,
    openListOnFocus,
    disableInternalFilter,
    isRequired,
    hasListDivider,
    icons: {
      left: showElements ? () => (0, _jsxRuntime.jsx)(_icons.Location, {
        color: "var(--chakra-colors-darkGrey1)"
      }) : null,
      right: props => inputHasFocus ? (0, _jsxRuntime.jsx)(ClearInputIcon, _objectSpread({}, props)) : null
    },
    inputStyles,
    wrapperStyles,
    autocompleteStyles,
    inputSelectedValue,
    dataTestId,
    showElements
  };
  return (0, _jsxRuntime.jsx)(_Autocomplete.default, _objectSpread({}, autoCompleteProps));
  function ClearInputIcon(_ref2) {
    var {
      autocompleteRef,
      autocompleteInputRef
    } = _ref2;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      bg: hasClearIcon && inputHasFocus ? 'lightGrey2' : 'inherit',
      borderRadius: "50%",
      p: "xs",
      cursor: "pointer",
      "aria-label": "clear-icon",
      "data-testid": "".concat(dataTestId, "-clearLocationButton"),
      onMouseDown: event => {
        var _autocompleteRef$curr, _autocompleteRef$curr2, _autocompleteInputRef, _autocompleteInputRef2;
        event.preventDefault();
        autocompleteRef === null || autocompleteRef === void 0 || (_autocompleteRef$curr = autocompleteRef.current) === null || _autocompleteRef$curr === void 0 || _autocompleteRef$curr.resetItems();
        autocompleteRef === null || autocompleteRef === void 0 || (_autocompleteRef$curr2 = autocompleteRef.current) === null || _autocompleteRef$curr2 === void 0 || _autocompleteRef$curr2.removeItem(autocompleteInputRef === null || autocompleteInputRef === void 0 || (_autocompleteInputRef = autocompleteInputRef.current) === null || _autocompleteInputRef === void 0 ? void 0 : _autocompleteInputRef.value);
        (_autocompleteInputRef2 = autocompleteInputRef.current) === null || _autocompleteInputRef2 === void 0 || _autocompleteInputRef2.focus();
        onClearInput && onClearInput();
      },
      children: (0, _jsxRuntime.jsx)(_react.Box, {
        as: "span",
        display: "".concat(hasClearIcon && inputHasFocus ? 'initial' : 'none'),
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          style: {
            transform: 'scale(0.7)'
          },
          svg: (0, _jsxRuntime.jsx)(_icons.Dismiss, {})
        })
      })
    }, "clear-input-icon");
  }
}
var getAutocompleteLocationStyles = autocompleteStyles => {
  var inputStyles = _objectSpread(_objectSpread({
    variant: 'unstyled',
    textOverflow: 'ellipsis',
    py: 'md',
    border: '1px solid transparent',
    cursor: 'pointer',
    _hover: {
      border: '1px solid var(--chakra-colors-darkGrey1)',
      borderRadius: '4px'
    },
    _focus: {
      border: '2px solid var(--chakra-colors-primary)',
      cursor: 'auto',
      borderRadius: '4px'
    },
    _placeholder: {
      color: 'var(--chakra-colors-darkGrey2)'
    }
  }, autocompleteStyles === null || autocompleteStyles === void 0 ? void 0 : autocompleteStyles.inputElementStyles), autocompleteStyles === null || autocompleteStyles === void 0 ? void 0 : autocompleteStyles.errorInputElementStyles);
  var wrapperStyles = _objectSpread(_objectSpread({
    w: '50%'
  }, autocompleteStyles === null || autocompleteStyles === void 0 ? void 0 : autocompleteStyles.inputGroupStyles), autocompleteStyles === null || autocompleteStyles === void 0 ? void 0 : autocompleteStyles.errorInputGroupStyles);
  return {
    inputStyles,
    wrapperStyles
  };
};