"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Autocomplete;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _chakraAutocomplete = require("@choc-ui/chakra-autocomplete");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var NOT_GROUPED_KEY = 'notGrouped';
function Autocomplete(_ref) {
  var {
    items,
    inputSelectedValue,
    inputPlaceholder,
    multiSelectable = false,
    openListOnFocus = true,
    disableInternalFilter = false,
    isRequired = false,
    hasListDivider = true,
    wrapperStyles,
    icons = {},
    inputStyles,
    dataTestId,
    onChange,
    onSelectOption,
    onInputChange,
    onBlurInput,
    onFocusInput,
    setInputHasFocus,
    showElements = true
  } = _ref;
  var ref = (0, _react2.useRef)();
  var inputRef = (0, _react2.useRef)(null);
  var autocompleteListRef = (0, _react2.useRef)(null);
  var groupedItems = items.reduce((acc, cur) => {
    var {
      group = NOT_GROUPED_KEY
    } = cur;
    acc[group] = acc[group] || [];
    acc[group].push(cur);
    return acc;
  }, {});
  var handleSelectOption = params => {
    onSelectOption === null || onSelectOption === void 0 || onSelectOption(params.item.value);
  };
  return (0, _jsxRuntime.jsx)(_react.Box, {
    as: "span",
    children: (0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoComplete, {
      ref: ref,
      openOnFocus: openListOnFocus,
      disableFilter: disableInternalFilter,
      multiple: multiSelectable,
      onChange: onChange,
      onSelectOption: handleSelectOption,
      style: {
        height: '100%'
      },
      children: autocompleteState => {
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.InputGroup, _objectSpread(_objectSpread({
            "data-testid": "".concat(dataTestId, "-inputGroup")
          }, wrapperStyles), {}, {
            children: multiSelectable ? renderMultipleInput(inputPlaceholder) : renderSimpleInput(autocompleteState, inputPlaceholder, dataTestId)
          })), Object.entries(groupedItems).length > 0 && (0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoCompleteList, {
            style: autocompleteListStyles,
            w: autocompleteListWidth,
            ref: autocompleteListRef,
            "data-testid": "".concat(dataTestId, "-autocompleteList"),
            children: Object.entries(groupedItems).map((_ref2, id) => {
              var [key, values] = _ref2;
              if (key === NOT_GROUPED_KEY) {
                return values.map((v, id) => renderItem(id, v));
              } else {
                return renderItemGroup(key, values, id);
              }
            })
          })]
        });
      }
    })
  });
  function renderItemGroup(key, values, id) {
    return (0, _jsxRuntime.jsxs)(_chakraAutocomplete.AutoCompleteGroup, {
      showDivider: hasListDivider,
      "data-testid": "".concat(dataTestId, "-autocompleteGroup"),
      children: [showElements && (0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoCompleteGroupTitle, _objectSpread(_objectSpread({
        "data-testid": "".concat(dataTestId, "-hotelsLabel")
      }, autocompleteGroupTitleStyles), {}, {
        children: key
      })), values.map((v, id) => renderItem(id, v))]
    }, id);
  }
  function renderItem(key, item) {
    return (0, _jsxRuntime.jsxs)(_chakraAutocomplete.AutoCompleteItem, _objectSpread(_objectSpread({
      value: item.value
    }, autocompleteListItemStyles), {}, {
      children: [item.component, (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        as: "span"
      }, autocompleteItemValueStyles), {}, {
        children: item.value
      }))]
    }), key);
  }
  function renderMultipleInput(inputPlaceholder) {
    return (0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoCompleteInput, _objectSpread(_objectSpread({}, inputStyles), {}, {
      placeholder: inputPlaceholder,
      "data-testid": "".concat(dataTestId, "-locationPlaceholder"),
      isRequired: isRequired,
      value: inputSelectedValue,
      onChange: event => {
        var _event$target;
        onInputChange && onInputChange(event === null || event === void 0 || (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.value);
      },
      onFocus: () => {
        onFocusInput === null || onFocusInput === void 0 || onFocusInput();
        setInputHasFocus === null || setInputHasFocus === void 0 || setInputHasFocus(true);
      },
      onBlur: () => {
        setInputHasFocus === null || setInputHasFocus === void 0 || setInputHasFocus(true);
      },
      children: _ref3 => {
        var {
          tags
        } = _ref3;
        return tags.map((tag, index) => (0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoCompleteTag, {
          label: tag.label,
          onRemove: tag.onRemove
        }, "".concat(tag.label, "-").concat(index)));
      }
    }));
  }
  function renderSimpleInput(autocompleteState, inputPlaceholder, dataTestId) {
    var createIcon = (icon, showLeftIcon) => {
      if (!icon || (0, _utils.logicalAndOperator)(icon === null, !showLeftIcon)) {
        return null;
      }
      var PrimaryIcon = icon;
      return (0, _jsxRuntime.jsx)(PrimaryIcon, _objectSpread(_objectSpread({}, autocompleteState), {}, {
        inputPlaceholder: inputPlaceholder,
        autocompleteRef: ref,
        autocompleteInputRef: inputRef
      }));
    };
    var leftIcon = createIcon(icons === null || icons === void 0 ? void 0 : icons.left, showElements);
    var rightIcon = createIcon(icons === null || icons === void 0 ? void 0 : icons.right);
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_chakraAutocomplete.AutoCompleteInput, _objectSpread(_objectSpread(_objectSpread({
        ref: inputRef
      }, renderStyles()), {}, {
        placeholder: inputPlaceholder,
        "data-testid": "".concat(dataTestId, "-locationPlaceholder"),
        isRequired: isRequired
      }, inputSelectedValue === undefined ? {} : {
        value: inputSelectedValue
      }), {}, {
        onChange: event => {
          var _event$target2;
          onInputChange === null || onInputChange === void 0 || onInputChange(event === null || event === void 0 || (_event$target2 = event.target) === null || _event$target2 === void 0 ? void 0 : _event$target2.value);
        },
        onFocus: () => {
          onFocusInput === null || onFocusInput === void 0 || onFocusInput();
          setInputHasFocus === null || setInputHasFocus === void 0 || setInputHasFocus(true);
        },
        onBlur: event => {
          var _inputRef$current, _inputRef$current2, _event$relatedTarget, _inputRef$current3;
          setInputHasFocus === null || setInputHasFocus === void 0 || setInputHasFocus(false);
          var list = Object.entries(groupedItems);
          if (inputRef !== null && inputRef !== void 0 && (_inputRef$current = inputRef.current) !== null && _inputRef$current !== void 0 && _inputRef$current.value && (inputRef === null || inputRef === void 0 || (_inputRef$current2 = inputRef.current) === null || _inputRef$current2 === void 0 || (_inputRef$current2 = _inputRef$current2.value) === null || _inputRef$current2 === void 0 ? void 0 : _inputRef$current2.length) >= 3 && list !== null && list !== void 0 && list.length && ((_event$relatedTarget = event.relatedTarget) === null || _event$relatedTarget === void 0 || (_event$relatedTarget = _event$relatedTarget.parentNode) === null || _event$relatedTarget === void 0 ? void 0 : _event$relatedTarget.parentNode) !== (inputRef === null || inputRef === void 0 || (_inputRef$current3 = inputRef.current) === null || _inputRef$current3 === void 0 || (_inputRef$current3 = _inputRef$current3.parentNode) === null || _inputRef$current3 === void 0 ? void 0 : _inputRef$current3.parentNode)) {
            var [, values] = list[0];
            if (values !== null && values !== void 0 && values.length) {
              var item = values[0];
              onBlurInput === null || onBlurInput === void 0 || onBlurInput(item.value);
            }
          }
        }
      })), leftIcon !== null && (0, _jsxRuntime.jsx)(_react.InputLeftElement, {
        top: "50%",
        transform: "auto",
        translateY: "-50%",
        children: leftIcon
      }), rightIcon && (0, _jsxRuntime.jsx)(_react.InputRightElement, {
        top: "50%",
        transform: "auto",
        translateY: "-50%",
        "data-testid": "".concat(dataTestId, "-inputRight"),
        children: rightIcon
      })]
    });
  }
  function renderStyles() {
    return _objectSpread(_objectSpread({}, icons !== null && icons !== void 0 && icons.left ? {
      pl: '2xl'
    } : {}), inputStyles);
  }
}
var autocompleteListWidth = {
  mobile: '18rem',
  xs: '21.4375rem',
  sm: '23.375rem',
  lg: '25.25rem',
  xl: '26.25rem'
};
var autocompleteListStyles = {
  border: '1px solid var(--chakra-colors-lightGrey3)',
  borderRadius: 'radiusSmall',
  boxShadow: '0 2px var(--chakra-space-xmd) var(--chakra-colors-lightGrey2)',
  marginTop: 0,
  maxHeight: '28.75rem',
  overflow: 'hidden',
  py: 'sm'
};
var autocompleteGroupTitleStyles = {
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  margin: 'var(--chakra-space-xmd) 0 var(--chakra-space-sm) var(--chakra-space-md)',
  color: 'darkGrey1',
  textTransform: 'capitalize'
};
var autocompleteListItemStyles = {
  h: 'var(--chakra-space-2xl)',
  fontSize: 'sm',
  lineHeight: '1',
  fontWeight: 'normal',
  color: 'darkGrey1',
  mx: 0,
  p: 'var(--chakra-space-xmd) var(--chakra-space-md)',
  borderRadius: 0,
  bg: 'var(--chakra-colors-baseWhite) !important',
  _hover: {
    bg: 'var(--chakra-colors-lightGrey5) !important'
  }
};
var autocompleteItemValueStyles = {
  display: 'block',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};