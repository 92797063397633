"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateConfirmationPageAnalytics = exports.updateConfirmationAnalytics = exports.default = exports.analyticsConfirmationPageName = void 0;
var _api = require("@whitbread-eos/api");
var _dateFns = require("date-fns");
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _getters = require("../../getters");
var _selectors = require("../../selectors");
var _analytics = _interopRequireWildcard(require("./analytics"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var analyticsConfirmationPageName = exports.analyticsConfirmationPageName = 'booking confirmation';
var initConfirmationAnalytics = bookingFlowComplete => {
  var {
    paymentSessionID,
    paymentTakenNow,
    paymentTemplateID,
    productSelectedRate,
    bookingReasonForStay,
    productDetails,
    browserTimeZone,
    cardType,
    pageName,
    validation,
    upsells
  } = window.analyticsData || {};
  var {
    bookingId
  } = window.analyticsDataCartConfirmation || {};
  _analytics.analyticsConfirmation.update({
    BusinessAccountCardExtrasUpsells: {},
    CartItems: productDetails,
    InvoiceType: 'Email',
    bookingCity: browserTimeZone,
    userisSubscribed: false,
    typeOfTrip: bookingReasonForStay,
    paymentTakenNow,
    productSelectedRate,
    businessAccQuestions: '',
    userDefinedQuestions: '',
    bookingId: bookingId || '',
    bookingPanelComponentsOrder: '',
    contentComponentOrder: '',
    bookingZipCode: '',
    paymentSessionID: '',
    paymentTemplateID: '',
    price: {
      cartTotal: {
        amount: '',
        currency: ''
      },
      currency: '',
      voucherCode: '',
      voucherDiscount: ''
    },
    cardType: '',
    validation: ''
  });
  if (cardType) {
    _analytics.analyticsConfirmation.update({
      cardType
    });
  }
  if (paymentTemplateID) {
    _analytics.analyticsConfirmation.update({
      paymentTemplateID
    });
  }
  if (paymentSessionID) {
    _analytics.analyticsConfirmation.update({
      paymentSessionID
    });
  }
  if (validation) {
    _analytics.analyticsConfirmation.update({
      validation
    });
  }
  if (productDetails && productDetails.length == 1) {
    var _productDetails$0$pro;
    var additionalCartItems = [];
    var baseCartPrice = productDetails[0].price.basePrice;
    var totalNumberOfRooms = (_productDetails$0$pro = productDetails[0].productInfo.totalNumberOfRooms) !== null && _productDetails$0$pro !== void 0 ? _productDetails$0$pro : '1';
    additionalCartItems.push({
      type: _analytics.analyticsTrackings.DATE,
      quantity: +totalNumberOfRooms,
      price: {
        basePrice: baseCartPrice
      },
      productInfo: {
        sku: productDetails[0].productInfo.startDate
      }
    });
    if (upsells && upsells.rooms.length > 0 && upsells.rooms[0].length > 0) {
      var _productDetails$0$pro2;
      var totalNumberOfNights = (_productDetails$0$pro2 = productDetails[0].productInfo.numberOfNights) !== null && _productDetails$0$pro2 !== void 0 ? _productDetails$0$pro2 : '1';
      var foodRatePlans = [];
      var foodPerRoom = [];
      var totalFoodPrice = 0.0;
      var totalFoodQuantity = 0;
      var currency;
      upsells.rooms.forEach((roomUpsells, roomIndex) => {
        currency = roomUpsells[0].currency;
        var foodPerRoomCodes = [];
        roomUpsells.forEach(roomUpsell => {
          foodRatePlans.push(getFoodRatePlan(roomUpsell, totalNumberOfNights, currency));
          if (roomUpsell.quantity > 0) {
            var pricePerRoomCode = +roomUpsell.price * roomUpsell.quantity * +totalNumberOfNights;
            foodPerRoomCodes.push(getFoodPerRoomCode(roomUpsell, pricePerRoomCode, currency));
            totalFoodPrice += pricePerRoomCode;
            totalFoodQuantity += roomUpsell.quantity;
          }
        });
        if (foodPerRoomCodes.length > 0) {
          foodPerRoom.push({
            codes: foodPerRoomCodes,
            roomNumber: String(roomIndex + 1)
          });
        }
      });
      var uniqueFoodRatePlans = foodRatePlans.filter((obj, index) => foodRatePlans.findIndex(item => item.code === obj.code) === index);
      additionalCartItems = trackFoodUpsellsRatePlan(additionalCartItems, uniqueFoodRatePlans, foodPerRoom, +totalNumberOfRooms, totalFoodQuantity, totalFoodPrice, baseCartPrice, currency);
    }
    additionalCartItems.push({
      type: _analytics.analyticsTrackings.ADDON,
      quantity: 0,
      price: {
        basePrice: '0.00'
      },
      productInfo: {
        sku: 'Donation'
      }
    });
    _analytics.analyticsConfirmation.update({
      CartItems: [...productDetails, ...additionalCartItems]
    });
  }
  if (window.__satelliteLoaded && pageName === analyticsConfirmationPageName) {
    window._satellite.track(bookingFlowComplete ? 'bookingflowComplete' : 'operaConfirmationFailed');
  }
};
var updateConfirmationAnalytics = () => {
  var {
    dailyRates,
    donationAmount
  } = window.analyticsData || {};
  var {
    CartItems,
    price
  } = window.analyticsDataCartConfirmation || {};
  if (CartItems && dailyRates) {
    trackDailyRates(CartItems, dailyRates, price === null || price === void 0 ? void 0 : price.currency);
  }
  if (CartItems && donationAmount) {
    trackDonationAddon(CartItems, donationAmount);
  }
};
exports.updateConfirmationAnalytics = updateConfirmationAnalytics;
var updateConfirmationPageAnalytics = (bookingConfirmation, packages, paymentAnalyticsData, variant) => {
  var _packages$roomSelecti, _bookingConfirmation$, _firstRoom$rateExtraI, _paymentAnalyticsData, _paymentAnalyticsData2, _paymentAnalyticsData3, _paymentAnalyticsData4, _paymentAnalyticsData5, _paymentAnalyticsData6, _paymentAnalyticsData7, _paymentAnalyticsData8, _paymentAnalyticsData9, _paymentAnalyticsData10;
  var firstRoom = bookingConfirmation.reservationByIdList[0].roomStay || {};
  var noNights = (0, _getters.getNightsNumber)(firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.arrivalDate, firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.departureDate);
  var roomSelection = (_packages$roomSelecti = packages.roomSelection) !== null && _packages$roomSelecti !== void 0 ? _packages$roomSelecti : [];
  var adultsMeals = (0, _selectors.adultsMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.meals, noNights);
  var childrenMeals = (0, _selectors.childrenMealsSelector)(packages === null || packages === void 0 ? void 0 : packages.mealsKids);
  var reasonForStay = bookingConfirmation === null || bookingConfirmation === void 0 || (_bookingConfirmation$ = bookingConfirmation.reservationByIdList) === null || _bookingConfirmation$ === void 0 || (_bookingConfirmation$ = _bookingConfirmation$[0]) === null || _bookingConfirmation$ === void 0 || (_bookingConfirmation$ = _bookingConfirmation$.additionalGuestInfo) === null || _bookingConfirmation$ === void 0 ? void 0 : _bookingConfirmation$.purposeOfStay;
  var totalAdults = 0;
  var totalChildren = 0;
  bookingConfirmation === null || bookingConfirmation === void 0 || bookingConfirmation.reservationByIdList.forEach(room => {
    totalAdults += room.roomStay.adultsNumber;
    totalChildren += room.roomStay.childrenNumber;
  });
  var arrivalDay = (0, _dateFns.format)(new Date(firstRoom.arrivalDate), 'EEEE');
  var departureDay = (0, _dateFns.format)(new Date(firstRoom.departureDate), 'EEEE');
  var ancMealSelection = [];
  bookingConfirmation === null || bookingConfirmation === void 0 || bookingConfirmation.reservationByIdList.forEach((_, roomIndex) => {
    var packagesPerRoom = [...adultsMeals.map(meal => {
      var _meal$id, _meal$name, _meal$id2, _meal$price$toFixed, _meal$price;
      return {
        code: (_meal$id = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id !== void 0 ? _meal$id : '',
        legend: (_meal$name = meal === null || meal === void 0 ? void 0 : meal.name) !== null && _meal$name !== void 0 ? _meal$name : '',
        quantity: (0, _getters.getMealQuantity)(packages, (_meal$id2 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id2 !== void 0 ? _meal$id2 : '', roomIndex),
        price: (_meal$price$toFixed = meal === null || meal === void 0 || (_meal$price = meal.price) === null || _meal$price === void 0 ? void 0 : _meal$price.toFixed(2)) !== null && _meal$price$toFixed !== void 0 ? _meal$price$toFixed : '',
        currency: meal === null || meal === void 0 ? void 0 : meal.currency,
        freeBreakfastCode: meal === null || meal === void 0 ? void 0 : meal.freeBreakfastCode,
        freeBreakfastOption: meal === null || meal === void 0 ? void 0 : meal.freeBreakfastOption
      };
    }), ...childrenMeals.map(meal => {
      var _meal$id3, _meal$name2, _meal$id4;
      return {
        code: (_meal$id3 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id3 !== void 0 ? _meal$id3 : '',
        legend: (_meal$name2 = meal === null || meal === void 0 ? void 0 : meal.name) !== null && _meal$name2 !== void 0 ? _meal$name2 : '',
        quantity: (0, _getters.getMealQuantity)(packages, (_meal$id4 = meal === null || meal === void 0 ? void 0 : meal.id) !== null && _meal$id4 !== void 0 ? _meal$id4 : '', roomIndex),
        price: '0.00'
      };
    })];
    ancMealSelection.push([...packagesPerRoom]);
  });
  var roomTypes, roomNames;
  if (bookingConfirmation !== null && bookingConfirmation !== void 0 && bookingConfirmation.reservationByIdList) {
    roomTypes = (0, _selectors.getUniqueRoomProperties)(bookingConfirmation === null || bookingConfirmation === void 0 ? void 0 : bookingConfirmation.reservationByIdList);
    roomNames = (0, _selectors.getUniqueRoomProperties)(bookingConfirmation === null || bookingConfirmation === void 0 ? void 0 : bookingConfirmation.reservationByIdList, 'roomName');
  }
  _analytics.default.update({
    cardNotPresent: paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.cardNotPresent,
    cardType: paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.cardType,
    echoID: paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.echoID,
    validation: '',
    bookingReasonForStay: reasonForStay,
    wifiAccessAllowed: false,
    wifiOption: '',
    currencyCode: bookingConfirmation.currencyCode,
    FromToDate: {
      ArrivalDay: arrivalDay,
      DepartureDay: departureDay,
      FromToDay: "".concat(arrivalDay, "-").concat(departureDay)
    },
    rateCode: firstRoom.ratePlanCode,
    productSelectedRate: (_firstRoom$rateExtraI = firstRoom.rateExtraInfo) === null || _firstRoom$rateExtraI === void 0 ? void 0 : _firstRoom$rateExtraI.rateName,
    RoomTypes: roomTypes,
    RoomNames: roomNames,
    paymentCards: '',
    paymentCardSelected: (_paymentAnalyticsData = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paymentCardSelected) !== null && _paymentAnalyticsData !== void 0 ? _paymentAnalyticsData : '',
    paymentCardTypes: '',
    paymentTakenNow: (_paymentAnalyticsData2 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paymentTakenNow) !== null && _paymentAnalyticsData2 !== void 0 ? _paymentAnalyticsData2 : '',
    paymentSessionID: (_paymentAnalyticsData3 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paymentSessionID) !== null && _paymentAnalyticsData3 !== void 0 ? _paymentAnalyticsData3 : '',
    paymentTemplateID: (_paymentAnalyticsData4 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paymentTemplateID) !== null && _paymentAnalyticsData4 !== void 0 ? _paymentAnalyticsData4 : '',
    paymentLoadTime: (_paymentAnalyticsData5 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paymentLoadTime) !== null && _paymentAnalyticsData5 !== void 0 ? _paymentAnalyticsData5 : '',
    piba: (_paymentAnalyticsData6 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.piba) !== null && _paymentAnalyticsData6 !== void 0 ? _paymentAnalyticsData6 : '',
    alcoholAllowed: (_paymentAnalyticsData7 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.alcoholAllowed) !== null && _paymentAnalyticsData7 !== void 0 ? _paymentAnalyticsData7 : false,
    carParkingAllowed: (_paymentAnalyticsData8 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.carParkingAllowed) !== null && _paymentAnalyticsData8 !== void 0 ? _paymentAnalyticsData8 : false,
    dinnerAllowance: (_paymentAnalyticsData9 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.dinnerAllowance) !== null && _paymentAnalyticsData9 !== void 0 ? _paymentAnalyticsData9 : false,
    otherChargesAllowed: (_paymentAnalyticsData10 = paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.otherChargesAllowed) !== null && _paymentAnalyticsData10 !== void 0 ? _paymentAnalyticsData10 : false,
    pageName: analyticsConfirmationPageName,
    paypal: paymentAnalyticsData === null || paymentAnalyticsData === void 0 ? void 0 : paymentAnalyticsData.paypal,
    productDetails: [{
      type: _analytics.analyticsTrackings.HOTEL,
      quantity: bookingConfirmation.reservationByIdList.length,
      price: {
        basePrice: (+(bookingConfirmation === null || bookingConfirmation === void 0 ? void 0 : bookingConfirmation.totalCost) - (0, _selectors.calculateTotalCostRoomSelection)(adultsMeals, roomSelection, noNights)).toFixed(2)
      },
      productInfo: {
        sku: bookingConfirmation.hotelId,
        totalNumberOfRooms: bookingConfirmation.reservationByIdList.length.toString(),
        roomAdults: totalAdults.toString(),
        roomChildren: totalChildren.toString(),
        numberOfGuests: (totalAdults + totalChildren).toString(),
        startDate: (0, _dateFns.format)(new Date(firstRoom.arrivalDate), 'yyyy-MM-dd'),
        endDate: (0, _dateFns.format)(new Date(firstRoom.departureDate), 'yyyy-MM-dd'),
        numberOfNights: noNights.toString(),
        daysToCheckIn: (0, _differenceInDays.default)(new Date(firstRoom.arrivalDate), new Date()).toString()
      }
    }],
    upsells: {
      rooms: ancMealSelection
    }
  });
  if (variant === _api.Area.BB || variant === _api.Area.CCUI) {
    _analytics.default.update({
      siteType: '6.5',
      bookingPanelComponentsOrder: 'submitdetails,bookingoverview,bookingdonation,bookingdetails, infotext_FlexPID,infotext_FlexPI,infotext_FlexHub,infotext_Advance,infotext_standard,infotext, infotext_semiflex,submitdetails_0',
      contentComponentOrder: 'employees,upsell,contactpreference,wifi,submitdetails,contactdetails',
      loginFormComponentsOrder: 'bookingflowmessages,loginregisterauth0,'
    });
  }
  if (variant === _api.Area.BB) {
    var idTokenCookie = (0, _getters.getAuthCookie)();
    var {
      companyId,
      sessionId,
      accessLevel,
      employeeId
    } = (0, _getters.getLoggedInUserInfo)(idTokenCookie);
    _analytics.default.update({
      companyID: companyId,
      sessionId: sessionId,
      userLevel: accessLevel,
      userID: employeeId
    });
  }
};
exports.updateConfirmationPageAnalytics = updateConfirmationPageAnalytics;
var getFoodRatePlan = (roomUpsell, totalNumberOfNights, currency) => {
  return {
    code: roomUpsell.code,
    legend: roomUpsell.legend,
    price: {
      amount: roomUpsell.price,
      currency
    },
    foodUpsell: roomUpsell.price !== '0.00',
    freeBreakfastOption: !!roomUpsell.freeBreakfastOption,
    freeBreakfastCode: roomUpsell.freeBreakfastCode ? roomUpsell.freeBreakfastCode : '',
    totalCostPerGuest: {
      amount: roomUpsell !== null && roomUpsell !== void 0 && roomUpsell.price ? +(roomUpsell === null || roomUpsell === void 0 ? void 0 : roomUpsell.price) * +totalNumberOfNights : 0,
      currency
    }
  };
};
var getFoodPerRoomCode = (roomUpsell, pricePerRoomCode, currency) => {
  var _roomUpsell$code;
  return {
    code: (_roomUpsell$code = roomUpsell.code) !== null && _roomUpsell$code !== void 0 ? _roomUpsell$code : '',
    adults: roomUpsell.price !== '0.00' ? roomUpsell.quantity : 0,
    children: roomUpsell.price === '0.00' ? roomUpsell.quantity : 0,
    price: {
      amount: Number(pricePerRoomCode),
      currency
    }
  };
};
var trackFoodUpsellsRatePlan = (cartItems, foodRatePlans, foodPerRoom, totalNumberOfRooms, totalFoodQuantity, totalFoodPrice, baseCartPrice, currency) => {
  cartItems.push({
    type: _analytics.analyticsTrackings.FOOD,
    quantity: totalFoodQuantity,
    price: {
      basePrice: totalFoodPrice.toFixed(2)
    },
    productInfo: {
      FoodPerRoom: foodPerRoom
    },
    AvailabilityFoodRatePlan: foodRatePlans
  }, {
    type: _analytics.analyticsTrackings.RATE_PLAN,
    quantity: totalNumberOfRooms,
    price: {
      basePrice: baseCartPrice
    },
    productInfo: {
      sku: ''
    },
    rawRatePlan: {
      upsellItems: foodRatePlans,
      totalCost: {
        amount: baseCartPrice,
        currency
      },
      totalFoodCost: {
        amount: totalFoodPrice,
        currency
      }
    }
  });
  return cartItems;
};
var trackDailyRates = (cartItems, dailyRates, currency) => {
  var dateIndex = cartItems.findIndex(obj => {
    var _obj$productInfo;
    return obj.type === _analytics.analyticsTrackings.DATE && !((_obj$productInfo = obj.productInfo) !== null && _obj$productInfo !== void 0 && _obj$productInfo.dailyRates);
  });
  if (dateIndex > -1) {
    var ratesPerDate = [];
    dailyRates.forEach(rates => {
      var dailyRate = [];
      rates.forEach(rate => {
        var _rate$pricePerNight;
        var date = new Date(rate.startDate);
        dailyRate.push({
          date: {
            day: (0, _dateFns.format)(date, 'EEEE'),
            date: +(0, _dateFns.format)(date, 'dd'),
            year: +(0, _dateFns.format)(date, 'yyyy'),
            month: (0, _dateFns.format)(date, 'MMMM'),
            shortDay: (0, _dateFns.format)(date, 'EEE'),
            shortMonth: (0, _dateFns.format)(date, 'MMM')
          },
          backupDate: rate.startDate,
          price: {
            amount: (_rate$pricePerNight = rate.pricePerNight) === null || _rate$pricePerNight === void 0 ? void 0 : _rate$pricePerNight.toFixed(2),
            currency
          },
          cityTax: rate.cityTaxPerNight
        });
      });
      ratesPerDate.push(dailyRate);
    });
    cartItems[dateIndex].productInfo.dailyRates = ratesPerDate;
    _analytics.analyticsConfirmation.update({
      CartItems: cartItems
    });
  }
};
var trackDonationAddon = (cartItems, donationAmount) => {
  var addonIndex = cartItems.findIndex(obj => {
    return obj.type === _analytics.analyticsTrackings.ADDON;
  });
  if (addonIndex > -1) {
    cartItems[addonIndex].price.basePrice = donationAmount.toFixed(2);
    cartItems[addonIndex].quantity = 1;
    _analytics.analyticsConfirmation.update({
      CartItems: cartItems
    });
  }
};
var _default = exports.default = initConfirmationAnalytics;