"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Footer;
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _dynamic = _interopRequireDefault(require("next/dynamic"));
var _router = require("next/router");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var FooterComponent = (0, _dynamic.default)(() => Promise.resolve().then(() => _interopRequireWildcard(require('./Footer.component'))), {
  ssr: false
});
function Footer(_ref) {
  var _data$footer, _data$footer3, _data$footer4;
  var {
    isPremierInn,
    site = _api.SITE_LEISURE
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    query
  } = (0, _router.useRouter)() || {
    query: {}
  };
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'Footer';
  var brand = query !== null && query !== void 0 && query.BRAND ? String(query.BRAND).toLowerCase() : 'pi';
  var isLeisureBrand = (0, _react2.useMemo)(() => {
    return _api.LEISURE_BRANDS.includes(brand);
  }, [brand]);
  var {
    isLoading,
    isError,
    data,
    error
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: site,
    businessBooker: false
  });
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "loading-message",
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: String(error),
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  var footerData = [data === null || data === void 0 || (_data$footer = data.footer) === null || _data$footer === void 0 ? void 0 : _data$footer.tabs[0]];
  if (isPremierInn && isLeisureBrand) {
    var _data$footer2;
    footerData = data === null || data === void 0 || (_data$footer2 = data.footer) === null || _data$footer2 === void 0 ? void 0 : _data$footer2.tabs;
  }
  return (0, _jsxRuntime.jsx)(FooterComponent, {
    baseDataTestId: baseDataTestId,
    language: language || 'en',
    copyrightData: data === null || data === void 0 || (_data$footer3 = data.footer) === null || _data$footer3 === void 0 ? void 0 : _data$footer3.copyrightInfo,
    socialIcons: data === null || data === void 0 || (_data$footer4 = data.footer) === null || _data$footer4 === void 0 ? void 0 : _data$footer4.socialMediaIcons,
    tabsInfo: footerData,
    isPI: isPremierInn ? isLeisureBrand : false
  });
}