"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.navDefault = exports.manageSubNav = void 0;
var navDefault = exports.navDefault = {
  home: {
    label: 'Home',
    path: '/homepage'
  },
  spending: {
    label: 'Spending',
    path: '/'
  },
  manage: {
    label: 'Manage',
    path: '/'
  },
  bookings: {
    label: 'Bookings',
    path: '/'
  }
};
var manageSubNav = exports.manageSubNav = {
  manageEmployees: {
    label: 'Manage employees',
    path: '/homepage'
  },
  bookingAllowances: {
    label: 'Booking allowances',
    path: '/'
  },
  bookingAlerts: {
    label: 'Booking alerts',
    path: '/'
  },
  cardManagement: {
    label: 'Card management',
    path: '/'
  },
  employeeQuestions: {
    label: 'Employee questions',
    path: '/'
  },
  companyDetails: {
    label: 'Company details',
    path: '/'
  }
};