"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.attachHotelCard = attachHotelCard;
exports.createMarkers = createMarkers;
exports.default = MapViewBBVariant;
exports.getCenterByHotels = getCenterByHotels;
exports.getItemStatus = getItemStatus;
exports.removeActiveClass = removeActiveClass;
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _jsApiLoader = require("@googlemaps/js-api-loader");
var _utils = require("@whitbread-eos/utils");
var _config = _interopRequireDefault(require("next/config"));
var _react2 = _interopRequireWildcard(require("react"));
var _reactDom = _interopRequireDefault(require("react-dom"));
var _index = require("../../../index");
var _utilities = require("../../utilities");
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var AVAILABLE_STATUS = 'available';
var SOLD_OUT_STATUS = 'soldOut';
var OPEN_SOON_STATUS = 'openSoon';
function MapViewBBVariant(_ref) {
  var {
    items,
    multiSearchParams,
    locale,
    partialTranslations,
    headerInformation,
    baseDataTestId,
    variant
  } = _ref;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var hotelMapRef = (0, _react2.useRef)(null);
  var hotelOverlayRef = (0, _react2.useRef)(null);
  var [displayedItem, setDisplayedItem] = (0, _react2.useState)(null);
  (0, _react2.useEffect)(() => {
    var loader = new _jsApiLoader.Loader({
      apiKey: publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: []
    });
    loader.load().then(() => {
      if (hotelMapRef !== null && hotelMapRef !== void 0 && hotelMapRef.current) {
        if (items.length === 0) {
          initializeEmptyCountryMap(_constants.emptyCountryMapCoordinates[locale].lat, _constants.emptyCountryMapCoordinates[locale].long, hotelMapRef);
          return (0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'mapView')
          });
        }
        var {
          centerLat,
          centerLong
        } = getCenterByHotels(items);
        var hotelMap = initializeMap(centerLat, centerLong, hotelMapRef);
        var params = {
          hotelMap,
          hotels: items,
          partialTranslations,
          headerInformation,
          multiSearchParams,
          locale,
          setDisplayedItem,
          hotelOverlayRef
        };
        displayMarkers(params);
      }
    });
  }, [multiSearchParams, locale, items, partialTranslations, headerInformation, publicRuntimeConfig.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [hotelOverlayRef.current && displayedItem && _reactDom.default.createPortal((0, _jsxRuntime.jsx)(_index.SRMapHotelCard, {
      data: displayedItem,
      locale: locale,
      partialTranslations: partialTranslations,
      multiSearchParams: multiSearchParams,
      variant: variant
    }), hotelOverlayRef.current), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'mapView'),
      ref: hotelMapRef
    }, mapContainerStyles))]
  });
}
function getCenterByHotels(hotels) {
  var latitudes = [];
  var longitudes = [];
  hotels.forEach(hotel => {
    var _hotel$hotelInformati, _hotel$hotelInformati2;
    (hotel === null || hotel === void 0 || (_hotel$hotelInformati = hotel.hotelInformation) === null || _hotel$hotelInformati === void 0 ? void 0 : _hotel$hotelInformati.coordinates) && latitudes.push(hotel.hotelInformation.coordinates.latitude);
    (hotel === null || hotel === void 0 || (_hotel$hotelInformati2 = hotel.hotelInformation) === null || _hotel$hotelInformati2 === void 0 ? void 0 : _hotel$hotelInformati2.coordinates) && longitudes.push(hotel.hotelInformation.coordinates.longitude);
  });
  var centerLat = (Math.max(...latitudes) + Math.min(...latitudes)) / 2;
  var centerLong = (Math.max(...longitudes) + Math.min(...longitudes)) / 2;
  return {
    centerLat,
    centerLong
  };
}
function getMarkerLabel(item, partialTranslations, multiSearchParams, locale) {
  var _item$hotelAvailabili;
  var lowestRate = item === null || item === void 0 || (_item$hotelAvailabili = item.hotelAvailability) === null || _item$hotelAvailabili === void 0 ? void 0 : _item$hotelAvailabili.lowestRoomRate;
  var {
    status
  } = getItemStatus(item, multiSearchParams);
  var content = '';
  if (status === AVAILABLE_STATUS && lowestRate) {
    content = (0, _utils.formatPriceWithDecimal)(locale, (0, _utils.formatCurrency)(lowestRate.currencyCode), lowestRate.netTotal);
  }
  if (status === SOLD_OUT_STATUS) {
    var _partialTranslations$, _partialTranslations$2;
    content = (_partialTranslations$ = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$2 = partialTranslations.searchInformation) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.content) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.results) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.result) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.fullyBooked) !== null && _partialTranslations$ !== void 0 ? _partialTranslations$ : '';
  }
  if (status === OPEN_SOON_STATUS) {
    var _partialTranslations$3, _partialTranslations$4;
    content = (_partialTranslations$3 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$4 = partialTranslations.searchInformation) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.content) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.results) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.result) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.openingSoon) !== null && _partialTranslations$3 !== void 0 ? _partialTranslations$3 : '';
  }
  return content;
}
function getItemStatus(item, multiSearchParams) {
  var _item$hotelInformatio, _item$hotelInformatio2;
  var hotelAvailability = item.hotelAvailability;
  var lowestRate = hotelAvailability === null || hotelAvailability === void 0 ? void 0 : hotelAvailability.lowestRoomRate;
  var soldOut = !(hotelAvailability !== null && hotelAvailability !== void 0 && hotelAvailability.available);
  var openingDate = (_item$hotelInformatio = item === null || item === void 0 || (_item$hotelInformatio2 = item.hotelInformation) === null || _item$hotelInformatio2 === void 0 ? void 0 : _item$hotelInformatio2.hotelOpeningDate) !== null && _item$hotelInformatio !== void 0 ? _item$hotelInformatio : '';
  var isOpeningSoon = (0, _utilities.hotelOpensSoon)(openingDate, multiSearchParams);
  if (lowestRate && !isOpeningSoon) {
    return {
      status: 'available'
    };
  }
  if (soldOut) {
    return {
      status: 'soldOut'
    };
  }
  if (isOpeningSoon) {
    return {
      status: 'openSoon'
    };
  }
  return {
    status: ''
  };
}
function initializeEmptyCountryMap(latitude, longitude, mapReference) {
  return new google.maps.Map(mapReference.current, {
    zoom: 6,
    center: {
      lat: latitude,
      lng: longitude
    },
    disableDefaultUI: true
  });
}
function initializeMap(latitude, longitude, mapReference) {
  return new google.maps.Map(mapReference.current, {
    center: {
      lat: latitude,
      lng: longitude
    },
    zoom: 11,
    controlSize: 40,
    mapTypeControlOptions: {
      mapTypeIds: [],
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    zoomControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    streetViewControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    },
    fullscreenControl: false
  });
}
var marker;
function createMarkers(items, partialTranslations, multiSearchParams, locale, hotelMap, hotelOverlayRef, setDisplayedItem) {
  return items.map(item => {
    try {
      var _item$hotelInformatio3, _item$hotelInformatio4, _item$hotelInformatio5, _item$hotelInformatio6;
      var {
        status
      } = getItemStatus(item, multiSearchParams);
      var isSoldOut = status === SOLD_OUT_STATUS ? 'soldOut' : '';
      var markerLabel = {
        text: getMarkerLabel(item, partialTranslations, multiSearchParams, locale),
        className: "mapMarker mapMarker-".concat(item.hotelId, " ").concat(isSoldOut)
      };
      marker = new google.maps.Marker({
        position: {
          lat: (_item$hotelInformatio3 = item === null || item === void 0 || (_item$hotelInformatio4 = item.hotelInformation) === null || _item$hotelInformatio4 === void 0 || (_item$hotelInformatio4 = _item$hotelInformatio4.coordinates) === null || _item$hotelInformatio4 === void 0 ? void 0 : _item$hotelInformatio4.latitude) !== null && _item$hotelInformatio3 !== void 0 ? _item$hotelInformatio3 : 0,
          lng: (_item$hotelInformatio5 = item === null || item === void 0 || (_item$hotelInformatio6 = item.hotelInformation) === null || _item$hotelInformatio6 === void 0 || (_item$hotelInformatio6 = _item$hotelInformatio6.coordinates) === null || _item$hotelInformatio6 === void 0 ? void 0 : _item$hotelInformatio6.longitude) !== null && _item$hotelInformatio5 !== void 0 ? _item$hotelInformatio5 : 0
        },
        map: hotelMap,
        label: markerLabel,
        icon: " "
      });
      attachHotelCard({
        marker,
        hotelMap,
        markerLabel,
        hotelOverlayRef,
        setDisplayedItem,
        item,
        isSoldOut
      });
      return marker;
    } catch (e) {
      throw new Error();
    }
  });
}
function getMapBounds(markers) {
  var bounds = new google.maps.LatLngBounds();
  markers.forEach(marker => {
    var markerPosition = marker.getPosition();
    bounds.extend(markerPosition);
  });
  return bounds;
}
function displayMarkers(params) {
  var {
    hotelMap,
    hotels,
    partialTranslations,
    multiSearchParams,
    locale,
    hotelOverlayRef,
    setDisplayedItem
  } = params;
  var markers = createMarkers(hotels, partialTranslations, multiSearchParams, locale, hotelMap, hotelOverlayRef, setDisplayedItem);
  if (markers.length > 1) {
    var bounds = getMapBounds(markers);
    hotelMap.fitBounds(bounds);
  }
}
var infoWindow;
function attachHotelCard(_ref2) {
  var {
    marker,
    hotelMap,
    markerLabel,
    hotelOverlayRef,
    setDisplayedItem,
    item,
    isSoldOut
  } = _ref2;
  var stringyfiedContent = "<div id=\"hotel-overlay\"></div>";
  infoWindow = new google.maps.InfoWindow({
    content: stringyfiedContent
  });
  marker.addListener('mouseover', (0, _asyncToGenerator2.default)(function* () {
    document.querySelectorAll(".mapMarker").forEach(hotel => {
      hotel.classList.remove('activeHover');
    });
    marker.setLabel(_objectSpread(_objectSpread({}, markerLabel), {}, {
      className: "mapMarker ".concat(isSoldOut, " hoverMarker")
    }));
  }));
  marker.addListener('mouseout', (0, _asyncToGenerator2.default)(function* () {
    document.querySelectorAll(".mapMarker").forEach(hotel => {
      hotel.classList.remove('activeHover');
    });
    marker.setLabel(markerLabel);
  }));
  marker.addListener('click', (0, _asyncToGenerator2.default)(function* () {
    removeActiveClass(".mapMarker", 'activeMarker');
    var selectedHotel = document.querySelector(".mapMarker-".concat(item.hotelId));
    selectedHotel === null || selectedHotel === void 0 || selectedHotel.classList.add('activeMarker');
    marker.setLabel(_objectSpread(_objectSpread({}, markerLabel), {}, {
      className: "mapMarker mapMarker-".concat(item.hotelId, " activeMarker")
    }));
    marker.addListener('mouseover', (0, _asyncToGenerator2.default)(function* () {
      removeActiveClass(".mapMarker", 'activeMarker');
      selectedHotel === null || selectedHotel === void 0 || selectedHotel.classList.add('activeHover');
    }));
    marker.addListener('mouseout', (0, _asyncToGenerator2.default)(function* () {
      removeActiveClass(".mapMarker", 'activeMarker');
      marker.setLabel(_objectSpread(_objectSpread({}, markerLabel), {}, {
        className: "mapMarker activeMarker"
      }));
    }));
    infoWindow.open({
      map: hotelMap,
      anchor: marker
    });
    yield (0, _utils.waitForElement)('#hotel-overlay');
    var el = document.getElementById('hotel-overlay');
    if (el) {
      hotelOverlayRef.current = el;
      setDisplayedItem(item);
      infoWindow.addListener('closeclick', () => {
        removeActiveClass(".mapMarker", 'activeMarker');
        infoWindow.close();
        marker.setLabel(markerLabel);
      });
      google.maps.event.addListener(hotelMap, 'click', () => {
        removeActiveClass(".mapMarker", 'activeMarker');
        infoWindow.close();
        marker.setLabel(markerLabel);
      });
      google.maps.event.addListener(hotelMap, 'center_changed', () => {
        removeActiveClass(".mapMarker", 'activeMarker');
        marker.setLabel(markerLabel);
      });
      google.maps.event.addListener(hotelMap, 'drag', () => {
        removeActiveClass(".mapMarker", 'activeMarker');
        marker.setLabel(markerLabel);
        infoWindow.close();
      });
    }
  }));
}
function removeActiveClass(selector, className) {
  var allHotels = document.querySelectorAll(selector);
  return allHotels.forEach(hotel => {
    hotel.classList.remove(className);
  });
}
var mapContainerStyles = {
  height: '100%'
};