"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateAgentOverrideForm;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function validateAgentOverrideForm(params) {
  var {
    t
  } = params;
  var formValidationObject = {
    selectReason: yup.string().required(),
    callerName: yup.string().matches(_atoms.FORM_VALIDATIONS.AGENT_OVERRIDE_MODAL_TEXT_INPUT.MATCHES, t('ccui.manageBooking.options.agentOverrideModal.callerName.invalid')).max(_atoms.FORM_VALIDATIONS.AGENT_OVERRIDE_MODAL_TEXT_INPUT.MAX, t('ccui.manageBooking.options.agentOverrideModal.callerName.max')),
    managerName: yup.string().when('managerName', {
      is: managerName => managerName != '',
      then: yup.string().matches(_atoms.FORM_VALIDATIONS.AGENT_OVERRIDE_MODAL_TEXT_INPUT.MATCHES, t('ccui.manageBooking.options.agentOverrideModal.managerName.invalid')).max(_atoms.FORM_VALIDATIONS.AGENT_OVERRIDE_MODAL_TEXT_INPUT.MAX, t('ccui.manageBooking.options.agentOverrideModal.managerName.max')),
      otherwise: yup.string().notRequired()
    })
  };
  var formValidationSchema = yup.object().shape(formValidationObject, [['selectReason', 'selectReason'], ['callerName', 'callerName'], ['managerName', 'managerName']]);
  return {
    formValidationObject,
    formValidationSchema
  };
}