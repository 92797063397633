"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccountToCompanyPreAuthorisedCharges;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _yup = require("yup");
var _preAuthorisedCharges = _interopRequireDefault(require("./preAuthorisedCharges"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var COMPANY_REFERENCE_VALIDATIONS = {
  MATCHES: /^[a-zA-Z0-9\s.,:;!?"*%=+£$€¥&@#()\-'À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/g,
  MAX: 50
};
function AccountToCompanyPreAuthorisedCharges(_ref) {
  var {
    setPreAuthorisedCharges,
    setCompanyReferenceError,
    setACCompanyReference,
    initialCharges,
    initialCompanyReference
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [charges, setCharges] = (0, _react2.useState)(initialCharges !== null && initialCharges !== void 0 ? initialCharges : []);
  var [companyReference, setCompanyReference] = (0, _react2.useState)(initialCompanyReference !== null && initialCompanyReference !== void 0 ? initialCompanyReference : '');
  var [referenceError, setReferenceError] = (0, _react2.useState)('');
  var baseTestId = 'AccountToCompanyPreAuthorisedCharges';
  var companyReferenceValidationSchema = (0, _yup.string)().when('$value', _ref2 => {
    var [value] = _ref2;
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(COMPANY_REFERENCE_VALIDATIONS.MATCHES, t("ccui.accountToCompanyReference.invalidCharactersError")).max(COMPANY_REFERENCE_VALIDATIONS.MAX, t("ccui.accountToCompanyReference.maxCharactersError")) : (0, _yup.string)().notRequired();
  });
  (0, _react2.useEffect)(() => {
    return () => {
      setCompanyReferenceError(false);
    };
  }, []);
  var handleInputErrors = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* (value, fieldValidation) {
      var _validationResult$err, _validationResult$err2;
      setReferenceError('');
      var validationResult = yield fieldValidation.validate(value.toUpperCase(), {
        context: {
          value
        }
      }).catch(err => {
        return err;
      });
      var hasValidationError = validationResult !== null && validationResult !== void 0 && validationResult.errors ? validationResult.errors.length > 0 : false;
      setCompanyReferenceError(hasValidationError);
      setReferenceError((_validationResult$err = (_validationResult$err2 = validationResult.errors) === null || _validationResult$err2 === void 0 ? void 0 : _validationResult$err2[0]) !== null && _validationResult$err !== void 0 ? _validationResult$err : '');
    });
    return function handleInputErrors(_x, _x2) {
      return _ref3.apply(this, arguments);
    };
  }();
  var onChange = val => {
    setCompanyReference(val);
    setACCompanyReference(val);
    handleInputErrors(val, companyReferenceValidationSchema);
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": baseTestId,
    my: 16,
    children: [(0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyles), {}, {
        children: t('ccui.accountToCompanyCharges.title')
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, optionsWrapperStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Options'),
        children: renderCheckboxes()
      }))]
    }), (0, _jsxRuntime.jsxs)(_react.Box, {
      mt: 16,
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyles), {}, {
        children: t('ccui.accountToCompanyReference.title')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyles), {}, {
        children: t('ccui.accountToCompanyReference.description')
      })), (0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "companyReference",
        value: companyReference,
        onChange: onChange,
        label: t('ccui.accountToCompanyReference.placeholder'),
        placeholderText: t('ccui.accountToCompanyReference.placeholder'),
        error: referenceError,
        styles: {
          inputWrapperStyles,
          inputElementStyles
        }
      })]
    }), (0, _jsxRuntime.jsx)(_react.Box, {
      mt: 16,
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        maxWidth: "full",
        variant: "info",
        status: "info",
        description: t('ccui.accountToCompanyCharges.notification'),
        svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
      })
    })]
  });
  function renderCheckboxes() {
    var allowances = charges.map(charge => charge.label);
    _utils.analytics.update({
      allowances: allowances
    });
    return _preAuthorisedCharges.default.map((option, index) => {
      var isLast = index === _preAuthorisedCharges.default.length - 1;
      var isOptionChecked = charges.find(charge => charge.id === option.id) !== undefined;
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, "option-".concat(option.id)),
        children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, optionItemStyle), {}, {
          children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
            onChange: () => onCheckboxChange(option),
            "data-testid": (0, _utils.formatDataTestId)(baseTestId, "option-".concat(option.id, "-item")),
            isChecked: isOptionChecked,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textStyles), {}, {
              children: t(option.key)
            }))
          })
        })), !isLast && (0, _jsxRuntime.jsx)(_react.Divider, {})]
      }, option.id);
    });
  }
  function onCheckboxChange(option) {
    var copy = charges;
    var optionIndex = copy.findIndex(item => item.id === option.id);
    if (optionIndex !== -1) {
      copy.splice(optionIndex, 1);
    } else {
      copy.push(option);
    }
    setCharges(copy);
    setPreAuthorisedCharges(copy);
  }
}
var titleStyles = {
  color: 'darkGrey1',
  fontSize: 'xl',
  lineHeight: 3,
  fontWeight: 'semibold',
  mb: 'var(--chakra-space-4)'
};
var descriptionStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  lineHeight: 3,
  fontWeight: 'normal',
  mb: 'var(--chakra-space-8)'
};
var inputWrapperStyles = {
  w: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  maxW: '26.25rem'
};
var inputElementStyles = {
  color: 'darkGrey1',
  _disabled: {
    opacity: '0.4',
    cursor: 'not-allowed'
  }
};
var optionsWrapperStyles = {
  display: 'flex',
  flexDir: 'column',
  maxW: '26.25rem',
  border: '1px',
  borderColor: 'lightGrey4',
  w: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};
var optionItemStyle = {
  w: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  px: '1.188rem'
};
var textStyles = {
  color: 'darkGrey1',
  marginInlineStart: '0.688rem'
};