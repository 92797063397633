"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchResultsPIVariantContainer;
exports.getSearchRedirectURL = getSearchRedirectURL;
exports.viewTypeLabelsConstants = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _index = require("../../index");
var _utilities = require("../../search/utilities");
var _constants = require("../constants");
var _utilities2 = require("../utilities");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var HOTEL_AVAILABILITIES_QUERY_KEY = 'hotelAvailabilitiesV2';
function getNewSearchResults(_x, _x2) {
  return _getNewSearchResults.apply(this, arguments);
}
function _getNewSearchResults() {
  _getNewSearchResults = (0, _asyncToGenerator2.default)(function* (queryClient, paramsForQuery) {
    var queryResult = yield queryClient.fetchQuery({
      queryKey: [HOTEL_AVAILABILITIES_QUERY_KEY, paramsForQuery.startDate, paramsForQuery.endDate, paramsForQuery.rooms, paramsForQuery.place, paramsForQuery.oldWorldChannel, paramsForQuery.channel, paramsForQuery.subChannel, paramsForQuery.page, paramsForQuery.initialPageSize, paramsForQuery.lazyLoadPageSize, paramsForQuery.country, paramsForQuery.language, paramsForQuery.sort, paramsForQuery.filters, paramsForQuery.ratePlanCodes],
      queryFn: () => (0, _utils.graphQLRequest)(_api.MULTI_HOTEL_AVAILABILITIES_QUERY_PI_V2, _objectSpread({}, paramsForQuery))
    });
    var results = queryResult.hotelAvailabilitiesV2.multiHotelAvailabilities;
    var total = queryResult.hotelAvailabilitiesV2.total;
    return {
      results,
      total
    };
  });
  return _getNewSearchResults.apply(this, arguments);
}
var MAPPED_ROOM_TYPES = {
  DB: 'DB',
  SB: 'SB',
  FAM: 'FAM',
  TWIN: 'TWIN',
  DIS: 'DIS'
};
var {
  listView,
  mapView
} = _molecules.VIEW_TYPE_CONSTANTS;
var viewTypeLabelsConstants = exports.viewTypeLabelsConstants = {
  LIST_VIEW: 'list view',
  MAP_VIEW: 'map view'
};
var SORT_BY_DISTANCE = '1';
var SORT_BY_PRICE = '2';
var MAX_NR_OF_MAP_ITEMS = 40;
function SearchResultsPIVariantContainer(_ref) {
  var _router$query, _router$query2, _router$query$FILTERS, _router$query3, _headerInformationDat2, _headerInformationDat3, _headerInformationDat4;
  var {
    multiSearchParams,
    queryClient
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var router = (0, _router.useRouter)();
  var viewType = (_router$query = router.query) === null || _router$query === void 0 ? void 0 : _router$query.VIEW;
  var URLSortValue = (_router$query2 = router.query) === null || _router$query2 === void 0 ? void 0 : _router$query2.SORT;
  var filters = (_router$query$FILTERS = (_router$query3 = router.query) === null || _router$query3 === void 0 ? void 0 : _router$query3.FILTERS) !== null && _router$query$FILTERS !== void 0 ? _router$query$FILTERS : '';
  var baseDataTestId = 'SRP';
  var roomTypes = multiSearchParams.rooms.map(room => room.type);
  var {
    isLessThanSm,
    isLessThanMd
  } = (0, _utils.useScreenSize)();
  var [currentPage, setCurrentPage] = (0, _react2.useState)(1);
  var [items, setItems] = (0, _react2.useState)([]);
  var [availabilityResult, setAvailabilityResult] = (0, _react2.useState)(null);
  var [resultsMeta, setResultsMeta] = (0, _react2.useState)({
    total: 0,
    currentResults: 0,
    SRisLoading: true,
    SRisError: false,
    SRError: null,
    SRisSuccess: false
  });
  var [hotelList, setHotelList] = (0, _react2.useState)([]);
  var isValidDate = (0, _utils.isDateValid)(Number(multiSearchParams.arrivalDay), Number(multiSearchParams.arrivalMonth), Number(multiSearchParams.arrivalYear));
  var startDate = isValidDate ? new Date(Number(multiSearchParams.arrivalYear), Number(multiSearchParams.arrivalMonth && multiSearchParams.arrivalMonth - 1), Number(multiSearchParams.arrivalDay)) : null;
  var endDate = startDate && (0, _add.default)(startDate, {
    days: Number(multiSearchParams.numberOfNights)
  }) || null;
  var cellCodes = multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.cellCodes;
  var changeViewType = (0, _react2.useCallback)(() => {
    var searchRedirectURL = getSearchRedirectURL(router, ['VIEW', 'FILTERS'], country, language);
    var newViewType = viewType === mapView || viewType === undefined ? listView : mapView;
    router.push("".concat(searchRedirectURL, "&VIEW=").concat(newViewType, "&FILTERS=").concat(filters), undefined, {
      shallow: true
    });
  }, [viewType, filters]);
  var changeSortValue = (0, _react2.useCallback)(() => {
    var searchRedirectURL = getSearchRedirectURL(router, ['SORT', 'FILTERS'], country, language);
    var newSortValue = URLSortValue === SORT_BY_DISTANCE ? SORT_BY_PRICE : SORT_BY_DISTANCE;
    router.push("".concat(searchRedirectURL, "&SORT=").concat(newSortValue, "&FILTERS=").concat(filters), undefined, {
      shallow: true
    });
  }, [URLSortValue, filters]);
  var {
    data: partialTranslations,
    isError: partialTranslationsIsError,
    error: partialTranslationsError,
    isLoading: partialTranslationsisLoading
  } = (0, _utils.useQueryRequest)(['searchInformation', country, language], _api.SEARCH_INFORMATION_RESULTS, {
    country,
    language
  });
  var {
    data: headerInformationData,
    isError: headerInformationIsError,
    error: headerInformationError,
    isLoading: headerInformationIsLoading
  } = (0, _utils.useQueryRequest)(['GetStaticContent', language, country], _api.GET_STATIC_CONTENT, {
    country,
    language,
    site: _api.SITE_LEISURE,
    businessBooker: false
  });
  (0, _react2.useEffect)(() => {
    if (!partialTranslationsisLoading && !partialTranslationsIsError) {
      setCurrentPage(() => 1);
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        SRisLoading: true,
        SRisError: false,
        SRError: null,
        SRisSuccess: false
      }));
      queryClient.cancelQueries({
        queryKey: [HOTEL_AVAILABILITIES_QUERY_KEY]
      });
      getNewSearchResults(queryClient, _objectSpread(_objectSpread({}, paramsForQuery), {}, {
        page: 1
      })).then(_ref2 => {
        var {
          results,
          total
        } = _ref2;
        setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
          total,
          currentResults: results.length,
          SRisLoading: false,
          SRError: null,
          SRisError: false,
          SRisSuccess: true
        }));
        updateHotelList(results, {
          clearPreviousData: true
        });
        setItems(() => [...results]);
        setCurrentPage(page => page + 1);
      }).catch(error => {
        setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
          SRisError: true,
          SRError: error,
          SRisSuccess: false
        }));
      });
    }
  }, [multiSearchParams]);
  (0, _react2.useEffect)(() => {
    if (!multiSearchParams.code) {
      var _parsedStay$data;
      var stayDetailsState = window.localStorage.getItem('StayDetailsState');
      var parsedStay = stayDetailsState && JSON.parse(stayDetailsState);
      multiSearchParams.code = parsedStay === null || parsedStay === void 0 || (_parsedStay$data = parsedStay.data) === null || _parsedStay$data === void 0 || (_parsedStay$data = _parsedStay$data.info) === null || _parsedStay$data === void 0 || (_parsedStay$data = _parsedStay$data.suggestion) === null || _parsedStay$data === void 0 ? void 0 : _parsedStay$data.hotelId;
    }
    (0, _utilities.isHotelAvailable)((0, _utilities.mapMultiSearchToQueryParams)(multiSearchParams), country, language, MAPPED_ROOM_TYPES, _constants.APP_VARIANT.PI).then(setAvailabilityResult);
  }, [multiSearchParams]);
  var fetchNewHotels = () => {
    if (resultsMeta.SRisLoading) return;
    setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
      SRisLoading: true,
      SRisError: false,
      SRError: null,
      SRisSuccess: false
    }));
    getNewSearchResults(queryClient, paramsForQuery).then(_ref3 => {
      var {
        results,
        total
      } = _ref3;
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        total,
        currentResults: (0, _utilities2.getCurrentResultsNumber)(results, items, total),
        SRisLoading: false,
        SRisSuccess: true
      }));
      updateHotelList(results);
      setItems(items => [...items, ...results]);
      setCurrentPage(page => page + 1);
      if (isLessThanSm) {
        window.scrollTo(0, 4000 * currentPage);
      } else {
        window.scrollTo(0, 1500 * currentPage);
      }
    }).catch(error => {
      setResultsMeta(resultsMeta => _objectSpread(_objectSpread({}, resultsMeta), {}, {
        SRisError: true,
        SRisLoading: false,
        SRError: error,
        SRisSuccess: false
      }));
    });
  };
  var updateHotelList = function updateHotelList(hotels) {
    var {
      clearPreviousData
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      clearPreviousData: false
    };
    setHotelList(currentHotels => [...(!clearPreviousData ? currentHotels : []), ...hotels]);
    var totalHotels = [...(!clearPreviousData ? hotelList : []), ...hotels];
    var isMapView = viewType === mapView;
    var {
      LIST_VIEW,
      MAP_VIEW
    } = viewTypeLabelsConstants;
    (0, _utils.updateSearchResultsAnalytics)({
      addedResults: !clearPreviousData ? hotels.length : undefined,
      isNewSearch: clearPreviousData,
      multiSearchParams,
      startDate,
      endDate,
      viewType: isMapView ? MAP_VIEW : LIST_VIEW,
      searchResults: isMapView ? 0 : totalHotels.length,
      searchResultsDisplayed: totalHotels
    });
  };
  var place = (0, _utils.getPlace)(multiSearchParams, language);
  var paramsForQuery = getParamsForQuery({
    startDate,
    endDate,
    multiSearchParams,
    place,
    partialTranslations: partialTranslations,
    currentPage,
    country,
    language,
    filters,
    cellCodes
  });
  if (headerInformationIsError || partialTranslationsIsError) {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        children: "Error on loading translations..."
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        children: partialTranslationsError.message
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        children: headerInformationError.message
      })]
    });
  }
  if (partialTranslationsisLoading || headerInformationIsLoading || resultsMeta.SRisLoading && !items.length) {
    return (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
      loadingText: t('searchresults.list.hotel.loading')
    });
  }
  if (!resultsMeta.SRisLoading && resultsMeta.SRisError && resultsMeta.SRError) {
    var errorMessage = (0, _utilities2.firstError)(resultsMeta.SRError).message;
    if (errorMessage === _constants.INVALID_LOCATION_ERROR_MESSAGE) {
      return displayInvalidLocationWarning(headerInformationData, partialTranslations, viewType, language, multiSearchParams, baseDataTestId);
    }
  }
  var hasMore = resultsMeta.currentResults > 0 && resultsMeta.total > Number(paramsForQuery.initialPageSize);
  if (viewType === undefined || viewType !== listView && viewType !== mapView) {
    router.push({
      query: _objectSpread(_objectSpread({}, router.query), {}, {
        VIEW: listView
      })
    });
  }
  var controlsLabels = {
    buttonLabels: partialTranslations.searchInformation.content.results.menu,
    filterLabels: partialTranslations.searchInformation.content.filter
  };
  var defaultFilters = filters.length > 0 ? filters.split(',') : [];
  var hasHotels = resultsMeta.total > 0;
  var shouldDisplayNoHotelsWarning = !hasHotels && filters.length === 0;
  if (viewType === mapView) {
    var _headerInformationDat;
    return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
      position: "relative"
    }, mapViewContainerStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
        children: hasHotels && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          variant: "info",
          status: "info",
          description: headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat = headerInformationData.headerInformation) === null || _headerInformationDat === void 0 ? void 0 : _headerInformationDat.announcement.text,
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          isInnerHTML: true,
          wrapperStyles: notificationWrapperStyles
        })
      })), (0, _jsxRuntime.jsxs)(_react.Box, {
        position: "relative",
        height: "100%",
        paddingTop: "var(--chakra-space-lg)",
        children: [(0, _jsxRuntime.jsx)(_molecules.SRControls, {
          viewType: viewType,
          onChangeViewType: changeViewType,
          onChangeSortValue: changeSortValue,
          onChangeFilters: handleChangeFilters,
          labels: controlsLabels,
          sortValue: multiSearchParams.sort,
          defaultFilters: defaultFilters
        }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          pos: "absolute"
        }, mapViewFiltersErrorNotificationStyles), {}, {
          children: !hasHotels && filters.length > 0 && displayNoHotelsFoundWarning(partialTranslations.searchInformation.content.results.notifications.noFilteredHotels)
        })), isLessThanMd && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          "data-testid": "mobile-list-view-button"
        }, mobileViewTypeButtonStyles), {}, {
          top: "40px",
          onClick: changeViewType,
          children: (0, _jsxRuntime.jsx)(_atoms.MapCompress, {})
        })), (0, _jsxRuntime.jsx)(_index.MapViewPIVariant, {
          items: items.slice(0, MAX_NR_OF_MAP_ITEMS),
          multiSearchParams: multiSearchParams,
          locale: language,
          partialTranslations: partialTranslations,
          headerInformation: headerInformationData,
          baseDataTestId: baseDataTestId,
          variant: "pi"
        })]
      })]
    }));
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [shouldDisplayNoHotelsWarning && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      children: displayNoHotelsFoundWarning(headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat2 = headerInformationData.headerInformation) === null || _headerInformationDat2 === void 0 ? void 0 : _headerInformationDat2.results.notifications.noResults)
    })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      display: shouldDisplayNoHotelsWarning ? 'none' : 'initial',
      children: [(0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [hasHotels && (headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat3 = headerInformationData.headerInformation) === null || _headerInformationDat3 === void 0 ? void 0 : _headerInformationDat3.announcement.text) && (0, _jsxRuntime.jsx)(_atoms.Notification, {
          variant: "info",
          status: "info",
          description: (0, _jsxRuntime.jsx)(_react.Box, {
            dangerouslySetInnerHTML: {
              __html: (0, _utils.formatInnerHTMLAssetUrls)(headerInformationData === null || headerInformationData === void 0 || (_headerInformationDat4 = headerInformationData.headerInformation) === null || _headerInformationDat4 === void 0 ? void 0 : _headerInformationDat4.announcement.text)
            },
            className: "formatLinks"
          }),
          svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
          isInnerHTML: true,
          wrapperStyles: notificationWrapperStyles
        }), (0, _jsxRuntime.jsx)(_molecules.SRControls, {
          viewType: viewType !== null && viewType !== void 0 ? viewType : listView,
          labels: controlsLabels,
          sortValue: multiSearchParams.sort,
          onChangeViewType: changeViewType,
          onChangeSortValue: changeSortValue,
          onChangeFilters: handleChangeFilters,
          defaultFilters: defaultFilters
        }), !hasHotels && filters.length > 0 && displayNoHotelsFoundWarning(partialTranslations.searchInformation.content.results.notifications.noFilteredHotels)]
      }), (0, _jsxRuntime.jsx)(_index.ListView, {
        fetchNewHotels: fetchNewHotels,
        items: items,
        hasMore: hasMore,
        resultsMeta: resultsMeta,
        partialTranslations: partialTranslations,
        orderedHotels: hotelList,
        roomTypes: roomTypes,
        multiSearchParams: multiSearchParams,
        language: language,
        isHotelAvailable: availabilityResult,
        currentPage: currentPage,
        baseDataTestId: baseDataTestId,
        headerInformation: headerInformationData,
        changeViewType: changeViewType,
        variant: "pi"
      })]
    }))]
  });
  function handleChangeFilters(filters) {
    var searchRedirectURL = getSearchRedirectURL(router, ['FILTERS'], country, language);
    var url = filters.length === 0 ? searchRedirectURL : "".concat(searchRedirectURL, "&FILTERS=").concat(filters.join());
    return router.push(url, undefined, {
      shallow: true
    });
  }
}
var displayInvalidLocationWarning = (headerInformationData, partialTranslations, viewType, language, multiSearchParams, baseDataTestId) => {
  var _partialTranslations$, _partialTranslations$2;
  var description = (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, {
      children: (_partialTranslations$ = partialTranslations.searchInformation) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.content) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.results) === null || _partialTranslations$ === void 0 || (_partialTranslations$ = _partialTranslations$.notifications) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.errorTitle
    }), (0, _jsxRuntime.jsx)(_react.Text, {
      children: (_partialTranslations$2 = partialTranslations.searchInformation) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.content) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.results) === null || _partialTranslations$2 === void 0 || (_partialTranslations$2 = _partialTranslations$2.notifications) === null || _partialTranslations$2 === void 0 ? void 0 : _partialTranslations$2.availabilitiesErrorMessage
    })]
  });
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "warning",
        variant: "alert",
        description: description,
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {
          color: "var(--chakra-colors-alert)"
        }),
        wrapperStyles: notificationWrapperStyles
      })
    })), viewType === mapView && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "md",
      height: "100%",
      children: (0, _jsxRuntime.jsx)(_index.MapViewPIVariant, {
        items: [],
        multiSearchParams: multiSearchParams,
        locale: language,
        partialTranslations: partialTranslations,
        headerInformation: headerInformationData,
        baseDataTestId: baseDataTestId
      })
    })]
  });
};
var displayNoHotelsFoundWarning = description => {
  return (0, _jsxRuntime.jsx)(_atoms.Notification, {
    variant: "alert",
    status: "warning",
    description: description,
    svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
    wrapperStyles: notificationWrapperStyles
  });
};
function getSearchRedirectURL(router, paramsToIgnore, country, language) {
  var queryParamsUrl = (0, _utilities2.getSearchQueryUrl)(router, paramsToIgnore);
  return "".concat(country, "/").concat(language, "/search.html?").concat(queryParamsUrl);
}
function getParamsForQuery(_ref4) {
  var _partialTranslations$3, _partialTranslations$4, _partialTranslations$5, _partialTranslations$6;
  var {
    startDate,
    endDate,
    multiSearchParams,
    place,
    partialTranslations,
    currentPage,
    country,
    language,
    filters,
    cellCodes
  } = _ref4;
  return {
    startDate: startDate && (0, _format.default)(startDate, 'yyyy-MM-dd'),
    endDate: endDate && (0, _format.default)(endDate, 'yyyy-MM-dd'),
    rooms: multiSearchParams.rooms,
    place,
    oldWorldChannel: multiSearchParams.bookingChannel,
    channel: _constants.APP_VARIANT.PI,
    subChannel: multiSearchParams.bookingChannel,
    page: currentPage,
    initialPageSize: (_partialTranslations$3 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$4 = partialTranslations.searchInformation) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.config) === null || _partialTranslations$4 === void 0 || (_partialTranslations$4 = _partialTranslations$4.api) === null || _partialTranslations$4 === void 0 ? void 0 : _partialTranslations$4.initialPageSize) !== null && _partialTranslations$3 !== void 0 ? _partialTranslations$3 : '',
    lazyLoadPageSize: (_partialTranslations$5 = partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$6 = partialTranslations.searchInformation) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.config) === null || _partialTranslations$6 === void 0 || (_partialTranslations$6 = _partialTranslations$6.api) === null || _partialTranslations$6 === void 0 ? void 0 : _partialTranslations$6.lazyLoadPageSize) !== null && _partialTranslations$5 !== void 0 ? _partialTranslations$5 : '',
    country,
    language,
    sort: multiSearchParams.sort,
    filters: filters !== null && filters !== void 0 ? filters : '',
    ratePlanCodes: cellCodes !== null && cellCodes !== void 0 ? cellCodes : []
  };
}
var notificationWrapperStyles = {
  marginTop: {
    base: 'md',
    lg: 'lg'
  },
  zIndex: '1'
};
var containerStyles = {
  maxWidth: {
    mobile: '100%',
    lg: 'var(--chakra-space-breakpoint-lg)',
    xl: 'var(--chakra-space-breakpoint-xl)'
  },
  px: {
    mobile: '1rem',
    sm: '1.25rem',
    md: '1.5rem',
    lg: '1.75rem',
    xl: '4.125rem'
  },
  width: '100%',
  mx: 'auto'
};
var mapViewContainerStyles = {
  height: '100%',
  flexDirection: 'column'
};
var mapViewFiltersErrorNotificationStyles = _objectSpread(_objectSpread({}, containerStyles), {}, {
  mt: {
    base: '7xl',
    sm: '3xl',
    lg: '2xl'
  },
  zIndex: 1,
  px: {
    mobile: '1.1875rem',
    sm: 'var(--chakra-space-lg)',
    md: '2.375rem',
    lg: '1.75rem',
    xl: '4.8125rem'
  }
});
var mobileViewTypeButtonStyles = {
  pos: 'absolute',
  top: 'var(--chakra-space-lg)',
  right: 'var(--chakra-space-sm)',
  zIndex: '10',
  background: 'white',
  padding: 'var(--chakra-space-sm)',
  cursor: 'pointer'
};