"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = ExtrasItemComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function ExtrasItemComponent(_ref) {
  var {
    currency,
    description,
    id,
    name,
    price,
    addOrRemoveExtrasRoom,
    isRemovable,
    isAvailable
  } = _ref;
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var prefixDataTestId = 'Extras-Item';
  var formattedCurrency = (0, _utils.formatCurrency)(currency);
  var formattedPrice = (0, _utils.formatPrice)(formattedCurrency, price === null || price === void 0 ? void 0 : price.toFixed(2), language);
  var formattedName = name === null || name === void 0 ? void 0 : name.replace(' ', '-');
  var isUnavailable = !isRemovable && !isAvailable;
  var buttonName = isUnavailable ? t('hoteldetails.rates.notavailable') : isRemovable ? t('upsell.extras.remove') : t('upsell.extras.add');
  var buttonVariant = isRemovable ? 'tertiary' : 'secondary';
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Wrapper-".concat(formattedName)),
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperImageTitle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, containerImageStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Image-".concat(formattedName)),
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          children: "img"
        })
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerDescriptionStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Title-".concat(formattedName)),
          children: name
        })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, descriptionTextStyle), {}, {
          pt: "xs",
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Description-".concat(formattedName)),
          dangerouslySetInnerHTML: {
            __html: (0, _utils.formatInnerHTMLAssetUrls)(description !== null && description !== void 0 ? description : '')
          }
        }))]
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, buttonAndPriceContainerStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, priceTextStyle), {
        color: isUnavailable ? 'lightGrey1' : 'darkGrey1'
      })), {}, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "Price-".concat(formattedName)),
        children: formattedPrice
      })), (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, buttonAddStyle), {}, {
        variant: buttonVariant,
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "ButtonAdd-".concat(formattedName)),
        onClick: () => addOrRemoveExtrasRoom(id, price),
        isDisabled: isUnavailable,
        children: buttonName
      }))]
    }))]
  }));
}
var containerDescriptionStyle = {
  pl: {
    mobile: 0,
    md: 'lg'
  },
  pr: {
    mobile: 0,
    md: '3xl'
  },
  flexDir: 'column',
  justifyContent: 'space-between'
};
var descriptionTextStyle = {
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal',
  color: 'darkGrey2'
};
var containerStyle = {
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  },
  border: '1px solid var(--chakra-colors-lightGrey3)',
  padding: 'md',
  mt: 'xl'
};
var priceTextStyle = {
  fontSize: 'md',
  lineHeight: '1.375rem',
  fontWeight: 'semibold'
};
var titleStyle = {
  fontSize: '1.25rem',
  lineHeight: '1.5rem',
  fontWeight: 'semibold',
  color: 'darkGrey1'
};
var containerImageStyle = {};
var buttonAndPriceContainerStyle = {
  flexDirection: {
    mobile: 'row',
    sm: 'column'
  },
  alignItems: {
    mobile: 'center',
    sm: 'flex-end'
  },
  alignSelf: 'stretch',
  justifyContent: {
    mobile: 'space-between',
    sm: 'center'
  },
  gap: 1,
  mt: 'xs',
  paddingLeft: {
    mobile: '1rem',
    sm: 0
  },
  paddingRight: {
    mobile: '1rem',
    sm: 0
  }
};
var buttonAddStyle = {
  width: '8rem',
  height: '2.5rem'
};
var wrapperImageTitle = {
  p: 'md',
  gap: 'lg',
  flex: '1 0 0',
  flexDirection: {
    mobile: 'column',
    sm: 'row'
  }
};