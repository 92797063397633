"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.displayStorageSubstitutionLabels = displayStorageSubstitutionLabels;
exports.getI18nLabels = getI18nLabels;
exports.getServerSideCustomLocale = getServerSideCustomLocale;
Object.defineProperty(exports, "transformLabels", {
  enumerable: true,
  get: function get() {
    return _formatters.transformLabels;
  }
});
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _config = _interopRequireDefault(require("next/config"));
var _formatters = require("../formatters");
var _hooks = require("../hooks");
var _logger = require("../logger/logger");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getServerSideCustomLocale(locale) {
  var language = locale === _api.CountryCode.GB ? 'en' : 'de';
  var country = locale === _api.CountryCode.GB ? 'gb' : 'de';
  return {
    language,
    country
  };
}
function getStaticContentLabels(_x) {
  return _getStaticContentLabels.apply(this, arguments);
}
function _getStaticContentLabels() {
  _getStaticContentLabels = (0, _asyncToGenerator2.default)(function* (queryClient) {
    var fetchPreCheckInLabel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var fetchGroupBookingFormLabel = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    var query = (0, _api.getStaticLabels)(fetchPreCheckInLabel, fetchGroupBookingFormLabel);
    var enContent = queryClient.fetchQuery({
      queryKey: ['GetStaticContent', 'en', 'gb'],
      queryFn: () => (0, _hooks.graphQLRequest)(query, {
        language: 'en',
        country: 'gb'
      })
    });
    var deContent = queryClient.fetchQuery({
      queryKey: ['GetStaticContent', 'de', 'de'],
      queryFn: () => (0, _hooks.graphQLRequest)(query, {
        language: 'de',
        country: 'de'
      })
    });
    var [enLabels, deLabels] = yield Promise.all([enContent, deContent]);
    return {
      enLabels,
      deLabels
    };
  });
  return _getStaticContentLabels.apply(this, arguments);
}
function setLabelsInCache(enLabels, deLabels, startTime) {
  var cache = global.WB.cache;
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var expiringTime = startTime + Number(publicRuntimeConfig.NEXT_APP_STATIC_CONTENT_CACHE_TTL) * 1000;
  cache["enLabels"] = {
    value: _objectSpread({}, (0, _formatters.transformLabels)(enLabels)),
    expiringTime
  };
  cache["deLabels"] = {
    value: _objectSpread({}, (0, _formatters.transformLabels)(deLabels)),
    expiringTime
  };
}
function getI18nLabels(_x2) {
  return _getI18nLabels.apply(this, arguments);
}
function _getI18nLabels() {
  _getI18nLabels = (0, _asyncToGenerator2.default)(function* (_ref) {
    var {
      language,
      queryClient,
      fetchPreCheckInLabel = false,
      forceRefetch = false,
      fetchGroupBookingFormLabel = false
    } = _ref;
    var now = new Date().getTime();
    _logger.logger.trace({
      label: 'FETCH_LABELS_START'
    });
    var cache = global.WB.cache;
    var labelsKey = "".concat(language, "Labels");
    var shouldFetchAgain = forceRefetch || !cache[labelsKey] || now >= cache[labelsKey].expiringTime;
    if (shouldFetchAgain) {
      var {
        enLabels,
        deLabels
      } = yield getStaticContentLabels(queryClient, fetchPreCheckInLabel, fetchGroupBookingFormLabel);
      _logger.logger.info({
        label: 'END_FETCH_GET_STATIC_CONTENT',
        message: {
          timeSpentFetchingLabels: "".concat(new Date().getTime() - now, "ms")
        }
      });
      setLabelsInCache(enLabels, deLabels, now);
    }
    _logger.logger.trace({
      label: 'FETCH_LABELS_END'
    });
    return {
      _nextI18Next: {
        initialI18nStore: {
          [language]: {
            common: cache["".concat(labelsKey)].value
          }
        },
        initialLocale: language,
        userConfig: null
      }
    };
  });
  return _getI18nLabels.apply(this, arguments);
}
function displayStorageSubstitutionLabels(currentReservationRoomData, operaRoomName, isSilentFeatureFlagEnabled) {
  return "".concat(isSilentFeatureFlagEnabled && (currentReservationRoomData === null || currentReservationRoomData === void 0 ? void 0 : currentReservationRoomData.silentSubstitution) && (currentReservationRoomData === null || currentReservationRoomData === void 0 ? void 0 : currentReservationRoomData.roomLabelCode) || operaRoomName);
}