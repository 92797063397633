"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgSelfCheckInKiosks = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 21,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.822 0c1.15 0 2.082.895 2.082 2v10c0 1.105-.932 2-2.082 2H16.74v9h.52a.51.51 0 0 1 .52.5.51.51 0 0 1-.52.5H3.726a.51.51 0 0 1-.52-.5c0-.276.232-.5.52-.5h.52v-9H2.164c-1.15 0-2.082-.895-2.082-2V2c0-1.105.932-2 2.082-2h16.658Zm-3.123 14H5.287v9h10.41v-9Zm3.123-13H2.164c-.534 0-.974.386-1.034.883L1.123 2v10c0 .513.402.935.92.993l.121.007h16.658c.534 0 .974-.386 1.034-.883l.007-.117V2c0-.513-.402-.936-.92-.993L18.822 1Zm-5.206 1v10H2.164V2h11.452Zm-1.04 1h-9.37v8h9.37V3Zm5.205 2c.575 0 1.04.448 1.04 1v4c0 .552-.465 1-1.04 1h-2.082c-.575 0-1.042-.448-1.042-1V6c0-.552.466-1 1.041-1h2.083Zm0 1h-2.082v4h2.082V6Zm-7.288 1v1H5.288V7h5.205Zm0-2v1H5.288V5h5.205Zm7.808-2a.51.51 0 0 1 .52.5.51.51 0 0 1-.52.5h-3.123a.51.51 0 0 1-.52-.5c0-.276.232-.5.52-.5H18.3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgSelfCheckInKiosks;