"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TableRow = exports.TableHeader = exports.TableHead = exports.TableFooter = exports.TableCell = exports.TableCaption = exports.TableBody = exports.Table = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _server = require("@whitbread-eos/utils/server");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["className"],
  _excluded2 = ["className"],
  _excluded3 = ["className"],
  _excluded4 = ["className"],
  _excluded5 = ["className"],
  _excluded6 = ["className"],
  _excluded7 = ["className"],
  _excluded8 = ["className"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var Table = exports.Table = React.forwardRef((_ref, ref) => {
  var {
      className
    } = _ref,
    props = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  return (0, _jsxRuntime.jsx)("div", {
    className: "relative w-full overflow-auto",
    children: (0, _jsxRuntime.jsx)("table", _objectSpread({
      ref: ref,
      className: (0, _server.cn)('text-sm table-auto rounded-lg border  border-separate border-spacing-0 border-lightGrey3 w-full font-normal text-4', className)
    }, props))
  });
});
Table.displayName = 'Table';
var TableHeader = exports.TableHeader = React.forwardRef((_ref2, ref) => {
  var {
      className
    } = _ref2,
    props = (0, _objectWithoutProperties2.default)(_ref2, _excluded2);
  return (0, _jsxRuntime.jsx)("thead", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('bg-successTint px-4 py-2 h-10 font-normal', className)
  }, props));
});
TableHeader.displayName = 'TableHeader';
var TableBody = exports.TableBody = React.forwardRef((_ref3, ref) => {
  var {
      className
    } = _ref3,
    props = (0, _objectWithoutProperties2.default)(_ref3, _excluded3);
  return (0, _jsxRuntime.jsx)("tbody", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('h-10', className)
  }, props));
});
TableBody.displayName = 'TableBody';
var TableFooter = exports.TableFooter = React.forwardRef((_ref4, ref) => {
  var {
      className
    } = _ref4,
    props = (0, _objectWithoutProperties2.default)(_ref4, _excluded4);
  return (0, _jsxRuntime.jsx)("tfoot", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('', className)
  }, props));
});
TableFooter.displayName = 'TableFooter';
var TableRow = exports.TableRow = React.forwardRef((_ref5, ref) => {
  var {
      className
    } = _ref5,
    props = (0, _objectWithoutProperties2.default)(_ref5, _excluded5);
  return (0, _jsxRuntime.jsx)("tr", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('', className)
  }, props));
});
TableRow.displayName = 'TableRow';
var TableHead = exports.TableHead = React.forwardRef((_ref6, ref) => {
  var {
      className
    } = _ref6,
    props = (0, _objectWithoutProperties2.default)(_ref6, _excluded6);
  return (0, _jsxRuntime.jsx)("th", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('font-normal text-left px-4', className)
  }, props));
});
TableHead.displayName = 'TableHead';
var TableCell = exports.TableCell = React.forwardRef((_ref7, ref) => {
  var {
      className
    } = _ref7,
    props = (0, _objectWithoutProperties2.default)(_ref7, _excluded7);
  return (0, _jsxRuntime.jsx)("td", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('px-4', className)
  }, props));
});
TableCell.displayName = 'TableCell';
var TableCaption = exports.TableCaption = React.forwardRef((_ref8, ref) => {
  var {
      className
    } = _ref8,
    props = (0, _objectWithoutProperties2.default)(_ref8, _excluded8);
  return (0, _jsxRuntime.jsx)("caption", _objectSpread({
    ref: ref,
    className: (0, _server.cn)('', className)
  }, props));
});
TableCaption.displayName = 'TableCaption';