"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgBritishFlagRounded = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M1.417 6.41c-.46.852-.818 1.767-1.06 2.727h4.757L1.417 6.41ZM9.053.491a11.958 11.958 0 0 0-5.558 3.135l5.558 4.099V.491ZM14.864 23.562a11.958 11.958 0 0 0 5.558-3.135l-5.558-4.099v7.234ZM22.5 17.643c.461-.852.82-1.766 1.06-2.727h-4.756l3.697 2.727ZM23.56 9.137c-.24-.96-.599-1.875-1.06-2.727l-3.696 2.727h4.757ZM5.114 14.916H.357c.242.96.6 1.875 1.06 2.727l3.697-2.727ZM3.495 20.427a11.959 11.959 0 0 0 5.558 3.135v-7.234l-5.558 4.1ZM20.422 3.626A11.959 11.959 0 0 0 14.864.491v7.234l5.558-4.099Z",
    fill: "#0B318F"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M8.041 9.137 2.354 4.942c-.231.31-.447.63-.648.962L6.09 9.137H8.04ZM15.877 14.916l5.687 4.195c.23-.31.447-.63.647-.962l-4.383-3.233h-1.951ZM9.053 14.916H8.04l-5.686 4.195c.23.31.477.608.738.894l5.96-4.396v-.693ZM14.864 9.137h1.012l5.687-4.194c-.231-.31-.478-.61-.738-.895l-5.96 4.396v.693Z",
    fill: "#E60012"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.79 13.76H13.703v10.03a11.96 11.96 0 0 0 1.162-.228v-7.234l5.558 4.1c.412-.41.793-.851 1.141-1.318l-5.686-4.194h1.95l4.384 3.233c.1-.166.197-.335.289-.506l-3.697-2.727h4.756c.096-.378.172-.764.23-1.156ZM23.56 9.137h-4.756L22.5 6.41a11.875 11.875 0 0 0-.937-1.467l-5.687 4.194h-1.012v-.693l5.96-4.396a11.9 11.9 0 0 0-.403-.422l-5.557 4.099V.49c-.38-.094-.768-.171-1.162-.228v10.03H23.79a11.743 11.743 0 0 0-.23-1.156ZM.128 13.76c.057.392.134.778.23 1.156h4.756l-3.697 2.727c.277.513.59 1.003.937 1.467l5.687-4.194h1.012v.693l-5.961 4.396c.13.144.265.285.403.422l5.558-4.099v7.234c.38.095.768.171 1.162.229V13.76H.128ZM9.053.491v7.234L3.495 3.626c-.412.41-.793.85-1.141 1.317L8.04 9.137H6.09L1.706 5.904c-.1.166-.197.335-.29.506l3.698 2.727H.357c-.095.379-.172.764-.23 1.156H10.216V.263C9.821.32 9.434.397 9.053.49Z",
    fill: "#fff"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M23.79 10.293H13.702V.263a12.122 12.122 0 0 0-3.488 0v10.03H.128A11.934 11.934 0 0 0 0 12.027c0 .589.045 1.167.128 1.733h10.087v10.03a12.087 12.087 0 0 0 3.488 0V13.76H23.79a11.926 11.926 0 0 0 0-3.467Z",
    fill: "#E60012"
  })]
}));
var _default = exports.default = SvgBritishFlagRounded;