"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AgentMemoContext = void 0;
exports.AgentMemoProvider = AgentMemoProvider;
exports.useAgentMemo = useAgentMemo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _api = require("@whitbread-eos/api");
var _react = _interopRequireWildcard(require("react"));
var _hooks = require("../hooks");
var _noop = _interopRequireDefault(require("../utils/noop"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["agentMemoState", "isAgentMemoOpen"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var AgentMemoContext = exports.AgentMemoContext = _react.default.createContext({
  isAgentMemoOpen: false,
  openAgentMemo: _noop.default,
  closeAgentMemo: _noop.default,
  setAgentMemoReservationId: _noop.default,
  agentMemoState: {
    reservationId: '',
    variant: ''
  },
  user: {}
});
function useAgentMemo() {
  var _agentMemoData$memos;
  var _useContext = (0, _react.useContext)(AgentMemoContext),
    {
      agentMemoState,
      isAgentMemoOpen
    } = _useContext,
    rest = (0, _objectWithoutProperties2.default)(_useContext, _excluded);
  var {
    data: agentMemoData
  } = (0, _hooks.useQueryRequest)(['GetAgentMemos', agentMemoState.reservationId], _api.GET_AGENT_MEMOS, {
    basketReference: agentMemoState.reservationId
  }, {
    enabled: !!agentMemoState.reservationId,
    select: data => {
      return data.getMemos || data.createMemo;
    },
    onError: error => {
      console.log(error);
    }
  }, undefined, !!'returnError');
  return _objectSpread({
    agentMemoData,
    agentMemoCount: agentMemoData === null || agentMemoData === void 0 || (_agentMemoData$memos = agentMemoData.memos) === null || _agentMemoData$memos === void 0 ? void 0 : _agentMemoData$memos.length,
    agentMemoState,
    isAgentMemoOpen
  }, rest);
}
function AgentMemoProvider(_ref) {
  var {
    children,
    user
  } = _ref;
  var defaultState = {
    reservationId: '',
    variant: _api.MemoModalVariants.PAGE
  };
  var [isAgentMemoOpen, setIsAgentMemoOpen] = (0, _react.useState)(false);
  var [agentMemoState, setAgentMemoState] = (0, _react.useState)(defaultState);
  var setAgentMemoReservationId = reservationId => {
    setAgentMemoState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      reservationId
    }));
  };
  var openAgentMemo = variant => {
    setAgentMemoState(prev => _objectSpread(_objectSpread({}, prev), {}, {
      variant
    }));
    setIsAgentMemoOpen(true);
  };
  var closeAgentMemo = () => {
    if (agentMemoState.variant === _api.MemoModalVariants.CARD) {
      setAgentMemoState(defaultState);
    }
    setIsAgentMemoOpen(false);
  };
  var value = {
    isAgentMemoOpen,
    openAgentMemo,
    closeAgentMemo,
    agentMemoState,
    setAgentMemoReservationId,
    user
  };
  return (0, _jsxRuntime.jsx)(AgentMemoContext.Provider, {
    value: value,
    children: children
  });
}