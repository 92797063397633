"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _Countries = _interopRequireDefault(require("../guest-details/Countries"));
var _RoomCard = _interopRequireDefault(require("./RoomCard"));
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var PersonalDetails = _ref => {
  var _errors$nationality, _errors$nationality2, _errors$nationality3, _errors$dateOfBirth;
  var {
    control,
    formField,
    errors,
    getValues,
    handleSetValue
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    query
  } = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    setIsLocationRequired
  } = formField.props;
  var [darkGrey1] = (0, _react.useToken)('colors', ['darkGrey1']);
  var [lightGrey1, primary] = (0, _react.useToken)('colors', ['lightGrey1', 'primary']);
  var [space4xl] = (0, _react.useToken)('space', ['4xl']);
  var [fontMd] = (0, _react.useToken)('fontSizes', ['md']);
  var [darkGrey2] = (0, _react.useToken)('colors', ['darkGrey2']);
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  var [countries, setCountries] = (0, _react2.useState)([]);
  var [nationalities, setNationalities] = (0, _react2.useState)([]);
  var [accordionIndex, setAccordionIndex] = (0, _react2.useState)(0);
  var [toggleDobInfo, setToggleDobInfo] = (0, _react2.useState)(false);
  var {
    data: countriesData,
    isSuccess: countriesRequestSuccess
  } = (0, _utils.useQueryRequest)(['getCountries', country, language, 'leisure'], _api.GET_COUNTRIES, {
    country,
    language,
    site: 'leisure'
  });
  var nationalityErrorMessage = errors !== null && errors !== void 0 && (_errors$nationality = errors['nationality']) !== null && _errors$nationality !== void 0 && _errors$nationality.message ? errors === null || errors === void 0 || (_errors$nationality2 = errors['nationality']) === null || _errors$nationality2 === void 0 ? void 0 : _errors$nationality2.message : errors === null || errors === void 0 || (_errors$nationality3 = errors['nationality']) === null || _errors$nationality3 === void 0 || (_errors$nationality3 = _errors$nationality3.value) === null || _errors$nationality3 === void 0 ? void 0 : _errors$nationality3.message;
  var multiSelectStyles = {
    control: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      borderColor: lightGrey1,
      minHeight: space4xl,
      fontSize: fontMd,
      boxShadow: 'none',
      borderWidth: '1px',
      borderRadius: '0.375rem',
      ':hover': {
        borderColor: lightGrey1
      },
      border: nationalityErrorMessage ? '2px solid var(--chakra-colors-error)' : baseStyles.border
    }),
    placeholder: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      color: darkGrey2
    }),
    valueContainer: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      padding: '2px 16px'
    }),
    container: baseStyles => _objectSpread(_objectSpread({}, baseStyles), {}, {
      '&:hover': {
        borderColor: primary
      }
    })
  };
  (0, _react2.useEffect)(() => {
    if (countriesRequestSuccess && !(countries !== null && countries !== void 0 && countries.length)) {
      var _countriesData$countr;
      var sortedCountries = (0, _utils.getSortedCountriesByCurrentLang)(countriesData === null || countriesData === void 0 || (_countriesData$countr = countriesData.countries) === null || _countriesData$countr === void 0 ? void 0 : _countriesData$countr.countries, language);
      setCountries(sortedCountries === null || sortedCountries === void 0 ? void 0 : sortedCountries.map(_ref2 => {
        var {
          countryName
        } = _ref2;
        return {
          value: countryName
        };
      }));
      setNationalities(sortedCountries === null || sortedCountries === void 0 ? void 0 : sortedCountries.map(_ref3 => {
        var {
          nationality = '',
          countryCode,
          flagSrc
        } = _ref3;
        return {
          value: countryCode,
          label: nationality || '',
          image: flagSrc
        };
      }));
    }
  }, [countriesRequestSuccess, countriesData]);
  var datePickerStyles = _objectSpread(_objectSpread({}, _common.inputStyle), {}, {
    _placeholder: {
      color: darkGrey1,
      opacity: 0.8
    }
  });
  if (errors !== null && errors !== void 0 && (_errors$dateOfBirth = errors.dateOfBirth) !== null && _errors$dateOfBirth !== void 0 && _errors$dateOfBirth.message) {
    datePickerStyles = _objectSpread(_objectSpread({}, datePickerStyles), {}, {
      border: '1px solid var(--chakra-colors-error)',
      borderColor: 'none'
    });
  }
  (0, _react2.useEffect)(() => {
    var timeoutId;
    if (toggleDobInfo) timeoutId = setTimeout(() => setToggleDobInfo(false), 3000);
    return () => clearTimeout(timeoutId);
  }, [toggleDobInfo]);
  var fieldController = _ref4 => {
    var _errors$name, _errors$name2, _errors$name3, _errors$name4;
    var {
      field,
      label,
      name,
      type,
      testId,
      disabled
    } = _ref4;
    switch (type) {
      case 'input':
        return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), (0, _common.fieldProps)(field)), {}, {
          isDisabled: disabled,
          type: "text",
          showIcon: false,
          placeholderText: t("precheckin.details.".concat(label)),
          showLabel: true,
          label: t("precheckin.details.".concat(label)),
          error: errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
          className: "sessioncamhidetext assist-no-show"
        }));
      case 'countryDropdown':
        return (0, _jsxRuntime.jsx)(_Countries.default, {
          formField: _objectSpread(_objectSpread({}, formField), {}, {
            label: t("precheckin.details.".concat(label)),
            type: _atoms.FORM_FIELD_TYPES.DYNAMIC_FIELD,
            testid: (0, _utils.formatDataTestId)(formField.testid, 'CountryDropdown'),
            props: _objectSpread(_objectSpread({}, formField.props), {}, {
              showIcon: false,
              setIsLocationRequired,
              styles: _common.countryDropdownStyle
            })
          }),
          getValues: getValues(),
          field: field
        });
      case 'datePicker':
        return (0, _jsxRuntime.jsxs)(_react.Box, {
          position: "relative",
          mb: toggleDobInfo ? '2xl' : '',
          children: [(0, _jsxRuntime.jsx)(_atoms.Tooltip, _objectSpread(_objectSpread({}, tooltipStyle), {}, {
            description: t('precheckin.dob.tooltip'),
            variant: "inlineInfo",
            placement: "bottom-start",
            isOpen: toggleDobInfo,
            textStyle: {
              fontSize: '9px'
            },
            position: "relative",
            children: (0, _jsxRuntime.jsx)(_react.InputGroup, {
              display: "block",
              isolation: "auto",
              children: (0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, _objectSpread(_objectSpread({}, language === 'de' ? {
                locale: 'de'
              } : {
                locale: 'en'
              }), {}, {
                name: name,
                minDate: new Date(new Date().getFullYear() - 100, 0, 1),
                maxDate: new Date(),
                inputPlaceholder: t("precheckin.details.".concat(label)),
                inputLabel: t("precheckin.details.".concat(label)),
                defaultStartDate: field.value || null,
                isRightIcon: true,
                popperPlacement: isLargerThanSm ? 'bottom' : 'top',
                displayDateFormat: "dd MMM yyyy",
                dataTestId: (0, _utils.formatDataTestId)(formField.testid, testId),
                dateFormat: "dd MMM yyyy",
                customHeader: true,
                labels: {
                  todayLabel: _common.todayLabel,
                  tomorrowLabel: _common.tomorrowLabel
                },
                datepickerStyles: {
                  inputGroupStyles: {},
                  datepickerInputElementStyles: datePickerStyles,
                  iconStyles: _common.inputIconStyles
                },
                onSelectDate: field.onChange,
                isError: errors !== null && errors !== void 0 && (_errors$name2 = errors[name]) !== null && _errors$name2 !== void 0 && _errors$name2.message ? true : false
              }))
            })
          })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, infoIconStyles), {}, {
            onClick: () => !toggleDobInfo && setToggleDobInfo(true),
            children: (0, _jsxRuntime.jsx)(_atoms.Info, {
              "data-testid": "datepicker-info-icon"
            })
          })), (errors === null || errors === void 0 || (_errors$name3 = errors[name]) === null || _errors$name3 === void 0 ? void 0 : _errors$name3.message) && (0, _jsxRuntime.jsx)(_react.Text, {
            style: _common.dataErrorText,
            mt: "2",
            children: errors === null || errors === void 0 || (_errors$name4 = errors[name]) === null || _errors$name4 === void 0 ? void 0 : _errors$name4.message
          })]
        });
      case 'autoComplete':
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.MultiSelect, {
            name: name,
            options: nationalities,
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, testId),
            value: (0, _common.getNationality)(field.value, nationalities),
            onChange: fieldProp => (0, _common.handleNationalityChange)(fieldProp, field, 'passport', handleSetValue),
            placeholder: t("precheckin.details.".concat(label)),
            styles: multiSelectStyles,
            isClearable: true
          }), nationalityErrorMessage && (0, _jsxRuntime.jsx)(_react.Text, {
            style: _common.dataErrorText,
            mt: "2",
            children: nationalityErrorMessage
          })]
        });
      default:
        return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }
  };
  var {
    firstName,
    lastName,
    adultsNumber,
    childrenNumber,
    roomName,
    roomNo,
    preCheckInStatus
  } = getValues();
  var room = {
    firstName,
    lastName,
    adultsNumber,
    childrenNumber,
    roomName,
    preCheckInStatus
  };
  (0, _react2.useEffect)(() => {
    var _Object$keys;
    if (errors && (_Object$keys = Object.keys(errors)) !== null && _Object$keys !== void 0 && _Object$keys.length) {
      setAccordionIndex(0);
    }
    (0, _common.addScrollEvent)();
  }, [errors]);
  return (0, _jsxRuntime.jsx)(_atoms.Accordion, {
    accordionItems: [{
      onToggleSection: () => (0, _common.handleAccordionToggle)(accordionIndex, setAccordionIndex),
      title: query !== null && query !== void 0 && query.reservationId ? (0, _jsxRuntime.jsx)(_RoomCard.default, {
        room: room,
        roomIndex: roomNo,
        testid: 'LeadGuestDetailsHeader',
        roomCardContainerStyle: roomCardContainerStyle,
        displayIcon: false,
        clickEventRequired: false,
        preCheckInStatus: room.preCheckInStatus
      }) : t('precheckin.yourdetails.title'),
      content: (0, _react2.createElement)(_react.Flex, _objectSpread(_objectSpread({
        wrap: "wrap",
        justifyContent: 'space-between',
        flexDirection: "column"
      }, _common.wraperBoxStyle), {}, {
        key: "personalDetail-".concat(roomNo || 0)
      }), !!roomNo && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomCardContainerStyle.subHeadingStyle), {}, {
        mb: '2xl',
        children: t('precheckin.yourdetails.title')
      })), _common.personalDetailsFields.map(_ref5 => {
        var {
          label,
          name,
          testId,
          type,
          disabled
        } = _ref5;
        return !!(name !== 'passport' || (0, _common.showPassportField)(getValues(), 'nationality')) && (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, testId),
          key: "".concat(name, "-").concat(roomNo || 0)
        }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: name,
          control: control,
          render: _ref6 => {
            var {
              field
            } = _ref6;
            return fieldController({
              field,
              label,
              name,
              type,
              testId,
              disabled
            });
          }
        }));
      }))
    }],
    bgColor: "var(--chakra-colors-baseWhite)",
    allowMultiple: false,
    accordionOverwriteStyles: _objectSpread(_objectSpread({}, _common.accordionOverwriteStyles), {}, {
      container: _objectSpread(_objectSpread({}, _common.accordionOverwriteStyles === null || _common.accordionOverwriteStyles === void 0 ? void 0 : _common.accordionOverwriteStyles.container), {}, {
        index: [accordionIndex]
      })
    })
  });
};
var roomCardContainerStyle = {
  boxParentStyle: {
    border: 0,
    p: {
      mobile: '0',
      md: '0'
    },
    mb: '0',
    mt: 0,
    px: {
      mobile: '0',
      md: '0'
    },
    cursor: 'pointer'
  },
  boxesContainerStyle: {
    p: {
      mobile: 'md md',
      md: 'xl xl'
    },
    direction: {
      mobile: 'column',
      md: 'row'
    },
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  firstColumnStyle: {
    w: {
      mobile: '100%',
      md: 'auto'
    },
    pr: 'md'
  },
  secondColumnStyle: {
    w: {
      mobile: '100%',
      md: 'auto'
    },
    alignSelf: 'flex-end',
    fontWeight: 'normal',
    fontSize: 'md'
  },
  thirdColumnStyle: {
    ml: 'auto',
    w: {
      mobile: '100%',
      md: 'auto'
    },
    alignSelf: 'center',
    textAlign: 'right'
  },
  headingStyle: {
    fontSize: '2xl',
    fontWeight: 'semibold',
    color: 'baseBlack',
    mb: 'lg',
    mt: 'sm'
  },
  subHeadingStyle: {
    fontSize: 'lg',
    fontWeight: 'semibold',
    color: 'baseBlack',
    textTransform: 'capitalize',
    mb: 'md'
  },
  textStyle: {
    color: 'var(--chakra-colors-darkGrey1)',
    fontWeight: 'sm',
    textTransform: 'capitalize'
  },
  iconStyle: {
    justifyContent: 'center'
  }
};
var infoIconStyles = {
  position: 'absolute',
  right: '-30px',
  top: '4',
  display: {
    sm: 'none',
    md: 'block'
  },
  py: '1',
  cursor: 'pointer'
};
var tooltipStyle = {
  h: '2.5rem',
  fontSize: '9px',
  display: 'flex',
  alignContent: 'center',
  maxWidth: '100%'
};
var _default = exports.default = PersonalDetails;