"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BBGuestDetailsRoom;
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _DynamicSearchEmployees = _interopRequireDefault(require("./DynamicSearchEmployees"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onChange", "ref"],
  _excluded2 = ["onChange", "ref"],
  _excluded3 = ["onChange", "ref"],
  _excluded4 = ["onChange", "ref"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BBGuestDetailsRoom(_ref) {
  var _userData$business, _errors$bbGuestDetail3, _errors$bbGuestDetail4;
  var {
    roomNumber,
    numberOfRooms,
    labels,
    testid,
    t,
    control,
    errors,
    dropdownOptions,
    formField,
    queryClient,
    guestList,
    setGuestUser,
    index,
    reset,
    isDynamicSearchVisible,
    isAmendPage,
    bbEmployeeList,
    defaultGuest,
    onEditBbInput
  } = _ref;
  var [displayDynamic, setDisplayDynamic] = (0, _react2.useState)(isDynamicSearchVisible);
  var prevObject = (0, _react2.useRef)({});
  var getComposedGuest = () => {
    var _control$_formValues;
    if ((_control$_formValues = control._formValues) !== null && _control$_formValues !== void 0 && (_control$_formValues = _control$_formValues.bbGuestDetails[index]) !== null && _control$_formValues !== void 0 && _control$_formValues.composedName) {
      var _control$_formValues2;
      var composedName = ((_control$_formValues2 = control._formValues) === null || _control$_formValues2 === void 0 || (_control$_formValues2 = _control$_formValues2.bbGuestDetails) === null || _control$_formValues2 === void 0 ? void 0 : _control$_formValues2[index].composedName.split(' ')) || [];
      prevObject.current = {
        title: composedName[0],
        firstName: composedName[1],
        lastName: composedName[2],
        emailAddress: composedName[3].replace(/[{()}]/g, '')
      };
      return _objectSpread({}, prevObject.current);
    }
    return {
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: ''
    };
  };
  (0, _react2.useEffect)(() => {
    var _control$_formValues$;
    if (!displayDynamic && (_control$_formValues$ = control._formValues.bbGuestDetails[index].id) !== null && _control$_formValues$ !== void 0 && _control$_formValues$.length && !(0, _utils.isTheSameGuest)(control._formValues.bbGuestDetails[index], getComposedGuest())) {
      control._formValues.bbGuestDetails[index].id = '';
    }
  });
  var handleSwitchToDynamic = () => {
    if (Object.keys(control._formState.errors).length > 0 && control._formState.errors.bbGuestDetails) {
      control._formState.errors.bbGuestDetails[index] = {};
    }
    reset(guestList, {
      keepValues: true,
      keepErrors: true
    });
    setDisplayDynamic(!displayDynamic);
  };
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var userData = (0, _utils.useUserDetails)(true, isLoggedIn);
  var accessLevel = userData === null || userData === void 0 || (_userData$business = userData.business) === null || _userData$business === void 0 ? void 0 : _userData$business.accessLevel;
  var isInputDisabled = isAmendPage && accessLevel === _api.BUSINESS_BOOKER_USER_ROLES.SELF;
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(testid, "Room-".concat(roomNumber + 1)),
    children: [numberOfRooms > 1 && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, roomNumberStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testid, "LabelRoomNumber ".concat(roomNumber + 1)),
      children: "".concat(t('booking.summary.room'), " ").concat(roomNumber + 1)
    })), displayDynamic ? (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDirection: "column",
      "data-testid": (0, _utils.formatDataTestId)(testid, "DynamicGuestLead-".concat(roomNumber + 1)),
      children: [(0, _jsxRuntime.jsx)(_DynamicSearchEmployees.default, {
        baseDataTestId: testid,
        queryClient: queryClient,
        listExclusion: guestList.bbGuestDetails,
        setGuestUser: setGuestUser,
        setDisplayDynamic: setDisplayDynamic,
        index: index,
        control: control,
        errors: errors,
        isDisabled: isInputDisabled,
        bbEmployeeList: bbEmployeeList,
        defaultGuest: defaultGuest,
        isAmendPage: isAmendPage,
        amendInputWidth: amendInputWidth,
        onEditBbInput: onEditBbInput
      }), !isInputDisabled && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, switchLinkStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(testid, 'SwitchToManual'),
        onClick: () => {
          if (control._formState.errors.bbGuestDetails && Object.keys(control._formState.errors.bbGuestDetails[index] || {}).length) {
            control._formState.errors.bbGuestDetails[index] = {};
          }
          setDisplayDynamic(!displayDynamic);
        },
        children: t('booking.enterManualEmployee')
      }))]
    }) : (0, _jsxRuntime.jsxs)(_react.Flex, {
      flexDirection: "column",
      "data-testid": (0, _utils.formatDataTestId)(testid, "ManualGuestLead-".concat(roomNumber + 1)),
      children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, inputWrapperStyle), {}, {
        width: isAmendPage ? amendInputWidth : inputWrapperStyle.width,
        children: [(0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: "bbGuestDetails[".concat(roomNumber, "][title]"),
          control: control,
          render: _ref2 => {
            var _errors$bbGuestDetail, _errors$bbGuestDetail2;
            var {
              field
            } = _ref2;
            var {
                onChange: _onChange,
                ref: _
              } = field,
              extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded);
            return (0, _jsxRuntime.jsx)(_atoms.Dropdown, _objectSpread(_objectSpread({}, extraPropsField), {}, {
              onChange: o => {
                _onChange(o === null || o === void 0 ? void 0 : o.id);
                var editedField = {
                  key: 'title',
                  value: o === null || o === void 0 ? void 0 : o.id
                };
                onEditBbInput === null || onEditBbInput === void 0 || onEditBbInput(editedField);
              },
              showStatusIcon: true,
              options: dropdownOptions,
              placeholder: labels === null || labels === void 0 ? void 0 : labels.title,
              hasError: Boolean(errors === null || errors === void 0 || (_errors$bbGuestDetail = errors.bbGuestDetails) === null || _errors$bbGuestDetail === void 0 || (_errors$bbGuestDetail = _errors$bbGuestDetail[roomNumber]) === null || _errors$bbGuestDetail === void 0 || (_errors$bbGuestDetail = _errors$bbGuestDetail.title) === null || _errors$bbGuestDetail === void 0 ? void 0 : _errors$bbGuestDetail.message),
              dataTestId: (0, _utils.formatDataTestId)(testid, 'TitleDropdown'),
              selectedId: field.value,
              matchWidth: true,
              dropdownStyles: _objectSpread(_objectSpread({}, dropdownStyles), {}, {
                menuButtonStyles: renderDropdownStyles(Boolean(errors === null || errors === void 0 || (_errors$bbGuestDetail2 = errors.bbGuestDetails) === null || _errors$bbGuestDetail2 === void 0 || (_errors$bbGuestDetail2 = _errors$bbGuestDetail2[roomNumber]) === null || _errors$bbGuestDetail2 === void 0 || (_errors$bbGuestDetail2 = _errors$bbGuestDetail2.title) === null || _errors$bbGuestDetail2 === void 0 ? void 0 : _errors$bbGuestDetail2.message))
              })
            }));
          }
        }), (errors === null || errors === void 0 || (_errors$bbGuestDetail3 = errors.bbGuestDetails) === null || _errors$bbGuestDetail3 === void 0 || (_errors$bbGuestDetail3 = _errors$bbGuestDetail3[roomNumber]) === null || _errors$bbGuestDetail3 === void 0 || (_errors$bbGuestDetail3 = _errors$bbGuestDetail3.title) === null || _errors$bbGuestDetail3 === void 0 ? void 0 : _errors$bbGuestDetail3.message) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, formErrorStyles), {}, {
          "data-testid": (0, _utils.formatDataTestId)(testid, 'ErrorContainer'),
          children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, errorMessageStyles), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, errorTextStyles), {}, {
              "data-testid": (0, _utils.formatDataTestId)(testid, 'ErrorText'),
              children: errors === null || errors === void 0 || (_errors$bbGuestDetail4 = errors.bbGuestDetails) === null || _errors$bbGuestDetail4 === void 0 || (_errors$bbGuestDetail4 = _errors$bbGuestDetail4[roomNumber]) === null || _errors$bbGuestDetail4 === void 0 || (_errors$bbGuestDetail4 = _errors$bbGuestDetail4.title) === null || _errors$bbGuestDetail4 === void 0 ? void 0 : _errors$bbGuestDetail4.message
            }))
          }))
        }))]
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputWrapperStyle), {}, {
        width: isAmendPage ? amendInputWidth : inputWrapperStyle.width,
        children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: "bbGuestDetails[".concat(roomNumber, "][firstName]"),
          control: control,
          render: _ref3 => {
            var _errors$bbGuestDetail5;
            var {
              field
            } = _ref3;
            var {
                onChange: _onChange2,
                ref: _
              } = field,
              extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded2);
            return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), {}, {
              styles: formInputStyle
            }, extraPropsField), {}, {
              onChange: v => {
                _onChange2(v);
                var editedField = {
                  key: 'firstName',
                  value: v
                };
                onEditBbInput === null || onEditBbInput === void 0 || onEditBbInput(editedField);
              },
              type: "text",
              showIcon: true,
              placeholderText: labels === null || labels === void 0 ? void 0 : labels.firstName,
              label: labels === null || labels === void 0 ? void 0 : labels.firstName,
              error: errors === null || errors === void 0 || (_errors$bbGuestDetail5 = errors.bbGuestDetails) === null || _errors$bbGuestDetail5 === void 0 || (_errors$bbGuestDetail5 = _errors$bbGuestDetail5[roomNumber]) === null || _errors$bbGuestDetail5 === void 0 || (_errors$bbGuestDetail5 = _errors$bbGuestDetail5.firstName) === null || _errors$bbGuestDetail5 === void 0 ? void 0 : _errors$bbGuestDetail5.message,
              className: "sessioncamhidetext assist-no-show",
              "data-testid": (0, _utils.formatDataTestId)(testid, 'FirstName')
            }));
          }
        })
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputWrapperStyle), {}, {
        width: isAmendPage ? amendInputWidth : inputWrapperStyle.width,
        children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: "bbGuestDetails[".concat(roomNumber, "][lastName]"),
          control: control,
          render: _ref4 => {
            var _errors$bbGuestDetail6;
            var {
              field
            } = _ref4;
            var {
                onChange: _onChange3,
                ref: _
              } = field,
              extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded3);
            return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), {}, {
              styles: formInputStyle
            }, extraPropsField), {}, {
              onChange: v => {
                _onChange3(v);
                var editedField = {
                  key: 'lastName',
                  value: v
                };
                onEditBbInput === null || onEditBbInput === void 0 || onEditBbInput(editedField);
              },
              type: "text",
              placeholderText: labels === null || labels === void 0 ? void 0 : labels.lastName,
              label: labels === null || labels === void 0 ? void 0 : labels.lastName,
              showIcon: true,
              error: errors === null || errors === void 0 || (_errors$bbGuestDetail6 = errors.bbGuestDetails) === null || _errors$bbGuestDetail6 === void 0 || (_errors$bbGuestDetail6 = _errors$bbGuestDetail6[roomNumber]) === null || _errors$bbGuestDetail6 === void 0 || (_errors$bbGuestDetail6 = _errors$bbGuestDetail6.lastName) === null || _errors$bbGuestDetail6 === void 0 ? void 0 : _errors$bbGuestDetail6.message,
              className: "sessioncamhidetext assist-no-show",
              "data-testid": (0, _utils.formatDataTestId)(testid, 'LastName')
            }));
          }
        })
      })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, inputWrapperStyle), {}, {
        width: isAmendPage ? amendInputWidth : inputWrapperStyle.width,
        children: (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: "bbGuestDetails[".concat(roomNumber, "][emailAddress]"),
          control: control,
          render: _ref5 => {
            var _errors$bbGuestDetail7;
            var {
              field
            } = _ref5;
            var {
                onChange: _onChange4,
                ref: _
              } = field,
              extraPropsField = (0, _objectWithoutProperties2.default)(field, _excluded4);
            return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), {}, {
              styles: formInputStyle
            }, extraPropsField), {}, {
              onChange: v => {
                _onChange4(v);
                var editedField = {
                  key: 'emailAddress',
                  value: v
                };
                onEditBbInput === null || onEditBbInput === void 0 || onEditBbInput(editedField);
              },
              type: "text",
              placeholderText: labels === null || labels === void 0 ? void 0 : labels.email,
              label: labels === null || labels === void 0 ? void 0 : labels.email,
              error: errors === null || errors === void 0 || (_errors$bbGuestDetail7 = errors.bbGuestDetails) === null || _errors$bbGuestDetail7 === void 0 || (_errors$bbGuestDetail7 = _errors$bbGuestDetail7[roomNumber]) === null || _errors$bbGuestDetail7 === void 0 || (_errors$bbGuestDetail7 = _errors$bbGuestDetail7.emailAddress) === null || _errors$bbGuestDetail7 === void 0 ? void 0 : _errors$bbGuestDetail7.message,
              showIcon: true,
              className: "sessioncamhidetext assist-no-show",
              "data-testid": (0, _utils.formatDataTestId)(testid, 'Email')
            }));
          }
        })
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(testid, 'SwitchToDynamic')
      }, switchLinkStyle), {}, {
        onClick: handleSwitchToDynamic,
        children: t('booking.searchForEmployee')
      }))]
    }, roomNumber)]
  });
}
var roomNumberStyle = {
  fontSize: 'md',
  fontWeight: 'semibold',
  lineHeight: '3',
  color: 'darkGrey1',
  mt: 'xl'
};
var inputWrapperStyle = {
  width: {
    mobile: '100%',
    sm: '21.938rem',
    md: '21.75rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  marginTop: 'md',
  borderColor: 'lightGrey1'
};
var formInputStyle = {
  inputElementStyles: {
    borderColor: 'lightGrey1',
    _hover: {
      borderColor: 'darkGrey1'
    }
  }
};
var formErrorStyles = {
  height: 'var(--chakra-space-lg)',
  position: 'relative'
};
var errorMessageStyles = {
  position: 'absolute'
};
var errorTextStyles = {
  color: 'error',
  fontSize: 'xs',
  marginLeft: 'md',
  marginTop: 'sm',
  whiteSpace: 'nowrap'
};
var renderDropdownStyles = hasError => {
  return {
    border: hasError ? '2px solid' : '1px solid',
    borderColor: hasError ? 'var(--chakra-colors-error)' : 'lightGrey1',
    borderRadius: 'var(--chakra-radii-md)'
  };
};
var switchLinkStyle = {
  textDecoration: 'underline',
  width: 'fit-content',
  color: 'btnSecondaryEnabled',
  mt: 'md',
  fontSize: 'md',
  lineHeight: '3',
  cursor: 'pointer',
  fontWeight: 'normal',
  whiteSpace: 'nowrap'
};
var amendInputWidth = {
  mobile: '100%',
  sm: '100%',
  md: '100%',
  lg: '100%',
  xl: '100%'
};
var dropdownStyles = {
  menuListStyles: {
    py: 0,
    maxHeight: '12.5rem',
    zIndex: 999999
  }
};