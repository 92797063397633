"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgCostaCoffee = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 18,
  height: 20,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16.18 0a1 1 0 0 1 .98.804L18 5h-1.125L15 20H3L1.125 5H0L.84.804A1 1 0 0 1 1.82 0h14.36Zm-.314 5H2.133l1.75 14h10.233l1.75-14ZM8.482 7.084l2.238.001a.16.16 0 0 1 .085.02l-.23.168c-.345.268-.65.573-.843.973-.277.576-.31 1.169-.036 1.751.158.337.36.655.55.974.245.405.464.82.552 1.292.083.446.028.874-.169 1.283-.256.529-.638.95-1.104 1.3l-.142.101c-.077.046-.127.052-.216.031l-.172-.049c-.557-.164-1.114-.322-1.672-.481l-.085-.03.392-.334c.063-.055.124-.112.183-.172.31-.312.534-.674.584-1.125a1.469 1.469 0 0 0-.169-.872 8.941 8.941 0 0 0-.335-.562l-.176-.276c-.23-.36-.436-.73-.52-1.156a2.56 2.56 0 0 1 .121-1.398c.174-.472.448-.874.81-1.221l.177-.156a.25.25 0 0 1 .177-.062ZM16.18 1H1.82l-.601 3H16.78l-.6-3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgCostaCoffee;