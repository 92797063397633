"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelParkingInformationComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelParkingInformationComponent(_ref) {
  var {
    data,
    isLoading,
    isError,
    error
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  if (!data) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_atoms.Section, {
    dataTestId: "hdp_parking",
    children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
      maxW: "full",
      "data-testid": "hotel-details-parking"
    }, containerStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        as: "h3"
      }, headingStyles), {}, {
        children: t('hoteldetails.mapparking.title')
      })), (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "1",
        dangerouslySetInnerHTML: {
          __html: (0, _utils.formatInnerHTMLAssetUrls)(data)
        },
        className: "formatLinks"
      })]
    }))
  });
}
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4'
};
var containerStyles = {
  my: {
    base: '1.5rem',
    md: '3rem'
  }
};