"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SearchComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _nanoid = require("nanoid");
var _react2 = require("react");
var _BookingDatepicker = _interopRequireDefault(require("../BookingDatepicker"));
var _LocationPicker = _interopRequireDefault(require("../LocationPicker"));
var _RoomPicker = _interopRequireDefault(require("../RoomPicker"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FIELDS = {
  datepicker: 'datepicker',
  location: 'location',
  occupancy: 'occupancy',
  numberOfNights: 'numberOfNights'
};
function SearchComponent(_ref) {
  var _dataStayRules$maxNig, _dataStayRules$maxArr;
  var {
    defaultLocation,
    defaultRooms,
    dataStayRules,
    dataRoomOccupancyLimitations,
    partialTranslations,
    roomCodes,
    AEMTranslations,
    locale,
    screenSize,
    handleButtonClick,
    suggestions,
    onIsSearchActive,
    onLocationInputChange,
    onLocationInputClear,
    onLocationInputFocus,
    numberOfNightsComponent,
    promotionComponent,
    companyNameComponent,
    showCheckCompany,
    companyNameError,
    searchStyles,
    onSelectDates,
    onOccupancyChange,
    errorField,
    errorMessage,
    isSearchActive,
    mappedRoomLabels,
    startDate,
    endDate,
    isDatepickerError,
    showMultipleRooms,
    isActiveMatchedOffer,
    matchedOffer,
    channel,
    isDatePickerFocus,
    displayDatesNotification,
    searchDisabled = false,
    isPIGroupBookingFormEnabled,
    isBBGroupBookingFormEnabled
  } = _ref;
  var isGBLocale = locale === _utils.GLOBALS.language.EN;
  var isGroupBookingFormMessage = isGBLocale && (isPIGroupBookingFormEnabled || isBBGroupBookingFormEnabled);
  var {
    isLessThanLg,
    isLessThanMd,
    isLessThanSm,
    isLessThanXs
  } = screenSize;
  var [location, setLocation] = (0, _react2.useState)(undefined);
  var mappedDefaultRooms = mapDefaultRoomsForRoomPicker(defaultRooms);
  var getRoomsInitialState = () => {
    if (mappedDefaultRooms.length) {
      return mappedDefaultRooms;
    }
    return [{
      id: (0, _nanoid.nanoid)(),
      adults: 1,
      children: 0,
      shouldIncludeCot: false,
      roomType: partialTranslations.content.global.double
    }];
  };
  var [rooms, setRooms] = (0, _react2.useState)(getRoomsInitialState());
  var {
    searchWrapper,
    locationPickerStyles,
    inputGroupStyles,
    inputElementStyles,
    bookingDatepickerSize,
    datepickerInputElementStyles,
    iconStyles,
    errorInputGroupStyles,
    errorInputElementStyles,
    errorMarginBottom,
    buttonStyles,
    roomPickerSize,
    roomPickerWrapperStyles,
    roomPickerInputElementStyles
  } = searchStyles;
  var autocompleteStyles = _objectSpread({
    locationPickerStyles,
    inputGroupStyles,
    inputElementStyles,
    errorMarginBottom
  }, errorField === FIELDS.location && {
    errorInputGroupStyles,
    errorInputElementStyles
  });
  var bookingDatepickerStyles = _objectSpread({
    inputGroupStyles,
    bookingDatepickerSize,
    datepickerInputElementStyles,
    iconStyles
  }, (errorField === FIELDS.datepicker || errorField === FIELDS.numberOfNights && isDatepickerError) && {
    errorInputGroupStyles,
    errorInputElementStyles,
    errorMarginBottom
  });
  var queryParams = getMappedInputData();
  var maxNumberOfRooms = getMaxNumberOfRooms();
  var groupBookingMessage = isGroupBookingFormMessage ? partialTranslations.results.notifications.groupBookingFormPageMessage : partialTranslations.results.notifications.groupBookingMessage;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread(_objectSpread({
    align: "center",
    bgColor: "baseWhite"
  }, searchWrapper), getContainerSpacingStyles(isSearchActive, companyNameComponent, showCheckCompany, companyNameError)), {}, {
    "data-testid": "search-component",
    onFocus: () => {
      if (onIsSearchActive) {
        onIsSearchActive(true);
      }
    },
    children: [(0, _jsxRuntime.jsx)(_LocationPicker.default, _objectSpread({
      inputPlaceholder: getLocationPlaceholder(),
      defaultInputValue: defaultLocation,
      isLocationRequired: true,
      hasListDivider: false,
      styles: autocompleteStyles,
      onSelectLocation: handleSelectLocation,
      suggestions: suggestions,
      onInputChange: onLocationInputChange,
      onInputClear: onLocationInputClear,
      onInputFocus: onLocationInputFocus
    }, errorField === FIELDS.location && {
      showErrorMessage: true,
      errorMessage: errorMessage
    })), (0, _jsxRuntime.jsx)(_BookingDatepicker.default, _objectSpread(_objectSpread({
      displayDateFormat: "dd MMM yyyy",
      inputPlaceholderDatepicker: getInputPlaceholderDatepicker(),
      maxNumberOfNights: dataStayRules && Number(dataStayRules === null || dataStayRules === void 0 || (_dataStayRules$maxNig = dataStayRules.maxNightsLimitation) === null || _dataStayRules$maxNig === void 0 ? void 0 : _dataStayRules$maxNig.maxNights),
      maxArrivalDate: dataStayRules && Number(dataStayRules === null || dataStayRules === void 0 || (_dataStayRules$maxArr = dataStayRules.maxArrivalDateLimitation) === null || _dataStayRules$maxArr === void 0 ? void 0 : _dataStayRules$maxArr.maxArrivalDate),
      datepickerStyles: bookingDatepickerStyles,
      onSelectDates: onSelectDates,
      defaultStartDate: startDate,
      defaultEndDate: endDate,
      partialTranslations: partialTranslations,
      locale: locale,
      isError: isDatepickerError
    }, (errorField === FIELDS.datepicker || errorField === FIELDS.numberOfNights && isDatepickerError) && {
      showErrorMessage: true,
      errorMessage: errorMessage
    }), {}, {
      isLessThanSm: isLessThanSm,
      isDatePickerFocus: isDatePickerFocus,
      displayDatesNotification: displayDatesNotification
    })), numberOfNightsComponent, (0, _jsxRuntime.jsx)(_RoomPicker.default, _objectSpread(_objectSpread({
      showMultipleRooms: showMultipleRooms,
      roomPickerInputElementStyles: roomPickerInputElementStyles,
      boxWrapperStyles: roomPickerWrapperStyles,
      roomPickerSize: roomPickerSize,
      onSubmit: rooms => {
        setRooms(rooms);
        onOccupancyChange === null || onOccupancyChange === void 0 || onOccupancyChange();
      },
      initialState: rooms,
      maxNumberOfRooms: maxNumberOfRooms,
      labels: {
        roomsWarningTitle: partialTranslations.results.notifications.groupBookingHeader,
        roomsWarningDescription: maxNumberOfRooms === 2 && (matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.cellCode) === _api.OfferEnum.EMPLOYEE ? partialTranslations.results.notifications.emp01groupBookingMessage : groupBookingMessage,
        roomsWarningDescriptionCCUI: partialTranslations.results.notifications.ccuiGroupBookingMessage,
        addMoreRoomsLabel: partialTranslations.content.global.addRoom,
        removeRoomButtonLabel: partialTranslations.form.removeRoom,
        doneButtonLabel: partialTranslations.content.global.done,
        adult: partialTranslations.content.global.adult,
        adults: partialTranslations.content.global.adults,
        adultsLabel: AEMTranslations.adultsLabel,
        adultsMaxPerRoomLabel: partialTranslations.form.adultsHelperText,
        child: partialTranslations.content.global.child,
        children: partialTranslations.content.global.children,
        childrenLabel: AEMTranslations.childrenLabel,
        childrenAgeLabel: partialTranslations.form.childrenHelperText,
        cotLimit: partialTranslations.form.cotLimit,
        cotLabel: partialTranslations.form.includeCot,
        room: partialTranslations.content.global.room,
        rooms: partialTranslations.content.global.rooms,
        roomLabel: partialTranslations.content.global.room,
        roomTypeLabel: AEMTranslations.roomTypeLabel,
        single: partialTranslations.content.global.single,
        double: partialTranslations.content.global.double,
        accessible: partialTranslations.content.global.accessible,
        twin: partialTranslations.content.global.twin,
        family: partialTranslations.content.global.family
      },
      dataRoomOccupancyLimitations: dataRoomOccupancyLimitations,
      roomCodes: roomCodes,
      screenSize: screenSize
    }, errorField === FIELDS.occupancy && {
      showErrorMessage: true,
      errorMessage: errorMessage
    }), {}, {
      channel: channel
    })), isLessThanSm && errorField === FIELDS.occupancy && errorMessage && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
      infoMessage: errorMessage,
      otherStyles: alertStyles
    }), isLessThanSm && (errorField === FIELDS.datepicker || errorField === FIELDS.numberOfNights) && errorMessage && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
      infoMessage: errorMessage
    }), promotionComponent, (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
      size: "md",
      variant: "primary",
      name: "search-button",
      isDisabled: searchDisabled
    }, buttonStyles), {}, {
      mt: {
        base: 'md',
        sm: '0'
      },
      onClick: () => handleButtonClick(queryParams),
      children: getButtonContent()
    })), isSearchActive && companyNameComponent]
  }));
  function getMaxNumberOfRooms() {
    var _dataStayRules$maxRoo;
    if (isActiveMatchedOffer) {
      return Number(matchedOffer === null || matchedOffer === void 0 ? void 0 : matchedOffer.maxRooms);
    }
    return Number(dataStayRules === null || dataStayRules === void 0 || (_dataStayRules$maxRoo = dataStayRules.maxRoomsLimitation) === null || _dataStayRules$maxRoo === void 0 ? void 0 : _dataStayRules$maxRoo.maxRooms);
  }
  function getInputPlaceholderDatepicker() {
    if (isDatepickerError) {
      return AEMTranslations === null || AEMTranslations === void 0 ? void 0 : AEMTranslations.datepickerInvalidDates;
    }
    return "".concat(AEMTranslations.datepickerCheckinLabel, " | ").concat(AEMTranslations.datepickerCheckoutLabel);
  }
  function getContainerSpacingStyles(isSearchActive, companyNameComponent, showCheckCompany, checkCompanyError) {
    var mb = 0;
    if (isSearchActive && companyNameComponent) {
      mb += 7;
      if (showCheckCompany && checkCompanyError) {
        mb += 8.75;
      } else if (showCheckCompany) {
        mb += 5;
      }
    }
    return {
      mb: {
        mobile: 'var(--chakra-space-56)',
        xs: 'var(--chakra-space-52)',
        sm: "".concat(mb, "rem")
      }
    };
  }
  function handleSelectLocation(location) {
    setLocation(location);
  }
  function getMappedInputData() {
    if (!location) {
      return;
    }
    var start = startDate !== null && startDate !== void 0 ? startDate : new Date();
    var end = endDate !== null && endDate !== void 0 ? endDate : (0, _add.default)(new Date(start), {
      days: 1
    });
    var queryParams = {
      searchTerm: location.suggestion,
      ARRdd: start.getDate(),
      ARRmm: start.getMonth() + 1,
      ARRyyyy: start.getFullYear(),
      nights: (0, _differenceInDays.default)(end, start),
      roomsNumber: rooms.length,
      rooms: rooms
    };
    if ('placeId' in location) {
      queryParams.placeId = location.placeId;
    }
    if ('code' in location) {
      queryParams.code = location.code;
    }
    if ('geometry' in location) {
      queryParams.location = location.geometry.coordinates;
    }
    if ('brand' in location) {
      queryParams.brand = location.brand;
    }
    return queryParams;
  }
  function getLocationPlaceholder() {
    var _partialTranslations$;
    return isLessThanLg && !isLessThanSm ? AEMTranslations.locationPlaceholder : partialTranslations === null || partialTranslations === void 0 || (_partialTranslations$ = partialTranslations.form) === null || _partialTranslations$ === void 0 ? void 0 : _partialTranslations$.where;
  }
  function getButtonContent() {
    if (isLessThanSm && !isLessThanXs) {
      return AEMTranslations.submitButtonLabel;
    }
    if (isLessThanMd && !isLessThanSm) {
      return (0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: (0, _jsxRuntime.jsx)(_atoms.SearchIcon, {
          color: "var(--chakra-colors-baseWhite)"
        })
      });
    }
    return AEMTranslations.submitButtonLabel;
  }
  function mapDefaultRoomsForRoomPicker(rooms) {
    if (!rooms || rooms.length > dataStayRules.maxNightsLimitation.maxNights) return [];
    var filteredRooms = rooms.filter(r => r.adults && r.children !== null && r.children !== undefined && !isNaN(r.children));
    return filteredRooms.map(r => {
      var roomTypeCode = r.roomType;
      var roomType = mappedRoomLabels[roomTypeCode];
      if (!Object.keys(mappedRoomLabels).includes(roomTypeCode)) return _objectSpread(_objectSpread({}, r), {}, {
        adults: 1,
        roomType: mappedRoomLabels.DB
      });
      return _objectSpread(_objectSpread({}, r), {}, {
        id: (0, _nanoid.nanoid)(),
        roomType: (0, _utils.upperFirst)(roomType)
      });
    });
  }
}
var alertStyles = {
  w: 'full',
  zIndex: 1,
  _before: {
    content: '" "',
    position: 'absolute',
    top: '-0.15rem',
    right: '1.15rem',
    width: '1.5rem',
    height: '0.75rem',
    zIndex: '-1',
    transform: 'rotate(-45deg)',
    bgColor: 'tooltipError',
    borderRadius: '3'
  }
};