"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingSummaryInfoMessages;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function BookingSummaryInfoMessages(_ref) {
  var {
    infoMessages = [],
    prefixDataTestId
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)(prefixDataTestId, 'HotelInformation');
  var baseDataTestMessageId = (0, _utils.formatDataTestId)(baseDataTestId, 'InfoMessages');
  return (0, _jsxRuntime.jsx)(_react.Box, {
    mt: "sm",
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'InfoMessages'),
    children: infoMessages === null || infoMessages === void 0 ? void 0 : infoMessages.map((message, index) => {
      if (message.length) {
        return (0, _jsxRuntime.jsx)(_react.Box, {
          mt: "md",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestMessageId, "".concat(index)),
          children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
            maxWidth: "full",
            variant: "info",
            status: "info",
            description: (0, _jsxRuntime.jsx)(_react.Box, {
              dangerouslySetInnerHTML: {
                __html: message
              }
            }),
            svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
          })
        }, message);
      }
    })
  });
}