"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _add = _interopRequireDefault(require("date-fns/add"));
var _nextI18next = require("next-i18next");
var _reactHookForm = require("react-hook-form");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var BookingDetails = _ref => {
  var {
    control,
    formField,
    errors
  } = _ref;
  var arrivalMinDate = new Date();
  var arrivalMaxDate = (0, _add.default)(arrivalMinDate, {
    years: 1
  });
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  var [darkGrey2] = (0, _react.useToken)('colors', ['darkGrey2']);
  var {
    bookingReferenceError
  } = formField.props;
  var datePickerStyles = _objectSpread(_objectSpread({}, _common.inputStyle), {}, {
    _placeholder: {
      color: darkGrey2
    }
  });
  var fieldController = _ref2 => {
    var {
      field,
      label,
      name,
      type,
      testId
    } = _ref2;
    switch (type) {
      case 'input':
        {
          var _errors$name;
          var labelAndPlaceHolderText = label === 'bookingnumber' ? t("precheckin.".concat(label)) : t("precheckin.details.".concat(label));
          return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), (0, _common.fieldProps)(field)), {}, {
            type: "text",
            showIcon: false,
            placeholderText: labelAndPlaceHolderText,
            label: labelAndPlaceHolderText,
            error: errors === null || errors === void 0 || (_errors$name = errors[name]) === null || _errors$name === void 0 ? void 0 : _errors$name.message,
            className: "sessioncamhidetext assist-no-show"
          }));
        }
      case 'datePicker':
        {
          var _errors$name2, _errors$name3, _errors$arrivalDate;
          var {
            onChange
          } = field;
          if (errors !== null && errors !== void 0 && (_errors$name2 = errors[name]) !== null && _errors$name2 !== void 0 && _errors$name2.message) {
            datePickerStyles = _objectSpread(_objectSpread({}, datePickerStyles), {}, {
              border: '1px solid var(--chakra-colors-error)',
              borderColor: 'none'
            });
          }
          return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, _objectSpread(_objectSpread({}, language === 'de' ? {
              locale: 'de'
            } : {
              locale: 'en'
            }), {}, {
              name: 'arrivalDate',
              dataTestId: (0, _utils.formatDataTestId)(formField.testid, testId),
              minDate: arrivalMinDate,
              maxDate: arrivalMaxDate,
              inputPlaceholder: t("precheckin.details.".concat(label)),
              inputLabel: t("precheckin.details.".concat(label)),
              defaultStartDate: field.value,
              isRightIcon: true,
              popperPlacement: isLargerThanSm ? 'bottom' : 'top',
              displayDateFormat: "dd MMM yyyy",
              dateFormat: "dd MMM yyyy",
              labels: {
                todayLabel: _common.todayLabel,
                tomorrowLabel: _common.tomorrowLabel
              },
              datepickerStyles: {
                inputGroupStyles: {},
                datepickerInputElementStyles: datePickerStyles,
                bookingDatepickerSize: {},
                iconStyles: _common.inputIconStyles
              },
              onSelectDate: date => {
                onChange(date);
              }
            })), (errors === null || errors === void 0 || (_errors$name3 = errors[name]) === null || _errors$name3 === void 0 ? void 0 : _errors$name3.message) && (0, _jsxRuntime.jsx)(_react.Text, {
              style: _common.dataErrorText,
              mt: "2",
              children: errors === null || errors === void 0 || (_errors$arrivalDate = errors.arrivalDate) === null || _errors$arrivalDate === void 0 ? void 0 : _errors$arrivalDate.message
            })]
          });
        }
      default:
        return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }
  };
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailboxStyle), {}, {
    mb: '18em',
    children: [!!bookingReferenceError && (0, _jsxRuntime.jsx)(_atoms.Notification, {
      svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
      status: "error",
      description: t('precheckin.details.error'),
      variant: "error",
      prefixDataTestId: 'BookingDetails',
      wrapperStyles: {
        mb: 'xl',
        mt: 'sm',
        maxW: {
          base: '100%',
          md: '56%'
        }
      }
    }), (0, _jsxRuntime.jsxs)(_react.Flex, {
      wrap: "wrap",
      justifyContent: 'space-between',
      children: [_common.bookingDetailsFields.map(_ref3 => {
        var {
          label,
          name,
          testId,
          type
        } = _ref3;
        return (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
          key: name,
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, testId)
        }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: name,
          control: control,
          render: _ref4 => {
            var {
              field
            } = _ref4;
            return fieldController({
              field,
              label,
              name,
              type,
              testId
            });
          }
        }));
      }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
        "data-testid": (0, _utils.formatDataTestId)(formField.testid, 'searchButtonWrapper'),
        children: (0, _jsxRuntime.jsx)(_react.Button, {
          "data-testid": "submit-reg-booking-details-form",
          form: "preCheckInBookingDetailsForm",
          type: "submit",
          children: t('precheckin.searchbutton')
        })
      }))]
    })]
  }));
};
var _default = exports.default = BookingDetails;