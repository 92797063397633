"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireWildcard(require("react"));
var _RoomCardHeader = _interopRequireDefault(require("./RoomCardHeader.component"));
var _RoomCardInfo = _interopRequireDefault(require("./RoomCardInfo.component"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomCard(_ref) {
  var {
    room,
    roomNumber,
    currency,
    t,
    currentLang,
    taxesMessage,
    brand,
    isCardExpanded,
    cardsExpanded,
    setCardsExpanded,
    handleExpandCollapse
  } = _ref;
  var {
    roomReservationStartDate,
    roomReservationEndDate,
    leadGuestTitle,
    leadGuestName,
    roomType,
    roomTypeDescription,
    rateType,
    rateTypeDescription,
    roomGroup,
    roomTotalPrice,
    ratesPerNight,
    adultMealDescription,
    childrenMealDescription,
    mealPrice
  } = room;
  var [showRoomCardInfo, setShowRoomCardInfo] = (0, _react2.useState)(false);
  var createCardsArray = (0, _react2.useCallback)(() => {
    if (cardsExpanded.length === 0) {
      setCardsExpanded(prevState => [...prevState, {
        roomNumber: roomNumber,
        expanded: showRoomCardInfo
      }]);
    }
  }, [cardsExpanded.length]);
  (0, _react2.useEffect)(() => {
    setShowRoomCardInfo(isCardExpanded);
  }, [isCardExpanded]);
  (0, _react2.useEffect)(() => {
    createCardsArray();
  }, [createCardsArray]);
  var roomCardHeaderData = {
    roomReservationStartDate,
    roomReservationEndDate,
    roomNumber,
    roomTotalPrice,
    currency,
    currentLang,
    t,
    showRoomCardInfo,
    handleExpandCollapse
  };
  var roomCardInfoData = {
    leadGuestTitle,
    leadGuestName,
    roomType,
    roomTypeDescription,
    rateType,
    rateTypeDescription,
    roomGroup,
    roomReservationEndDate,
    roomTotalPrice,
    roomNumber,
    ratesPerNight,
    adultMealDescription,
    childrenMealDescription,
    mealPrice,
    taxesMessage,
    brand,
    currency,
    currentLang,
    t
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, roomCardStyle), {}, {
      sx: {
        '@media print': {
          display: 'none'
        }
      },
      children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, roomCardDetailsStyle), {}, {
        children: [(0, _jsxRuntime.jsx)(_RoomCardHeader.default, _objectSpread({}, roomCardHeaderData)), showRoomCardInfo && (0, _jsxRuntime.jsx)(_RoomCardInfo.default, _objectSpread({}, roomCardInfoData))]
      }))
    })), (0, _jsxRuntime.jsxs)(_react.Box, {
      display: "none",
      sx: {
        '@media print': {
          display: 'block',
          my: 'md'
        }
      },
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        mb: "sm",
        fontWeight: "bold",
        children: t('booking.confirmation.room').replace('[roomNumber]', roomNumber.toString())
      }), (0, _jsxRuntime.jsxs)(_react.Text, {
        fontWeight: "bold",
        children: [leadGuestTitle, " ", leadGuestName]
      }), (0, _jsxRuntime.jsx)(_react.Text, {
        children: roomGroup
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        justifyContent: "space-between",
        fontSize: "md",
        children: [(0, _jsxRuntime.jsx)(_react.Text, {
          children: roomType
        }), (0, _jsxRuntime.jsx)(_react.Text, {
          fontWeight: "bold",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), room === null || room === void 0 ? void 0 : room.roomTotalPrice.toFixed(2), currentLang)
        })]
      })]
    })]
  });
}
var roomCardStyle = {
  mb: 'md',
  w: 'full',
  border: '1px solid',
  borderColor: 'lightGrey3',
  borderRadius: '3px'
};
var roomCardDetailsStyle = {
  direction: 'column',
  w: 'full'
};