"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = StaticFooterComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _format = _interopRequireDefault(require("date-fns/format"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function StaticFooterComponent() {
  var baseDataTestId = 'Footer';
  var footerWrapperStyles = () => {
    return {
      w: 'full',
      borderTop: '1px solid var(--chakra-colors-lightGrey1)',
      pt: {
        mobile: '0',
        md: 'lg',
        lg: 'lg',
        xl: '3xl'
      },
      px: {
        mobile: 'md',
        md: 'md',
        lg: 'xl',
        xl: '5xl'
      },
      pb: {
        lg: 'lg',
        xl: '3xl'
      }
    };
  };
  var copyrightData = "&copy; ".concat((0, _format.default)(new Date(), 'yyyy'), " Premier Inn");
  return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
    maxW: "var(--chakra-space-breakpoint-xl)"
  }, footerWrapperStyles()), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Wrapper-Error'),
    children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CopyrightSection')
    }, copyrightSectionStyles), {}, {
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, copyrightStyles), {}, {
        dangerouslySetInnerHTML: {
          __html: copyrightData
        },
        className: "formatLinks"
      }))
    }))
  }));
}
var copyrightStyles = {
  fontSize: 'sm',
  fontWeight: 'normal',
  lineHeight: 1,
  color: 'darkGrey1'
};
var copyrightSectionStyles = {
  justifyContent: 'space-between',
  alignItems: 'center',
  w: 'full'
};