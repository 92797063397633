"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Datepicker;
exports.getFormattedDate = getFormattedDate;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _classnames = _interopRequireDefault(require("classnames"));
var _add = _interopRequireDefault(require("date-fns/add"));
var _endOfMonth = _interopRequireDefault(require("date-fns/endOfMonth"));
var _endOfWeek = _interopRequireDefault(require("date-fns/endOfWeek"));
var _format = _interopRequireDefault(require("date-fns/format"));
var _de = _interopRequireDefault(require("date-fns/locale/de"));
var _enGB = _interopRequireDefault(require("date-fns/locale/en-GB"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _startOfWeek = _interopRequireDefault(require("date-fns/startOfWeek"));
var _nanoid = require("nanoid");
var _react2 = require("react");
var _reactDatepicker = _interopRequireWildcard(require("react-datepicker"));
var _Calendar = _interopRequireDefault(require("../../assets/icons/Calendar"));
var _DatePickerGlobalStyles = _interopRequireDefault(require("../../theme/components/DatePickerGlobalStyles"));
var _Button = _interopRequireDefault(require("../Button"));
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function handleSameDateAsToday(endDate, tomorrow, dateFormat, labels, locale) {
  if (endDate === null) {
    return "".concat(labels === null || labels === void 0 ? void 0 : labels.todayLabel, " | ").concat(labels === null || labels === void 0 ? void 0 : labels.checkoutLabel);
  }
  if ((0, _utils.isSameDate)(endDate, tomorrow)) {
    return "".concat(labels === null || labels === void 0 ? void 0 : labels.todayLabel, " | ").concat(labels === null || labels === void 0 ? void 0 : labels.tomorrowLabel);
  }
  return "".concat(labels === null || labels === void 0 ? void 0 : labels.todayLabel, " | ").concat((0, _format.default)(endDate, dateFormat, locale === _api.CountryCode.DE ? {
    locale: _de.default
  } : {
    locale: _enGB.default
  }));
}
function getFormattedDate(startDate, endDate, dateFormat, locale, props) {
  var {
    labels,
    selectsRange: hasDatepickerRange,
    isError,
    displayDatesNotification
  } = props;
  var today = new Date();
  var tomorrow = (0, _add.default)(today, {
    days: 1
  });
  var isLocaleGerman = locale === _api.CountryCode.DE;
  var localeOption = {
    locale: isLocaleGerman ? _de.default : _enGB.default
  };
  dateFormat = isLocaleGerman ? _api.DATE_TYPE.E_DAY_MONTH_YEAR : dateFormat;
  (0, _nanoid.nanoid)();
  if (hasDatepickerRange) {
    if (startDate === null && endDate === null) {
      if (isError) return '';
      if (displayDatesNotification) {
        return "".concat(labels === null || labels === void 0 ? void 0 : labels.checkInLabel, " | ").concat(labels === null || labels === void 0 ? void 0 : labels.checkoutLabel);
      } else {
        return "".concat(labels === null || labels === void 0 ? void 0 : labels.todayLabel, " | ").concat(labels === null || labels === void 0 ? void 0 : labels.tomorrowLabel);
      }
    }
    if ((0, _utils.isSameDate)(startDate, today)) {
      return handleSameDateAsToday(endDate, tomorrow, dateFormat, labels, locale);
    }
    if ((0, _utils.isSameDate)(startDate, tomorrow)) {
      if (endDate === null) {
        return "".concat(labels === null || labels === void 0 ? void 0 : labels.tomorrowLabel, " | ").concat(labels === null || labels === void 0 ? void 0 : labels.checkoutLabel);
      }
      return "".concat(labels === null || labels === void 0 ? void 0 : labels.tomorrowLabel, " | ").concat((0, _format.default)(endDate, dateFormat, localeOption));
    }
    if (startDate && endDate === null) {
      return "".concat((0, _format.default)(startDate, dateFormat, localeOption), " | ").concat(labels === null || labels === void 0 ? void 0 : labels.checkoutLabel);
    }
    return "".concat((0, _format.default)(startDate, dateFormat, localeOption), " | ").concat((0, _format.default)(endDate, dateFormat, localeOption));
  }
  return startDate ? (0, _format.default)(startDate, dateFormat, localeOption) : '';
}
function Datepicker(props) {
  var _props$hasFooter;
  var {
    defaultStartDate,
    defaultEndDate,
    isDatePickerFocus
  } = props;
  var baseDatepickerStyles = (0, _react.useStyleConfig)('Datepicker');
  var hasFooter = (_props$hasFooter = props.hasFooter) !== null && _props$hasFooter !== void 0 ? _props$hasFooter : false;
  var [startDate, setStartDate] = (0, _react2.useState)(defaultStartDate !== null && defaultStartDate !== void 0 ? defaultStartDate : null);
  var [endDate, setEndDate] = (0, _react2.useState)(defaultEndDate !== null && defaultEndDate !== void 0 ? defaultEndDate : null);
  var ref = (0, _react2.useRef)(null);
  var refInput = (0, _react2.useRef)(null);
  var [isCalendarOpen, setIsCalendarOpen] = (0, _react2.useState)(false);
  var {
    inputStyles,
    inputIconStyles,
    resetButtonStyles,
    doneButtonStyles
  } = getDatepickerStyles(isCalendarOpen, props.datepickerStyles);
  (0, _react.useOutsideClick)({
    ref: ref,
    handler: () => setIsCalendarOpen(false)
  });
  (0, _reactDatepicker.registerLocale)(_api.CountryCode.DE, _de.default);
  (0, _react2.useEffect)(() => {
    if (defaultStartDate !== undefined) setStartDate(defaultStartDate);
    if (defaultEndDate !== undefined) setEndDate(defaultEndDate);
  }, [defaultStartDate, defaultEndDate]);
  (0, _react2.useEffect)(() => {
    var _refInput$current;
    isDatePickerFocus && (refInput === null || refInput === void 0 || (_refInput$current = refInput.current) === null || _refInput$current === void 0 ? void 0 : _refInput$current.focus());
  }, []);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    __css: baseDatepickerStyles,
    ref: ref,
    children: [(0, _jsxRuntime.jsx)(_DatePickerGlobalStyles.default, {}), (0, _jsxRuntime.jsx)(_reactDatepicker.default, _objectSpread(_objectSpread({}, props.locale === _api.CountryCode.DE ? {
      locale: _api.CountryCode.DE
    } : {
      locale: _api.CountryCode.EN
    }), {}, {
      selected: startDate,
      startDate: startDate,
      endDate: endDate,
      onChange: handleDatepickerChange,
      customInput: renderInput(),
      minDate: props.minDate,
      maxDate: props === null || props === void 0 ? void 0 : props.maxDate,
      useWeekdaysShort: true,
      calendarStartDay: 1,
      disabled: props.isDisabled,
      open: isCalendarOpen,
      selectsRange: props.selectsRange,
      renderDayContents: renderDayContents,
      dayClassName: getDayClassName,
      openToDate: startDate !== null && startDate !== void 0 ? startDate : new Date(),
      children: hasFooter && renderCalendarFooter()
    }))]
  });
  function getDayClassName(date) {
    var startWeek = (0, _startOfWeek.default)(date, {
      weekStartsOn: 1
    });
    var endWeek = (0, _endOfWeek.default)(date, {
      weekStartsOn: 1
    });
    var startMonth = (0, _startOfMonth.default)(date);
    var endMonth = (0, _endOfMonth.default)(date);
    var arr = [{
      'start-week': startWeek.getDate() === date.getDate()
    }, {
      'end-week': endWeek.getDate() === date.getDate()
    }, {
      'start-month': startMonth.getDate() === date.getDate()
    }, {
      'end-month': endMonth.getDate() === date.getDate()
    }, {
      'react-datepicker__day--disabled': isDayDisabled(date)
    }];
    return (0, _classnames.default)(arr);
  }
  function isDayDisabled(date) {
    if (!startDate) return false;
    if (props.selectsRange && !endDate) {
      return resetDate(date) < resetDate(startDate);
    }
    return resetDate(date) < resetDate(props.minDate);
  }
  function renderInput() {
    var _props$displayDateFor, _props$datepickerStyl;
    var displayDateFormat = (_props$displayDateFor = props.displayDateFormat) !== null && _props$displayDateFor !== void 0 ? _props$displayDateFor : props.dateFormat;
    var borderStyles = isCalendarOpen ? {
      borderColor: 'primary'
    } : {};
    var locale = props.locale === _api.CountryCode.DE ? _api.CountryCode.DE : _api.CountryCode.EN;
    var value = getFormattedDate(startDate, endDate, displayDateFormat, locale, props);
    return (0, _jsxRuntime.jsx)(_react.InputGroup, _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, props.datepickerStyles.inputGroupStyles), {}, {
      minW: '0',
      border: {
        base: "".concat(isCalendarOpen ? '0' : '1px solid var(--chakra-colors-lightGrey1)'),
        sm: '0'
      }
    })), (_props$datepickerStyl = props.datepickerStyles) === null || _props$datepickerStyl === void 0 ? void 0 : _props$datepickerStyl.errorInputGroupStyles), borderStyles), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        w: "full",
        children: [(0, _jsxRuntime.jsx)(_react.InputLeftElement, _objectSpread(_objectSpread({
          pointerEvents: "none"
        }, inputIconStyles), {}, {
          children: (0, _jsxRuntime.jsx)(_Icon.default, {
            svg: (0, _jsxRuntime.jsx)(_Calendar.default, {})
          })
        })), (0, _jsxRuntime.jsx)(_react.Input, _objectSpread(_objectSpread({
          "aria-label": "datepicker-input",
          placeholder: props.inputPlaceholder,
          value: value,
          onChange: event => {
            props.onInputChange && props.onInputChange(event.target.value);
          },
          isDisabled: props.isDisabled,
          onClick: () => {
            setIsCalendarOpen(true);
          }
        }, inputStyles), {}, {
          ref: refInput
        }))]
      })
    }));
  }
  function renderCalendarFooter() {
    var _props$labels, _props$labels2;
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      textAlign: "center",
      mb: "xl",
      children: [(0, _jsxRuntime.jsx)(_Button.default, _objectSpread(_objectSpread({
        size: "md",
        variant: "tertiary",
        onClick: handleReset,
        disabled: startDate === null
      }, resetButtonStyles), {}, {
        children: (_props$labels = props.labels) === null || _props$labels === void 0 ? void 0 : _props$labels.resetButtonLabel
      })), (0, _jsxRuntime.jsx)(_Button.default, _objectSpread(_objectSpread({
        size: "sm",
        variant: "tertiary",
        onClick: handleDone
      }, doneButtonStyles), {}, {
        children: (_props$labels2 = props.labels) === null || _props$labels2 === void 0 ? void 0 : _props$labels2.doneButtonLabel
      }))]
    });
  }
  function renderDayContents(day) {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)("div", {
        className: "highlight"
      }), (0, _jsxRuntime.jsx)("div", {
        className: "day-number",
        children: day
      })]
    });
  }
  function handleDone() {
    setIsCalendarOpen(false);
    props.onDone && props.onDone([startDate, endDate]);
  }
  function handleReset() {
    setStartDate(null);
    setEndDate(null);
    props.onReset && props.onReset();
  }
  function handleDatepickerChange(dates) {
    var _props$onSelectDates;
    if (props.selectsRange) {
      var [start, end] = dates;
      setStartDate(start);
      if ((0, _utils.isSameDate)(start, end)) return;
      setEndDate(end);
      props.onSelectDates && props.onSelectDates([start, end]);
      return;
    }
    setStartDate(dates);
    (_props$onSelectDates = props.onSelectDates) === null || _props$onSelectDates === void 0 || _props$onSelectDates.call(props, dates);
    if (props.closeCalendarOnSelectDate) {
      setIsCalendarOpen(false);
    }
  }
}
function getDatepickerStyles(isCalendarOpen, datepickerStyles) {
  var inputStyles = _objectSpread(_objectSpread({
    padding: 'var(--chakra-space-lg) var(--chakra-space-lg) var(--chakra-space-lg) var(--chakra-space-2xl)',
    outline: 'none',
    color: 'var(--chakra-colors-darkGrey1)',
    cursor: 'pointer',
    border: isCalendarOpen ? '0.125rem solid var(--chakra-colors-primary)' : '0.125rem solid transparent',
    borderRadius: isCalendarOpen ? 'var(--chakra-space-xs)' : 0,
    borderColor: isCalendarOpen ? 'var(--chakra-colors-primary)' : 'transparent',
    borderRight: {
      base: "".concat(isCalendarOpen ? '0.125rem solid var(--chakra-colors-primary)' : '0'),
      sm: "".concat(isCalendarOpen ? '0.125rem solid var(--chakra-colors-primary)' : '0.063rem solid var(--chakra-colors-lightGrey4)')
    },
    borderTopRightRadius: isCalendarOpen ? 'var(--chakra-space-xs)' : 0,
    borderBottomRightRadius: isCalendarOpen ? 'var(--chakra-space-xs)' : 0,
    textOverflow: 'ellipsis',
    marginLeft: {
      mobile: '-1px',
      xs: '-1px',
      sm: 0
    },
    _hover: isCalendarOpen ? {
      border: '0.125rem solid var(--chakra-colors-primary)',
      borderRadius: 'var(--chakra-space-xs)',
      borderColor: 'var(--chakra-colors-primary)'
    } : {
      border: '0.063rem solid var(--chakra-colors-darkGrey1)',
      borderRadius: 'var(--chakra-space-xs)',
      borderColor: 'var(--chakra-colors-darkGrey1)'
    },
    _active: {
      border: '0.125rem solid var(--chakra-colors-primary)',
      borderRadius: 'var(--chakra-space-xs)',
      borderColor: 'var(--chakra-colors-primary)'
    },
    _focus: {
      border: '0.125rem solid var(--chakra-colors-primary)',
      borderRadius: 'var(--chakra-space-xs)',
      borderColor: 'var(--chakra-colors-primary)'
    },
    _disabled: {
      border: 'none'
    }
  }, datepickerStyles.datepickerInputElementStyles), datepickerStyles === null || datepickerStyles === void 0 ? void 0 : datepickerStyles.errorInputElementStyles);
  var inputIconStyles = _objectSpread({
    top: 'var(--chakra-space-md)'
  }, datepickerStyles.iconStyles);
  var responsiveButtonWidth = {
    mobile: '8.375rem',
    xs: '9.0625rem',
    sm: '9.25rem'
  };
  var resetButtonStyles = {
    mr: 'lg',
    border: 'none',
    boxShadow: 'none',
    _hover: {
      bg: 'baseWhite',
      boxShadow: 'none',
      textDecoration: 'underline'
    },
    _disabled: {
      color: 'lightGrey2',
      cursor: 'default',
      _hover: {
        textDecoration: 'none'
      }
    },
    w: responsiveButtonWidth
  };
  var doneButtonStyles = {
    w: responsiveButtonWidth,
    _hover: {
      bg: 'baseWhite'
    }
  };
  return {
    inputStyles,
    inputIconStyles,
    resetButtonStyles,
    doneButtonStyles
  };
}
function resetDate(date) {
  return date.setHours(12, 0, 0, 0);
}