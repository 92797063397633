"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = EmailUpdates;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function EmailUpdates(_ref) {
  var _formField$testid, _formField$props;
  var {
    control,
    formField,
    field
  } = _ref;
  var testId = (_formField$testid = formField.testid) !== null && _formField$testid !== void 0 ? _formField$testid : 'EmailUpdate';
  var currentLang = ((_formField$props = formField.props) === null || _formField$props === void 0 ? void 0 : _formField$props.currentLang) || 'en';
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var selectedCountry = (0, _reactHookForm.useWatch)({
    name: 'countryCode',
    control
  });
  var isGermanyAsCountry = selectedCountry === 'DE';
  var isReceiveEmailByDefault = currentLang === 'en' && isGermanyAsCountry || currentLang === 'de';
  var [isChecked, setIsChecked] = (0, _react2.useState)(false);
  var getCheckboxText = () => {
    var description = '';
    switch (currentLang) {
      case 'en':
        if (isGermanyAsCountry) {
          return t('booking.emailBoxText.GermanResidence');
        }
        return t('booking.emailBoxText.premierinnhubzip');
      case 'de':
        return t('booking.emailBoxText.GermanResidence');
      default:
        return description;
    }
  };
  var onChange = option => {
    var _option$target, _option$target2, _option$target3;
    var value = isReceiveEmailByDefault ? option === null || option === void 0 || (_option$target = option.target) === null || _option$target === void 0 ? void 0 : _option$target.checked : !(option !== null && option !== void 0 && (_option$target2 = option.target) !== null && _option$target2 !== void 0 && _option$target2.checked);
    setIsChecked(option === null || option === void 0 || (_option$target3 = option.target) === null || _option$target3 === void 0 ? void 0 : _option$target3.checked);
    field.onChange(value);
  };
  var getEmailText = () => {
    if (isReceiveEmailByDefault) {
      return t('booking.emailTextGermanResidence');
    }
    return t('booking.emailText');
  };
  (0, _react2.useEffect)(() => {
    var value = getFieldValue();
    field.onChange(value);
  }, [currentLang, selectedCountry]);
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(testId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, headerStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testId, 'Header'),
      children: t('booking.emailTitle')
    })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, descriptionStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(testId, 'Description'),
      dangerouslySetInnerHTML: {
        __html: (0, _utils.formatInnerHTMLAssetUrls)(getEmailText())
      },
      className: "formatLinks"
    })), (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
      name: field.name,
      value: field.value,
      onChange: onChange,
      "data-testid": (0, _utils.formatDataTestId)(testId, 'CheckboxContainer'),
      children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(testId, 'CheckboxText'),
        children: getCheckboxText()
      }))
    })]
  });
  function getFieldValue() {
    if (!isChecked) {
      return !isReceiveEmailByDefault;
    }
    return isReceiveEmailByDefault;
  }
}
var headerStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  lineHeight: '4',
  color: 'darkGrey1',
  mb: 'md'
};
var descriptionStyle = {
  fontSize: 'md',
  fontWeight: 'normal',
  lineHeight: '3',
  color: 'darkGrey1',
  mb: 'md'
};