"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CompanyName;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _yup = require("yup");
var _CompanySelection = _interopRequireDefault(require("../../common/CompanySelection"));
var _modalType = require("../../common/CompanySelection/modalType.enum");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var COMPANY_NAME_VALIDATIONS = {
  MATCHES: /^[a-zA-Z0-9\s.,:;_!?"*%=+£$€¥&@#()\-'À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/g,
  MIN: 2,
  MAX: 50
};
function CompanyName(_ref) {
  var {
    showCheckCompany,
    setShowCheckCompany,
    companyNameError,
    setCompanyNameError,
    setSearchDisabled,
    contractRateCompanyState
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [inputValue, setInputValue] = (0, _react2.useState)('');
  var [errorMessage, setErrorMessage] = (0, _react2.useState)('');
  var [showCompanySelectionModal, setShowCompanySelectionModal] = (0, _react2.useState)(false);
  var [companies, setCompanies] = (0, _react2.useState)([]);
  var [checkCompanyClicked, setCheckCompanyClicked] = (0, _react2.useState)(false);
  var [verifiedCompany, setVerifiedCompany] = (0, _react2.useState)(null);
  var inputRef = (0, _react2.useRef)();
  var inputPlaceholder = t('ccui.search.companyName.placeholder');
  var baseDataTestId = 'CompanyName';
  var [contractRateCompany, setContractRateCompany] = contractRateCompanyState;
  var inputChangeValidationSchema = (0, _yup.string)().when('$value', _ref2 => {
    var [value] = _ref2;
    return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(COMPANY_NAME_VALIDATIONS.MATCHES, t('ccui.company.invalidCompanyError')).max(COMPANY_NAME_VALIDATIONS.MAX, t('ccui.company.characterLengthError')) : (0, _yup.string)().notRequired();
  });
  var submitValidationSchema = (0, _yup.string)().required(t('ccui.company.invalidCompanyError')).matches(COMPANY_NAME_VALIDATIONS.MATCHES, t('ccui.company.invalidCompanyError')).min(COMPANY_NAME_VALIDATIONS.MIN, t('ccui.company.minCharacterLengthError')).max(COMPANY_NAME_VALIDATIONS.MAX, t('ccui.company.characterLengthError'));
  var {
    data: companiesData,
    isFetching: companiesRequestFetching,
    isError: companiesRequestError,
    refetch: fetchCompanies
  } = (0, _utils.useQueryRequest)(['searchCompanies', inputValue, true, 50, 1], _api.SEARCH_COMPANIES, {
    searchTerm: inputValue,
    negotiatedRateCompanies: true,
    limit: 50,
    offset: 1
  }, {
    enabled: false
  }, undefined, true);
  (0, _react2.useEffect)(() => {
    if (!companiesRequestFetching && checkCompanyClicked) {
      if (companiesRequestError) {
        setCompanies([]);
        setCompanyNameError(true);
        setErrorMessage(t('ccui.company.technicalerror'));
      } else {
        var _companiesData$search;
        validateCompanies(companiesData === null || companiesData === void 0 || (_companiesData$search = companiesData.searchCompanies) === null || _companiesData$search === void 0 ? void 0 : _companiesData$search.companies);
      }
      setCheckCompanyClicked(false);
    }
  }, [companiesRequestFetching, companiesData, companiesRequestError, checkCompanyClicked]);
  (0, _react2.useEffect)(() => {
    if (contractRateCompany) {
      setVerifiedCompany(contractRateCompany);
      setInputValue(contractRateCompany.name);
    }
  }, [contractRateCompany]);
  var validateCompanies = companies => {
    if (companies && companies.length > 0) {
      setCompanies(companies);
      setShowCompanySelectionModal(true);
    } else {
      setCompanies([]);
      setCompanyNameError(true);
      setErrorMessage(t('ccui.company.nocompanies'));
    }
  };
  var handleFocusLost = event => {
    if (!event.relatedTarget || !event.currentTarget.contains(event.relatedTarget)) {
      setShowCheckCompany(false);
      var searchEnabled = verifiedCompany && verifiedCompany.name === inputValue || inputValue === '';
      setSearchDisabled(!searchEnabled);
    }
  };
  var handleFocus = () => {
    var isVerifiedCompany = inputValue === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.name);
    setShowCheckCompany(!isVerifiedCompany);
    setSearchDisabled(!isVerifiedCompany);
  };
  var handleInputErrors = function () {
    var _ref3 = (0, _asyncToGenerator2.default)(function* (value, fieldValidation) {
      var _validationResult$err, _validationResult$err2, _validationResult$err3;
      var validationResult = yield fieldValidation.validate(value.toUpperCase(), {
        context: {
          value
        }
      }).catch(err => {
        return err;
      });
      var hasValidationError = (validationResult === null || validationResult === void 0 ? void 0 : validationResult.errors) && validationResult.errors.length > 0;
      setCompanyNameError(hasValidationError);
      setErrorMessage((_validationResult$err = (_validationResult$err2 = validationResult.errors) === null || _validationResult$err2 === void 0 ? void 0 : _validationResult$err2[0]) !== null && _validationResult$err !== void 0 ? _validationResult$err : '');
      return {
        hasError: hasValidationError,
        error: (_validationResult$err3 = validationResult.errors) === null || _validationResult$err3 === void 0 ? void 0 : _validationResult$err3[0]
      };
    });
    return function handleInputErrors(_x, _x2) {
      return _ref3.apply(this, arguments);
    };
  }();
  var onChangeFn = val => {
    handleInputErrors(val, inputChangeValidationSchema);
    var isVerifiedCompany = val === (verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.name);
    setShowCheckCompany(!isVerifiedCompany);
    setSearchDisabled(!isVerifiedCompany);
    setContractRateCompany(isVerifiedCompany ? verifiedCompany : null);
    setInputValue(val);
  };
  var handleCheckCompanyClicked = function () {
    var _ref4 = (0, _asyncToGenerator2.default)(function* () {
      var {
        hasError
      } = yield handleInputErrors(inputValue, submitValidationSchema);
      if (!hasError) {
        fetchCompanies();
        setCheckCompanyClicked(true);
      }
    });
    return function handleCheckCompanyClicked() {
      return _ref4.apply(this, arguments);
    };
  }();
  var handleCompanySelectionModalClosed = () => {
    setShowCompanySelectionModal(false);
    setSearchDisabled(true);
    setShowCheckCompany(true);
  };
  var handleCompanyVerified = company => {
    setInputValue(company.name);
    setShowCompanySelectionModal(false);
    setSearchDisabled(false);
    setShowCheckCompany(false);
    setVerifiedCompany(company);
    setContractRateCompany(company);
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
      spacing: companyNameError ? 4 : 6,
      align: "stretch"
    }, checkCompanyStyles), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId),
      onFocus: handleFocus,
      onBlur: handleFocusLost,
      children: [(0, _jsxRuntime.jsx)(_atoms.Input, {
        name: "companyName",
        placeholderText: inputPlaceholder,
        value: inputValue,
        onChange: onChangeFn,
        label: inputPlaceholder,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Input'),
        styles: inputStyles,
        inputRef: inputRef,
        error: showCheckCompany && companyNameError
      }), showCheckCompany && companyNameError && errorMessage && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
        infoMessage: errorMessage,
        otherStyles: errorMessageStyles,
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Error')
      }), showCheckCompany && (0, _jsxRuntime.jsx)(_atoms.Button, {
        size: "full",
        variant: "tertiary",
        transition: 'unset',
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CheckCompanyBtn'),
        isDisabled: companiesRequestFetching,
        onClick: handleCheckCompanyClicked,
        children: t('ccui.company.checkCompany')
      })]
    })), showCompanySelectionModal && (0, _jsxRuntime.jsx)(_CompanySelection.default, {
      showCompanySelectionModal: showCompanySelectionModal,
      companyModalType: _modalType.COMPANY_MODAL_TYPE.CONTRACT_RATE_CODE,
      companies: companies,
      finalFocusRef: inputRef,
      onClose: handleCompanySelectionModalClosed,
      onCompanyVerified: handleCompanyVerified
    })]
  });
}
var checkCompanyStyles = {
  position: 'absolute',
  left: 'auto',
  right: 0,
  top: {
    mobile: '20.5rem',
    xs: '17rem',
    md: '5rem',
    lg: 'var(--chakra-space-8xl)'
  },
  w: {
    mobile: '21.4375rem',
    md: '17.625rem',
    lg: '17.625rem',
    xl: '26rem'
  }
};
var errorMessageStyles = {
  position: 'relative',
  w: 'full'
};
var inputStyles = {
  inputElementStyles: {
    p: '1rem',
    borderRadius: 'var(--chakra-space-radiusSmall)',
    borderColor: 'lightGrey1',
    color: 'darkGrey1',
    bg: 'baseWhite',
    boxShadow: {
      base: 'none',
      sm: '0px 0.125rem 0.75rem var(--chakra-colors-lightGrey2)'
    },
    _hover: {
      borderColor: 'darkGrey1'
    }
  }
};