"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingActionsContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _common = require("../../../../common");
var _BookingActions = _interopRequireDefault(require("./BookingActions.component"));
var _bookingActionsFactory = _interopRequireDefault(require("./bookingActionsFactory"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingActionsContainer(props) {
  var _props$role;
  var [showChangeLogModal, setShowChangeLogModal] = (0, _react2.useState)(false);
  var {
    openAgentMemo,
    setAgentMemoReservationId
  } = (0, _utils.useAgentMemo)();
  var isChangeLogFeatureEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_CHANGE_LOG,
    fallbackValue: false
  });
  var handleAgentMemo = _ref => {
    var {
      variant,
      reservationId
    } = _ref;
    setAgentMemoReservationId(reservationId);
    openAgentMemo(variant);
  };
  var handleChangeLogClicked = (0, _react2.useCallback)(() => {
    if (isChangeLogFeatureEnabled) {
      setShowChangeLogModal(true);
    }
  }, [isChangeLogFeatureEnabled]);
  var criteria = {
    area: props.area,
    role: (_props$role = props === null || props === void 0 ? void 0 : props.role) !== null && _props$role !== void 0 ? _props$role : 'manager',
    bookingType: props.bookingType,
    bookingStatus: props.bookingStatus
  };
  var baseDataTestId = 'BookingActions';
  var linksToBeShown = (0, _bookingActionsFactory.default)(criteria, props === null || props === void 0 ? void 0 : props.basketReference, isChangeLogFeatureEnabled, props === null || props === void 0 ? void 0 : props.setIsAgentOverrideModalVisible, handleAgentMemo, props === null || props === void 0 ? void 0 : props.handleResendInvoiceAction, props === null || props === void 0 ? void 0 : props.handleResendConfirmationAction, props === null || props === void 0 ? void 0 : props.handleRepeatBookingAction, handleChangeLogClicked);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
      children: (0, _jsxRuntime.jsx)(_BookingActions.default, _objectSpread(_objectSpread({}, props), {}, {
        config: linksToBeShown,
        baseDataTestId: baseDataTestId
      }))
    }), isChangeLogFeatureEnabled && showChangeLogModal && (0, _jsxRuntime.jsx)(_common.ChangeLog, {
      showChangeLogModal: showChangeLogModal,
      setShowChangeLogModal: setShowChangeLogModal,
      bookingReference: props.bookingReference
    })]
  });
}