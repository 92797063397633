"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONFIRM_AMEND = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var CONFIRM_AMEND = exports.CONFIRM_AMEND = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  mutation ConfirmAmendLogic(\n    $tempBookingRef: String!\n    $originalBookingRef: String!\n    $token: String!\n    $channel: Channel!\n    $subchannel: String!\n    $language: String\n    $paymentOptionSelected: String!\n    $paymentOption: String\n    $environment: String!\n    $emailAddress: String\n    $paymentRequest: PaymentCcuiRequest\n    $ccuiExtraItems: CcuiExtraItems\n  ) {\n    confirmAmendLogic(\n      confirmAmendLogicCriteria: {\n        originalBookingRef: $originalBookingRef\n        tempBookingRef: $tempBookingRef\n        token: $token\n        bookingChannel: { channel: $channel, subchannel: $subchannel, language: $language }\n        paymentOptionSelected: $paymentOptionSelected\n        paymentOption: $paymentOption\n        environment: $environment\n        emailAddress: $emailAddress\n        paymentRequest: $paymentRequest\n        ccuiExtraItems: $ccuiExtraItems\n      }\n    ) {\n      payment {\n        status\n        paymentRequiredDetails {\n          paymentRedirect\n          sessionId\n          template\n        }\n      }\n    }\n  }\n"])));