"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStaticLabels = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject;
var getStaticLabels = exports.getStaticLabels = function getStaticLabels() {
  var fetchPreCheckInLabel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var fetchGroupBookingFormLabel = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query GetStaticLabels($language: String!, $country: String!) {\n    labels(country: $country, language: $language) {\n      booking\n      main\n      piBookings\n      ", "\n      ", "\n       extras\n    }\n  }\n"])), fetchPreCheckInLabel ? 'piPreCheckIn' : '', fetchGroupBookingFormLabel ? 'piGroupBooking' : '');
};