"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgMapExpand = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  height: 22,
  width: 22,
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 384.97 384.97",
  xmlSpace: "preserve"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    d: "M384.97 12.03c0-6.713-5.317-12.03-12.03-12.03H264.847c-6.833 0-11.922 5.39-11.934 12.223 0 6.821 5.101 11.838 11.934 11.838h96.062l-.193 96.519c0 6.833 5.197 12.03 12.03 12.03 6.833-.012 12.03-5.197 12.03-12.03l.193-108.369c0-.036-.012-.06-.012-.084.001-.037.013-.061.013-.097zM120.496 0H12.403c-.036 0-.06.012-.096.012-.024 0-.06-.012-.084-.012C5.51 0 .192 5.317.192 12.03L0 120.399c0 6.833 5.39 11.934 12.223 11.934 6.821 0 11.838-5.101 11.838-11.934l.192-96.339h96.242c6.833 0 12.03-5.197 12.03-12.03C132.514 5.197 127.317 0 120.496 0zM120.123 360.909H24.061v-96.242c0-6.833-5.197-12.03-12.03-12.03S0 257.833 0 264.667v108.092c0 .036.012.06.012.084 0 .036-.012.06-.012.096 0 6.713 5.317 12.03 12.03 12.03h108.092c6.833 0 11.922-5.39 11.934-12.223.001-6.82-5.1-11.837-11.933-11.837zM372.747 252.913c-6.833 0-11.85 5.101-11.838 11.934v96.062h-96.242c-6.833 0-12.03 5.197-12.03 12.03s5.197 12.03 12.03 12.03h108.092c.036 0 .06-.012.084-.012.036-.012.06.012.096.012 6.713 0 12.03-5.317 12.03-12.03V264.847c.001-6.833-5.389-11.934-12.222-11.934z"
  })
}));
var _default = exports.default = SvgMapExpand;