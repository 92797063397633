"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateUpdateAccountForm;
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var VALIDATIONS = {
  FIRST_NAME: {
    MATCHES: RegExp(/^[-A-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']+-?[\sA-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']*$/),
    MIN: 2,
    MAX: 20
  },
  LAST_NAME: {
    MATCHES: RegExp(/^[-A-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']+-?[\sA-Za-zÀ-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ ']*$/),
    MIN: 2,
    MAX: 30
  },
  COMPANY_NAME: {
    MATCHES: RegExp(/^[a-zA-Z0-9\s.,&@#()-À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/),
    MIN: 2,
    MAX: 50
  },
  EMAIL: {
    MATCHES: RegExp(/^[a-z0-9]+(?:[._-][a-z0-9]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i),
    MAX: 50,
    MIN: 2
  },
  ADDRESS: {
    MATCHES: RegExp(/^[a-zA-Z0-9\s.,&@#()-À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/),
    MIN: 2,
    MAX: 200
  },
  POSTAL_CODE: {
    MATCHES: RegExp(/^[a-zA-Z0-9 ]*$/i),
    MIN: 2,
    MAX: 7
  },
  PHONE: {
    MATCHES: RegExp(/^\s*\+?\s*(\d[\s-]*){6,}$/im),
    MIN: 9,
    MAX: 15
  }
};
var standarValidationForUpdateForm = (nameOfTest, defaultValue, ValidationRule) => {
  return yup.string().transform(value => value === '' ? null : value).nullable().test(nameOfTest, '', function (value, ctx) {
    var _value$length, _value$length2;
    var isDefaultValue = defaultValue === value;
    if (isDefaultValue) {
      return true;
    }
    var isEmptyByDefault = (value !== null && value !== void 0 ? value : '').trim() === '' && defaultValue === '';
    if (isEmptyByDefault) {
      return true;
    }
    var isInvalidLength = ((_value$length = value === null || value === void 0 ? void 0 : value.length) !== null && _value$length !== void 0 ? _value$length : 0) < ValidationRule.min;
    var isNotMatching = typeof value === 'string' && value.trim() !== '' && !ValidationRule.match.test(value);
    var isTooLong = ((_value$length2 = value === null || value === void 0 ? void 0 : value.length) !== null && _value$length2 !== void 0 ? _value$length2 : 0) > ValidationRule.max;
    if (isInvalidLength) {
      throw ctx.createError({
        message: ValidationRule.minErrorMsg
      });
    }
    if (isNotMatching) {
      throw ctx.createError({
        message: ValidationRule.matchErrorMsg
      });
    }
    if (isTooLong) {
      throw ctx.createError({
        message: ValidationRule.maxErrorMsg
      });
    }
    return true;
  });
};
function validateUpdateAccountForm(t, defaultValues) {
  var firstNameCodeValidationRules = {
    match: VALIDATIONS.FIRST_NAME.MATCHES,
    max: VALIDATIONS.FIRST_NAME.MAX,
    min: VALIDATIONS.FIRST_NAME.MIN,
    matchErrorMsg: 'Please enter a valid first name (max 20 characters)',
    maxErrorMsg: 'Please enter a valid first name (max 20 characters)',
    minErrorMsg: 'Please enter at least 2 characters'
  };
  var lastnameCodeValidationRules = {
    match: VALIDATIONS.LAST_NAME.MATCHES,
    max: VALIDATIONS.LAST_NAME.MAX,
    min: VALIDATIONS.LAST_NAME.MIN,
    matchErrorMsg: 'Please enter a valid surname (max 30 characters)',
    maxErrorMsg: 'Please enter a valid surname (max 30 characters)',
    minErrorMsg: 'Please enter at least 2 characters'
  };
  var companynameCodeValidationRules = {
    match: VALIDATIONS.COMPANY_NAME.MATCHES,
    max: VALIDATIONS.COMPANY_NAME.MAX,
    min: VALIDATIONS.COMPANY_NAME.MIN,
    matchErrorMsg: 'Please enter a valid company (max 50 characters)',
    maxErrorMsg: 'Please enter a valid company (max 50 characters)',
    minErrorMsg: 'Please enter at least 2 characters'
  };
  var emailCodeValidationRules = {
    match: VALIDATIONS.EMAIL.MATCHES,
    max: VALIDATIONS.EMAIL.MAX,
    min: VALIDATIONS.EMAIL.MIN,
    matchErrorMsg: 'Please enter a valid e-mail address',
    maxErrorMsg: 'Please enter a valid e-mail address',
    minErrorMsg: 'Please enter a valid e-mail address'
  };
  var addressCodeValidationRules = {
    match: VALIDATIONS.ADDRESS.MATCHES,
    max: VALIDATIONS.ADDRESS.MAX,
    min: VALIDATIONS.ADDRESS.MIN,
    matchErrorMsg: 'Please enter a valid address',
    maxErrorMsg: 'Please enter a valid address',
    minErrorMsg: 'Please enter the home or business address'
  };
  var postalCodeValidationRules = {
    match: VALIDATIONS.POSTAL_CODE.MATCHES,
    max: VALIDATIONS.POSTAL_CODE.MAX,
    min: VALIDATIONS.POSTAL_CODE.MIN,
    matchErrorMsg: t('config.errorMessages.yourDetails.postcode.invalid'),
    maxErrorMsg: t('config.errorMessages.yourDetails.postcode.invalid'),
    minErrorMsg: t('config.errorMessages.yourDetails.postcode.invalid')
  };
  var formValidationObject = {
    firstName: standarValidationForUpdateForm('FirstnameCodeValidation', defaultValues.firstName, firstNameCodeValidationRules),
    lastName: standarValidationForUpdateForm('lastNameValidation', defaultValues.lastName, lastnameCodeValidationRules),
    companyName: standarValidationForUpdateForm('companyNameValidation', defaultValues.companyName, companynameCodeValidationRules),
    email: standarValidationForUpdateForm('emailValidation', defaultValues.email, emailCodeValidationRules),
    address: standarValidationForUpdateForm('addressValidation', defaultValues.address, addressCodeValidationRules),
    postalCode: standarValidationForUpdateForm('postalCodeValidation', defaultValues.postalCode, postalCodeValidationRules),
    mobileNumber: yup.string().transform(value => value || null).nullable().test('mobileAndLandlineValidation', 'Please enter a valid phone number', function (value, ctx) {
      var {
        landlineNumber
      } = ctx.parent;
      var mobileIsDefaultValue = defaultValues.mobileNumber === value;
      var landlineIsDefaultValue = defaultValues.landlineNumber === landlineNumber;
      if (!mobileIsDefaultValue && !landlineIsDefaultValue && !value && !landlineNumber) {
        throw ctx.createError({
          message: 'Please enter a valid phone number'
        });
      }
      if (value && !VALIDATIONS.PHONE.MATCHES.test(value)) {
        throw ctx.createError({
          message: t('config.errorMessages.yourDetails.mobile.invalid')
        });
      }
      if (value && (value.length < VALIDATIONS.PHONE.MIN || value.length > VALIDATIONS.PHONE.MAX)) {
        throw ctx.createError({
          message: t('config.errorMessages.yourDetails.mobile.invalid')
        });
      }
      ctx.parent.mobileError = null;
      ctx.parent.landlineError = null;
      return true;
    }),
    landlineNumber: yup.string().transform(value => value || null).nullable().test('mobileAndLandlineValidation', 'Please enter a valid phone number', function (value, ctx) {
      var {
        mobileNumber
      } = ctx.parent;
      var landlineIsDefaultValue = defaultValues.landlineNumber === value;
      var mobileIsDefaultValue = defaultValues.mobileNumber === mobileNumber;
      if (!landlineIsDefaultValue && !mobileIsDefaultValue && !value && !mobileNumber) {
        throw ctx.createError({
          message: 'Please enter a valid phone number'
        });
      }
      if (value && !VALIDATIONS.PHONE.MATCHES.test(value)) {
        throw ctx.createError({
          message: t('config.errorMessages.yourDetails.telephone.invalid')
        });
      }
      if (value && (value.length < VALIDATIONS.PHONE.MIN || value.length > VALIDATIONS.PHONE.MAX)) {
        throw ctx.createError({
          message: t('config.errorMessages.yourDetails.telephone.invalid')
        });
      }
      ctx.parent.mobileError = null;
      ctx.parent.landlineError = null;
      return true;
    })
  };
  var formValidationSchema = yup.object().shape(formValidationObject, [['phone', 'landline']]);
  return {
    formValidationObject,
    formValidationSchema
  };
}