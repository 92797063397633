"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgHairDryer = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 20,
  height: 21,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M14.975.141A4.403 4.403 0 0 1 17 8.675V16.5a1.5 1.5 0 0 1-1.022 1.422c-.185 1.742-1.532 2.989-3.737 3.073L12 21H8v-1h4c1.742 0 2.746-.8 2.958-2h-.147a1.5 1.5 0 0 1-1.432-1.055l-.035-.136-.402-1.904-.806.201-.742-2.97.917-.23-.744-3.534-5.28-.754a1.5 1.5 0 0 1-1.28-1.347L5 6.133V2.867c0-.7.482-1.3 1.152-1.459l.136-.026 8.687-1.24Zm-2.354 8.38 1.701 8.082a.5.5 0 0 0 .403.39l.086.007h.689a.5.5 0 0 0 .492-.41L16 16.5V8.885a4.46 4.46 0 0 1-.819-.002l-.206-.024-2.354-.337Zm4.394-7.115a3.383 3.383 0 0 0-1.676-.3l-.223.025-8.687 1.241a.5.5 0 0 0-.42.404L6 2.867v3.266a.5.5 0 0 0 .34.473l.09.022 8.686 1.24a3.404 3.404 0 0 0 1.9-.274C16.34 6.652 16 5.617 16 4.5a5.2 5.2 0 0 1 .924-2.966l.09-.128Zm.837.546-.014.02C17.276 2.752 17 3.592 17 4.5c0 .915.28 1.76.852 2.547a3.389 3.389 0 0 0 1.133-2.868l-.02-.16-.03-.184a3.387 3.387 0 0 0-1.083-1.883ZM3.5 5a.5.5 0 0 1 .09.992L3.5 6h-3a.5.5 0 0 1-.09-.992L.5 5h3Zm0-2a.5.5 0 0 1 .09.992L3.5 4h-3a.5.5 0 0 1-.09-.992L.5 3h3Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgHairDryer;