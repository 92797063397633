"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsControllerContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _formatISO = _interopRequireDefault(require("date-fns/formatISO"));
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingDetailsController = _interopRequireDefault(require("./BookingDetailsController.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsControllerContainer(_ref) {
  var {
    hotelId,
    basketReference,
    bookingReference,
    area = 'pi',
    getBookingStatus,
    bookingStatus,
    bookingType,
    idvData,
    setIdvData,
    defaultDataFromBooking,
    dpaInfo,
    setDpaInfo,
    ammendOrCancelLabel,
    overridenUserInfo,
    inputValues,
    isAmendSuccessful,
    rateType,
    arrivalDate,
    bookingSurname,
    isAmendPage
  } = _ref;
  var date = (0, _formatISO.default)(Date.now());
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var {
    data = {
      manageBooking: {
        isCancellable: false,
        isAmendable: false,
        isRuleCompliant: true
      }
    },
    isError,
    isLoading,
    error,
    refetch
  } = (0, _utils.useQueryRequest)(['manageBookingDashBoard', basketReference, hotelId], _api.DASHBOARD_MANAGE_BOOKING, {
    cancelInformationCriteria: {
      userDateTime: date,
      bookingChannel: {
        channel: area === 'pi' ? _api.BOOKING_CHANNEL.PI : _api.BOOKING_CHANNEL.CCUI,
        subchannel: _api.BOOKING_SUBCHANNEL.WEB,
        language: language
      },
      basketReference: basketReference,
      hotelId: hotelId,
      token: (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token)
    }
  }, {
    enabled: (area === _api.Area.PI && bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED || area === _api.Area.CCUI && (bookingType === _api.BOOKING_TYPE.UPCOMING || isAmendPage)) && !!hotelId,
    staleTime: 0,
    cacheTime: 0
  });
  (0, _react2.useEffect)(() => {
    var bookingCanBeRefetched = (bookingType === _api.BOOKING_TYPE.UPCOMING || isAmendPage) && (overridenUserInfo === null || overridenUserInfo === void 0 ? void 0 : overridenUserInfo.reservationOverridden) && ((dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaOverride) || (dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaPassed)) || bookingType === _api.BOOKING_TYPE.CANCELLED;
    if (bookingCanBeRefetched) {
      refetch();
    }
  }, [overridenUserInfo === null || overridenUserInfo === void 0 ? void 0 : overridenUserInfo.reservationOverridden, dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaOverride, dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaPassed, bookingType, isAmendPage]);
  if (isError) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: String(error),
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingDetailsController",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  var {
    isCancellable,
    isAmendable,
    isRuleCompliant
  } = data.manageBooking;
  return (0, _jsxRuntime.jsx)(_BookingDetailsController.default, {
    refetchManageBooking: () => {
      getBookingStatus();
    },
    isCancellable: isCancellable,
    isAmendable: isAmendable,
    isRuleCompliant: isRuleCompliant,
    bookingReference: bookingReference,
    basketReference: basketReference,
    area: area,
    bookingStatus: bookingStatus,
    bookingType: bookingType,
    ammendOrCancelLabel: ammendOrCancelLabel,
    idvData: idvData,
    setIdvData: setIdvData,
    defaultDataFromBooking: defaultDataFromBooking,
    dpaInfo: dpaInfo,
    setDpaInfo: setDpaInfo,
    inputValues: inputValues,
    isAmendSuccessful: isAmendSuccessful,
    rateType: rateType,
    arrivalDate: arrivalDate,
    bookingSurname: bookingSurname,
    isAmendPage: isAmendPage
  });
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};