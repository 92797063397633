"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BusinessAllowances;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
var _templateObject;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BusinessAllowances(_ref) {
  var _businessAllowances$t;
  var {
    toggleDinnerAllowance,
    dinnerAllowance,
    paymentHasError,
    businessAllowances,
    setBusinessAllowances,
    businessAllowancesSections = {
      amountDisabled: false,
      allowDinner: true,
      allowAlcohol: true,
      allowCarParking: true,
      allowWiFi: true,
      amount: 0
    },
    reservationDetails = {
      currency: _api.Currency.GBP
    },
    hasBusinessAllowanceErrorState
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var baseDataTestId = 'BusinessAllowances';
  var setDinnerBudget = val => {
    var inputRegex = new RegExp(/^\d{0,3}$/g);
    if (inputRegex.test(val)) {
      validateDinnerAllowance(val);
      setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
        totalDinnerBudgetPersonNight: val.replace(/^0+/, '')
      }));
    }
  };
  var {
    totalDinnerBudgetPersonNight
  } = businessAllowances;
  var [hasBusinessAllowanceError, setHasBusinessAllowanceError] = hasBusinessAllowanceErrorState;
  var refTextWidth = (0, _react2.useRef)(null);
  var [width, setWidth] = (0, _react2.useState)(0);
  var currency = reservationDetails.currency === 'GBP' ? _api.Currency.GBP : _api.Currency.EUR;
  var hasDinnerAllowanceValidValue = totalDinnerBudgetPersonNight !== '' && !isNaN(parseInt(totalDinnerBudgetPersonNight, 10));
  var isEuroHotelUkSite = currency !== _api.Currency.GBP && language === 'en';
  (0, _react2.useEffect)(() => {
    if (refTextWidth.current) {
      setWidth(refTextWidth.current ? refTextWidth.current.offsetWidth : 0);
    }
  }, []);
  var validateDinnerAllowance = dinnerAllowance => {
    var isInvalidDinnerAllowance = dinnerAllowance < 1 || dinnerAllowance > 999 || !_atoms.FORM_VALIDATIONS.DINNER_ALLOWANCE.MATCHES.test(dinnerAllowance.toString());
    setHasBusinessAllowanceError(isInvalidDinnerAllowance);
  };
  var resetDinnerAllowanceHandler = () => {
    setHasBusinessAllowanceError(false);
    if (!dinnerAllowance) {
      businessAllowances.totalDinnerBudgetPersonNight = businessAllowancesSections === null || businessAllowancesSections === void 0 ? void 0 : businessAllowancesSections.amount;
    }
  };
  (0, _react2.useEffect)(() => {
    if (paymentHasError && totalDinnerBudgetPersonNight < 1) {
      setHasBusinessAllowanceError(true);
    } else {
      setHasBusinessAllowanceError(false);
    }
  }, [paymentHasError]);
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, businessAllowancesWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
    }, titleStyle), {}, {
      children: t('booking.bac.businessAllowances')
    })), businessAllowancesSections.allowDinner && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_react.Box, {
        border: "1px",
        borderColor: "lightGrey4",
        children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleDinnerAllowance'),
          ml: "md",
          onChange: () => {
            toggleDinnerAllowance();
            resetDinnerAllowanceHandler();
          },
          isChecked: dinnerAllowance,
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleDinnerAllowance-Label')
          }, checkboxLabelStyle), {}, {
            children: t('booking.bac.businessDinnerBudget')
          }))
        })
      }), dinnerAllowance && (0, _jsxRuntime.jsx)(CustomBox, _objectSpread(_objectSpread({}, customBoxStyle), {}, {
        innertextwidth: width,
        currency: (0, _utils.formatCurrency)(reservationDetails.currency),
        hasvalue: hasDinnerAllowanceValidValue ? 'true' : 'false',
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "budget-".concat((0, _utils.formatCurrency)(reservationDetails.currency))),
        isEuroHotelUkSite: isEuroHotelUkSite,
        children: (0, _jsxRuntime.jsx)(_atoms.Input, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DinnerBudget'),
          label: t('booking.bac.dinnerBudget'),
          placeholderText: t('booking.bac.dinnerBudget'),
          name: "totalDinnerBudgetPersonNight",
          value: (_businessAllowances$t = businessAllowances.totalDinnerBudgetPersonNight) === null || _businessAllowances$t === void 0 ? void 0 : _businessAllowances$t.toString(),
          onChange: val => {
            setDinnerBudget(val);
          },
          error: hasBusinessAllowanceError && t('booking.bac.dinnerBudget.invalid'),
          isDisabled: businessAllowancesSections.amountDisabled,
          showLabel: true
        })
      }))]
    }), dinnerAllowance && businessAllowancesSections.allowDinner && businessAllowancesSections.allowAlcohol && (0, _jsxRuntime.jsx)(_react.Box, {
      border: "1px",
      borderColor: "lightGrey4",
      children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleAlcoholDinner'),
        ml: "md",
        onChange: () => {
          setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
            isAlcoholDinner: !businessAllowances.isAlcoholDinner
          }));
        },
        isChecked: businessAllowances.isAlcoholDinner,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AlcoholLabel')
        }, checkboxLabelStyle), {}, {
          children: t('booking.bac.alcohol')
        }))
      })
    }), (businessAllowancesSections.allowCarParking || businessAllowancesSections.allowWiFi) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [businessAllowancesSections.allowDinner && (0, _jsxRuntime.jsx)(_react.Text, {
        mt: "lg",
        mb: "xs",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'OtherAllowances'),
        children: t('businessAllowances.otherAllowances')
      }), businessAllowancesSections.allowCarParking && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          border: "1px",
          borderColor: "lightGrey4",
          children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CarParkingCheckbox'),
            ml: "md",
            onChange: () => setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
              carParking: !businessAllowances.carParking
            })),
            isChecked: businessAllowances.carParking,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CarParkingLabel')
            }, checkboxLabelStyle), {}, {
              children: "".concat(t('booking.bac.carParking'))
            }))
          })
        }), (0, _jsxRuntime.jsx)(_react.Divider, {})]
      }), businessAllowancesSections.allowWiFi && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
        children: [(0, _jsxRuntime.jsx)(_react.Box, {
          border: "1px",
          borderColor: "lightGrey4",
          children: (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'WifiCheckbox'),
            ml: "md",
            onChange: () => setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
              wifi: !businessAllowances.wifi
            })),
            isChecked: businessAllowances.wifi,
            children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'WifiLabel')
            }, checkboxLabelStyle), {}, {
              children: t('booking.bac.wifi')
            }))
          })
        }), (0, _jsxRuntime.jsx)(_react.Divider, {})]
      })]
    })]
  }));
}
var businessAllowancesWrapperStyle = {
  mb: '2xl',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    xl: '26.25rem'
  }
};
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  mb: 'xl',
  lineHeight: '3',
  color: 'darkGrey1'
};
var checkboxLabelStyle = {
  fontSize: 'md',
  lineHeight: '3',
  mx: ' sm',
  color: 'darkGrey1'
};
var customBoxStyle = {
  maxW: '26.25rem',
  my: 'lg',
  mt: 'xl',
  mb: 'xl',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '26.25rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};
var CustomBox = (0, _styled.default)(_react.Box)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  ", "\n"])), _ref2 => {
  var {
    hasvalue,
    innertextwidth,
    currency,
    isEuroHotelUkSite
  } = _ref2;
  return hasvalue === 'true' && "\n  & > div > div:first-of-type {\n    position: relative;\n    &:after {\n      content: '".concat(currency, "';\n      position: absolute;\n      left: ").concat(currency === _api.Currency.GBP || isEuroHotelUkSite ? '1.25rem' : "".concat(innertextwidth / 16, "rem"), ";\n      top: 0;\n      height: 100%;\n      padding-left: ").concat(currency === _api.Currency.GBP || isEuroHotelUkSite ? 0 : "1.25rem", ";\n      display: flex;\n      align-items: center;\n    },\n  }\n  & > div > div > input {\n    padding-left: 1.875rem;\n  }\n  ");
});