"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelDetailsComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelDetailsComponent(_ref) {
  var _data$hotelInformatio, _data$hotelInformatio2, _data$hotelInformatio3, _galleryImages$, _data$hotelInformatio4, _galleryImages$2, _data$hotelInformatio5;
  var {
    data
  } = _ref;
  var parkingInfo = data === null || data === void 0 || (_data$hotelInformatio = data.hotelInformation) === null || _data$hotelInformatio === void 0 ? void 0 : _data$hotelInformatio.parkingDescription;
  var hdpUrl = data === null || data === void 0 || (_data$hotelInformatio2 = data.hotelInformation) === null || _data$hotelInformatio2 === void 0 || (_data$hotelInformatio2 = _data$hotelInformatio2.links) === null || _data$hotelInformatio2 === void 0 ? void 0 : _data$hotelInformatio2.detailsPage;
  var galleryImages = data === null || data === void 0 || (_data$hotelInformatio3 = data.hotelInformation) === null || _data$hotelInformatio3 === void 0 ? void 0 : _data$hotelInformatio3.galleryImages;
  var hotelImage = galleryImages && (0, _utils.formatAssetsUrl)((_galleryImages$ = galleryImages[0]) === null || _galleryImages$ === void 0 ? void 0 : _galleryImages$.thumbnailSrc);
  var brand = data === null || data === void 0 || (_data$hotelInformatio4 = data.hotelInformation) === null || _data$hotelInformatio4 === void 0 ? void 0 : _data$hotelInformatio4.brand;
  var altText = galleryImages === null || galleryImages === void 0 || (_galleryImages$2 = galleryImages[0]) === null || _galleryImages$2 === void 0 ? void 0 : _galleryImages$2.alt;
  var address = data === null || data === void 0 || (_data$hotelInformatio5 = data.hotelInformation) === null || _data$hotelInformatio5 === void 0 ? void 0 : _data$hotelInformatio5.address;
  var baseDataTestId = 'HotelDetails';
  var {
    country,
    language
  } = (0, _utils.useCustomLocale)();
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsWrapperStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [hotelImage && (0, _jsxRuntime.jsx)(_molecules.HotelThumbnail, {
      image: "".concat(hotelImage),
      alt: altText,
      url: "/".concat(country, "/").concat(language, "/hotels").concat(hdpUrl, ".html"),
      brand: brand
    }), (0, _jsxRuntime.jsxs)(_react.Flex, {
      mt: "xmd",
      children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Location24, {})
      }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, addressStyle), {}, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Address'),
        children: address
      }))]
    }), (0, _jsxRuntime.jsx)(_molecules.HotelParking, {
      content: parkingInfo
    })]
  }));
}
var addressStyle = {
  fontSize: 'sm',
  fontWeight: 'medium',
  lineHeight: '2',
  color: 'darkGrey2',
  ml: 'sm'
};
var hotelDetailsWrapperStyle = {
  flexDirection: 'column',
  mt: {
    mobile: 'lg',
    lg: 0
  },
  ml: {
    mobile: 0,
    lg: '5xl'
  },
  w: {
    mobile: 'full',
    lg: '21rem'
  },
  whiteSpace: 'pre-wrap'
};