"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgIron32 = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 25,
  height: 28,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M9 0c.135 0 .318.064.54.191L9.714.3l.092.063.193.143.206.167c.035.03.071.06.107.093l.28.257.355.358.31.34c.085.095.17.194.257.297l.263.322c.089.112.178.227.268.346l.273.37c.09.127.183.258.275.393l.279.417.28.44c.047.076.094.152.14.23l.281.476c.094.163.187.33.28.5l.279.524C16.167 9.962 18 15.8 18 23.529c-.313.096-.624.187-.931.273L16.334 26h2c1.86 0 2.77-1.02 2.83-3.264l.003-.236c0-2.663 1.103-4.396 3.602-4.495L25 18v1c-1.938 0-2.777 1.162-2.83 3.278l-.003.222c0 2.836-1.235 4.403-3.608 4.496l-.226.004H16l-.075.16c-.28.56-.588.84-.925.84H3c-.368 0-.702-.333-1-1L.943 23.806A42.73 42.73 0 0 1 0 23.529C0 15.8 1.833 9.962 3.868 6.035l.279-.524c.093-.17.186-.337.28-.5l.28-.476.351-.562.349-.525.138-.2.274-.381c.091-.123.181-.242.27-.358l.266-.334c.088-.107.174-.21.26-.31l.253-.286.124-.134.3-.31.286-.272.11-.098L8 .505l.193-.143.092-.063L8.46.19C8.682.064 8.865 0 9 0ZM2.032 24.094 3 27h12l.969-2.906c-4.853 1.208-9.084 1.208-13.937 0ZM9 1c-.117 0-.35.16-.662.45l-.218.215-.238.25-.126.14-.264.304-.277.337-.289.368c-.097.128-.196.26-.296.398l-.455.648-.306.463c-.204.317-.407.65-.606.995l-.296.528-.144.27c-2.231 4.25-3.634 9.614-3.805 15.859l-.012.558.406.115c5.353 1.47 9.824 1.47 15.176 0l.405-.115-.01-.558c-.167-6.03-1.48-11.239-3.578-15.415l-.228-.443-.144-.271-.296-.528c-.15-.26-.3-.51-.453-.754l-.306-.476a24.022 24.022 0 0 0-.153-.228l-.304-.439c-.1-.142-.2-.279-.3-.412l-.293-.383-.143-.18-.277-.337-.264-.304-.126-.14-.238-.25-.218-.214C9.35 1.159 9.117 1 9 1ZM3.5 20.25a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm11 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5ZM4 14.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm10 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm-8.5-5.5a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Zm7 0a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5ZM9 3.75a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgIron32;