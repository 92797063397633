"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = validateForm;
var _atoms = require("@whitbread-eos/atoms");
var yup = _interopRequireWildcard(require("yup"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function validateForm(labels) {
  var _labels$headerInforma, _labels$headerInforma2;
  var invalidReferenceLabel = labels === null || labels === void 0 || (_labels$headerInforma = labels.headerInformation) === null || _labels$headerInforma === void 0 || (_labels$headerInforma = _labels$headerInforma.form) === null || _labels$headerInforma === void 0 ? void 0 : _labels$headerInforma.invalidReference;
  var invalidSurnameLabel = labels === null || labels === void 0 || (_labels$headerInforma2 = labels.headerInformation) === null || _labels$headerInforma2 === void 0 || (_labels$headerInforma2 = _labels$headerInforma2.form) === null || _labels$headerInforma2 === void 0 ? void 0 : _labels$headerInforma2.invalidSurname;
  var formValidationObject = {
    bookingReference: yup.string().required(invalidReferenceLabel).matches(_atoms.FORM_VALIDATIONS.MANAGE_BOOKING_BOOKING_REFERENCE.MATCHES, invalidReferenceLabel).min(_atoms.FORM_VALIDATIONS.MANAGE_BOOKING_BOOKING_REFERENCE.MIN, invalidReferenceLabel),
    bookingSurname: yup.string().transform(value => value.replace(/^(\s+|\s+$)/gm, '')).required(invalidSurnameLabel).matches(_atoms.FORM_VALIDATIONS.MANAGE_BOOKING_MODAL_SURNAME.MATCHES, invalidSurnameLabel).min(_atoms.FORM_VALIDATIONS.MANAGE_BOOKING_MODAL_SURNAME.MIN, invalidSurnameLabel).max(_atoms.FORM_VALIDATIONS.MANAGE_BOOKING_MODAL_SURNAME.MAX, invalidSurnameLabel)
  };
  var formValidationSchema = yup.object().shape(formValidationObject);
  return {
    formValidationObject,
    formValidationSchema
  };
}