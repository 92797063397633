"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = IframeEmbed;
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function IframeEmbed(_ref) {
  var {
    iframeId,
    iframeContent,
    onIframeLoad,
    providerUrl = ''
  } = _ref;
  var paymentContainerRef = (0, _react2.useRef)(null);
  var html = (0, _react2.useMemo)(() => {
    if (iframeContent) {
      return Buffer.from(iframeContent, 'base64');
    }
    return null;
  }, [iframeContent]);
  (0, _react2.useEffect)(() => {
    window.scrollTo(0, 0);
  }, []);
  (0, _react2.useEffect)(() => {
    if (html && !document.getElementById(iframeId)) {
      var _paymentContainerRef$, _iframe$contentWindow, _iframe$contentWindow2, _iframe$contentWindow3;
      var iframe = document.createElement('iframe');
      iframe.style.width = '100%';
      iframe.style.minHeight = '58rem';
      iframe.style.height = '100%';
      iframe.style.marginBottom = 'var(--chakra-fontSizes-md)';
      paymentContainerRef === null || paymentContainerRef === void 0 || (_paymentContainerRef$ = paymentContainerRef.current) === null || _paymentContainerRef$ === void 0 || _paymentContainerRef$.appendChild(iframe);
      iframe.id = iframeId;
      iframe.allow = "payment ".concat(providerUrl);
      (_iframe$contentWindow = iframe.contentWindow) === null || _iframe$contentWindow === void 0 || _iframe$contentWindow.document.open();
      (_iframe$contentWindow2 = iframe.contentWindow) === null || _iframe$contentWindow2 === void 0 || _iframe$contentWindow2.document.write(html.toString());
      (_iframe$contentWindow3 = iframe.contentWindow) === null || _iframe$contentWindow3 === void 0 || _iframe$contentWindow3.document.close();
      var timer = (0, _utils.timeTrackerSeconds)();
      var listen = () => {
        onIframeLoad(timer());
        iframe.removeEventListener('load', listen);
      };
      iframe.addEventListener('load', listen);
      return () => iframe.removeEventListener('load', listen);
    }
  }, [html, onIframeLoad]);
  return (0, _jsxRuntime.jsx)(_react.Box, {
    w: "full",
    "data-testid": "paymentContainer",
    ref: paymentContainerRef
  });
}