"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgPointer = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 23,
  height: 28,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.916 12.376v7.19c-.5 1.293-1.49 3.856-2.116 5.47H7.97A2.822 2.822 0 0 0 7.6 23.9c-.735-1.19-4.464-7.227-5.67-9.143-.662-1.05-.938-1.835-.82-2.334.043-.18.16-.458.676-.773.659-.402 1.34-.819 3.61 2.188l.01.01v2.32c0 .295.242.535.542.535.3 0 .542-.24.542-.535V2.68c0-.889.733-1.612 1.633-1.612.902 0 1.635.723 1.635 1.612v8.826c0 .296.242.535.541.535.3 0 .543-.239.543-.535V7.46h1.654c.901 0 1.634.723 1.634 1.611v2.438c0 .295.243.534.542.534.3 0 .542-.239.542-.534v-2.32h1.212c.9 0 1.633.724 1.633 1.613v2.082c0 .295.243.534.542.534.3 0 .542-.239.542-.534v-2.082l-.002-.037h1.144c.9 0 1.632.722 1.631 1.611ZM19.243 26.46c-.046.11-.217.471-.466.471H8.274c-.135 0-.221-.005-.276-.012-.008-.1-.008-.254-.008-.349v-.465h11.394c-.075.193-.126.32-.14.355Zm1.04-16.765h-1.385a2.724 2.724 0 0 0-2.473-1.575h-1.392a2.719 2.719 0 0 0-2.538-1.73H10.84V2.68C10.84 1.203 9.62 0 8.123 0S5.406 1.202 5.406 2.68v9.449c-1.65-1.908-2.788-2.245-4.19-1.388-.644.393-1.024.863-1.16 1.438-.192.807.11 1.806.953 3.142 1.204 1.912 4.93 7.945 5.666 9.135.135.219.23.57.23.855v.268l.001.99c0 .838.099 1.431 1.367 1.431h10.503c.63 0 1.165-.412 1.467-1.13.133-.313 2.616-6.743 2.722-7.016a.517.517 0 0 0 .035-.19v-7.288c0-1.478-1.218-2.68-2.716-2.681Z",
    fill: props.color || '#000'
  })
}));
var _default = exports.default = SvgPointer;