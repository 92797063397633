"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BillingAddress;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react = require("react");
var _billingAddressFormConfig = require("./billingAddressFormConfig");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BillingAddress(_ref) {
  var {
    currentBillingAddress,
    continueToNextStep,
    t,
    currentLang,
    isAmendPage,
    hasError,
    setBillingAddress
  } = _ref;
  var baseDataTestId = 'Payment';
  var testid = (0, _utils.formatDataTestId)(baseDataTestId, 'BillingAdress-MainContainer');
  var [isLocationRequired, setIsLocationRequired] = (0, _react.useState)(false);
  var [defaultValues, setDefaultValues] = (0, _react.useState)({
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postalCode: '',
    cityName: '',
    postcodeAddress: '',
    addressSelection: 'HOME',
    billingAddressSelection: 'CurrentAddress',
    countryCode: currentLang === 'en' ? 'GB' : 'DE',
    manualAddressToggle: currentLang === 'en' ? '' : 'manualAddress'
  });
  var getFormState = (0, _react.useCallback)(state => {
    setDefaultValues(state);
  }, [setDefaultValues]);
  (0, _react.useEffect)(() => {
    if (defaultValues.countryCode === 'DE') {
      setIsLocationRequired(true);
    }
  }, [defaultValues.countryCode]);
  var extractBillingAddress = data => {
    var billingAddress;
    if (data.billingAddressSelection === 'DifferentAddress') {
      var _data$addressLine, _data$addressLine2, _data$addressLine3;
      billingAddress = {
        addressLine1: data.addressLine1,
        addressLine2: (_data$addressLine = data.addressLine2) !== null && _data$addressLine !== void 0 ? _data$addressLine : '',
        addressLine3: (_data$addressLine2 = data.addressLine3) !== null && _data$addressLine2 !== void 0 ? _data$addressLine2 : '',
        addressLine4: (_data$addressLine3 = data.addressLine4) !== null && _data$addressLine3 !== void 0 ? _data$addressLine3 : '',
        postalCode: data.postalCode.trim(),
        countryCode: data.countryCode,
        companyName: data.companyName
      };
    } else {
      billingAddress = currentBillingAddress;
    }
    return billingAddress;
  };
  var onChangeAction = data => {
    var billingAddress = extractBillingAddress(data);
    setBillingAddress === null || setBillingAddress === void 0 || setBillingAddress(billingAddress);
  };
  var onSubmitAction = data => {
    var billingAddress = extractBillingAddress(data);
    !hasError && continueToNextStep(billingAddress);
  };
  var currentAddressLabel = "".concat(currentBillingAddress === null || currentBillingAddress === void 0 ? void 0 : currentBillingAddress.postalCode, " ").concat(currentBillingAddress === null || currentBillingAddress === void 0 ? void 0 : currentBillingAddress.addressLine1);
  return (0, _jsxRuntime.jsx)(_atoms.Form, _objectSpread(_objectSpread({}, (0, _billingAddressFormConfig.billingAddressFormConfig)({
    getFormState,
    defaultValues,
    onSubmitAction,
    baseDataTestId,
    testid,
    t,
    currentLang,
    currentAddressLabel,
    isAmendPage,
    isLocationRequired,
    setIsLocationRequired
  })), {}, {
    onChange: onChangeAction
  }));
}