"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccountToCompanyFields;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireDefault(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AccountToCompanyFields(_ref) {
  var {
    verifiedCompany,
    setVerifiedCompany
  } = _ref;
  var {
    name,
    address,
    arNumber
  } = verifiedCompany;
  var {
    postalCode
  } = address;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseTestId = 'AccountToCompanyFields';
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": baseTestId,
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyles), {}, {
      children: t('ccui.accountToCompanyFields.title')
    })), (0, _jsxRuntime.jsx)(_react.VStack, _objectSpread(_objectSpread({
      spacing: 6,
      my: 8
    }, inputWrapperStyles), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.InputGroup, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "companyName",
          value: name,
          placeholderText: t('ccui.accountToCompanyFields.placeholderName'),
          isDisabled: true,
          styles: {
            inputElementStyles
          }
        }), (0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "arNumber",
          value: arNumber !== null && arNumber !== void 0 ? arNumber : '',
          placeholderText: t('ccui.accountToCompanyFields.placeholderNumber'),
          isDisabled: true,
          styles: {
            inputElementStyles
          }
        }), (0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "address",
          value: getFormattedAddress(address),
          placeholderText: t('ccui.accountToCompanyFields.placeholderAddress'),
          isDisabled: true,
          styles: {
            inputElementStyles
          }
        }), (0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "postalCode",
          value: postalCode,
          placeholderText: t('ccui.accountToCompanyFields.placeholderPostcode'),
          isDisabled: true,
          styles: {
            inputElementStyles
          }
        })]
      })
    })), (0, _jsxRuntime.jsx)(_atoms.Notification, {
      maxWidth: "full",
      variant: "info",
      status: "info",
      title: t('ccui.accountToCompanyFields.notRestrictedTitle'),
      description: t('ccui.accountToCompanyFields.notRestrictedDescription'),
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {})
    }), (0, _jsxRuntime.jsx)(_atoms.Button, {
      type: "button",
      variant: "tertiary",
      size: "full",
      maxW: {
        mobile: '18rem',
        lg: '18rem',
        xl: '19.375rem'
      },
      onClick: () => {
        setVerifiedCompany(null);
      },
      mt: 8,
      children: t('ccui.accountToCompanyFields.changeCompany')
    })]
  });
}
function getFormattedAddress(address) {
  var {
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4 = '',
    country
  } = address;
  return [addressLine1, addressLine2, addressLine3, addressLine4, country].filter(Boolean).join(', ');
}
var titleStyles = {
  color: 'darkGrey1',
  fontSize: 'xl',
  lineHeight: 3,
  fontWeight: 'semibold',
  mb: 'var(--chakra-space-8)'
};
var inputWrapperStyles = {
  w: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  maxW: '26.25rem'
};
var inputElementStyles = {
  color: 'darkGrey1',
  _disabled: {
    opacity: '0.4'
  }
};