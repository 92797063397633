"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetailsContainer;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _BookingDetails = _interopRequireDefault(require("./BookingDetails.component"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetailsContainer(_ref) {
  var {
    bookingReference,
    basketReference,
    tempBookingReference,
    shouldShowTypeOfBooking,
    area = 'pi',
    getBookingStatus,
    bookingStatus,
    bookingType,
    paymentOption,
    sourcePms,
    dpaInfo,
    setDpaInfo,
    overridenUserInfo,
    inputValues,
    gdsReferenceNumber,
    distBookingChannel,
    isAmendSuccessful,
    arrivalDate,
    bookingSurname,
    isReadOnly,
    isAmendPage
  } = _ref;
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var [bookingState, setBookingState] = (0, _react2.useState)({
    isLoading: false,
    error: null
  });
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var loggedOrCCUI = idTokenCookie || area === _api.Area.CCUI;
  var [basketReferenceValue, setBasketReferenceValue] = (0, _react2.useState)(basketReference);
  var [bookingDetails, setBookingDetails] = (0, _react2.useState)({
    reasonForStay: '',
    currencyCode: '',
    totalCost: '',
    newTotal: '',
    paymentOption: '',
    previousTotal: '',
    balanceOutstanding: '',
    hotelId: '',
    roomDetails: [],
    ammendOrCancelLabel: '',
    donationPkg: undefined,
    rateType: '',
    shouldDisplayCityTaxMessage: false,
    cardType: ''
  });
  var [defaultDataFromBooking, setDefaultDataFromBooking] = (0, _react2.useState)();
  var getGuestName = reservations => {
    var _firstReservation$res, _firstReservation$res2;
    var firstReservation = reservations[0];
    var secondReservation = reservations[1];
    var guestName = "".concat(firstReservation === null || firstReservation === void 0 || (_firstReservation$res = firstReservation.reservationGuestList[0]) === null || _firstReservation$res === void 0 ? void 0 : _firstReservation$res.surName, " ").concat(firstReservation === null || firstReservation === void 0 || (_firstReservation$res2 = firstReservation.reservationGuestList[0]) === null || _firstReservation$res2 === void 0 ? void 0 : _firstReservation$res2.givenName);
    if (reservations.length > 1) {
      var _secondReservation$re, _secondReservation$re2;
      guestName = "".concat(secondReservation === null || secondReservation === void 0 || (_secondReservation$re = secondReservation.reservationGuestList[0]) === null || _secondReservation$re === void 0 ? void 0 : _secondReservation$re.surName, " ").concat(secondReservation === null || secondReservation === void 0 || (_secondReservation$re2 = secondReservation.reservationGuestList[0]) === null || _secondReservation$re2 === void 0 ? void 0 : _secondReservation$re2.givenName);
    }
    return guestName;
  };
  var [idvData, setIdvData] = (0, _react2.useState)({
    personalInformation: {
      bookerName: '',
      guestName: '',
      address: '',
      postcode: '',
      telephoneNumber: '',
      cardUsedToMakeBooking: ''
    },
    bookingInformation: {
      reservationNumber: {
        value: '',
        partOfSearch: false
      },
      hotelName: '',
      arrivalDate: '',
      departureDate: '',
      emailAddress: ''
    },
    dpaStatus: {
      dpaPassed: dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaPassed,
      dpaOverride: dpaInfo === null || dpaInfo === void 0 ? void 0 : dpaInfo.dpaOverride,
      eCnpPassword: ''
    }
  });
  var queryClient = (0, _reactQuery.useQueryClient)();
  var getBookingDetails = (0, _react2.useCallback)(function () {
    var _ref2 = (0, _asyncToGenerator2.default)(function* (bookingReference, basketReference, tempBookingReference) {
      setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
        isLoading: true
      }));
      var amendConfirmationPrices;
      if (tempBookingReference) {
        try {
          var token = (0, _utils.formatFindBookingToken)((0, _utils.getFindBookingToken)().token);
          var data = yield queryClient.fetchQuery({
            queryKey: ['getAmendConfirmationPrices', basketReference, tempBookingReference],
            queryFn: () => (0, _utils.graphQLRequest)(_api.GET_AMEND_CONFIRMATION_PRICES, {
              originalBookingRef: basketReference,
              tempBookingRef: tempBookingReference,
              token
            })
          });
          amendConfirmationPrices = data.amendConfirmationPrices;
        } catch (error) {
          _utils.logger.error(error);
        }
      }
      var bookingConfirmationQuery = queryClient.fetchQuery(_objectSpread({
        queryKey: [loggedOrCCUI ? 'getBookingConfirmationAuthenticated' : 'getBookingConfirmation', loggedOrCCUI ? bookingReference : basketReference, language, country],
        queryFn: () => (0, _utils.graphQLRequest)(loggedOrCCUI ? _api.GET_DASHBOARD_BOOKING_CONFIRMATION_AUTHENTICATED : _api.GET_DASHBOARD_BOOKING_CONFIRMATION, loggedOrCCUI ? {
          bookingReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        } : {
          basketReference,
          language,
          country,
          bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
        }, loggedOrCCUI ? idTokenCookie : undefined)
      }, {
        gcTime: 0,
        staleTime: 0
      }));
      yield bookingConfirmationQuery.then(function () {
        var _ref3 = (0, _asyncToGenerator2.default)(function* (bookingConfirmationData) {
          var _firstRoom$roomStay, _firstRoom$roomStay2;
          var bookingData = loggedOrCCUI ? bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmationAuthenticated : bookingConfirmationData === null || bookingConfirmationData === void 0 ? void 0 : bookingConfirmationData.bookingConfirmation;
          var {
            bookingFlowId,
            hotelId,
            reservationByIdList,
            hotelName
          } = bookingData || {
            bookingFlowId: -1,
            reservationByIdList: [],
            hotelName: -1
          };
          var firstRoom = reservationByIdList[0] || {
            roomStay: {
              arrivalDate: '',
              departureDate: ''
            }
          };
          var noNights = (0, _utils.getNightsNumber)((_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.arrivalDate, (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate);
          var basketReferenceBC = basketReference !== null && basketReference !== void 0 ? basketReference : bookingData === null || bookingData === void 0 ? void 0 : bookingData.basketReference;
          setBasketReferenceValue(basketReferenceBC);
          var pcksQueryInput = {
            country,
            language,
            hotelId: hotelId,
            basketReferenceId: basketReferenceBC,
            adultsNumber: (0, _utils.getMaxValueFromRoomStays)(reservationByIdList, 'adultsNumber'),
            childrenNumber: (0, _utils.getMaxValueFromRoomStays)(reservationByIdList, 'childrenNumber'),
            startDate: firstRoom.roomStay.arrivalDate,
            endDate: firstRoom.roomStay.departureDate,
            bookingFlowId: bookingFlowId || '',
            nightsNumber: noNights
          };
          var getBookingPackages = queryClient.fetchQuery({
            queryKey: ['GetPackages', language, country, pcksQueryInput.hotelId, pcksQueryInput.bookingFlowId, pcksQueryInput.startDate, pcksQueryInput.endDate, pcksQueryInput.nightsNumber, pcksQueryInput.adultsNumber, pcksQueryInput.childrenNumber, pcksQueryInput.basketReferenceId],
            queryFn: () => (0, _utils.graphQLRequest)(_api.GET_PACKAGES, _objectSpread({}, pcksQueryInput))
          });
          yield getBookingPackages.then(function () {
            var _ref4 = (0, _asyncToGenerator2.default)(function* (pkgResponse) {
              var pcksDonationQueryInput = {
                country,
                language,
                hotelId: hotelId,
                rateCode: firstRoom.roomStay.ratePlanCode
              };
              var getDonationPackages = queryClient.fetchQuery({
                queryKey: ['getDonationPackages', country, language, hotelId, firstRoom.roomStay.ratePlanCode],
                queryFn: () => (0, _utils.graphQLRequest)(_api.GET_DASHBOARD_DONATIONS_PACKAGES, _objectSpread(_objectSpread({}, pcksDonationQueryInput), {}, {
                  bookingChannel: area === null || area === void 0 ? void 0 : area.toUpperCase()
                }))
              });
              var sortedMeals = [];
              yield getDonationPackages.then(pckDonation => {
                var _pkgResponse$packages, _pckDonation$donation, _bookingData$reservat, _bookingData$rateMess, _firstRoom$roomStay3, _reservationByIdList$, _reservationByIdList$2, _reservationByIdList$3, _reservationByIdList$4, _reservationByIdList$5, _reservationByIdList$6, _reservationByIdList$7, _reservationByIdList$8, _reservationByIdList$9, _reservationByIdList$10, _firstRoom$roomStay4, _firstRoom$roomStay5, _reservationByIdList$11, _reservationByIdList$12;
                var {
                  meals,
                  mealsKids,
                  roomSelection
                } = (pkgResponse === null || pkgResponse === void 0 || (_pkgResponse$packages = pkgResponse.packages) === null || _pkgResponse$packages === void 0 ? void 0 : _pkgResponse$packages.packages) || {};
                sortedMeals = (0, _utils.sortMealsByReservationId)(roomSelection, bookingData.reservationByIdList);
                var adultsMeals = (0, _utils.adultsMealsSelector)(meals, noNights);
                var childrenMeals = (0, _utils.childrenMealsSelector)(mealsKids);
                var mealsPerRoom = (0, _utils.selectedMealsPerRoomSelector)((0, _utils.mealsMapperSelector)(meals, mealsKids, sortedMeals), adultsMeals, childrenMeals);
                var donationPkg = (pckDonation === null || pckDonation === void 0 ? void 0 : pckDonation.donations) && (0, _utils.getSelectedDonationPackage)(pckDonation === null || pckDonation === void 0 || (_pckDonation$donation = pckDonation.donations) === null || _pckDonation$donation === void 0 ? void 0 : _pckDonation$donation.donationPackages, roomSelection[0]);
                var reasonForStay = bookingData === null || bookingData === void 0 || (_bookingData$reservat = bookingData.reservationByIdList[0]) === null || _bookingData$reservat === void 0 || (_bookingData$reservat = _bookingData$reservat.additionalGuestInfo) === null || _bookingData$reservat === void 0 ? void 0 : _bookingData$reservat.purposeOfStay;
                var shouldDisplayCityTaxMessage = reasonForStay === _api.PurposeOfStay.LEISURE && pkgResponse.packages.hotelHasCityTaxForLeisure || reasonForStay === _api.PurposeOfStay.BUSINESS && pkgResponse.packages.hotelHasCityTaxForBusiness;
                setBookingDetails({
                  reasonForStay: reasonForStay,
                  ammendOrCancelLabel: (_bookingData$rateMess = bookingData === null || bookingData === void 0 ? void 0 : bookingData.rateMessage) !== null && _bookingData$rateMess !== void 0 ? _bookingData$rateMess : '',
                  shouldDisplayCityTaxMessage: shouldDisplayCityTaxMessage,
                  hotelId: hotelId,
                  paymentOption: paymentOption,
                  currencyCode: bookingData.currencyCode,
                  totalCost: bookingData.totalCost,
                  previousTotal: amendConfirmationPrices ? amendConfirmationPrices.previousTotal : bookingData.previousTotal,
                  balanceOutstanding: amendConfirmationPrices ? amendConfirmationPrices.outstandingBalance : bookingData.balanceOutstanding,
                  newTotal: amendConfirmationPrices ? amendConfirmationPrices.newTotalCost : bookingData.newTotal,
                  donationPkg: donationPkg,
                  rateType: (_firstRoom$roomStay3 = firstRoom.roomStay) === null || _firstRoom$roomStay3 === void 0 ? void 0 : _firstRoom$roomStay3.ratePlanCode,
                  roomDetails: reservationByIdList.map((room, index) => {
                    var _room$reservationGues, _room$reservationGues2, _room$roomStay, _room$roomStay2, _mealsPerRoom$index, _mealsPerRoom$index2;
                    return {
                      leadGuestName: "".concat(room === null || room === void 0 || (_room$reservationGues = room.reservationGuestList) === null || _room$reservationGues === void 0 || (_room$reservationGues = _room$reservationGues[0]) === null || _room$reservationGues === void 0 ? void 0 : _room$reservationGues.givenName, " ").concat(room === null || room === void 0 || (_room$reservationGues2 = room.reservationGuestList) === null || _room$reservationGues2 === void 0 || (_room$reservationGues2 = _room$reservationGues2[0]) === null || _room$reservationGues2 === void 0 ? void 0 : _room$reservationGues2.surName),
                      roomType: room === null || room === void 0 || (_room$roomStay = room.roomStay) === null || _room$roomStay === void 0 || (_room$roomStay = _room$roomStay.roomExtraInfo) === null || _room$roomStay === void 0 ? void 0 : _room$roomStay.roomName,
                      roomPrice: room === null || room === void 0 || (_room$roomStay2 = room.roomStay) === null || _room$roomStay2 === void 0 ? void 0 : _room$roomStay2.roomPrice,
                      adultMealDescription: (_mealsPerRoom$index = mealsPerRoom[index]) === null || _mealsPerRoom$index === void 0 ? void 0 : _mealsPerRoom$index.adultsMeals,
                      childrenMealDescription: (_mealsPerRoom$index2 = mealsPerRoom[index]) === null || _mealsPerRoom$index2 === void 0 ? void 0 : _mealsPerRoom$index2.childrenMeals,
                      mealPrice: 0,
                      cot: room === null || room === void 0 ? void 0 : room.roomStay.cot,
                      noAdults: room === null || room === void 0 ? void 0 : room.roomStay.adultsNumber,
                      noChildren: room === null || room === void 0 ? void 0 : room.roomStay.childrenNumber,
                      noNights: noNights
                    };
                  })
                });
                setIdvData({
                  personalInformation: {
                    bookerName: (_reservationByIdList$ = reservationByIdList[0]) !== null && _reservationByIdList$ !== void 0 && _reservationByIdList$.billing ? "".concat((_reservationByIdList$2 = reservationByIdList[0]) === null || _reservationByIdList$2 === void 0 || (_reservationByIdList$2 = _reservationByIdList$2.billing) === null || _reservationByIdList$2 === void 0 ? void 0 : _reservationByIdList$2.lastName, " ").concat((_reservationByIdList$3 = reservationByIdList[0]) === null || _reservationByIdList$3 === void 0 || (_reservationByIdList$3 = _reservationByIdList$3.billing) === null || _reservationByIdList$3 === void 0 ? void 0 : _reservationByIdList$3.firstName) : '',
                    guestName: getGuestName(reservationByIdList),
                    address: (_reservationByIdList$4 = reservationByIdList[0]) !== null && _reservationByIdList$4 !== void 0 && _reservationByIdList$4.billing ? "".concat((_reservationByIdList$5 = reservationByIdList[0]) === null || _reservationByIdList$5 === void 0 || (_reservationByIdList$5 = _reservationByIdList$5.billing) === null || _reservationByIdList$5 === void 0 || (_reservationByIdList$5 = _reservationByIdList$5.address) === null || _reservationByIdList$5 === void 0 ? void 0 : _reservationByIdList$5.addressLine1) : '',
                    postcode: (_reservationByIdList$6 = reservationByIdList[0]) !== null && _reservationByIdList$6 !== void 0 && _reservationByIdList$6.billing ? "".concat((_reservationByIdList$7 = reservationByIdList[0]) === null || _reservationByIdList$7 === void 0 || (_reservationByIdList$7 = _reservationByIdList$7.billing) === null || _reservationByIdList$7 === void 0 || (_reservationByIdList$7 = _reservationByIdList$7.address) === null || _reservationByIdList$7 === void 0 ? void 0 : _reservationByIdList$7.postalCode) : '',
                    telephoneNumber: (_reservationByIdList$8 = reservationByIdList[0]) !== null && _reservationByIdList$8 !== void 0 && _reservationByIdList$8.billing ? "".concat((_reservationByIdList$9 = reservationByIdList[0]) === null || _reservationByIdList$9 === void 0 || (_reservationByIdList$9 = _reservationByIdList$9.billing) === null || _reservationByIdList$9 === void 0 ? void 0 : _reservationByIdList$9.telephone) : '',
                    cardUsedToMakeBooking: (_reservationByIdList$10 = reservationByIdList[0]) === null || _reservationByIdList$10 === void 0 || (_reservationByIdList$10 = _reservationByIdList$10.paymentCard) === null || _reservationByIdList$10 === void 0 ? void 0 : _reservationByIdList$10.cardNumberMasked
                  },
                  bookingInformation: {
                    reservationNumber: _objectSpread(_objectSpread({}, idvData.bookingInformation.reservationNumber), {}, {
                      value: bookingReference
                    }),
                    hotelName: hotelName,
                    arrivalDate: (_firstRoom$roomStay4 = firstRoom.roomStay) === null || _firstRoom$roomStay4 === void 0 ? void 0 : _firstRoom$roomStay4.arrivalDate,
                    departureDate: (_firstRoom$roomStay5 = firstRoom.roomStay) === null || _firstRoom$roomStay5 === void 0 ? void 0 : _firstRoom$roomStay5.departureDate,
                    emailAddress: (_reservationByIdList$11 = reservationByIdList[0]) !== null && _reservationByIdList$11 !== void 0 && _reservationByIdList$11.billing ? (_reservationByIdList$12 = reservationByIdList[0]) === null || _reservationByIdList$12 === void 0 || (_reservationByIdList$12 = _reservationByIdList$12.billing) === null || _reservationByIdList$12 === void 0 ? void 0 : _reservationByIdList$12.email : ''
                  },
                  dpaStatus: _objectSpread({}, idvData.dpaStatus)
                });
                setDefaultDataFromBooking((0, _utils.getDefaultDataFromBooking)(reservationByIdList !== null && reservationByIdList !== void 0 ? reservationByIdList : [], {}, basketReferenceValue !== null && basketReferenceValue !== void 0 ? basketReferenceValue : '', language, ''));
                setBookingState(_objectSpread(_objectSpread({}, bookingState), {}, {
                  isLoading: false
                }));
              }).catch(error => {
                setBookingState({
                  isLoading: false,
                  error
                });
                console.log(error);
              });
            });
            return function (_x5) {
              return _ref4.apply(this, arguments);
            };
          }()).catch(error => {
            setBookingState({
              isLoading: false,
              error
            });
            console.log(error);
          });
        });
        return function (_x4) {
          return _ref3.apply(this, arguments);
        };
      }()).catch(error => {
        setBookingState({
          isLoading: false,
          error
        });
        console.log(error);
      });
    });
    return function (_x, _x2, _x3) {
      return _ref2.apply(this, arguments);
    };
  }(), []);
  (0, _react2.useEffect)(() => {
    getBookingDetails(bookingReference, basketReference, tempBookingReference);
  }, [bookingReference, basketReference, tempBookingReference]);
  if (bookingState.error) {
    return (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "error",
      description: bookingState.error,
      variant: "alert",
      maxW: "full",
      svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
    });
  }
  if (bookingState.isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingDetails",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  return (0, _jsxRuntime.jsx)(_BookingDetails.default, {
    bookingDetails: bookingDetails,
    idvData: idvData,
    setIdvData: setIdvData,
    defaultDataFromBooking: defaultDataFromBooking,
    bookingReference: bookingReference,
    basketReference: basketReferenceValue,
    area: area,
    getBookingStatus: getBookingStatus,
    bookingStatus: bookingStatus,
    bookingType: bookingType,
    shouldShowTypeOfBooking: shouldShowTypeOfBooking,
    sourcePms: sourcePms,
    dpaInfo: dpaInfo,
    setDpaInfo: setDpaInfo,
    baseDataTestId: 'BookingDetails',
    overridenUserInfo: overridenUserInfo,
    inputValues: inputValues,
    gdsReferenceNumber: gdsReferenceNumber,
    distBookingChannel: distBookingChannel,
    isAmendSuccessful: isAmendSuccessful,
    arrival: arrivalDate,
    bookingSurname: bookingSurname,
    isReadOnly: isReadOnly,
    isAmendPage: isAmendPage
  });
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};