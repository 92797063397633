"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = SortBy;
var _atoms = require("@whitbread-eos/atoms");
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function SortBy(_ref) {
  var {
    labels,
    selectedOption,
    isDisabled = false,
    onChange
  } = _ref;
  var dropdownStyles = {
    wrapperStyles: dropdownWrapperStyles,
    menuButtonStyles: dropdownMenuStyles
  };
  var sortByOptions = getSortByOptions(labels);
  return (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
    options: sortByOptions,
    matchWidth: true,
    dropdownStyles: dropdownStyles,
    selectedId: selectedOption,
    onChange: onChange,
    dataTestId: "sort-by",
    disabled: isDisabled
  });
}
var dropdownWrapperStyles = {
  w: {
    base: '8.125rem',
    xs: '10.25rem',
    sm: '12.125rem',
    lg: '11.5rem'
  },
  marginRight: {
    mobile: '0',
    xs: 'lg'
  },
  zIndex: '99'
};
var dropdownMenuStyles = {
  w: 'full',
  h: 'var(--chakra-space-2xl)',
  borderRadius: '0.1875rem',
  borderColor: 'lightGrey2',
  _hover: {
    borderColor: 'primary'
  },
  _focus: {
    borderColor: 'lightGrey2'
  }
};
function getSortByOptions(labels) {
  return [{
    id: _constants.SORT_TYPES[1],
    label: labels.sortByDistance
  }, {
    id: _constants.SORT_TYPES[2],
    label: labels.sortByPrice
  }];
}