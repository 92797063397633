"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCityTaxMessages = getCityTaxMessages;
exports.getDefaultDataFromBooking = getDefaultDataFromBooking;
var _api = require("@whitbread-eos/api");
var _formatters = require("../formatters");
function getCityTaxMessages(hotelHasCityTaxForLeisure, hotelHasCityTaxForBusiness, reasonForStay, t, currencyCode, language, totalCost) {
  var messages = {
    mainBanner: '',
    secondaryBanner: '',
    summaryText: '',
    confPageBusinessNotif: ''
  };
  var totalPrice = (0, _formatters.formatPrice)((0, _formatters.formatCurrency)(currencyCode), totalCost === null || totalCost === void 0 ? void 0 : totalCost.toFixed(2), language);
  if (hotelHasCityTaxForLeisure && !hotelHasCityTaxForBusiness) {
    messages.mainBanner = t('booking.reason.citytax.leisure.message.noExempt');
    messages.confPageBusinessNotif = t('booking.confirmation.cityTaxExemptNotification');
    if (reasonForStay === 'LEI') {
      messages.secondaryBanner = "".concat(t('booking.reason.citytax.notification.price.included'), " ").concat(totalPrice);
      messages.summaryText = t('booking.overview.includeCityTax');
    }
    return messages;
  }
  if (hotelHasCityTaxForLeisure && hotelHasCityTaxForBusiness) {
    messages.mainBanner = t('booking.reason.citytax.all.message');
    messages.confPageBusinessNotif = t('booking.confirmation.cityTaxExemptNotification');
    if (reasonForStay) {
      messages.summaryText = t('booking.overview.includeCityTax');
    }
    return messages;
  }
  if (!hotelHasCityTaxForLeisure && hotelHasCityTaxForBusiness) {
    messages.mainBanner = t('booking.reason.citytax.notification.message.noExempt');
    if (reasonForStay === 'BUS') {
      messages.secondaryBanner = "".concat(t('booking.reason.citytax.notification.price.included'), " ").concat(totalPrice);
      messages.summaryText = t('booking.overview.includeCityTax');
    }
    return messages;
  }
  return messages;
}
function getDefaultDataFromBooking(reservations, formDetails, basketReference, currentLang, brand, isGermanHotel, isMultiRoomRedesignEnabled) {
  var _reservations$;
  var alreadyCompleted = (_reservations$ = reservations[0]) === null || _reservations$ === void 0 || (_reservations$ = _reservations$.additionalGuestInfo) === null || _reservations$ === void 0 ? void 0 : _reservations$.purposeOfStay;
  var setCurrentLanguage = currentLang => {
    return currentLang === 'en' ? 'GB' : 'DE';
  };
  var defaultData = {
    reasonForStay: _api.HotelBrand.HUB === brand ? _api.PurposeOfStay.LEISURE : '',
    title: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    landline: '',
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    addressLine4: '',
    postalCode: '',
    manualAddressToggle: currentLang === 'de' && !isMultiRoomRedesignEnabled ? 'manualAddress' : '',
    cityName: '',
    postcodeAddress: '',
    addressSelection: isGermanHotel && isMultiRoomRedesignEnabled ? 'HOME' : '',
    countryCode: setCurrentLanguage(currentLang),
    acceptFutureMailing: false,
    bookingForSomeoneElse: false,
    basketReferenceId: '',
    leadGuest: [],
    billing_countryCode: setCurrentLanguage(currentLang),
    billing_companyName: '',
    billing_addressLine1: '',
    billing_addressLine2: '',
    billing_addressLine3: '',
    billing_addressLine4: '',
    billing_cityName: '',
    billing_postalCode: '',
    whoBookerIsTabs: 'MYSELF'
  };
  if (alreadyCompleted) {
    var _reservations$2, _reservations$3, _billingData$address$, _billingData$address, _billingData$address$2, _billingData$address2, _billingData$address$3, _billingData$address3, _billingData$address$4, _billingData$address4, _billingData$address$5, _billingData$address5, _billingData$address6, _billingData$address$6, _billingData$address7, _billingData$address$7, _billingData$address8, _billingData$address$8, _billingData$address9, _billingData$landline, _billingData$address$9, _billingData$address10, _billingData$telephon, _formDetails$addressS, _formDetails$leadGues;
    var billingData = (_reservations$2 = reservations[0]) === null || _reservations$2 === void 0 ? void 0 : _reservations$2.billing;
    var reasonForStay = (_reservations$3 = reservations[0]) === null || _reservations$3 === void 0 || (_reservations$3 = _reservations$3.additionalGuestInfo) === null || _reservations$3 === void 0 ? void 0 : _reservations$3.purposeOfStay;
    var guests = reservations === null || reservations === void 0 ? void 0 : reservations.map(reservation => reservation.reservationGuestList).flat();
    defaultData = {
      acceptFutureMailing: false,
      addressLine1: formDetails.addressLine1 || ((_billingData$address$ = billingData === null || billingData === void 0 || (_billingData$address = billingData.address) === null || _billingData$address === void 0 ? void 0 : _billingData$address.addressLine1) !== null && _billingData$address$ !== void 0 ? _billingData$address$ : ''),
      addressLine2: formDetails.addressLine2 || ((_billingData$address$2 = billingData === null || billingData === void 0 || (_billingData$address2 = billingData.address) === null || _billingData$address2 === void 0 ? void 0 : _billingData$address2.addressLine2) !== null && _billingData$address$2 !== void 0 ? _billingData$address$2 : ''),
      addressLine3: formDetails.addressLine3 || ((_billingData$address$3 = billingData === null || billingData === void 0 || (_billingData$address3 = billingData.address) === null || _billingData$address3 === void 0 ? void 0 : _billingData$address3.addressLine3) !== null && _billingData$address$3 !== void 0 ? _billingData$address$3 : ''),
      addressLine4: formDetails.addressLine4 || ((_billingData$address$4 = billingData === null || billingData === void 0 || (_billingData$address4 = billingData.address) === null || _billingData$address4 === void 0 ? void 0 : _billingData$address4.addressLine4) !== null && _billingData$address$4 !== void 0 ? _billingData$address$4 : ''),
      postcodeAddress: formDetails.postcodeAddress || ((_billingData$address$5 = billingData === null || billingData === void 0 || (_billingData$address5 = billingData.address) === null || _billingData$address5 === void 0 ? void 0 : _billingData$address5.postalCode) !== null && _billingData$address$5 !== void 0 ? _billingData$address$5 : ''),
      addressSelection: formDetails.addressSelection || (billingData !== null && billingData !== void 0 && (_billingData$address6 = billingData.address) !== null && _billingData$address6 !== void 0 && _billingData$address6.companyName ? 'BUSINESS' : 'HOME'),
      cityName: formDetails.cityName || ((_billingData$address$6 = billingData === null || billingData === void 0 || (_billingData$address7 = billingData.address) === null || _billingData$address7 === void 0 ? void 0 : _billingData$address7.cityName) !== null && _billingData$address$6 !== void 0 ? _billingData$address$6 : ''),
      companyName: formDetails.companyName || ((_billingData$address$7 = billingData === null || billingData === void 0 || (_billingData$address8 = billingData.address) === null || _billingData$address8 === void 0 ? void 0 : _billingData$address8.companyName) !== null && _billingData$address$7 !== void 0 ? _billingData$address$7 : ''),
      countryCode: formDetails.countryCode || ((_billingData$address$8 = billingData === null || billingData === void 0 || (_billingData$address9 = billingData.address) === null || _billingData$address9 === void 0 ? void 0 : _billingData$address9.country) !== null && _billingData$address$8 !== void 0 ? _billingData$address$8 : ''),
      email: formDetails.email || (billingData === null || billingData === void 0 ? void 0 : billingData.email),
      firstName: formDetails.firstName || (billingData === null || billingData === void 0 ? void 0 : billingData.firstName),
      landline: formDetails.landline || ((_billingData$landline = billingData === null || billingData === void 0 ? void 0 : billingData.landline) !== null && _billingData$landline !== void 0 ? _billingData$landline : ''),
      lastName: formDetails.lastName || (billingData === null || billingData === void 0 ? void 0 : billingData.lastName),
      postalCode: formDetails.postalCode || ((_billingData$address$9 = billingData === null || billingData === void 0 || (_billingData$address10 = billingData.address) === null || _billingData$address10 === void 0 ? void 0 : _billingData$address10.postalCode) !== null && _billingData$address$9 !== void 0 ? _billingData$address$9 : ''),
      phone: formDetails.phone || ((_billingData$telephon = billingData === null || billingData === void 0 ? void 0 : billingData.telephone) !== null && _billingData$telephon !== void 0 ? _billingData$telephon : ''),
      reasonForStay: formDetails.reasonForStay || (reasonForStay !== null && reasonForStay !== void 0 ? reasonForStay : ''),
      manualAddressToggle: formDetails.manualAddressToggle || (currentLang === 'de' && !isMultiRoomRedesignEnabled ? (_formDetails$addressS = formDetails.addressSelection) !== null && _formDetails$addressS !== void 0 ? _formDetails$addressS : 'manualAddress' : ''),
      title: formDetails.title || (billingData === null || billingData === void 0 ? void 0 : billingData.title),
      bookingForSomeoneElse: formDetails.bookingForSomeoneElse,
      basketReferenceId: basketReference,
      billing_countryCode: formDetails.billing_countryCode || setCurrentLanguage(currentLang),
      billing_companyName: formDetails.companyName,
      billing_addressLine1: formDetails.addressLine1,
      billing_addressLine2: formDetails.addressLine2,
      billing_addressLine3: formDetails.addressLine3,
      billing_addressLine4: formDetails.addressLine4,
      billing_cityName: formDetails.billing_cityName,
      billing_postalCode: formDetails.postalCode,
      whoBookerIsTabs: 'MYSELF',
      leadGuest: formDetails !== null && formDetails !== void 0 && (_formDetails$leadGues = formDetails.leadGuest) !== null && _formDetails$leadGues !== void 0 && (_formDetails$leadGues = _formDetails$leadGues[0]) !== null && _formDetails$leadGues !== void 0 && _formDetails$leadGues.firstName ? formDetails === null || formDetails === void 0 ? void 0 : formDetails.leadGuest : guests.map(guest => {
        var sameAsBooker = guest.givenName === (formDetails.firstName || (billingData === null || billingData === void 0 ? void 0 : billingData.firstName)) && guest.surName === (formDetails.lastName || (billingData === null || billingData === void 0 ? void 0 : billingData.lastName)) && guest.nameTitle === (formDetails.title || (billingData === null || billingData === void 0 ? void 0 : billingData.title));
        return {
          firstName: guest.givenName,
          lastName: guest.givenName === '' ? '' : guest.surName,
          stayInThisRoom: sameAsBooker,
          title: guest.nameTitle
        };
      })
    };
  }
  return defaultData;
}