"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateSelectorQueryWrapper;
var _reactQuery = require("@tanstack/react-query");
var _RateSelector = _interopRequireDefault(require("./RateSelector.container"));
var _jsxRuntime = require("react/jsx-runtime");
function RateSelectorQueryWrapper(_ref) {
  var {
    channel,
    variant,
    queryClient,
    hotelAvailabilityResponse,
    isParentAnalytics,
    isHotelOpeningSoon,
    arrival,
    departure,
    numberOfUnits,
    numberOfNights,
    isLessThanSm,
    isLessThanMd,
    isLessThanLg,
    prevReservationId,
    mappedRoomLabels,
    isSilentFeatureFlagEnabled,
    thirdParties,
    companyData,
    isPrePopulateBillingAddressEnabled
  } = _ref;
  return (0, _jsxRuntime.jsx)(_reactQuery.QueryClientProvider, {
    client: queryClient,
    children: (0, _jsxRuntime.jsx)(_RateSelector.default, {
      channel,
      variant,
      queryClient,
      hotelAvailabilityResponse,
      isParentAnalytics,
      isHotelOpeningSoon,
      arrival,
      departure,
      numberOfUnits,
      numberOfNights,
      isLessThanSm,
      isLessThanMd,
      isLessThanLg,
      prevReservationId,
      mappedRoomLabels,
      isSilentFeatureFlagEnabled,
      thirdParties,
      companyData,
      isPrePopulateBillingAddressEnabled
    })
  });
}