"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DATE_TYPE = void 0;
var DATE_TYPE = exports.DATE_TYPE = function (DATE_TYPE) {
  DATE_TYPE["YEAR_MONTH_DAY"] = "yyyy-MM-dd";
  DATE_TYPE["EEE_DAY_MONTH_YEAR"] = "EEE dd MMM yyyy";
  DATE_TYPE["E_DAY_MONTH_YEAR"] = "E dd MMM yyyy";
  DATE_TYPE["DD_MM_YYYY"] = "dd/MM/yyyy";
  return DATE_TYPE;
}({});