"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBasketStatus = getBasketStatus;
exports.isStayDatesSectionUpdated = isStayDatesSectionUpdated;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function isStayDatesSectionUpdated(originalArrival, originalDeparture, newArrival, newDeparture) {
  var isSameArrivalDate = (0, _utils.isSameDate)(new Date(originalArrival), new Date(newArrival));
  var isSameDepartureDate = (0, _utils.isSameDate)(new Date(originalDeparture), new Date(newDeparture));
  return !isSameArrivalDate || !isSameDepartureDate;
}
function getBasketStatus(_x, _x2) {
  return _getBasketStatus.apply(this, arguments);
}
function _getBasketStatus() {
  _getBasketStatus = (0, _asyncToGenerator2.default)(function* (queryClient, basketReference) {
    try {
      var result = yield queryClient.fetchQuery(_objectSpread({
        queryKey: ['BasketStatus', basketReference],
        queryFn: () => (0, _utils.graphQLRequest)(_api.CHECK_BASKET_STATUS, {
          basketReference: basketReference
        })
      }, {
        gcTime: 0,
        staleTime: 0
      }));
      return result.basketStatus;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  });
  return _getBasketStatus.apply(this, arguments);
}