"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginFormConfig = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var yup = _interopRequireWildcard(require("yup"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var loginFormConfig = _ref => {
  var _labels$login, _labels$login2, _labels$login3, _labels$login4, _labels$login5, _labels$login6, _labels$login7, _labels$login8;
  var {
    getFormState,
    defaultValues,
    defaultErrors,
    onSubmit,
    handleResetPass,
    baseDataTestId,
    extraStyles,
    buttonsContainerStyles,
    resetForm,
    fieldProps,
    labels
  } = _ref;
  var loginFormValidationSchema = yup.object().shape({
    email: yup.string().required((labels === null || labels === void 0 || (_labels$login = labels.login) === null || _labels$login === void 0 || (_labels$login = _labels$login.business) === null || _labels$login === void 0 ? void 0 : _labels$login.bookingLoginRequiredText) || (labels === null || labels === void 0 || (_labels$login2 = labels.login) === null || _labels$login2 === void 0 ? void 0 : _labels$login2.invalidEmail)).matches(_atoms.FORM_VALIDATIONS.EMAIL.MATCHES, (labels === null || labels === void 0 || (_labels$login3 = labels.login) === null || _labels$login3 === void 0 || (_labels$login3 = _labels$login3.business) === null || _labels$login3 === void 0 ? void 0 : _labels$login3.bookingsInvalidEmailMsg) || (labels === null || labels === void 0 || (_labels$login4 = labels.login) === null || _labels$login4 === void 0 ? void 0 : _labels$login4.invalidEmail)).max(_atoms.FORM_VALIDATIONS.EMAIL.MAX, (labels === null || labels === void 0 || (_labels$login5 = labels.login) === null || _labels$login5 === void 0 || (_labels$login5 = _labels$login5.business) === null || _labels$login5 === void 0 ? void 0 : _labels$login5.bookingEmailMaxLengthMsg) || (labels === null || labels === void 0 || (_labels$login6 = labels.login) === null || _labels$login6 === void 0 ? void 0 : _labels$login6.invalidEmail)),
    password: yup.string().required((labels === null || labels === void 0 || (_labels$login7 = labels.login) === null || _labels$login7 === void 0 || (_labels$login7 = _labels$login7.business) === null || _labels$login7 === void 0 ? void 0 : _labels$login7.bookingLoginRequiredText) || (labels === null || labels === void 0 || (_labels$login8 = labels.login) === null || _labels$login8 === void 0 ? void 0 : _labels$login8.badCredentialsError))
  });
  var {
    hideForgottenPassword = false
  } = fieldProps;
  var config = {
    elements: {
      buttonsContainerStyles,
      fields: [{
        type: _atoms.FORM_FIELD_TYPES.INPUT_TEXT,
        name: 'email',
        label: labels.login.leisure.emailPlaceholder,
        props: _objectSpread({}, fieldProps),
        styles: {
          marginBottom: 'lg'
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Email')
      }, {
        type: _atoms.FORM_FIELD_TYPES.INPUT_PASSWORD,
        name: 'password',
        label: labels.login.passwordPlaceholder,
        props: _objectSpread({}, fieldProps),
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'Password')
      }, {
        type: _atoms.FORM_FIELD_TYPES.NON_FIELD_CONTENT,
        name: 'forgottenPassLink',
        hidden: hideForgottenPassword,
        content: (0, _jsxRuntime.jsxs)(_react.Link, _objectSpread(_objectSpread({}, extraStyles), {}, {
          textDecoration: "underline",
          display: "block",
          mb: "md",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ResetPassLink'),
          onClick: handleResetPass,
          children: [' ', labels.login.forgotPassword]
        }))
      }],
      buttons: [{
        type: _atoms.FORM_BUTTON_TYPES.SUBMIT,
        label: labels.login.leisure.loginButton,
        action: onSubmit,
        props: {
          variant: 'primary',
          size: 'full'
        },
        testid: (0, _utils.formatDataTestId)(baseDataTestId, 'ButtonLogin')
      }]
    },
    defaultValues,
    validationSchema: loginFormValidationSchema,
    getFormState,
    defaultErrors,
    resetForm
  };
  return config;
};
exports.loginFormConfig = loginFormConfig;