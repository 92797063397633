"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _reactHookForm = require("react-hook-form");
var _constants = require("../utils/constants");
var _common = require("./common");
var _jsxRuntime = require("react/jsx-runtime");
var _react2 = require("react");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var DependentInfo = _ref => {
  var _errors$dependents;
  var {
    getValues,
    index: dependentIndex,
    control,
    removeDependent: _removeDependent,
    nationalities,
    formField,
    errors,
    handleSetValue
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var [isLargerThanSm] = (0, _react.useMediaQuery)('(min-width: 576px)');
  var datePickerStyles = _objectSpread({}, _common.inputStyle);
  if (errors !== null && errors !== void 0 && (_errors$dependents = errors.dependents) !== null && _errors$dependents !== void 0 && (_errors$dependents = _errors$dependents[dependentIndex]) !== null && _errors$dependents !== void 0 && (_errors$dependents = _errors$dependents['dateofbirth']) !== null && _errors$dependents !== void 0 && _errors$dependents.message) {
    datePickerStyles = _objectSpread(_objectSpread({}, datePickerStyles), {}, {
      border: '1px solid var(--chakra-colors-error)',
      borderColor: 'none'
    });
  }
  var fieldController = _ref2 => {
    var _errors$dependents2, _errors$dependents3, _errors$dependents4, _errors$dependents5, _errors$dependents7;
    var {
      field,
      label,
      name,
      type
    } = _ref2;
    switch (type) {
      case 'input':
        return (0, _jsxRuntime.jsx)(_atoms.Input, _objectSpread(_objectSpread(_objectSpread({}, formField.props), (0, _common.fieldProps)(field)), {}, {
          type: "text",
          showIcon: false,
          placeholderText: t("precheckin.details.".concat(label)),
          label: t("precheckin.details.".concat(label)),
          error: errors === null || errors === void 0 || (_errors$dependents2 = errors.dependents) === null || _errors$dependents2 === void 0 || (_errors$dependents2 = _errors$dependents2[dependentIndex]) === null || _errors$dependents2 === void 0 || (_errors$dependents2 = _errors$dependents2[name]) === null || _errors$dependents2 === void 0 ? void 0 : _errors$dependents2.message,
          className: "sessioncamhidetext assist-no-show"
        }));
      case 'datePicker':
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.SingleDatePicker, _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, language === 'de' ? {
            locale: 'de'
          } : {
            locale: 'en'
          }), formField.props), (0, _common.fieldProps)(field)), {}, {
            minDate: new Date(new Date().getFullYear() - 100, 0, 1),
            maxDate: new Date(),
            inputPlaceholder: t("precheckin.details.".concat(label)),
            inputLabel: t("precheckin.details.".concat(label)),
            defaultStartDate: field.value || null,
            isRightIcon: true,
            popperPlacement: isLargerThanSm ? 'bottom' : 'top',
            displayDateFormat: _constants.DATE_FORMAT,
            dateFormat: _constants.DATE_FORMAT,
            customHeader: true,
            labels: {
              todayLabel: _common.todayLabel,
              tomorrowLabel: _common.tomorrowLabel
            },
            datepickerStyles: {
              inputGroupStyles: {},
              datepickerInputElementStyles: datePickerStyles,
              iconStyles: _common.inputIconStyles
            },
            onSelectDate: field.onChange,
            isError: errors !== null && errors !== void 0 && (_errors$dependents3 = errors.dependents) !== null && _errors$dependents3 !== void 0 && (_errors$dependents3 = _errors$dependents3[dependentIndex]) !== null && _errors$dependents3 !== void 0 && (_errors$dependents3 = _errors$dependents3[name]) !== null && _errors$dependents3 !== void 0 && _errors$dependents3.message ? true : false
          })), (errors === null || errors === void 0 || (_errors$dependents4 = errors.dependents) === null || _errors$dependents4 === void 0 || (_errors$dependents4 = _errors$dependents4[dependentIndex]) === null || _errors$dependents4 === void 0 || (_errors$dependents4 = _errors$dependents4[name]) === null || _errors$dependents4 === void 0 ? void 0 : _errors$dependents4.message) && (0, _jsxRuntime.jsx)(_react.Text, {
            style: _common.dataErrorText,
            mt: "2",
            children: errors === null || errors === void 0 || (_errors$dependents5 = errors.dependents) === null || _errors$dependents5 === void 0 || (_errors$dependents5 = _errors$dependents5[dependentIndex]) === null || _errors$dependents5 === void 0 || (_errors$dependents5 = _errors$dependents5[name]) === null || _errors$dependents5 === void 0 ? void 0 : _errors$dependents5.message
          })]
        });
      case 'autoComplete':
        return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_atoms.MultiSelect, {
            name: name,
            options: nationalities,
            dataTestId: (0, _utils.formatDataTestId)(formField.testid, formField.testid),
            value: (0, _common.getNationality)(field.value, nationalities),
            onChange: fieldProp => (0, _common.handleNationalityChange)(fieldProp, field, "dependents[".concat(dependentIndex, "].passport"), handleSetValue),
            placeholder: t("precheckin.details.".concat(label)),
            styles: {
              control: baseStyles => {
                var _errors$dependents6;
                return _objectSpread(_objectSpread({}, baseStyles), {}, {
                  border: errors !== null && errors !== void 0 && (_errors$dependents6 = errors.dependents) !== null && _errors$dependents6 !== void 0 && (_errors$dependents6 = _errors$dependents6[dependentIndex]) !== null && _errors$dependents6 !== void 0 && _errors$dependents6[label] ? '2px solid var(--chakra-colors-error)' : '1px solid var(--chakra-colors-lightGrey1)',
                  minHeight: 'var(--chakra-space-4xl)',
                  fontSize: 'var(--chakra-fontSizes-md)'
                });
              }
            },
            isClearable: true
          }), (errors === null || errors === void 0 || (_errors$dependents7 = errors.dependents) === null || _errors$dependents7 === void 0 || (_errors$dependents7 = _errors$dependents7[dependentIndex]) === null || _errors$dependents7 === void 0 ? void 0 : _errors$dependents7[label]) && (0, _jsxRuntime.jsx)(_react.Text, {
            style: _common.dataErrorText,
            mt: "2",
            children: t('precheckin.errors.empty.nationality')
          })]
        });
      default:
        return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {});
    }
  };
  var fieldValues = getValues();
  return (0, _jsxRuntime.jsx)(_react.Flex, {
    wrap: "wrap",
    justifyContent: "space-between",
    flexDirection: "column",
    children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
      mb: 0,
      "data-testid": (0, _utils.formatDataTestId)(formField.testid, "dependents-".concat(dependentIndex, "-guest")),
      children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, BoxStyle), {}, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          wrap: "nowrap",
          justifyContent: "space-between",
          flexDirection: "row",
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
            children: "".concat(t('precheckin.guest'), "  ").concat(dependentIndex + 1)
          })), (0, _jsxRuntime.jsx)(DeleteBtn, {
            removeDependent: () => _removeDependent(dependentIndex),
            "data-testid": "".concat(t('deleteguest'), "  ").concat(dependentIndex + 1)
          })]
        })
      })), _common.dependencyFields.map(_ref3 => {
        var {
          label,
          name,
          testId,
          type
        } = _ref3;
        var formattedName = "dependents[".concat(dependentIndex, "].").concat(name);
        return !!(name !== 'passport' || !(0, _common.showDependencyPassportField)(fieldValues, dependentIndex)) && (0, _react2.createElement)(_react.Box, _objectSpread(_objectSpread({}, _common.bookDetailGridStyles), {}, {
          "data-testid": (0, _utils.formatDataTestId)(formField.testid, "dependents-".concat(dependentIndex, "-").concat(testId)),
          key: "dependents[".concat(dependentIndex, "].").concat(name)
        }), (0, _jsxRuntime.jsx)(_reactHookForm.Controller, {
          name: formattedName,
          control: control,
          render: _ref4 => {
            var {
              field
            } = _ref4;
            return fieldController({
              field,
              label,
              name,
              type,
              testId
            });
          }
        }));
      })]
    }))
  });
};
var _default = exports.default = DependentInfo;
var DeleteBtn = _ref5 => {
  var {
    removeDependent
  } = _ref5;
  return (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
    type: "button",
    variant: "circle",
    size: "sm"
  }, deleteBtnStyle), {}, {
    onClick: removeDependent,
    children: (0, _jsxRuntime.jsx)(_atoms.Line, {})
  }));
};
var deleteBtnStyle = {
  width: '8',
  height: '8',
  borderRadius: '50%',
  borderColor: 'primary',
  color: 'primary'
};
var titleStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  color: 'baseBlack'
};
var BoxStyle = {
  mb: 'lg',
  mt: 'lg',
  verticalAlign: 'middle'
};