"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgInstagramLogo32 = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 32,
  height: 32,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M16 0c-4.345 0-4.89.018-6.597.096C7.7.174 6.537.444 5.52.84a7.843 7.843 0 0 0-2.834 1.845A7.843 7.843 0 0 0 .84 5.52C.444 6.537.174 7.7.096 9.403.018 11.11 0 11.655 0 16c0 4.345.018 4.89.096 6.597.078 1.703.348 2.866.744 3.884a7.843 7.843 0 0 0 1.845 2.834A7.844 7.844 0 0 0 5.52 31.16c1.018.395 2.181.666 3.884.744C11.11 31.982 11.655 32 16 32c4.345 0 4.89-.018 6.597-.096 1.703-.078 2.866-.349 3.884-.744a7.844 7.844 0 0 0 2.834-1.845 7.844 7.844 0 0 0 1.845-2.834c.395-1.018.666-2.181.744-3.884C31.982 20.89 32 20.345 32 16c0-4.345-.018-4.89-.096-6.597-.078-1.703-.349-2.866-.744-3.884a7.844 7.844 0 0 0-1.845-2.834A7.843 7.843 0 0 0 26.48.84C25.463.444 24.3.174 22.597.096 20.89.018 20.345 0 16 0Zm0 2.883c4.272 0 4.778.016 6.465.093 1.56.071 2.407.332 2.971.551.747.29 1.28.637 1.84 1.197s.907 1.093 1.197 1.84c.219.563.48 1.41.55 2.97.078 1.688.094 2.194.094 6.466s-.016 4.778-.093 6.465c-.071 1.56-.332 2.407-.551 2.971a4.954 4.954 0 0 1-1.197 1.84c-.56.56-1.093.907-1.84 1.197-.564.219-1.41.48-2.97.55-1.688.078-2.194.094-6.466.094-4.273 0-4.778-.016-6.465-.093-1.56-.071-2.408-.332-2.971-.551a4.955 4.955 0 0 1-1.84-1.197 4.956 4.956 0 0 1-1.197-1.84c-.219-.564-.48-1.41-.55-2.97-.078-1.688-.094-2.194-.094-6.466s.016-4.778.093-6.465c.071-1.56.332-2.408.551-2.971.29-.747.637-1.28 1.197-1.84a4.957 4.957 0 0 1 1.84-1.197c.563-.219 1.41-.48 2.97-.55 1.688-.078 2.194-.094 6.466-.094ZM7.784 16a8.216 8.216 0 1 1 16.432 0 8.216 8.216 0 0 1-16.432 0ZM16 21.333a5.333 5.333 0 1 1 0-10.666 5.333 5.333 0 0 1 0 10.666ZM24.54 9.38a1.92 1.92 0 1 0 0-3.84 1.92 1.92 0 0 0 0 3.84Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgInstagramLogo32;