"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _BookingDetailsController = require("./BookingDetailsController");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingDetails(_ref) {
  var _cancellationInfoResp, _cancellationInfoResp2, _cancellationInfoResp3;
  var {
    baseDataTestId,
    basketReference,
    bookingReference,
    shouldShowTypeOfBooking,
    area = 'pi',
    getBookingStatus,
    bookingStatus,
    bookingType,
    idvData,
    setIdvData,
    defaultDataFromBooking,
    dpaInfo,
    setDpaInfo,
    bookingDetails,
    noOfRooms,
    sourcePms = 'opera',
    overridenUserInfo,
    inputValues,
    skipContainerRendering = false,
    gdsReferenceNumber,
    distBookingChannel,
    bookingChannel,
    sourceSystem,
    isAmendSuccessful = false,
    arrival,
    bookingSurname,
    isReadOnly,
    isAmendPage,
    showExtras = true
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    currencyCode,
    totalCost,
    roomDetails,
    hotelId,
    paymentOption,
    balanceOutstanding,
    previousTotal,
    newTotal,
    ammendOrCancelLabel,
    donationPkg,
    rateType,
    shouldDisplayCityTaxMessage,
    cancellationInfoResponse,
    hotelName,
    bookedFor,
    arrivalDate,
    noNights,
    cardType,
    guestSurname,
    reasonForStay,
    dinnerAllowance
  } = bookingDetails;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, containerStyle), {}, {
    w: {
      mobile: '100%',
      lg: area === _api.Area.CCUI && !isAmendPage ? 'unset' : '100%'
    },
    sx: {
      '@media print': {
        width: '100%',
        marginLeft: 'md'
      }
    },
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [shouldShowTypeOfBooking && (0, _jsxRuntime.jsx)(_react.Box, {
      children: (0, _jsxRuntime.jsx)(_molecules.BookingDetailsReservationInformation, {
        baseDataTestId: 'operaCardReservationInfo',
        sourcePms: sourcePms,
        rateType: rateType,
        isBart: false,
        t: t,
        distBookingChannel: distBookingChannel,
        reasonForStay: reasonForStay,
        gdsReferenceNumber: gdsReferenceNumber,
        isReadOnly: isReadOnly,
        paymentOption: paymentOption
      })
    }), roomDetails === null || roomDetails === void 0 ? void 0 : roomDetails.map((room, index) => {
      return (0, _jsxRuntime.jsx)(_react.Box, {
        mt: {
          mobile: 'lg',
          lg: index === 0 ? 0 : 'lg'
        },
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'BookingDetailWrapper'),
        children: (0, _jsxRuntime.jsx)(_molecules.BookingDetailsRoomInformation, _objectSpread(_objectSpread({
          bookingStatus: bookingStatus,
          roomNumber: index + 1
        }, room), {}, {
          currencyCode: currencyCode,
          showExtras: showExtras
        }))
      }, "".concat(room.roomType, " - ").concat(room.roomPrice));
    }), ![_api.BC_RESERVATION_STATUS.CANCELLED, _api.BOOKING_TYPE.CANCELLED].includes(bookingStatus) && (0, _jsxRuntime.jsx)(_molecules.BookingDetailsTotalCost, {
      currency: currencyCode,
      totalCost: totalCost,
      balanceOutstanding: balanceOutstanding,
      previousTotal: previousTotal,
      newTotal: newTotal,
      paymentOption: paymentOption,
      donationPkg: donationPkg,
      shouldDisplayCityTaxMessage: shouldDisplayCityTaxMessage,
      paidWithPiba: cardType === 'AT',
      bookingStatus: bookingStatus,
      dinnerAllowance: dinnerAllowance,
      isAmendPage: isAmendPage
    }), skipContainerRendering && cancellationInfoResponse ? (0, _jsxRuntime.jsx)(_BookingDetailsController.BookingDetailsControllerComponent, {
      isAmendable: (_cancellationInfoResp = cancellationInfoResponse.amendable) !== null && _cancellationInfoResp !== void 0 ? _cancellationInfoResp : false,
      isCancellable: (_cancellationInfoResp2 = cancellationInfoResponse.cancelable) !== null && _cancellationInfoResp2 !== void 0 ? _cancellationInfoResp2 : false,
      isRuleCompliant: (_cancellationInfoResp3 = cancellationInfoResponse.ruleCompliant) !== null && _cancellationInfoResp3 !== void 0 ? _cancellationInfoResp3 : false,
      refetchManageBooking: getBookingStatus,
      bookingReference: bookingReference,
      basketReference: basketReference,
      bookingStatus: bookingStatus,
      ammendOrCancelLabel: bookingDetails.ammendOrCancelLabel,
      skipBookingRequest: skipContainerRendering,
      hotelName: hotelName,
      bookedFor: bookedFor,
      arrivalDate: arrivalDate,
      noOfRooms: noOfRooms,
      noNights: noNights,
      hotelId: hotelId,
      bookingChannel: bookingChannel,
      sourceSystem: sourceSystem,
      guestSurname: guestSurname,
      area: area,
      isAmendSuccessful: isAmendSuccessful,
      rateType: bookingDetails.rateType,
      bookingSurname: bookingSurname,
      isAmendPage: isAmendPage
    }) : (0, _jsxRuntime.jsx)(_BookingDetailsController.BookingDetailsController, {
      hotelId: hotelId,
      basketReference: basketReference !== null && basketReference !== void 0 ? basketReference : '',
      bookingReference: bookingReference !== null && bookingReference !== void 0 ? bookingReference : '',
      area: area,
      getBookingStatus: getBookingStatus,
      bookingStatus: bookingStatus,
      bookingType: bookingType,
      idvData: idvData,
      setIdvData: setIdvData,
      defaultDataFromBooking: defaultDataFromBooking,
      dpaInfo: dpaInfo,
      setDpaInfo: setDpaInfo,
      ammendOrCancelLabel: ammendOrCancelLabel,
      overridenUserInfo: overridenUserInfo,
      inputValues: inputValues,
      isAmendSuccessful: isAmendSuccessful,
      rateType: bookingDetails.rateType,
      arrivalDate: arrival,
      bookingSurname: bookingSurname,
      isAmendPage: isAmendPage
    })]
  }));
}
var containerStyle = {
  pl: {
    mobile: 0,
    lg: 'lg'
  },
  color: 'darkGrey2',
  direction: 'column',
  fontSize: 'lg'
};