"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomPicker;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nanoid = require("nanoid");
var _react2 = require("react");
var _DropdownContent = _interopRequireDefault(require("./DropdownContent.component"));
var _constants = require("./constants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomPicker(_ref) {
  var {
    onSubmit,
    boxWrapperStyles,
    roomPickerInputElementStyles,
    roomPickerSize,
    initialState,
    labels,
    maxNumberOfRooms,
    dataRoomOccupancyLimitations,
    roomCodes,
    screenSize,
    showErrorMessage,
    errorMessage,
    showMultipleRooms,
    channel
  } = _ref;
  var createRoom = labels => ({
    id: (0, _nanoid.nanoid)(),
    adults: 1,
    children: 0,
    shouldIncludeCot: false,
    roomType: labels.double
  });
  var rooms = initialState;
  var [isContentDisplayed, setIsContentDisplayed] = (0, _react2.useState)(false);
  var addRoom = () => {
    if (rooms.length < maxNumberOfRooms) {
      var newRooms = [...rooms, createRoom(labels)];
      onSubmit(newRooms);
    }
  };
  var removeRoom = roomId => {
    var newRooms = rooms.filter(room => room.id !== roomId);
    onSubmit(newRooms);
  };
  var updateRoom = (roomId, option, value, labels) => {
    var newRooms = rooms.map(room => {
      if (room.id !== roomId) {
        return room;
      } else {
        var extraChanges = {};
        if (option === _constants.ADULTS_KEY && value > 0 && channel !== _api.BOOKING_CHANNEL.CCUI) {
          if (room.roomType === (labels === null || labels === void 0 ? void 0 : labels.twin) && value === 1) {
            extraChanges = {
              roomType: labels === null || labels === void 0 ? void 0 : labels.double
            };
          }
          if (room.roomType === (labels === null || labels === void 0 ? void 0 : labels.single) && value === 2) {
            extraChanges = {
              roomType: labels === null || labels === void 0 ? void 0 : labels.double
            };
          }
        }
        if (option === _constants.CHILDREN_KEY) {
          if (value === 0) {
            extraChanges = {
              roomType: labels === null || labels === void 0 ? void 0 : labels.double
            };
          } else {
            extraChanges = {
              roomType: labels === null || labels === void 0 ? void 0 : labels.family
            };
          }
        }
        return _objectSpread(_objectSpread({}, room), {}, {
          [option]: value
        }, extraChanges);
      }
    });
    onSubmit(newRooms);
  };
  var _onSubmit = () => {
    onSubmit(rooms);
  };
  var {
    isLessThanSm
  } = screenSize;
  var dropdownWrapperStyles = _objectSpread(_objectSpread(_objectSpread({}, wrapperStyles), boxWrapperStyles), showErrorMessage && !isContentDisplayed && _objectSpread({}, errorWrapperStyles));
  var menuBtnStyles = _objectSpread(_objectSpread(_objectSpread({}, menuButtonStyles), roomPickerInputElementStyles), showErrorMessage && !isContentDisplayed && _objectSpread({}, errorMenuButtonStyles));
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    position: "relative"
  }, roomPickerSize), {}, {
    children: [(0, _jsxRuntime.jsx)(_atoms.Dropdown, {
      dropdownStyles: {
        wrapperStyles: dropdownWrapperStyles,
        menuButtonStyles: menuBtnStyles,
        menuButtonWrapperStyles: menuButtonWrapperStyles,
        errorHoverMenuButtonStyles: showErrorMessage && !isContentDisplayed ? errorHoverMenuButtonStyles : {}
      },
      icon: (0, _jsxRuntime.jsx)(_atoms.Icon, {
        svg: (0, _jsxRuntime.jsx)(_atoms.OneAdult, {})
      }),
      skipChevron: true,
      placeholder: getPlaceholder(rooms, labels),
      dataTestId: "roomPicker-dropdown",
      onDisplayContent: handleDisplayContent,
      children: props => (0, _jsxRuntime.jsx)(_DropdownContent.default, _objectSpread(_objectSpread({}, props), {}, {
        showMultipleRooms: showMultipleRooms,
        onSubmit: _onSubmit,
        rooms: rooms,
        addRoom: addRoom,
        removeRoom: removeRoom,
        updateRoom: updateRoom,
        labels: labels,
        maxNumberOfRooms: maxNumberOfRooms,
        dataRoomOccupancyLimitations: dataRoomOccupancyLimitations,
        adultsOptions: (0, _utils.createOptionsAdultsChildrenDropdown)((0, _utils.getAdultsChildrenOptions)(dataRoomOccupancyLimitations, _constants.ADULTS_NUMBER_KEY), labels.adult, labels.adults),
        childrenOptions: (0, _utils.createOptionsAdultsChildrenDropdown)((0, _utils.getAdultsChildrenOptions)(dataRoomOccupancyLimitations, _constants.CHILDREN_NUMBER_KEY), labels.child, labels.children),
        dataTestId: "roomPicker-dropdownContent",
        roomCodes: roomCodes,
        screenSize: screenSize,
        channel: channel
      }))
    }), !isLessThanSm && showErrorMessage && errorMessage && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
      infoMessage: errorMessage,
      otherStyles: alertStyles
    })]
  }));
  function handleDisplayContent(isContentDisplayed) {
    setIsContentDisplayed(isContentDisplayed);
  }
}
function getTotalPeople(state) {
  return state.reduce((acc, cur) => {
    acc.adults += +cur.adults;
    acc.children += +cur.children;
    return acc;
  }, {
    adults: 0,
    children: 0
  });
}
function getPlaceholderString(obj, labels) {
  var {
    adult,
    adults,
    child,
    children,
    room,
    rooms
  } = labels;
  var adultsLabel = obj.adults === 1 ? adult : adults;
  var childLabel = obj.children === 1 ? child : children;
  var childrenLabel = obj.children === 0 ? '' : childLabel;
  var roomsLabel = obj.rooms === 1 ? room : rooms;
  return obj.children === 0 ? "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel) : "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.children, " ").concat(childrenLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel);
}
function getPlaceholder(state, labels) {
  var totalRooms = state.length;
  var totalPeople = getTotalPeople(state);
  return getPlaceholderString(_objectSpread(_objectSpread({}, totalPeople), {}, {
    rooms: totalRooms
  }), labels);
}
var menuButtonStyles = {
  bgColor: 'transparent',
  border: '0.063rem solid transparent',
  _hover: {
    border: '0.063rem solid var(--chakra-colors-darkGrey1)'
  },
  _active: {
    border: '0.125rem solid var(--chakra-colors-primary)'
  }
};
var errorHoverMenuButtonStyles = {
  _hover: {
    border: 'none'
  }
};
var errorMenuButtonStyles = {
  borderWidth: '0',
  _active: {
    border: 'none'
  }
};
var wrapperStyles = {
  mt: '3rem'
};
var errorWrapperStyles = {
  borderRadius: 'var(--chakra-space-radiusSmall)',
  border: '2px solid var(--chakra-colors-error)'
};
var menuButtonWrapperStyles = {
  display: 'block',
  height: '100%',
  marginLeft: '-1px'
};
var alertStyles = {
  w: 'full',
  zIndex: 1
};