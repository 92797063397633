"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccountToCompany;
var _react = _interopRequireWildcard(require("react"));
var _components = require("./components");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function AccountToCompany(_ref) {
  var {
    hotelId,
    selectedPaymentDetail,
    setPreAuthorisedCharges,
    setIsTotalCostVisible,
    setCompanyReferenceError,
    setCompanyNumber,
    setCompanyId,
    setACCompanyReference,
    setCompanyDetails
  } = _ref;
  var [verifiedCompany, setVerifiedCompany] = (0, _react.useState)(null);
  (0, _react.useEffect)(() => {
    var isVerifiedCompany = !!verifiedCompany;
    setIsTotalCostVisible(isVerifiedCompany);
    if (isVerifiedCompany) {
      var _verifiedCompany$arNu, _verifiedCompany$comp, _verifiedCompany$name, _verifiedCompany$addr, _verifiedCompany$addr2, _verifiedCompany$addr3, _verifiedCompany$addr4, _verifiedCompany$addr5, _verifiedCompany$addr6, _verifiedCompany$addr7;
      setCompanyNumber((_verifiedCompany$arNu = verifiedCompany.arNumber) !== null && _verifiedCompany$arNu !== void 0 ? _verifiedCompany$arNu : '');
      setCompanyId((_verifiedCompany$comp = verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.companyId) !== null && _verifiedCompany$comp !== void 0 ? _verifiedCompany$comp : '');
      setCompanyDetails({
        name: (_verifiedCompany$name = verifiedCompany === null || verifiedCompany === void 0 ? void 0 : verifiedCompany.name) !== null && _verifiedCompany$name !== void 0 ? _verifiedCompany$name : '',
        address: {
          addressLine1: verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr = verifiedCompany.address) === null || _verifiedCompany$addr === void 0 ? void 0 : _verifiedCompany$addr.addressLine1,
          addressLine2: verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr2 = verifiedCompany.address) === null || _verifiedCompany$addr2 === void 0 ? void 0 : _verifiedCompany$addr2.addressLine2,
          addressLine3: verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr3 = verifiedCompany.address) === null || _verifiedCompany$addr3 === void 0 ? void 0 : _verifiedCompany$addr3.addressLine3,
          cityName: (_verifiedCompany$addr4 = verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr5 = verifiedCompany.address) === null || _verifiedCompany$addr5 === void 0 ? void 0 : _verifiedCompany$addr5.cityName) !== null && _verifiedCompany$addr4 !== void 0 ? _verifiedCompany$addr4 : '',
          country: verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr6 = verifiedCompany.address) === null || _verifiedCompany$addr6 === void 0 ? void 0 : _verifiedCompany$addr6.country,
          postalCode: verifiedCompany === null || verifiedCompany === void 0 || (_verifiedCompany$addr7 = verifiedCompany.address) === null || _verifiedCompany$addr7 === void 0 ? void 0 : _verifiedCompany$addr7.postalCode
        }
      });
    } else {
      setCompanyNumber('');
      setCompanyId('');
      setACCompanyReference('');
      setCompanyDetails(null);
    }
    setPreAuthorisedCharges([]);
  }, [verifiedCompany]);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: !verifiedCompany ? (0, _jsxRuntime.jsx)(_components.AccountToCompanyDetails, {
      hotelId: hotelId,
      selectedPaymentDetail: selectedPaymentDetail,
      setVerifiedCompany: setVerifiedCompany
    }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_components.AccountToCompanyFields, {
        verifiedCompany: verifiedCompany,
        setVerifiedCompany: setVerifiedCompany
      }), (0, _jsxRuntime.jsx)(_components.AccountToCompanyPreAuthorisedCharges, {
        setPreAuthorisedCharges: setPreAuthorisedCharges,
        setCompanyReferenceError: setCompanyReferenceError,
        setACCompanyReference: setACCompanyReference
      })]
    })
  });
}