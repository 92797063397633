"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgGermanFlagRegular = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("rect", {
    y: 4,
    width: 24,
    height: 16.134,
    rx: 1.714,
    fill: "#fff"
  }), (0, _jsxRuntime.jsx)("mask", {
    id: "german-flag-regular_svg__a",
    style: {
      maskType: 'alpha'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 4,
    width: 24,
    height: 17,
    children: (0, _jsxRuntime.jsx)("rect", {
      y: 4,
      width: 24,
      height: 16.134,
      rx: 1.714,
      fill: "#fff"
    })
  }), (0, _jsxRuntime.jsxs)("g", {
    mask: "url(#german-flag-regular_svg__a)",
    children: [(0, _jsxRuntime.jsx)("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 9.378h24V4H0v5.378Z",
      fill: "#262626"
    }), (0, _jsxRuntime.jsx)("g", {
      filter: "url(#german-flag-regular_svg__b)",
      children: (0, _jsxRuntime.jsx)("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M0 14.756h24V9.378H0v5.378Z",
        fill: "#F01515"
      })
    }), (0, _jsxRuntime.jsx)("g", {
      filter: "url(#german-flag-regular_svg__c)",
      children: (0, _jsxRuntime.jsx)("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M0 20.134h24v-5.378H0v5.378Z",
        fill: "#FFD521"
      })
    })]
  }), (0, _jsxRuntime.jsxs)("defs", {
    children: [(0, _jsxRuntime.jsxs)("filter", {
      id: "german-flag-regular_svg__b",
      x: 0,
      y: 9.378,
      width: 24,
      height: 5.378,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB",
      children: [(0, _jsxRuntime.jsx)("feFlood", {
        floodOpacity: 0,
        result: "BackgroundImageFix"
      }), (0, _jsxRuntime.jsx)("feColorMatrix", {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }), (0, _jsxRuntime.jsx)("feOffset", {}), (0, _jsxRuntime.jsx)("feColorMatrix", {
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
      }), (0, _jsxRuntime.jsx)("feBlend", {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_1257_43065"
      }), (0, _jsxRuntime.jsx)("feBlend", {
        in: "SourceGraphic",
        in2: "effect1_dropShadow_1257_43065",
        result: "shape"
      })]
    }), (0, _jsxRuntime.jsxs)("filter", {
      id: "german-flag-regular_svg__c",
      x: 0,
      y: 14.756,
      width: 24,
      height: 5.378,
      filterUnits: "userSpaceOnUse",
      colorInterpolationFilters: "sRGB",
      children: [(0, _jsxRuntime.jsx)("feFlood", {
        floodOpacity: 0,
        result: "BackgroundImageFix"
      }), (0, _jsxRuntime.jsx)("feColorMatrix", {
        in: "SourceAlpha",
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0",
        result: "hardAlpha"
      }), (0, _jsxRuntime.jsx)("feOffset", {}), (0, _jsxRuntime.jsx)("feColorMatrix", {
        values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
      }), (0, _jsxRuntime.jsx)("feBlend", {
        in2: "BackgroundImageFix",
        result: "effect1_dropShadow_1257_43065"
      }), (0, _jsxRuntime.jsx)("feBlend", {
        in: "SourceGraphic",
        in2: "effect1_dropShadow_1257_43065",
        result: "shape"
      })]
    })]
  })]
}));
var _default = exports.default = SvgGermanFlagRegular;