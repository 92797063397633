"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactPaypalJs = require("@paypal/react-paypal-js");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _Error = _interopRequireDefault(require("../../assets/icons/Error"));
var _Notification = _interopRequireDefault(require("../Notification"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PaypalWBButton(_ref) {
  var {
    currency,
    onApprove,
    onError,
    onCancel,
    createBillingAgreement,
    style
  } = _ref;
  var [{
    options,
    isRejected,
    isPending
  }, dispatch] = (0, _reactPaypalJs.usePayPalScriptReducer)();
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  (0, _react2.useEffect)(() => {
    dispatch({
      type: 'resetOptions',
      value: _objectSpread(_objectSpread({}, options), {}, {
        currency: currency
      })
    });
  }, [currency]);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: isRejected && !isPending ? (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "sm",
      mb: "5xl",
      children: (0, _jsxRuntime.jsx)(_Notification.default, {
        variant: "error",
        status: "error",
        description: t('booking.payment.paypal.loading.error'),
        svg: (0, _jsxRuntime.jsx)(_Error.default, {}),
        wrapperStyles: {
          mb: '-2xl',
          mt: '2xl'
        }
      })
    }, "payment_custom_message") : (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, buttonStyle), {}, {
      children: (0, _jsxRuntime.jsx)(_reactPaypalJs.BraintreePayPalButtons, {
        style: _objectSpread({}, style),
        disabled: false,
        fundingSource: "paypal",
        forceReRender: [currency],
        createBillingAgreement: createBillingAgreement,
        onApprove: onApprove,
        onCancel: onCancel,
        onError: onError
      })
    }))
  });
}
var _default = exports.default = PaypalWBButton;
var buttonStyle = {
  mt: 'lg',
  w: {
    mobile: 'full',
    lg: '18rem',
    xl: '19.3125rem'
  }
};