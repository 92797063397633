"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GET_PAYMENT_TYPE_OPTS_QUERY_CCUI = exports.GET_PAYMENT_METHODS_QUERY_BB = exports.GET_PAYMENT_METHODS_QUERY = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject, _templateObject2;
var GET_PAYMENT_METHODS_QUERY = exports.GET_PAYMENT_METHODS_QUERY = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query getPaymentMethods(\n    $basketReference: String!\n    $language: String!\n    $country: String!\n    $userType: UserType\n    $clientChannel: String\n  ) {\n    paymentMethods(\n      paymentMethodsCriteria: {\n        basketReference: $basketReference\n        language: $language\n        country: $country\n        userType: $userType\n        clientChannel: $clientChannel\n      }\n    ) {\n      name\n      type\n      subType\n      order\n      enabled\n      cnpPreSelected\n      cnpOptionAvailable\n      clientId\n      clientToken\n      logoSrc\n\n      acceptedCardTypes {\n        type\n        name\n        logoSrc\n      }\n\n      card {\n        token\n        # cardMasked\n        cardName\n        cardNumber\n        expiryMonth\n        expiryYear\n        type\n        logoSrc\n        cardHolderName\n        cardType\n        cnpRequired\n      }\n\n      paymentOptions {\n        type\n        order\n        enabled\n      }\n      reasons\n    }\n  }\n"])));
var GET_PAYMENT_METHODS_QUERY_BB = exports.GET_PAYMENT_METHODS_QUERY_BB = (0, _graphqlRequest.gql)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  query getPaymentMethods(\n    $basketReference: String!\n    $language: String!\n    $country: String!\n    $userType: UserType\n    $clientChannel: String\n  ) {\n    paymentMethods(\n      paymentMethodsCriteria: {\n        basketReference: $basketReference\n        language: $language\n        country: $country\n        userType: $userType\n        clientChannel: $clientChannel\n      }\n    ) {\n      name\n      type\n      subType\n      order\n      enabled\n      cnpPreSelected\n      cnpOptionAvailable\n      logoSrc\n      clientId\n      clientToken\n\n      acceptedCardTypes {\n        type\n        name\n        logoSrc\n      }\n\n      card {\n        token\n        # cardMasked\n        cardNumber\n        expiryMonth\n        expiryYear\n        type\n        logoSrc\n        cardHolderName\n        cardType\n        cnpRequired\n      }\n\n      paymentOptions {\n        type\n        order\n        enabled\n      }\n      reasons\n      bookingAllowances {\n        allowAlcohol\n        allowCarParking\n        allowAdditionalCosts\n        maxDinnerBudgets {\n          ukWide {\n            amount\n            currency\n          }\n          greaterLondon {\n            amount\n            currency\n          }\n          ireland {\n            amount\n            currency\n          }\n        }\n      }\n    }\n  }\n"])));
var GET_PAYMENT_TYPE_OPTS_QUERY_CCUI = exports.GET_PAYMENT_TYPE_OPTS_QUERY_CCUI = "\n  query getPaymentMethodsCCUI($basketReference: String!, $language: String!, $country: String!) {\n    paymentCcuiMethods(\n      paymentCcuiMethodsCriteria: {\n        basketReference: $basketReference\n        language: $language\n        country: $country\n      }\n    ) {\n      acceptedCardTypes {\n        logoSrc\n        name\n        type\n      }\n      cnpOptionAvailable\n      cnpPreSelected\n      enabled\n      name\n      subType\n      order\n      paymentOptions {\n        enabled\n        order\n        type\n      }\n      reasons\n      type\n    }\n  }\n";