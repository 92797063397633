"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ANALYTICS_TRACKING_AMEND_PAGE = void 0;
exports.default = BookingDetailsController;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _reactQuery = require("@tanstack/react-query");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _CancelBookingModal = require("../../CancelBookingModal");
var _IDVModal = _interopRequireDefault(require("../../IDVModal"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var ANALYTICS_TRACKING_AMEND_PAGE = exports.ANALYTICS_TRACKING_AMEND_PAGE = 'lamendBooking';
function BookingDetailsController(_ref) {
  var {
    isCancellable,
    isAmendable,
    isRuleCompliant,
    area = 'pi',
    refetchManageBooking,
    basketReference,
    bookingReference,
    bookingType,
    bookingStatus,
    ammendOrCancelLabel,
    idvData,
    setIdvData,
    defaultDataFromBooking,
    dpaInfo,
    setDpaInfo,
    inputValues,
    skipBookingRequest,
    hotelName,
    bookedFor,
    arrivalDate,
    noOfRooms,
    noNights,
    hotelId,
    bookedBy,
    bookingChannel,
    sourceSystem,
    guestSurname,
    isAmendSuccessful,
    rateType,
    bookingSurname,
    isAmendPage
  } = _ref;
  var router = (0, _router.useRouter)();
  var [isCancelableModalVisible, setIsCancelableModalVisible] = (0, _react2.useState)(false);
  var [isIdvModalVisible, setIsIdvModalVisible] = (0, _react2.useState)(false);
  var [idvStatus, setIdvStatus] = (0, _utils.useLocalStorage)(_api.IDV_STATUS_KEY, _api.IDV_INITIAL_DATA);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var idvPassed = dpaInfo && (0, _utils.getIDVPassedStatus)(dpaInfo) || idvStatus.passed && idvStatus.bookingReference === bookingReference;
  var baseDataTestId = 'BookingDetailsController';
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    accessLevel,
    sessionId,
    profile
  } = (0, _utils.getLoggedInUserInfo)(idTokenCookie);
  var {
    reservationId
  } = router.query;
  (0, _react2.useEffect)(() => {
    setIsCancelableModalVisible(false);
  }, []);
  var handleCancelBooking = (0, _react2.useCallback)(() => {
    if (isCancellable) {
      setIsCancelableModalVisible(true);
    }
  }, [isCancellable]);
  var handleIDVModalClose = (dpaPassed, dpaOverride) => {
    setIsIdvModalVisible(false);
    if (setDpaInfo) {
      setDpaInfo({
        dpaPassed: dpaPassed,
        dpaOverride: dpaOverride
      });
    }
    if (setIdvData && idvData) {
      setIdvData(_objectSpread(_objectSpread({}, idvData), {}, {
        dpaStatus: _objectSpread(_objectSpread({}, idvData === null || idvData === void 0 ? void 0 : idvData.dpaStatus), {}, {
          dpaPassed: dpaPassed,
          dpaOverride: dpaOverride
        })
      }));
    }
    if (dpaPassed || dpaOverride) {
      setIdvStatus({
        bookingReference,
        passed: true
      });
    }
  };
  var handleReuseDetails = () => {
    if (reservationId) {
      if (defaultDataFromBooking) {
        var countryCode = country === null || country === void 0 ? void 0 : country.toLocaleUpperCase();
        try {
          window.localStorage.setItem('formDetails', JSON.stringify(_objectSpread(_objectSpread({}, defaultDataFromBooking), {}, {
            country: countryCode,
            countryCode,
            basketReferenceId: reservationId,
            leadGuest: undefined,
            updated: true
          })));
        } catch (error) {
          console.log(error);
        }
      }
      window.location.href = "/".concat(country, "/").concat(language, "/guest-details?reservationId=").concat(reservationId);
    }
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [renderControllers(), renderModal()]
  });
  function renderControllers() {
    var contactUsRedirectUrl = area === _api.Area.PI ? t('dashboard.bookings.contactUsButton.url') : t('dashboard.bookings.contactUsButtonBB.url');
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [isAmendable && bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
        "data-testid": "BookingDetailsControllerAmendOrCancelLabel"
      }, amendOrCancelLabelStyle), {}, {
        dangerouslySetInnerHTML: {
          __html: ammendOrCancelLabel
        }
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, buttonsContainerStyle), {}, {
        marginTop: bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && isAmendSuccessful ? '0' : buttonsContainerStyle.mt,
        sx: {
          '@media print': {
            display: 'none'
          }
        },
        "data-testid": "BookingDetailsControllerContainer",
        children: [area === _api.Area.CCUI && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, isAmendPage && {
          w: '100%',
          justifyContent: 'flex-end'
        }), {}, {
          children: [!isAmendPage && (0, _jsxRuntime.jsx)(_react.Box, {
            children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
              onClick: () => {
                setIsIdvModalVisible(true);
                setIdvStatus(_api.IDV_INITIAL_DATA);
              }
            }, idAndVButtonStyle(language)), {}, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CtaButton'),
              children: t('ccui.idv.ctaButton')
            }))
          }), (bookingType === _api.BOOKING_TYPE.UPCOMING && rateType !== 'EMPLOYEE' || isAmendPage) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, buttonContainerStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, cancelButtonStyle(language, _api.Area.CCUI, isAmendPage)), {}, {
                onClick: handleCancelBooking,
                isDisabled: !idvPassed || !isCancellable,
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CancelButton'),
                children: t('dashboard.bookings.cancelButton')
              }))
            })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, buttonContainerStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
                onClick: () => handleAmendBooking(),
                isDisabled: !idvPassed || !isAmendable
              }, amendButtonStyle(language, _api.Area.CCUI, isAmendPage)), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AmendButton'),
                children: t('dashboard.bookings.amendButton')
              }))
            }))]
          })]
        })), [_api.Area.PI, _api.Area.BB].includes(area) && bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && !isAmendSuccessful && (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
          children: !isRuleCompliant ? (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, buttonContainerStyle), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Link, {
              href: contactUsRedirectUrl,
              isExternal: true,
              children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, amendButtonStyle(language, _api.Area.PI)), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ContactUsButton'),
                children: t('dashboard.bookings.contactUsButton')
              }))
            })
          })) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [isCancellable && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, buttonContainerStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({}, cancelButtonStyle(language, _api.Area.PI)), {}, {
                onClick: handleCancelBooking,
                isDisabled: !isCancellable,
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CancelButton'),
                children: t('dashboard.bookings.cancelButton')
              }))
            })), isAmendable && accessLevel !== _api.BUSINESS_BOOKER_USER_ROLES.STAYER && (0, _jsxRuntime.jsx)(_react.Box, {
              pl: {
                mobile: 0,
                lg: 'lg'
              },
              pt: {
                mobile: 'md',
                lg: '0'
              },
              children: (0, _jsxRuntime.jsx)(_atoms.Button, _objectSpread(_objectSpread({
                onClick: () => handleAmendBooking()
              }, amendButtonStyle(language, _api.Area.PI)), {}, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AmendButton'),
                children: t('dashboard.bookings.amendButton')
              }))
            })]
          })
        })]
      }))]
    });
  }
  function renderModal() {
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [idvData && setIdvData && (0, _jsxRuntime.jsx)(_IDVModal.default, {
        isVisible: isIdvModalVisible,
        onClose: handleIDVModalClose,
        onReuseDetails: reservationId ? handleReuseDetails : undefined,
        data: idvData,
        setData: setIdvData,
        t: t,
        inputValues: inputValues
      }), skipBookingRequest ? bookingChannel && (0, _jsxRuntime.jsx)(_CancelBookingModal.BookingHistoryCancelBookingModal, {
        area: area,
        refetchManageBooking: refetchManageBooking,
        isModalVisible: isCancelableModalVisible,
        onModalClose: () => setIsCancelableModalVisible(false),
        basketReference: basketReference,
        bookingReference: bookingReference,
        hotelName: hotelName,
        bookedFor: bookedFor,
        arrivalDate: arrivalDate,
        noOfRooms: noOfRooms,
        noNights: noNights,
        hotelId: hotelId,
        bookedBy: bookedBy,
        bookingChannel: bookingChannel
      }) : (0, _jsxRuntime.jsx)(_CancelBookingModal.CancelBookingModal, {
        area: area,
        refetchManageBooking: refetchManageBooking,
        isModalVisible: isCancelableModalVisible,
        onModalClose: () => setIsCancelableModalVisible(false),
        basketReference: basketReference !== null && basketReference !== void 0 ? basketReference : '',
        bookingReference: bookingReference
      })]
    });
  }
  function setOperaBookingCookie(findBookingResponse) {
    if (!(findBookingResponse !== null && findBookingResponse !== void 0 && findBookingResponse.findBooking)) {
      return;
    }
    var {
      ref,
      basketReference,
      cookieName,
      token,
      minutesTillExpiry
    } = findBookingResponse.findBooking;
    var cookieValue = {
      token,
      basketReference,
      bookingReference: ref
    };
    if (typeof window !== 'undefined') {
      window.localStorage.setItem(_api.FIND_BOOKING_COOKIE_NAME_KEY, cookieName !== null && cookieName !== void 0 ? cookieName : '');
    }
    (0, _utils.setCookie)(cookieName !== null && cookieName !== void 0 ? cookieName : '', window.btoa(JSON.stringify(cookieValue)), Number(minutesTillExpiry));
  }
  function handleAmendBooking() {
    var isSecureAmend = sourceSystem === _api.SOURCE_SYSTEM.BART;
    window.__satelliteLoaded && window._satellite.track(ANALYTICS_TRACKING_AMEND_PAGE);
    if (isSecureAmend) {
      var cookieData = _objectSpread({
        reservationId: bookingReference,
        arrivalDate: arrivalDate !== null && arrivalDate !== void 0 ? arrivalDate : '',
        surname: guestSurname !== null && guestSurname !== void 0 ? guestSurname : '',
        bookingChannel: area === _api.Area.BB ? 'CBT' : 'WEB'
      }, area === _api.Area.BB ? {
        employeeId: profile.employeeId,
        sessionId: sessionId,
        companyId: profile.companyId,
        userlevel: accessLevel
      } : {});
      (0, _utils.setCookie)(_api.AMEND_COOKIE_NAME, Buffer.from(JSON.stringify(cookieData)).toString('base64'), _api.AMEND_COOKIE_EXPIRY_MINUTES, '/', '');
      var amendUrl = area === _api.Area.BB ? "".concat((0, _utils.getSecureTwoURL)(), "/").concat(country, "/").concat(language, "/business-booker/amend/business/details.html") : "".concat((0, _utils.getSecureTwoURL)(), "/").concat(country, "/").concat(language, "/amend/leisure/details.html");
      return router.push(amendUrl);
    } else if (arrivalDate && bookingSurname) {
      (0, _utils.cleanupFindBookingToken)();
      var queryClient = new _reactQuery.QueryClient();
      var findBookingCriteria = {
        country,
        language,
        arrivalDate: arrivalDate,
        lastName: bookingSurname,
        resNo: bookingReference,
        bookingChannel: bookingChannel !== null && bookingChannel !== void 0 ? bookingChannel : {
          channel: area.toUpperCase(),
          subchannel: 'WEB',
          language: language.toUpperCase()
        }
      };
      queryClient.fetchQuery({
        queryKey: ['amendFindBooking', findBookingCriteria.country, findBookingCriteria.language, findBookingCriteria.arrivalDate, findBookingCriteria.lastName, findBookingCriteria.resNo, findBookingCriteria.bookingChannel],
        queryFn: () => (0, _utils.graphQLRequest)(_api.AMEND_FIND_BOOKING, _objectSpread({}, findBookingCriteria))
      }).then(response => {
        setOperaBookingCookie(response);
        var BBUrlPrefix = area === _api.Area.BB ? '/business-booker' : '';
        return router.push("/".concat(country, "/").concat(language).concat(BBUrlPrefix, "/amend/details.html?bookingReference=").concat(bookingReference));
      }).catch(error => {
        console.log(error);
      });
    }
  }
}
var buttonsContainerStyle = {
  justifyContent: 'flex-end',
  mt: 'xl',
  direction: {
    mobile: 'column',
    lg: 'row'
  }
};
var ccuiButtonWidth = language => ({
  mobile: '100%',
  xl: '12.125rem',
  lg: language === 'de' ? '11.25rem' : '10rem'
});
var piButtonWidth = () => ({
  mobile: '100%',
  lg: '15.5rem'
});
var idAndVButtonStyle = language => _objectSpread(_objectSpread({}, ccuiButtonWidth(language)), {}, {
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '3',
  size: 'sm',
  variant: 'primary'
});
var cancelButtonStyle = (language, area, isAmendPage) => ({
  size: 'sm',
  variant: 'tertiary',
  w: area === _api.Area.CCUI && !isAmendPage ? _objectSpread({}, ccuiButtonWidth(language)) : _objectSpread({}, piButtonWidth())
});
var buttonContainerStyle = {
  pl: {
    mobile: 0,
    lg: 'lg'
  },
  pt: {
    mobile: 'md',
    lg: '0'
  }
};
var amendButtonStyle = (language, area, isAmendPage) => ({
  fontSize: 'lg',
  fontWeight: 'semibold',
  lineHeight: '3',
  size: 'sm',
  variant: 'secondary',
  w: area === _api.Area.CCUI && !isAmendPage ? _objectSpread({}, ccuiButtonWidth(language)) : _objectSpread({}, piButtonWidth())
});
var amendOrCancelLabelStyle = {
  mt: {
    mobile: 'xs',
    xl: 'sm'
  },
  as: 'h6',
  color: 'darkGrey2',
  fontWeight: 'normal',
  fontSize: 'md',
  lineHeight: '3',
  textAlign: 'right',
  whiteSpace: 'normal',
  pl: {
    mobile: 'md',
    xs: '5xl',
    sm: 0
  }
};