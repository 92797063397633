"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Notification;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _icons = require("../../assets/icons");
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["isInnerHTML", "description", "title", "prefixDataTestId", "showCloseButton", "wrapperStyles", "isClosed"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Notification(props) {
  var {
      isInnerHTML,
      description,
      title,
      prefixDataTestId,
      showCloseButton,
      wrapperStyles,
      isClosed
    } = props,
    alertProps = (0, _objectWithoutProperties2.default)(props, _excluded);
  var alertTitleStyle = _objectSpread(_objectSpread({}, alertProps), {}, {
    maxWidth: 'full',
    mr: 0
  });
  var closeIconStyle = {
    position: 'absolute',
    right: 'sm',
    top: 'sm'
  };
  var arrayDescription = Array.isArray(description);
  return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
    children: !isClosed && (title || description) && (0, _jsxRuntime.jsxs)(_react.Alert, _objectSpread(_objectSpread(_objectSpread({
      p: "md"
    }, alertProps), {}, {
      "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'Alert')
    }, wrapperStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_Icon.default, {
        style: {
          alignSelf: 'flex-start'
        },
        svg: props.svg
      }), (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'AlertColumn'),
        children: [isInnerHTML && typeof title === 'string' ? (0, _jsxRuntime.jsx)(_react.AlertTitle, _objectSpread(_objectSpread({}, alertTitleStyle), {}, {
          dangerouslySetInnerHTML: {
            __html: title
          },
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'AlertTitle')
        })) : (0, _jsxRuntime.jsx)(_react.AlertTitle, _objectSpread(_objectSpread({}, alertTitleStyle), {}, {
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'AlertTitle'),
          children: title
        })), isInnerHTML && typeof description === 'string' ? (0, _jsxRuntime.jsx)(_react.AlertDescription, {
          dangerouslySetInnerHTML: {
            __html: description
          },
          "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'AlertDescription')
        }) : displayAlertDescriptionComponent()]
      }), props.onClick && showCloseButton && (0, _jsxRuntime.jsx)(_react.CloseButton, _objectSpread(_objectSpread({}, closeIconStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_Icon.default, {
          svg: (0, _jsxRuntime.jsx)(_icons.Dismiss, {
            transform: "scale(1.1)"
          })
        })
      }))]
    }))
  });
  function displayAlertDescriptionComponent() {
    return arrayDescription ? description === null || description === void 0 ? void 0 : description.map((descriptionText, index) => {
      return (0, _jsxRuntime.jsx)(_react.AlertDescription, {
        "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, "AlertDescription-".concat(index)),
        children: descriptionText
      }, index);
    }) : (0, _jsxRuntime.jsx)(_react.AlertDescription, {
      "data-testid": (0, _utils.formatDataTestId)(prefixDataTestId, 'AlertDescription'),
      children: description
    });
  }
}