"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.getHotelLabel = getHotelLabel;
exports.getSearchResults = getSearchResults;
exports.labelsConstants = void 0;
var _dateFns = require("date-fns");
var _formatters = require("../../formatters");
var _analytics = _interopRequireDefault(require("./analytics"));
var labelsConstants = exports.labelsConstants = {
  AVAILABLE_HOTEL: 'available',
  UNAVAILABLE_HOTEL: 'unavailable',
  SOLD_OUT_CONSTANT: 'sold out',
  LAST_FEW_ROOMS_CONSTANT: 'last few rooms',
  OPEN_SOON_CONSTANT: 'open soon',
  NO_LABEL_CONSTANT: 'no label'
};
var updateSearchResultsAnalytics = _ref => {
  var _multiSearchParams$ro, _format, _format2, _multiSearchParams$ro2;
  var {
    isNewSearch,
    multiSearchParams,
    startDate,
    endDate,
    viewType,
    searchResults,
    searchResultsDisplayed,
    addedResults
  } = _ref;
  var LIST_VIEW = 'list view';
  var MAPPED_ROOM_TYPES = {
    DB: 'Double room',
    SB: 'Single room',
    FAM: 'Family room',
    TWIN: 'Twin room',
    DIS: 'Accessible room'
  };
  var searchDataReduce = multiSearchParams === null || multiSearchParams === void 0 || (_multiSearchParams$ro = multiSearchParams.rooms) === null || _multiSearchParams$ro === void 0 ? void 0 : _multiSearchParams$ro.reduce((acc, current) => {
    return {
      numberOfAdults: (acc === null || acc === void 0 ? void 0 : acc.numberOfAdults) + current.adultsNumber,
      numberOfChildren: (acc === null || acc === void 0 ? void 0 : acc.numberOfChildren) + current.childrenNumber
    };
  }, {
    numberOfAdults: 0,
    numberOfChildren: 0
  });
  var searchWeekdayFrom = startDate && ((_format = (0, _dateFns.format)(startDate, 'eee')) === null || _format === void 0 ? void 0 : _format.toLowerCase());
  var searchWeekdayTo = endDate && ((_format2 = (0, _dateFns.format)(endDate, 'eee')) === null || _format2 === void 0 ? void 0 : _format2.toLowerCase());
  _analytics.default.update({
    analyticsDataSearchResult: {
      newSearch: isNewSearch,
      addedResults: addedResults !== null && addedResults !== void 0 ? addedResults : searchResultsDisplayed.length,
      searchCheckInDate: startDate && (0, _dateFns.format)(startDate, 'dd/MM/yyyy'),
      searchCheckOutDate: endDate && (0, _dateFns.format)(endDate, 'dd/MM/yyyy'),
      searchDaysToCheckIn: startDate && (0, _dateFns.differenceInDays)(new Date(startDate), new Date()),
      searchFilter: multiSearchParams.filters,
      searchNumberOfAdults: searchDataReduce.numberOfAdults,
      searchNumberOfChildren: searchDataReduce.numberOfChildren,
      searchNumberOfGuests: searchDataReduce.numberOfAdults + searchDataReduce.numberOfChildren,
      searchNumberOfNights: multiSearchParams.numberOfNights,
      searchNumberOfRooms: multiSearchParams === null || multiSearchParams === void 0 || (_multiSearchParams$ro2 = multiSearchParams.rooms) === null || _multiSearchParams$ro2 === void 0 ? void 0 : _multiSearchParams$ro2.length,
      searchResults,
      searchResultsDisplayed: viewType === LIST_VIEW ? getSearchResults(searchResultsDisplayed, labelsConstants) : [],
      searchRoomType: multiSearchParams === null || multiSearchParams === void 0 ? void 0 : multiSearchParams.rooms.map(room => MAPPED_ROOM_TYPES[room.type]).join(':'),
      searchSort: multiSearchParams.sort.toLowerCase(),
      searchTerm: multiSearchParams.location.toLowerCase(),
      searchType: viewType,
      searchWeekdayFrom,
      searchWeekdayFromTo: "".concat(searchWeekdayFrom, "-").concat(searchWeekdayTo),
      searchWeekdayTo
    }
  });
};
function getSearchResults(searchResultsDisplayed, labelsConstants) {
  var {
    AVAILABLE_HOTEL,
    UNAVAILABLE_HOTEL
  } = labelsConstants;
  var hotelInformation = searchResultsDisplayed.map(hotel => {
    var _hotel$hotelAvailabil, _hotel$hotelAvailabil2, _hotel$hotelAvailabil3, _hotel$hotelAvailabil4, _hotel$hotelAvailabil5, _hotel$hotelInformati, _hotel$hotelInformati2, _hotel$hotelAvailabil6, _hotel$hotelAvailabil7;
    return {
      hotelResultPosition: searchResultsDisplayed.indexOf(hotel) + 1,
      hotelAvailability: hotel !== null && hotel !== void 0 && (_hotel$hotelAvailabil = hotel.hotelAvailability) !== null && _hotel$hotelAvailabil !== void 0 && _hotel$hotelAvailabil.available ? AVAILABLE_HOTEL : UNAVAILABLE_HOTEL,
      hotelCode: hotel.hotelId.toLowerCase(),
      bookingSystem: (0, _formatters.upperOnlyFirst)((_hotel$hotelAvailabil2 = hotel === null || hotel === void 0 || (_hotel$hotelAvailabil3 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil3 === void 0 ? void 0 : _hotel$hotelAvailabil3.pmsSource) !== null && _hotel$hotelAvailabil2 !== void 0 ? _hotel$hotelAvailabil2 : ''),
      hotelDistance: (_hotel$hotelAvailabil4 = hotel === null || hotel === void 0 || (_hotel$hotelAvailabil5 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil5 === void 0 ? void 0 : _hotel$hotelAvailabil5.distance) !== null && _hotel$hotelAvailabil4 !== void 0 ? _hotel$hotelAvailabil4 : 0,
      hotelFacilityIcons: ((_hotel$hotelInformati = hotel === null || hotel === void 0 || (_hotel$hotelInformati2 = hotel.hotelInformation) === null || _hotel$hotelInformati2 === void 0 ? void 0 : _hotel$hotelInformati2.hotelFacilities) !== null && _hotel$hotelInformati !== void 0 ? _hotel$hotelInformati : []).map(facility => facility.icon),
      hotelLabel: getHotelLabel(hotel, labelsConstants),
      hotelRates: [{
        cellCode: '',
        currencyCode: "".concat(hotel === null || hotel === void 0 || (_hotel$hotelAvailabil6 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil6 === void 0 || (_hotel$hotelAvailabil6 = _hotel$hotelAvailabil6.lowestRoomRate) === null || _hotel$hotelAvailabil6 === void 0 ? void 0 : _hotel$hotelAvailabil6.currencyCode),
        description: '',
        lettingType: '',
        price: "".concat(hotel === null || hotel === void 0 || (_hotel$hotelAvailabil7 = hotel.hotelAvailability) === null || _hotel$hotelAvailabil7 === void 0 || (_hotel$hotelAvailabil7 = _hotel$hotelAvailabil7.lowestRoomRate) === null || _hotel$hotelAvailabil7 === void 0 ? void 0 : _hotel$hotelAvailabil7.netTotal.toFixed(2)),
        rateCode: '',
        text: ''
      }]
    };
  });
  return hotelInformation;
}
function getHotelLabel(hotel, labelsConstants) {
  var _hotel$hotelAvailabil8, _hotel$hotelInformati3;
  var {
    SOLD_OUT_CONSTANT,
    LAST_FEW_ROOMS_CONSTANT,
    OPEN_SOON_CONSTANT,
    NO_LABEL_CONSTANT
  } = labelsConstants;
  var {
    available,
    limitedAvailability
  } = (_hotel$hotelAvailabil8 = hotel === null || hotel === void 0 ? void 0 : hotel.hotelAvailability) !== null && _hotel$hotelAvailabil8 !== void 0 ? _hotel$hotelAvailabil8 : {};
  if (available === false) {
    return SOLD_OUT_CONSTANT;
  } else if (limitedAvailability === true) {
    return LAST_FEW_ROOMS_CONSTANT;
  } else if ((hotel === null || hotel === void 0 || (_hotel$hotelInformati3 = hotel.hotelInformation) === null || _hotel$hotelInformati3 === void 0 ? void 0 : _hotel$hotelInformati3.hotelOpeningDate) !== '') {
    return OPEN_SOON_CONSTANT;
  } else {
    return NO_LABEL_CONSTANT;
  }
}
var _default = exports.default = updateSearchResultsAnalytics;