"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PromoCode;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PromoCode(_ref) {
  var {
    roomRates
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var router = (0, _router.useRouter)();
  var {
    promoCode
  } = router.query;
  var {
    [_api.FT_PI_BREAKFAST_PROMO_CODE]: isBreakfastPromoCodeEnabled
  } = (0, _utils.useFeatureToggle)();
  if (!isBreakfastPromoCodeEnabled) {
    return null;
  }
  var breakfastPromoCode = t('config.experiments.breakfastPromoCode.code');
  var enabled = t('config.experiments.breakfastPromoCode.isAvailable') === 'true';
  var availableText = t('config.experiments.breakfastPromoCode.available');
  var notAvailableText = t('config.experiments.breakfastPromoCode.notAvailable');
  var selectionInvalidText = t('config.experiments.breakfastPromoCode.selectionInvalid');
  var hasValidCookie = (0, _utils.getCookie)(_api.PROMO_CODE_COOKIE) === breakfastPromoCode;
  var isQueryParamValid = promoCode === breakfastPromoCode;
  if (promoCode && isQueryParamValid || !promoCode && hasValidCookie) {
    if (enabled) {
      (0, _utils.setCookie)(_api.PROMO_CODE_COOKIE, breakfastPromoCode, 7 * 24 * 60);
    } else {
      (0, _utils.setCookie)(_api.PROMO_CODE_COOKIE, null, -1);
    }
    var styles = enabled ? available : notAvailable;
    var text = enabled ? availableText : notAvailableText;
    if (enabled && Array.isArray(roomRates)) {
      var hasPromoRate = roomRates.some(roomRate => roomRate.promotionCode === breakfastPromoCode);
      if (!hasPromoRate) {
        styles = notAvailable;
        text = selectionInvalidText;
      }
    }
    return (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
      "data-testid": "HeaderBreakfastPromoCode"
    }, headerBreakfast), styles), {}, {
      children: text
    }));
  }
  return null;
}
var headerBreakfast = {
  align: 'center',
  padding: '0.125rem',
  fontWeight: 'bold',
  fontSize: '1rem'
};
var available = {
  background: 'var(--chakra-colors-promoCodeAvailable)'
};
var notAvailable = {
  background: 'var(--chakra-colors-promoCodeNotAvailable)',
  color: 'var(--chakra-colors-baseWhite)'
};