"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = InfoModal;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _react = require("@chakra-ui/react");
var _icons = require("../../../../assets/icons");
var _Icon = _interopRequireDefault(require("../../../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
var _excluded = ["onClose", "isOpen", "closeOnOverlayClick", "children", "variantProps", "dataTestId"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function InfoModal(_ref) {
  var {
      onClose,
      isOpen,
      closeOnOverlayClick = true,
      children,
      variantProps,
      dataTestId
    } = _ref,
    otherProps = (0, _objectWithoutProperties2.default)(_ref, _excluded);
  var prefix = dataTestId ? "".concat(dataTestId, "-") : '';
  return (0, _jsxRuntime.jsxs)(_react.Modal, _objectSpread(_objectSpread({
    "data-testid": "".concat(prefix, "ChakraModal"),
    scrollBehavior: "inside",
    isCentered: true,
    isOpen: isOpen,
    onClose: onClose,
    closeOnOverlayClick: closeOnOverlayClick
  }, otherProps), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {}), (0, _jsxRuntime.jsxs)(_react.ModalContent, _objectSpread(_objectSpread({
      "data-testid": "".concat(prefix, "ModalContent")
    }, modalContentStyle), {}, {
      width: otherProps.size ? 'auto' : {
        mobile: 'full',
        sm: 'auto'
      },
      children: [(0, _jsxRuntime.jsx)(_react.ModalHeader, {
        p: "lg",
        "data-testid": "".concat(prefix, "ModalHeader"),
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          children: [(0, _jsxRuntime.jsxs)(_react.Grid, _objectSpread(_objectSpread({}, headerContentStyle), {}, {
            children: [variantProps && (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, headerTitleStyle), {}, {
              "data-testid": "".concat(prefix, "ModalTitle"),
              children: [variantProps.headerIcon && (0, _jsxRuntime.jsx)(_Icon.default, _objectSpread({
                svg: variantProps.headerIcon
              }, variantProps.externalHeaderContentStyle)), variantProps.title]
            })), (0, _jsxRuntime.jsx)(_react.ModalCloseButton, _objectSpread(_objectSpread({
              "data-testid": "".concat(prefix, "ModalCloseButton")
            }, modalCloseButtonStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_Icon.default, {
                svg: (0, _jsxRuntime.jsx)(_icons.Dismiss, {
                  transform: "scale(1.1)"
                })
              })
            }))]
          })), (variantProps === null || variantProps === void 0 ? void 0 : variantProps.delimiter) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({
            "data-testid": "".concat(prefix, "Delimiter")
          }, delimiterModalStyle))]
        })
      }), (0, _jsxRuntime.jsx)(_react.ModalBody, _objectSpread(_objectSpread({
        "data-testid": "".concat(prefix, "ModalBody")
      }, modalBodyStyle), {}, {
        children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
          overflowX: "hidden"
        }, modalBodyBoxStyle), {}, {
          children: children
        }))
      }))]
    }))]
  }));
}
var modalBodyStyle = {
  overflow: {
    mobile: 'hidden',
    sm: 'auto'
  },
  p: 0
};
var modalBodyBoxStyle = {
  maxHeight: {
    mobile: 'calc(96vh - 4rem)',
    sm: '66vh'
  }
};
var modalContentStyle = {
  maxW: 'auto',
  maxH: 'auto',
  overflow: 'auto',
  height: {
    mobile: 'full',
    sm: 'fit-content'
  },
  my: 0,
  borderRadius: {
    mobile: '0',
    sm: '0.875rem'
  },
  boxShadow: '0 0.125rem 0.75rem var(--chakra-colors-darkGrey2)',
  px: '0'
};
var headerContentStyle = {
  my: 'md',
  mx: 'sm',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center'
};
var delimiterModalStyle = {
  w: 'full',
  h: '0.063rem',
  bgColor: 'lightGrey4'
};
var headerTitleStyle = {
  color: 'darkGrey1',
  justifyContent: 'flex-start',
  fontSize: 'lg',
  lineHeight: '3',
  fontFamily: 'header',
  ml: 'sm',
  padding: 0
};
var modalCloseButtonStyle = {
  h: 'var(--chakra-space-xl)',
  w: 'var(--chakra-space-xl)',
  ml: 'auto',
  position: 'static',
  _focus: {
    boxShadow: 'none'
  },
  _hover: {
    bgColor: 'transparent'
  },
  _active: {
    bgColor: 'transparent'
  }
};