"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AnalyticsProvider", {
  enumerable: true,
  get: function get() {
    return _AnalyticsProviderContainer.default;
  }
});
Object.defineProperty(exports, "AnalyticsProviderCCUI", {
  enumerable: true,
  get: function get() {
    return _AnalyticsProviderCCUI.default;
  }
});
Object.defineProperty(exports, "analytics", {
  enumerable: true,
  get: function get() {
    return _analytics.default;
  }
});
Object.defineProperty(exports, "analyticsConfirmation", {
  enumerable: true,
  get: function get() {
    return _analytics.analyticsConfirmation;
  }
});
Object.defineProperty(exports, "analyticsConfirmationPageName", {
  enumerable: true,
  get: function get() {
    return _confirmationPageAnalytics.analyticsConfirmationPageName;
  }
});
Object.defineProperty(exports, "analyticsTrackings", {
  enumerable: true,
  get: function get() {
    return _analytics.analyticsTrackings;
  }
});
Object.defineProperty(exports, "initConfirmationAnalytics", {
  enumerable: true,
  get: function get() {
    return _confirmationPageAnalytics.default;
  }
});
Object.defineProperty(exports, "setAnalyticsPageNameAndType", {
  enumerable: true,
  get: function get() {
    return _setAnalyticsPageNameAndType.default;
  }
});
Object.defineProperty(exports, "setAnalyticsUser", {
  enumerable: true,
  get: function get() {
    return _setAnalyticsUser.default;
  }
});
Object.defineProperty(exports, "timeTrackerSeconds", {
  enumerable: true,
  get: function get() {
    return _analytics.timeTrackerSeconds;
  }
});
Object.defineProperty(exports, "updateAmendPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _amendPageAnalytics.default;
  }
});
Object.defineProperty(exports, "updateAncillariesAnalytics", {
  enumerable: true,
  get: function get() {
    return _ancillariesAnalytics.default;
  }
});
Object.defineProperty(exports, "updateConfirmationAnalytics", {
  enumerable: true,
  get: function get() {
    return _confirmationPageAnalytics.updateConfirmationAnalytics;
  }
});
Object.defineProperty(exports, "updateConfirmationPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _confirmationPageAnalytics.updateConfirmationPageAnalytics;
  }
});
Object.defineProperty(exports, "updateDashboardAnalytics", {
  enumerable: true,
  get: function get() {
    return _dashboardAnalytics.default;
  }
});
Object.defineProperty(exports, "updateHotelDisplayPageAnalytics", {
  enumerable: true,
  get: function get() {
    return _hotelDisplayPageAnalytics.default;
  }
});
Object.defineProperty(exports, "updateSearchResultsAnalytics", {
  enumerable: true,
  get: function get() {
    return _searchResultsAnalytics.default;
  }
});
var _AnalyticsProviderCCUI = _interopRequireDefault(require("./AnalyticsProviderCCUI"));
var _AnalyticsProviderContainer = _interopRequireDefault(require("./AnalyticsProviderContainer"));
var _amendPageAnalytics = _interopRequireDefault(require("./amendPageAnalytics"));
var _analytics = _interopRequireWildcard(require("./analytics"));
var _ancillariesAnalytics = _interopRequireDefault(require("./ancillariesAnalytics"));
var _confirmationPageAnalytics = _interopRequireWildcard(require("./confirmationPageAnalytics"));
var _dashboardAnalytics = _interopRequireDefault(require("./dashboardAnalytics"));
var _hotelDisplayPageAnalytics = _interopRequireDefault(require("./hotelDisplayPageAnalytics"));
var _searchResultsAnalytics = _interopRequireDefault(require("./searchResultsAnalytics"));
var _setAnalyticsPageNameAndType = _interopRequireDefault(require("./setAnalyticsPageNameAndType"));
var _setAnalyticsUser = _interopRequireDefault(require("./setAnalyticsUser"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }