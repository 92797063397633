"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelParkingComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelParkingComponent(_ref) {
  var {
    content
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    mt: "md",
    sx: {
      '@media print': {
        width: '20.8125rem',
        height: '12.0625rem'
      }
    },
    children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
      svg: (0, _jsxRuntime.jsx)(_atoms.Tick24, {
        color: "var(--chakra-colors-darkGrey2)"
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, contentStyles), {}, {
      dangerouslySetInnerHTML: {
        __html: (0, _utils.formatInnerHTMLAssetUrls)(content)
      },
      className: "formatLinks",
      "data-testid": "HotelDetails-ParkingInformation"
    }))]
  });
}
var contentStyles = {
  fontSize: 'sm',
  fontWeight: 'medium',
  lineHeight: '2',
  color: 'darkGrey2',
  ml: 'sm'
};