"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Accessible", {
  enumerable: true,
  get: function get() {
    return _Accessible3.default;
  }
});
Object.defineProperty(exports, "Accessible24", {
  enumerable: true,
  get: function get() {
    return _Accessible.default;
  }
});
Object.defineProperty(exports, "Accessible32", {
  enumerable: true,
  get: function get() {
    return _Accessible2.default;
  }
});
Object.defineProperty(exports, "AirConditioning", {
  enumerable: true,
  get: function get() {
    return _AirConditioning2.default;
  }
});
Object.defineProperty(exports, "AirConditioning32", {
  enumerable: true,
  get: function get() {
    return _AirConditioning.default;
  }
});
Object.defineProperty(exports, "Alert", {
  enumerable: true,
  get: function get() {
    return _Alert.default;
  }
});
Object.defineProperty(exports, "AmazonLocker", {
  enumerable: true,
  get: function get() {
    return _AmazonLocker.default;
  }
});
Object.defineProperty(exports, "ArrowDown", {
  enumerable: true,
  get: function get() {
    return _ArrowDown.default;
  }
});
Object.defineProperty(exports, "ArrowLeft", {
  enumerable: true,
  get: function get() {
    return _ArrowLeft.default;
  }
});
Object.defineProperty(exports, "ArrowRight", {
  enumerable: true,
  get: function get() {
    return _ArrowRight.default;
  }
});
Object.defineProperty(exports, "ArrowUp", {
  enumerable: true,
  get: function get() {
    return _ArrowUp.default;
  }
});
Object.defineProperty(exports, "BedsideUsbCharging", {
  enumerable: true,
  get: function get() {
    return _BedsideUsbCharging2.default;
  }
});
Object.defineProperty(exports, "BedsideUsbCharging32", {
  enumerable: true,
  get: function get() {
    return _BedsideUsbCharging.default;
  }
});
Object.defineProperty(exports, "Bell", {
  enumerable: true,
  get: function get() {
    return _Bell.default;
  }
});
Object.defineProperty(exports, "Bicycle", {
  enumerable: true,
  get: function get() {
    return _Bicycle.default;
  }
});
Object.defineProperty(exports, "BritishFlagBlackAndWhite", {
  enumerable: true,
  get: function get() {
    return _BritishFlagBlackAndWhite.default;
  }
});
Object.defineProperty(exports, "BritishFlagRegular", {
  enumerable: true,
  get: function get() {
    return _BritishFlagRegular.default;
  }
});
Object.defineProperty(exports, "BritishFlagRounded", {
  enumerable: true,
  get: function get() {
    return _BritishFlagRounded.default;
  }
});
Object.defineProperty(exports, "BurgerMenu", {
  enumerable: true,
  get: function get() {
    return _BurgerMenu.default;
  }
});
Object.defineProperty(exports, "Calendar", {
  enumerable: true,
  get: function get() {
    return _Calendar.default;
  }
});
Object.defineProperty(exports, "ChargedParkingEu", {
  enumerable: true,
  get: function get() {
    return _ChargedParkingEu.default;
  }
});
Object.defineProperty(exports, "ChargedParkingGb", {
  enumerable: true,
  get: function get() {
    return _ChargedParkingGb.default;
  }
});
Object.defineProperty(exports, "CheckIn", {
  enumerable: true,
  get: function get() {
    return _CheckIn.default;
  }
});
Object.defineProperty(exports, "CheckOut", {
  enumerable: true,
  get: function get() {
    return _CheckOut.default;
  }
});
Object.defineProperty(exports, "ChevronDown", {
  enumerable: true,
  get: function get() {
    return _ChevronDown2.default;
  }
});
Object.defineProperty(exports, "ChevronDown24", {
  enumerable: true,
  get: function get() {
    return _ChevronDown.default;
  }
});
Object.defineProperty(exports, "ChevronLeft", {
  enumerable: true,
  get: function get() {
    return _ChevronLeft2.default;
  }
});
Object.defineProperty(exports, "ChevronLeft24", {
  enumerable: true,
  get: function get() {
    return _ChevronLeft.default;
  }
});
Object.defineProperty(exports, "ChevronRight", {
  enumerable: true,
  get: function get() {
    return _ChevronRight.default;
  }
});
Object.defineProperty(exports, "ChevronUp", {
  enumerable: true,
  get: function get() {
    return _ChevronUp2.default;
  }
});
Object.defineProperty(exports, "ChevronUp24", {
  enumerable: true,
  get: function get() {
    return _ChevronUp.default;
  }
});
Object.defineProperty(exports, "ChoiceOfPillows", {
  enumerable: true,
  get: function get() {
    return _ChoiceOfPillows.default;
  }
});
Object.defineProperty(exports, "ComfortableArmchair", {
  enumerable: true,
  get: function get() {
    return _ComfortableArmchair2.default;
  }
});
Object.defineProperty(exports, "ComfortableArmchair32", {
  enumerable: true,
  get: function get() {
    return _ComfortableArmchair.default;
  }
});
Object.defineProperty(exports, "CostaCoffee", {
  enumerable: true,
  get: function get() {
    return _CostaCoffee.default;
  }
});
Object.defineProperty(exports, "Desk", {
  enumerable: true,
  get: function get() {
    return _Desk2.default;
  }
});
Object.defineProperty(exports, "Desk32", {
  enumerable: true,
  get: function get() {
    return _Desk.default;
  }
});
Object.defineProperty(exports, "Dismiss", {
  enumerable: true,
  get: function get() {
    return _Dismiss.default;
  }
});
Object.defineProperty(exports, "DismissDarkGrey", {
  enumerable: true,
  get: function get() {
    return _DismissDarkGrey.default;
  }
});
Object.defineProperty(exports, "DismissGrey", {
  enumerable: true,
  get: function get() {
    return _DismissGrey.default;
  }
});
Object.defineProperty(exports, "DoubleBed", {
  enumerable: true,
  get: function get() {
    return _DoubleBed2.default;
  }
});
Object.defineProperty(exports, "DoubleBed32", {
  enumerable: true,
  get: function get() {
    return _DoubleBed.default;
  }
});
Object.defineProperty(exports, "ElectricVehicleCharging", {
  enumerable: true,
  get: function get() {
    return _ElectricVehicleCharging.default;
  }
});
Object.defineProperty(exports, "Error", {
  enumerable: true,
  get: function get() {
    return _Error.default;
  }
});
Object.defineProperty(exports, "Error24", {
  enumerable: true,
  get: function get() {
    return _Error2.default;
  }
});
Object.defineProperty(exports, "Expand", {
  enumerable: true,
  get: function get() {
    return _Expand.default;
  }
});
Object.defineProperty(exports, "FamilyRoom", {
  enumerable: true,
  get: function get() {
    return _FamilyRoom.default;
  }
});
Object.defineProperty(exports, "FbLogo24", {
  enumerable: true,
  get: function get() {
    return _FbLogo.default;
  }
});
Object.defineProperty(exports, "FbLogo24WhiteBg", {
  enumerable: true,
  get: function get() {
    return _FbLogo24WhiteBg.default;
  }
});
Object.defineProperty(exports, "FbLogo32", {
  enumerable: true,
  get: function get() {
    return _FbLogo2.default;
  }
});
Object.defineProperty(exports, "FbLogo48", {
  enumerable: true,
  get: function get() {
    return _FbLogo3.default;
  }
});
Object.defineProperty(exports, "FbLogo48WhiteBg", {
  enumerable: true,
  get: function get() {
    return _FbLogo48WhiteBg.default;
  }
});
Object.defineProperty(exports, "FbLogoMini", {
  enumerable: true,
  get: function get() {
    return _FbLogoMini.default;
  }
});
Object.defineProperty(exports, "FreeWifi", {
  enumerable: true,
  get: function get() {
    return _FreeWifi2.default;
  }
});
Object.defineProperty(exports, "FreeWifi32", {
  enumerable: true,
  get: function get() {
    return _FreeWifi.default;
  }
});
Object.defineProperty(exports, "FreeviewTv", {
  enumerable: true,
  get: function get() {
    return _FreeviewTv.default;
  }
});
Object.defineProperty(exports, "FrenchFlagBlackAndWhite", {
  enumerable: true,
  get: function get() {
    return _FrenchFlagBlackAndWhite.default;
  }
});
Object.defineProperty(exports, "FrenchFlagRegular", {
  enumerable: true,
  get: function get() {
    return _FrenchFlagRegular.default;
  }
});
Object.defineProperty(exports, "FrenchFlagRounded", {
  enumerable: true,
  get: function get() {
    return _FrenchFlagRounded.default;
  }
});
Object.defineProperty(exports, "GermanFlagBlackAndWhite", {
  enumerable: true,
  get: function get() {
    return _GermanFlagBlackAndWhite.default;
  }
});
Object.defineProperty(exports, "GermanFlagRegular", {
  enumerable: true,
  get: function get() {
    return _GermanFlagRegular.default;
  }
});
Object.defineProperty(exports, "GermanFlagRounded", {
  enumerable: true,
  get: function get() {
    return _GermanFlagRounded.default;
  }
});
Object.defineProperty(exports, "HairDryer", {
  enumerable: true,
  get: function get() {
    return _HairDryer.default;
  }
});
Object.defineProperty(exports, "HandheldShower", {
  enumerable: true,
  get: function get() {
    return _HandheldShower.default;
  }
});
Object.defineProperty(exports, "ImprovedDesk", {
  enumerable: true,
  get: function get() {
    return _ImprovedDesk2.default;
  }
});
Object.defineProperty(exports, "ImprovedDesk32", {
  enumerable: true,
  get: function get() {
    return _ImprovedDesk.default;
  }
});
Object.defineProperty(exports, "ImprovedTeaAndCoffee", {
  enumerable: true,
  get: function get() {
    return _ImprovedTeaAndCoffee2.default;
  }
});
Object.defineProperty(exports, "ImprovedTeaAndCoffee32", {
  enumerable: true,
  get: function get() {
    return _ImprovedTeaAndCoffee.default;
  }
});
Object.defineProperty(exports, "InRoomApp", {
  enumerable: true,
  get: function get() {
    return _InRoomApp.default;
  }
});
Object.defineProperty(exports, "Info", {
  enumerable: true,
  get: function get() {
    return _Info2.default;
  }
});
Object.defineProperty(exports, "Info24", {
  enumerable: true,
  get: function get() {
    return _Info.default;
  }
});
Object.defineProperty(exports, "InstagramLogo24", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo.default;
  }
});
Object.defineProperty(exports, "InstagramLogo24WhiteBg", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo24WhiteBg.default;
  }
});
Object.defineProperty(exports, "InstagramLogo32", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo2.default;
  }
});
Object.defineProperty(exports, "InstagramLogo32WhiteBg", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo32WhiteBg.default;
  }
});
Object.defineProperty(exports, "InstagramLogo48", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo3.default;
  }
});
Object.defineProperty(exports, "InstagramLogo48WhiteBg", {
  enumerable: true,
  get: function get() {
    return _InstagramLogo48WhiteBg.default;
  }
});
Object.defineProperty(exports, "InstagramLogoMini", {
  enumerable: true,
  get: function get() {
    return _InstagramLogoMini.default;
  }
});
Object.defineProperty(exports, "InterconnectingRooms", {
  enumerable: true,
  get: function get() {
    return _InterconnectingRooms.default;
  }
});
Object.defineProperty(exports, "Iron", {
  enumerable: true,
  get: function get() {
    return _Iron2.default;
  }
});
Object.defineProperty(exports, "Iron32", {
  enumerable: true,
  get: function get() {
    return _Iron.default;
  }
});
Object.defineProperty(exports, "ItalianFlagBlackAndWhite", {
  enumerable: true,
  get: function get() {
    return _ItalianFlagBlackAndWhite.default;
  }
});
Object.defineProperty(exports, "ItalianFlagRegular", {
  enumerable: true,
  get: function get() {
    return _ItalianFlagRegular.default;
  }
});
Object.defineProperty(exports, "ItalianFlagRounded", {
  enumerable: true,
  get: function get() {
    return _ItalianFlagRounded.default;
  }
});
Object.defineProperty(exports, "KingBed", {
  enumerable: true,
  get: function get() {
    return _KingBed2.default;
  }
});
Object.defineProperty(exports, "KingBed32", {
  enumerable: true,
  get: function get() {
    return _KingBed.default;
  }
});
Object.defineProperty(exports, "LanguageSelector", {
  enumerable: true,
  get: function get() {
    return _LanguageSelector.default;
  }
});
Object.defineProperty(exports, "Laundry", {
  enumerable: true,
  get: function get() {
    return _Laundry.default;
  }
});
Object.defineProperty(exports, "LiftAccess", {
  enumerable: true,
  get: function get() {
    return _LiftAccess.default;
  }
});
Object.defineProperty(exports, "Line", {
  enumerable: true,
  get: function get() {
    return _Line.default;
  }
});
Object.defineProperty(exports, "Location", {
  enumerable: true,
  get: function get() {
    return _Location2.default;
  }
});
Object.defineProperty(exports, "Location24", {
  enumerable: true,
  get: function get() {
    return _Location.default;
  }
});
Object.defineProperty(exports, "LogoHub", {
  enumerable: true,
  get: function get() {
    return _LogoHub.default;
  }
});
Object.defineProperty(exports, "LogoHubSimple", {
  enumerable: true,
  get: function get() {
    return _LogoHubSimple.default;
  }
});
Object.defineProperty(exports, "LogoPi", {
  enumerable: true,
  get: function get() {
    return _LogoPi.default;
  }
});
Object.defineProperty(exports, "LogoPiIcon", {
  enumerable: true,
  get: function get() {
    return _LogoPiIcon.default;
  }
});
Object.defineProperty(exports, "LogoPiSimple", {
  enumerable: true,
  get: function get() {
    return _LogoPiSimple.default;
  }
});
Object.defineProperty(exports, "LogoZip", {
  enumerable: true,
  get: function get() {
    return _LogoZip.default;
  }
});
Object.defineProperty(exports, "LogoZipSimple", {
  enumerable: true,
  get: function get() {
    return _LogoZipSimple.default;
  }
});
Object.defineProperty(exports, "LuggageStorage", {
  enumerable: true,
  get: function get() {
    return _LuggageStorage.default;
  }
});
Object.defineProperty(exports, "MapCompress", {
  enumerable: true,
  get: function get() {
    return _MapCompress.default;
  }
});
Object.defineProperty(exports, "MapExpand", {
  enumerable: true,
  get: function get() {
    return _MapExpand.default;
  }
});
Object.defineProperty(exports, "MastercardLogo", {
  enumerable: true,
  get: function get() {
    return _MastercardLogo.default;
  }
});
Object.defineProperty(exports, "MastercardLogoBlackAndWhite", {
  enumerable: true,
  get: function get() {
    return _MastercardLogoBlackAndWhite.default;
  }
});
Object.defineProperty(exports, "MeetingRooms", {
  enumerable: true,
  get: function get() {
    return _MeetingRooms.default;
  }
});
Object.defineProperty(exports, "MiniFridge", {
  enumerable: true,
  get: function get() {
    return _MiniFridge.default;
  }
});
Object.defineProperty(exports, "ModernRoom", {
  enumerable: true,
  get: function get() {
    return _ModernRoom2.default;
  }
});
Object.defineProperty(exports, "ModernRoom32", {
  enumerable: true,
  get: function get() {
    return _ModernRoom.default;
  }
});
Object.defineProperty(exports, "MyBookings", {
  enumerable: true,
  get: function get() {
    return _MyBookings.default;
  }
});
Object.defineProperty(exports, "NespressoMachine", {
  enumerable: true,
  get: function get() {
    return _NespressoMachine2.default;
  }
});
Object.defineProperty(exports, "NespressoMachine32", {
  enumerable: true,
  get: function get() {
    return _NespressoMachine.default;
  }
});
Object.defineProperty(exports, "NoOfNights", {
  enumerable: true,
  get: function get() {
    return _NoOfNights.default;
  }
});
Object.defineProperty(exports, "OneAdult", {
  enumerable: true,
  get: function get() {
    return _OneAdult.default;
  }
});
Object.defineProperty(exports, "OverheadStorage", {
  enumerable: true,
  get: function get() {
    return _OverheadStorage.default;
  }
});
Object.defineProperty(exports, "Parking", {
  enumerable: true,
  get: function get() {
    return _Parking.default;
  }
});
Object.defineProperty(exports, "Path", {
  enumerable: true,
  get: function get() {
    return _Path.default;
  }
});
Object.defineProperty(exports, "PaymentCard", {
  enumerable: true,
  get: function get() {
    return _PaymentCard.default;
  }
});
Object.defineProperty(exports, "PlayArea", {
  enumerable: true,
  get: function get() {
    return _PlayArea.default;
  }
});
Object.defineProperty(exports, "Pointer", {
  enumerable: true,
  get: function get() {
    return _Pointer.default;
  }
});
Object.defineProperty(exports, "PremierInnLogo", {
  enumerable: true,
  get: function get() {
    return _PremierInnLogo.default;
  }
});
Object.defineProperty(exports, "Printer", {
  enumerable: true,
  get: function get() {
    return _Printer.default;
  }
});
Object.defineProperty(exports, "RainfallShower", {
  enumerable: true,
  get: function get() {
    return _RainfallShower.default;
  }
});
Object.defineProperty(exports, "Rectangle", {
  enumerable: true,
  get: function get() {
    return _Rectangle.default;
  }
});
Object.defineProperty(exports, "Restaurant", {
  enumerable: true,
  get: function get() {
    return _Restaurant2.default;
  }
});
Object.defineProperty(exports, "Restaurant24", {
  enumerable: true,
  get: function get() {
    return _Restaurant.default;
  }
});
Object.defineProperty(exports, "RoomControls", {
  enumerable: true,
  get: function get() {
    return _RoomControls.default;
  }
});
Object.defineProperty(exports, "SearchIcon", {
  enumerable: true,
  get: function get() {
    return _SearchIcon.default;
  }
});
Object.defineProperty(exports, "Security", {
  enumerable: true,
  get: function get() {
    return _Security2.default;
  }
});
Object.defineProperty(exports, "Security16", {
  enumerable: true,
  get: function get() {
    return _Security.default;
  }
});
Object.defineProperty(exports, "SeeAll", {
  enumerable: true,
  get: function get() {
    return _SeeAll.default;
  }
});
Object.defineProperty(exports, "SelfCheckInKiosks", {
  enumerable: true,
  get: function get() {
    return _SelfCheckInKiosks.default;
  }
});
Object.defineProperty(exports, "SingleBed", {
  enumerable: true,
  get: function get() {
    return _SingleBed.default;
  }
});
Object.defineProperty(exports, "SmartTv40Inch", {
  enumerable: true,
  get: function get() {
    return _SmartTv40Inch.default;
  }
});
Object.defineProperty(exports, "Success", {
  enumerable: true,
  get: function get() {
    return _Success.default;
  }
});
Object.defineProperty(exports, "Success24", {
  enumerable: true,
  get: function get() {
    return _Success2.default;
  }
});
Object.defineProperty(exports, "TeaAndCoffee", {
  enumerable: true,
  get: function get() {
    return _TeaAndCoffee2.default;
  }
});
Object.defineProperty(exports, "TeaAndCoffee32", {
  enumerable: true,
  get: function get() {
    return _TeaAndCoffee.default;
  }
});
Object.defineProperty(exports, "Tick", {
  enumerable: true,
  get: function get() {
    return _Tick2.default;
  }
});
Object.defineProperty(exports, "Tick24", {
  enumerable: true,
  get: function get() {
    return _Tick.default;
  }
});
Object.defineProperty(exports, "TwitterLogo24", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo.default;
  }
});
Object.defineProperty(exports, "TwitterLogo24WhiteBg", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo24WhiteBg.default;
  }
});
Object.defineProperty(exports, "TwitterLogo32", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo2.default;
  }
});
Object.defineProperty(exports, "TwitterLogo32WhiteBg", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo32WhiteBg.default;
  }
});
Object.defineProperty(exports, "TwitterLogo48", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo3.default;
  }
});
Object.defineProperty(exports, "TwitterLogo48WhiteBg", {
  enumerable: true,
  get: function get() {
    return _TwitterLogo48WhiteBg.default;
  }
});
Object.defineProperty(exports, "TwitterLogoMini", {
  enumerable: true,
  get: function get() {
    return _TwitterLogoMini.default;
  }
});
Object.defineProperty(exports, "TwoAdultsMax", {
  enumerable: true,
  get: function get() {
    return _TwoAdultsMax.default;
  }
});
Object.defineProperty(exports, "UltimateWifi", {
  enumerable: true,
  get: function get() {
    return _UltimateWifi2.default;
  }
});
Object.defineProperty(exports, "UltimateWifi32", {
  enumerable: true,
  get: function get() {
    return _UltimateWifi.default;
  }
});
Object.defineProperty(exports, "UnderBedStorage", {
  enumerable: true,
  get: function get() {
    return _UnderBedStorage.default;
  }
});
Object.defineProperty(exports, "VanityArea", {
  enumerable: true,
  get: function get() {
    return _VanityArea.default;
  }
});
Object.defineProperty(exports, "Vending", {
  enumerable: true,
  get: function get() {
    return _Vending.default;
  }
});
Object.defineProperty(exports, "VisaLogo", {
  enumerable: true,
  get: function get() {
    return _VisaLogo.default;
  }
});
Object.defineProperty(exports, "WhiteAlert", {
  enumerable: true,
  get: function get() {
    return _WhiteAlert.default;
  }
});
Object.defineProperty(exports, "WhiteError", {
  enumerable: true,
  get: function get() {
    return _WhiteError.default;
  }
});
Object.defineProperty(exports, "WhiteInfo", {
  enumerable: true,
  get: function get() {
    return _WhiteInfo.default;
  }
});
Object.defineProperty(exports, "WhiteSuccess", {
  enumerable: true,
  get: function get() {
    return _WhiteSuccess.default;
  }
});
var _Accessible = _interopRequireDefault(require("./Accessible24"));
var _Accessible2 = _interopRequireDefault(require("./Accessible32"));
var _Accessible3 = _interopRequireDefault(require("./Accessible"));
var _AirConditioning = _interopRequireDefault(require("./AirConditioning32"));
var _AirConditioning2 = _interopRequireDefault(require("./AirConditioning"));
var _Alert = _interopRequireDefault(require("./Alert"));
var _AmazonLocker = _interopRequireDefault(require("./AmazonLocker"));
var _ArrowDown = _interopRequireDefault(require("./ArrowDown"));
var _ArrowLeft = _interopRequireDefault(require("./ArrowLeft"));
var _ArrowRight = _interopRequireDefault(require("./ArrowRight"));
var _ArrowUp = _interopRequireDefault(require("./ArrowUp"));
var _BedsideUsbCharging = _interopRequireDefault(require("./BedsideUsbCharging32"));
var _BedsideUsbCharging2 = _interopRequireDefault(require("./BedsideUsbCharging"));
var _Bell = _interopRequireDefault(require("./Bell"));
var _Bicycle = _interopRequireDefault(require("./Bicycle"));
var _BritishFlagBlackAndWhite = _interopRequireDefault(require("./BritishFlagBlackAndWhite"));
var _BritishFlagRegular = _interopRequireDefault(require("./BritishFlagRegular"));
var _BritishFlagRounded = _interopRequireDefault(require("./BritishFlagRounded"));
var _BurgerMenu = _interopRequireDefault(require("./BurgerMenu"));
var _Calendar = _interopRequireDefault(require("./Calendar"));
var _ChargedParkingEu = _interopRequireDefault(require("./ChargedParkingEu"));
var _ChargedParkingGb = _interopRequireDefault(require("./ChargedParkingGb"));
var _CheckIn = _interopRequireDefault(require("./CheckIn"));
var _CheckOut = _interopRequireDefault(require("./CheckOut"));
var _ChevronDown = _interopRequireDefault(require("./ChevronDown24"));
var _ChevronDown2 = _interopRequireDefault(require("./ChevronDown"));
var _ChevronLeft = _interopRequireDefault(require("./ChevronLeft24"));
var _ChevronLeft2 = _interopRequireDefault(require("./ChevronLeft"));
var _ChevronRight = _interopRequireDefault(require("./ChevronRight"));
var _ChevronUp = _interopRequireDefault(require("./ChevronUp24"));
var _ChevronUp2 = _interopRequireDefault(require("./ChevronUp"));
var _ChoiceOfPillows = _interopRequireDefault(require("./ChoiceOfPillows"));
var _ComfortableArmchair = _interopRequireDefault(require("./ComfortableArmchair32"));
var _ComfortableArmchair2 = _interopRequireDefault(require("./ComfortableArmchair"));
var _CostaCoffee = _interopRequireDefault(require("./CostaCoffee"));
var _Desk = _interopRequireDefault(require("./Desk32"));
var _Desk2 = _interopRequireDefault(require("./Desk"));
var _DismissDarkGrey = _interopRequireDefault(require("./DismissDarkGrey"));
var _DismissGrey = _interopRequireDefault(require("./DismissGrey"));
var _Dismiss = _interopRequireDefault(require("./Dismiss"));
var _DoubleBed = _interopRequireDefault(require("./DoubleBed32"));
var _DoubleBed2 = _interopRequireDefault(require("./DoubleBed"));
var _ElectricVehicleCharging = _interopRequireDefault(require("./ElectricVehicleCharging"));
var _Error = _interopRequireDefault(require("./Error"));
var _Error2 = _interopRequireDefault(require("./Error24"));
var _Expand = _interopRequireDefault(require("./Expand"));
var _FamilyRoom = _interopRequireDefault(require("./FamilyRoom"));
var _FbLogo24WhiteBg = _interopRequireDefault(require("./FbLogo24WhiteBg"));
var _FbLogo = _interopRequireDefault(require("./FbLogo24"));
var _FbLogo2 = _interopRequireDefault(require("./FbLogo32"));
var _FbLogo48WhiteBg = _interopRequireDefault(require("./FbLogo48WhiteBg"));
var _FbLogo3 = _interopRequireDefault(require("./FbLogo48"));
var _FbLogoMini = _interopRequireDefault(require("./FbLogoMini"));
var _FreeWifi = _interopRequireDefault(require("./FreeWifi32"));
var _FreeWifi2 = _interopRequireDefault(require("./FreeWifi"));
var _FreeviewTv = _interopRequireDefault(require("./FreeviewTv"));
var _FrenchFlagBlackAndWhite = _interopRequireDefault(require("./FrenchFlagBlackAndWhite"));
var _FrenchFlagRegular = _interopRequireDefault(require("./FrenchFlagRegular"));
var _FrenchFlagRounded = _interopRequireDefault(require("./FrenchFlagRounded"));
var _GermanFlagBlackAndWhite = _interopRequireDefault(require("./GermanFlagBlackAndWhite"));
var _GermanFlagRegular = _interopRequireDefault(require("./GermanFlagRegular"));
var _GermanFlagRounded = _interopRequireDefault(require("./GermanFlagRounded"));
var _HairDryer = _interopRequireDefault(require("./HairDryer"));
var _HandheldShower = _interopRequireDefault(require("./HandheldShower"));
var _ImprovedDesk = _interopRequireDefault(require("./ImprovedDesk32"));
var _ImprovedDesk2 = _interopRequireDefault(require("./ImprovedDesk"));
var _ImprovedTeaAndCoffee = _interopRequireDefault(require("./ImprovedTeaAndCoffee32"));
var _ImprovedTeaAndCoffee2 = _interopRequireDefault(require("./ImprovedTeaAndCoffee"));
var _InRoomApp = _interopRequireDefault(require("./InRoomApp"));
var _Info = _interopRequireDefault(require("./Info24"));
var _Info2 = _interopRequireDefault(require("./Info"));
var _InstagramLogo24WhiteBg = _interopRequireDefault(require("./InstagramLogo24WhiteBg"));
var _InstagramLogo = _interopRequireDefault(require("./InstagramLogo24"));
var _InstagramLogo32WhiteBg = _interopRequireDefault(require("./InstagramLogo32WhiteBg"));
var _InstagramLogo2 = _interopRequireDefault(require("./InstagramLogo32"));
var _InstagramLogo48WhiteBg = _interopRequireDefault(require("./InstagramLogo48WhiteBg"));
var _InstagramLogo3 = _interopRequireDefault(require("./InstagramLogo48"));
var _InstagramLogoMini = _interopRequireDefault(require("./InstagramLogoMini"));
var _InterconnectingRooms = _interopRequireDefault(require("./InterconnectingRooms"));
var _Iron = _interopRequireDefault(require("./Iron32"));
var _Iron2 = _interopRequireDefault(require("./Iron"));
var _ItalianFlagBlackAndWhite = _interopRequireDefault(require("./ItalianFlagBlackAndWhite"));
var _ItalianFlagRegular = _interopRequireDefault(require("./ItalianFlagRegular"));
var _ItalianFlagRounded = _interopRequireDefault(require("./ItalianFlagRounded"));
var _KingBed = _interopRequireDefault(require("./KingBed32"));
var _KingBed2 = _interopRequireDefault(require("./KingBed"));
var _LanguageSelector = _interopRequireDefault(require("./LanguageSelector"));
var _Laundry = _interopRequireDefault(require("./Laundry"));
var _LiftAccess = _interopRequireDefault(require("./LiftAccess"));
var _Line = _interopRequireDefault(require("./Line"));
var _Location = _interopRequireDefault(require("./Location24"));
var _Location2 = _interopRequireDefault(require("./Location"));
var _LogoHubSimple = _interopRequireDefault(require("./LogoHubSimple"));
var _LogoHub = _interopRequireDefault(require("./LogoHub"));
var _LogoPiIcon = _interopRequireDefault(require("./LogoPiIcon"));
var _LogoPiSimple = _interopRequireDefault(require("./LogoPiSimple"));
var _LogoPi = _interopRequireDefault(require("./LogoPi"));
var _LogoZipSimple = _interopRequireDefault(require("./LogoZipSimple"));
var _LogoZip = _interopRequireDefault(require("./LogoZip"));
var _LuggageStorage = _interopRequireDefault(require("./LuggageStorage"));
var _MapCompress = _interopRequireDefault(require("./MapCompress"));
var _MapExpand = _interopRequireDefault(require("./MapExpand"));
var _MastercardLogoBlackAndWhite = _interopRequireDefault(require("./MastercardLogoBlackAndWhite"));
var _MastercardLogo = _interopRequireDefault(require("./MastercardLogo"));
var _MeetingRooms = _interopRequireDefault(require("./MeetingRooms"));
var _MiniFridge = _interopRequireDefault(require("./MiniFridge"));
var _ModernRoom = _interopRequireDefault(require("./ModernRoom32"));
var _ModernRoom2 = _interopRequireDefault(require("./ModernRoom"));
var _MyBookings = _interopRequireDefault(require("./MyBookings"));
var _NespressoMachine = _interopRequireDefault(require("./NespressoMachine32"));
var _NespressoMachine2 = _interopRequireDefault(require("./NespressoMachine"));
var _NoOfNights = _interopRequireDefault(require("./NoOfNights"));
var _OneAdult = _interopRequireDefault(require("./OneAdult"));
var _OverheadStorage = _interopRequireDefault(require("./OverheadStorage"));
var _Parking = _interopRequireDefault(require("./Parking"));
var _Path = _interopRequireDefault(require("./Path"));
var _PaymentCard = _interopRequireDefault(require("./PaymentCard"));
var _PlayArea = _interopRequireDefault(require("./PlayArea"));
var _Pointer = _interopRequireDefault(require("./Pointer"));
var _PremierInnLogo = _interopRequireDefault(require("./PremierInnLogo"));
var _Printer = _interopRequireDefault(require("./Printer"));
var _RainfallShower = _interopRequireDefault(require("./RainfallShower"));
var _Rectangle = _interopRequireDefault(require("./Rectangle"));
var _Restaurant = _interopRequireDefault(require("./Restaurant24"));
var _Restaurant2 = _interopRequireDefault(require("./Restaurant"));
var _RoomControls = _interopRequireDefault(require("./RoomControls"));
var _SearchIcon = _interopRequireDefault(require("./SearchIcon"));
var _Security = _interopRequireDefault(require("./Security16"));
var _Security2 = _interopRequireDefault(require("./Security"));
var _SeeAll = _interopRequireDefault(require("./SeeAll"));
var _SelfCheckInKiosks = _interopRequireDefault(require("./SelfCheckInKiosks"));
var _SingleBed = _interopRequireDefault(require("./SingleBed"));
var _SmartTv40Inch = _interopRequireDefault(require("./SmartTv40Inch"));
var _Success = _interopRequireDefault(require("./Success"));
var _Success2 = _interopRequireDefault(require("./Success24"));
var _TeaAndCoffee = _interopRequireDefault(require("./TeaAndCoffee32"));
var _TeaAndCoffee2 = _interopRequireDefault(require("./TeaAndCoffee"));
var _Tick = _interopRequireDefault(require("./Tick24"));
var _Tick2 = _interopRequireDefault(require("./Tick"));
var _TwitterLogo24WhiteBg = _interopRequireDefault(require("./TwitterLogo24WhiteBg"));
var _TwitterLogo = _interopRequireDefault(require("./TwitterLogo24"));
var _TwitterLogo32WhiteBg = _interopRequireDefault(require("./TwitterLogo32WhiteBg"));
var _TwitterLogo2 = _interopRequireDefault(require("./TwitterLogo32"));
var _TwitterLogo48WhiteBg = _interopRequireDefault(require("./TwitterLogo48WhiteBg"));
var _TwitterLogo3 = _interopRequireDefault(require("./TwitterLogo48"));
var _TwitterLogoMini = _interopRequireDefault(require("./TwitterLogoMini"));
var _TwoAdultsMax = _interopRequireDefault(require("./TwoAdultsMax"));
var _UltimateWifi = _interopRequireDefault(require("./UltimateWifi32"));
var _UltimateWifi2 = _interopRequireDefault(require("./UltimateWifi"));
var _UnderBedStorage = _interopRequireDefault(require("./UnderBedStorage"));
var _VanityArea = _interopRequireDefault(require("./VanityArea"));
var _Vending = _interopRequireDefault(require("./Vending"));
var _VisaLogo = _interopRequireDefault(require("./VisaLogo"));
var _WhiteAlert = _interopRequireDefault(require("./WhiteAlert"));
var _WhiteError = _interopRequireDefault(require("./WhiteError"));
var _WhiteInfo = _interopRequireDefault(require("./WhiteInfo"));
var _WhiteSuccess = _interopRequireDefault(require("./WhiteSuccess"));