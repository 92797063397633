"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCardComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _BookingActions = _interopRequireDefault(require("./BookingActions"));
var _BookingDetails = require("./BookingDetails");
var _HotelDetails = _interopRequireDefault(require("./HotelDetails"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCardComponent(props) {
  var {
    isAmendPage,
    area
  } = props;
  return (0, _jsxRuntime.jsxs)(_atoms.Card, _objectSpread(_objectSpread({}, cardWrapperStyle(props.bookingStatus)), {}, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, hotelDetailsWrapperStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_BookingActions.default, _objectSpread(_objectSpread({}, props), {}, {
        shouldRenderStatusAndActions: isAmendPage && [_api.Area.PI, _api.Area.BB].includes(area)
      })), props.bookingStatus !== _api.BC_RESERVATION_STATUS.CANCELLED && (0, _jsxRuntime.jsx)(_HotelDetails.default, _objectSpread({}, props))]
    })), (0, _jsxRuntime.jsx)(_BookingDetails.BookingDetails, _objectSpread({}, props))]
  }));
}
var hotelDetailsWrapperStyle = {
  flexDirection: {
    mobile: 'column',
    lg: 'row'
  }
};
var cardWrapperStyle = bookingStatus => {
  return {
    display: bookingStatus === _api.BC_RESERVATION_STATUS.CANCELLED ? {
      mobile: 'flex',
      lg: 'grid'
    } : 'flex',
    gridTemplateColumns: {
      lg: '1fr 1fr'
    },
    boxShadow: 'none',
    justifyContent: 'space-between',
    flexDirection: {
      mobile: 'column',
      lg: 'row'
    },
    padding: 'lg'
  };
};