"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MANUAL_ADDRESS = exports.ID_TOKEN_COOKIE = exports.GLOBALS = exports.DEFAULT_TRACING_COOKIE_NAME = void 0;
var GLOBALS = exports.GLOBALS = {
  locale: {
    GB: 'gb',
    DE: 'de'
  },
  localeUpper: {
    GB: 'GB',
    DE: 'DE'
  },
  language: {
    EN: 'en',
    DE: 'de'
  },
  addressType: {
    HOME: 'HOME',
    BUSINESS: 'BUSINESS'
  },
  billingAddressType: {
    DIFFERENT: 'DifferentAddress',
    CURRENT: 'CurrentAddress'
  }
};
var ID_TOKEN_COOKIE = exports.ID_TOKEN_COOKIE = 'id_token_cookie';
var DEFAULT_TRACING_COOKIE_NAME = exports.DEFAULT_TRACING_COOKIE_NAME = 'WB-SESSION-ID';
var MANUAL_ADDRESS = exports.MANUAL_ADDRESS = 'manualAddress';