"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUnleashDefinitions = getUnleashDefinitions;
exports.getUnleashTogglesServerOrClient = getUnleashTogglesServerOrClient;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _nextjs = require("@unleash/nextjs");
var _config = _interopRequireDefault(require("next/config"));
var _globalConstants = require("../global-constants");
var _logger = require("../logger/logger");
var _getLoggedInUserInfo = _interopRequireDefault(require("./getLoggedInUserInfo"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function setUnleashDefinitionsInCache(unleashDefinitions, startTime) {
  var _global$WB$cache, _global;
  var cache = (_global$WB$cache = (_global = global) === null || _global === void 0 || (_global = _global.WB) === null || _global === void 0 ? void 0 : _global.cache) !== null && _global$WB$cache !== void 0 ? _global$WB$cache : {};
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var expiringTime = startTime + Number(publicRuntimeConfig.NEXT_APP_UNLEASH_DEFINITIONS_CACHE_TTL) * 1000;
  cache['unleashDefinitions'] = {
    value: unleashDefinitions,
    expiringTime
  };
}
function getUnleashDefinitions() {
  return _getUnleashDefinitions.apply(this, arguments);
}
function _getUnleashDefinitions() {
  _getUnleashDefinitions = (0, _asyncToGenerator2.default)(function* () {
    var _global$WB$cache2, _global2, _global3;
    var now = new Date().getTime();
    _logger.logger.info({
      label: 'FETCH_DEFINITIONS_START'
    });
    var cache = (_global$WB$cache2 = (_global2 = global) === null || _global2 === void 0 || (_global2 = _global2.WB) === null || _global2 === void 0 ? void 0 : _global2.cache) !== null && _global$WB$cache2 !== void 0 ? _global$WB$cache2 : {};
    var shouldFetchAgain = !cache['unleashDefinitions'] || now >= cache['unleashDefinitions'].expiringTime;
    if (shouldFetchAgain) {
      _logger.logger.info({
        label: 'START_FETCH_UNLEASH_DEFINITIONS'
      });
      var definitions = yield (0, _nextjs.getDefinitions)();
      _logger.logger.info({
        label: 'END_FETCH_UNLEASH_DEFINITIONS',
        msg: {
          definitions
        }
      });
      setUnleashDefinitionsInCache(definitions, now);
      _logger.logger.info({
        label: 'UNLEASH_CACHE_EXPIRING_TIME'
      });
      return definitions;
    } else {
      _logger.logger.info({
        label: 'UNLEASH_RETURNED_FROM_MEMORY'
      });
    }
    return (_global3 = global) === null || _global3 === void 0 || (_global3 = _global3.WB) === null || _global3 === void 0 || (_global3 = _global3.cache) === null || _global3 === void 0 || (_global3 = _global3.unleashDefinitions) === null || _global3 === void 0 ? void 0 : _global3.value;
  });
  return _getUnleashDefinitions.apply(this, arguments);
}
function getUnleashUserInfo(token, ccuiSession) {
  var {
    name,
    profile: {
      sessionId,
      isBusiness,
      accessLevel,
      employeeId,
      companyId
    },
    cdhCompanyId,
    cdhEmployeeId,
    operaCompanyId
  } = (0, _getLoggedInUserInfo.default)(token);
  return {
    name: ccuiSession !== null && ccuiSession !== void 0 ? ccuiSession : name,
    sessionId,
    isBusiness,
    accessLevel,
    employeeId,
    companyId,
    cdhCompanyId,
    cdhEmployeeId,
    operaCompanyId
  };
}
function getUnleashTogglesServerOrClient(_x, _x2, _x3, _x4, _x5, _x6) {
  return _getUnleashTogglesServerOrClient.apply(this, arguments);
}
function _getUnleashTogglesServerOrClient() {
  _getUnleashTogglesServerOrClient = (0, _asyncToGenerator2.default)(function* (cookies, label, flagsWithFallback, url, query, sessionTracing) {
    var _token$value, _ftOverride$value, _userIdCookie$value, _definitions$features;
    var context = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : {};
    var ccuiSession = arguments.length > 7 ? arguments[7] : undefined;
    var flags = {};
    var token = cookies.get(_globalConstants.ID_TOKEN_COOKIE);
    var idTokenCookie = typeof token === 'string' ? token : (_token$value = token === null || token === void 0 ? void 0 : token.value) !== null && _token$value !== void 0 ? _token$value : '';
    var ftOverride = cookies.get('ftOverride');
    var ftOverrideCookie = typeof ftOverride === 'string' ? ftOverride : (_ftOverride$value = ftOverride === null || ftOverride === void 0 ? void 0 : ftOverride.value) !== null && _ftOverride$value !== void 0 ? _ftOverride$value : '';
    var ftCookieObj = {};
    var definitions = {
      version: 2,
      features: []
    };
    var userInfo = getUnleashUserInfo(idTokenCookie, ccuiSession);
    var unleashLogger = _objectSpread({
      label,
      pageLink: url,
      params: query,
      userInfo,
      flags,
      error: ''
    }, sessionTracing);
    if (ftOverrideCookie) {
      ftOverrideCookie.split(',').forEach(fs => {
        var [key, value] = fs.split('=');
        if (key && value) {
          ftCookieObj[key] = value === 'true';
        }
      });
    }
    try {
      definitions = yield getUnleashDefinitions();
    } catch (err) {
      unleashLogger.error = err;
    }
    var userIdCookie = cookies.get('email');
    var userId = typeof userIdCookie === 'string' ? userIdCookie : (_userIdCookie$value = userIdCookie === null || userIdCookie === void 0 ? void 0 : userIdCookie.value) !== null && _userIdCookie$value !== void 0 ? _userIdCookie$value : '';
    var unleashContext = _objectSpread(_objectSpread({}, context), {}, {
      sessionId: sessionTracing['WB-SESSION-ID'],
      userId: userId,
      environment: process.env.NEXT_ENV
    });
    var defaultToggle = {
      variant: {
        name: 'disabled',
        enabled: false
      },
      impressionData: false
    };
    var toggles = Object.entries(flagsWithFallback).map(_ref => {
      var [name, enabled] = _ref;
      return _objectSpread(_objectSpread({}, defaultToggle), {}, {
        name,
        enabled
      });
    });
    if (definitions !== null && definitions !== void 0 && definitions.features && definitions !== null && definitions !== void 0 && (_definitions$features = definitions.features) !== null && _definitions$features !== void 0 && _definitions$features.length) {
      var {
        toggles: fetchedToggles
      } = (0, _nextjs.evaluateFlags)(definitions, unleashContext);
      var toggleSet = new Set(fetchedToggles.map(toggle => toggle.name));
      toggles.forEach(toggle => {
        if (!toggleSet.has(toggle.name)) {
          fetchedToggles.push(_objectSpread({}, toggle));
        }
      });
      toggles = fetchedToggles;
    }
    var {
      isEnabled
    } = (0, _nextjs.flagsClient)(toggles);
    Object.keys(flagsWithFallback).forEach(flag => {
      flags[flag] = flag in ftCookieObj ? ftCookieObj[flag] : isEnabled(flag);
    });
    _logger.logger.info(unleashLogger);
    return flags;
  });
  return _getUnleashTogglesServerOrClient.apply(this, arguments);
}