"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RoomCardInfo;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RoomCardInfo(_ref) {
  var {
    leadGuestTitle,
    leadGuestName,
    roomType,
    roomTypeDescription,
    rateType,
    rateTypeDescription,
    roomGroup,
    roomReservationEndDate,
    roomTotalPrice,
    roomNumber,
    ratesPerNight,
    adultMealDescription,
    childrenMealDescription,
    mealPrice,
    currency,
    taxesMessage,
    brand,
    currentLang,
    t
  } = _ref;
  var baseDataTestId = (0, _utils.formatDataTestId)('RoomCardInfo', "room".concat(roomNumber));
  var leftId = str => (0, _utils.formatDataTestId)('LeftColumn', str);
  var rightId = str => (0, _utils.formatDataTestId)('RightColumn', str);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": baseDataTestId,
    p: "lg",
    pt: 0,
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, columnsStyle), {}, {
      children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('Main'))
      }, leftColumnStyle), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, leftColBoxStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('LeadGuest-Label'))
          }, leftColBoxTitleStyle), {}, {
            children: t('booking.confirmation.leadGuest')
          })), (0, _jsxRuntime.jsxs)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('LeadGuest-Name')),
            fontSize: "lg",
            className: "sessioncamhidetext assist-no-show",
            children: [leadGuestTitle, " ", leadGuestName]
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, leftColBoxStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomLabel'))
          }, leftColBoxTitleStyle), {}, {
            children: t('booking.confirmation.yourRoom')
          })), (0, _jsxRuntime.jsxs)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomDetails')),
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              as: "b",
              children: roomType
            }), roomTypeDescription && " - ".concat(roomTypeDescription)]
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, leftColBoxStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomRate-Label'))
          }, leftColBoxTitleStyle), {}, {
            children: t('booking.confirmation.yourRate')
          })), (0, _jsxRuntime.jsxs)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomRate-Details')),
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              as: "b",
              children: "".concat(brand === _api.HotelBrand.HUB ? 'hub' : '', " ").concat(rateType)
            }), " -", ' ', rateTypeDescription]
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, leftColBoxStyle), {}, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomGroup-Label'))
          }, leftColBoxTitleStyle), {}, {
            children: t('booking.confirmation.yourGroup')
          })), (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, leftId('RoomGroup-Details')),
            children: roomGroup
          })]
        }))]
      })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Main'))
      }, rightColumnStyle), {}, {
        children: [(0, _jsxRuntime.jsxs)(_react.Box, {
          w: "full",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Arrival-Label')),
            fontWeight: "bold",
            fontSize: "md",
            children: t('booking.hotel.summary.arriving')
          }), ratesPerNight === null || ratesPerNight === void 0 ? void 0 : ratesPerNight.map((night, index) => {
            var _night$pricePerNight, _night$cityTaxPerNigh;
            return (0, _jsxRuntime.jsxs)(_react.Flex, {
              direction: "column",
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId("RatePerNight-".concat(index))),
              children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
                justifyContent: "space-between",
                alignItems: "center",
                children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, confDayStyle), {}, {
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId("Arrival-Day-".concat(index))),
                  children: (0, _utils.formatDate)(night.startDate, 'eeee', currentLang)
                })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Arrival-Checkin'))
                }, checkoutTimeStyle), {}, {
                  textAlign: "right",
                  children: t('booking.confirmation.checkinTime')
                }))]
              }), (0, _jsxRuntime.jsxs)(_react.Flex, {
                justify: "space-between",
                alignItems: "center",
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId("Room-PricePerNight-".concat(index))),
                children: [(0, _jsxRuntime.jsx)(_react.Text, {
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Leaving-Date')),
                  fontWeight: "medium",
                  fontSize: "md",
                  color: "darkGrey2",
                  children: language === 'en' ? (0, _utils.formatDate)(night.startDate, 'd MMM yyyy', currentLang) : (0, _utils.formatDate)(night.startDate, 'E dd MMM yyyy', currentLang)
                }), (0, _jsxRuntime.jsx)(_react.Text, {
                  fontSize: "xl",
                  fontWeight: "bold",
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('PricePerNight-CityTax')),
                  children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), (((_night$pricePerNight = night === null || night === void 0 ? void 0 : night.pricePerNight) !== null && _night$pricePerNight !== void 0 ? _night$pricePerNight : 0) + ((_night$cityTaxPerNigh = night === null || night === void 0 ? void 0 : night.cityTaxPerNight) !== null && _night$cityTaxPerNigh !== void 0 ? _night$cityTaxPerNigh : 0)).toFixed(2), language)
                })]
              }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle))]
            }, "".concat(night.startDate, "-").concat(index));
          })]
        }), (0, _jsxRuntime.jsxs)(_react.Box, {
          w: "full",
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Leaving-Label')),
            fontWeight: "bold",
            fontSize: "md",
            children: t('booking.hotel.summary.checkout')
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            justifyContent: "space-between",
            alignItems: "center",
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Leaving-Day'))
            }, confDayStyle), {}, {
              children: (0, _utils.formatDate)(roomReservationEndDate, 'eeee', currentLang)
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Leaving-CheckOut'))
            }, checkoutTimeStyle), {}, {
              textAlign: "right",
              children: t('booking.confirmation.checkoutTime')
            }))]
          }), (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Leaving-Date')),
            fontWeight: "medium",
            fontSize: "md",
            color: "darkGrey2",
            children: language === 'en' ? (0, _utils.formatDate)(roomReservationEndDate, 'd MMM yyyy', currentLang) : (0, _utils.formatDate)(roomReservationEndDate, 'E dd MMM yyyy', currentLang)
          })]
        }), (adultMealDescription.length > 0 || childrenMealDescription.length > 0) && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyle)), (0, _jsxRuntime.jsxs)(_react.Box, {
            w: "full",
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Meals-Label')),
              fontWeight: "bold",
              fontSize: "md",
              children: t('booking.hotel.summary.meals')
            }), (0, _jsxRuntime.jsxs)(_react.Flex, {
              justifyContent: "space-between",
              alignItems: "flex-end",
              children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
                direction: "column",
                children: [adultMealDescription.length > 0 && adultMealDescription.map((adultMealItem, index) => (0, _jsxRuntime.jsx)(_react.Text, {
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Meals-Adults')),
                  fontWeight: "medium",
                  fontSize: "md",
                  children: adultMealItem
                }, "adultMealItem-".concat(index))), childrenMealDescription.length > 0 && childrenMealDescription.map((childrenMealItem, index) => (0, _jsxRuntime.jsx)(_react.Text, {
                  "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Meals-Children')),
                  fontWeight: "medium",
                  fontSize: "md",
                  children: childrenMealItem
                }, "childrenMealItem-".concat(index)))]
              }), (0, _jsxRuntime.jsx)(_react.Text, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('Meals-Cost')),
                fontWeight: "bold",
                fontSize: "xl",
                children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), mealPrice.toFixed(2), language)
              })]
            })]
          })]
        })]
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Flex, {
      justifyContent: "flex-end",
      fontSize: "xl",
      mt: "md",
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('RoomTotalPrice-Label')),
        mr: "sm",
        children: t('booking.confirmation.roomTotalMD').replace('[roomNumber]', roomNumber.toString())
      }), (0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, rightId('RoomTotalPrice-Amount')),
        fontWeight: "bold",
        children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currency), roomTotalPrice.toFixed(2), language)
      })]
    }), !!(taxesMessage !== null && taxesMessage !== void 0 && taxesMessage.length) && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
      justifyContent: "flex-end"
    }, infoTextStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'CityTaxMessage'),
      children: taxesMessage
    }))]
  });
}
var leftColBoxStyle = {
  mb: 'xl'
};
var leftColBoxTitleStyle = {
  fontWeight: 'bold',
  fontSize: 'md'
};
var rightColumnStyle = {
  w: {
    xl: '33.5rem',
    mobile: 'full'
  },
  backgroundColor: 'lightGrey5',
  p: 'lg',
  direction: 'column',
  h: 'fit-content',
  mb: {
    xl: '0',
    mobile: '2xl'
  }
};
var dividerStyle = {
  my: 'sm',
  borderColor: 'lightGrey3'
};
var columnsStyle = {
  justifyContent: 'space-between',
  direction: {
    xl: 'row',
    mobile: 'column'
  }
};
var leftColumnStyle = {
  w: {
    xl: '12.5rem',
    mobile: 'full'
  },
  mb: {
    xl: '0',
    mobile: '3xl'
  }
};
var checkoutTimeStyle = {
  fontWeight: 'medium',
  fontSize: 'md',
  w: {
    sm: 'full',
    xs: '7.875rem',
    mobile: '6.125rem'
  }
};
var confDayStyle = {
  fontWeight: 'semibold',
  fontSize: 'lg',
  color: 'btnSecondaryEnabled'
};
var infoTextStyle = {
  justifyContent: 'flex-end',
  fontWeight: 'medium',
  lineHeight: '2',
  color: 'darkGrey1',
  fontSize: 'sm'
};