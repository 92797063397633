"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = NavigationMenuMobile;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _molecules = require("@whitbread-eos/molecules");
var _utils = require("@whitbread-eos/utils");
var _config = _interopRequireDefault(require("next/config"));
var _react2 = require("react");
var _index = require("../../../../index");
var _AuthSideNav = _interopRequireDefault(require("./AuthSideNav"));
var _BusinessSideNav = _interopRequireDefault(require("./BusinessSideNav"));
var _DiscoverSideNav = _interopRequireDefault(require("./DiscoverSideNav"));
var _HeaderSideNav = _interopRequireDefault(require("./HeaderSideNav"));
var _LanguageSideNav = _interopRequireDefault(require("./LanguageSideNav"));
var _LanguageSideNav2 = require("./LanguageSideNav/LanguageSideNav.component");
var _NavigationItem = _interopRequireDefault(require("./NavigationItem"));
var _NavigationMenuMobile = require("./NavigationMenuMobile.style");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function NavigationMenuMobile(_ref) {
  var {
    currentCountry,
    currentLanguage,
    languagesList,
    tickIcon,
    labels,
    area
  } = _ref;
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var [isManageBookingModalOpen, setIsManageBookingModalOpen] = (0, _react2.useState)(false);
  var [isLoginModalOpen, setIsLoginModalOpen] = (0, _react2.useState)(false);
  var [selectedSideNav, setSelectedSideNav] = (0, _react2.useState)('default');
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  var {
    isLoggedIn
  } = (0, _utils.useUserData)();
  var isLoginEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_DISPLAY_LOGIN_CTA,
    fallbackValue: true,
    country: currentCountry
  });
  var idTokenCookie = (0, _utils.getAuthCookie)();
  var {
    email
  } = (0, _utils.decodeIdToken)(idTokenCookie);
  var {
    data
  } = (0, _utils.useRestQueryRequest)(['userDetails', idTokenCookie], 'GET', "".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=false"), {
    Authorization: "Bearer ".concat(idTokenCookie)
  }, {
    enabled: isLoggedIn
  });
  var logout = () => {
    var authIframe = document.getElementById('authIframe');
    if (authIframe !== null && authIframe !== void 0 && authIframe.contentWindow) {
      var message = JSON.stringify({
        action: 'logout'
      });
      authIframe.contentWindow.postMessage(message, (0, _utils.getSecureTwoURL)());
    }
  };
  (0, _react2.useEffect)(() => {
    var handleMessages = message => {
      if ((message === null || message === void 0 ? void 0 : message.origin) === (0, _utils.getSecureTwoURL)()) {
        var _message$data;
        var action = typeof (message === null || message === void 0 ? void 0 : message.data) === 'string' ? message.data : (_message$data = message.data) === null || _message$data === void 0 ? void 0 : _message$data.action;
        if (action === 'userLoggedOut') {
          toggleSideNav('default');
        }
      }
    };
    window.addEventListener('message', handleMessages);
    return () => {
      window.removeEventListener('message', handleMessages);
    };
  }, []);
  var toggleManageModal = () => {
    setIsManageBookingModalOpen(!isManageBookingModalOpen);
  };
  var toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };
  var toggleSideNav = sideNav => {
    setSelectedSideNav(sideNav);
  };
  return (0, _jsxRuntime.jsxs)(_react.Flex, {
    flexDir: "column",
    "data-testid": "mobile-navigation-menu",
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, burgerMenuContainerStyle), {}, {
      sx: {
        '@media print': {
          marginLeft: 'auto'
        }
      },
      onClick: () => {
        setIsModalVisible(true);
      },
      children: [(0, _jsxRuntime.jsx)(_atoms.Icon, {
        _hover: {
          cursor: 'pointer'
        },
        svg: (0, _jsxRuntime.jsx)(_atoms.BurgerMenu, {
          "data-testid": "burgerMenu"
        })
      }), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, burgerMenuTextStyle), {}, {
        children: labels === null || labels === void 0 ? void 0 : labels.mobileMenuButton
      }))]
    })), (0, _jsxRuntime.jsxs)(_react.Modal, {
      isOpen: isModalVisible,
      onClose: () => {
        setIsModalVisible(false);
      },
      size: "full",
      children: [(0, _jsxRuntime.jsx)(_react.ModalOverlay, {
        zIndex: "var(--chakra-zIndices-banner)",
        "data-testid": "modalOverlay"
      }), (0, _jsxRuntime.jsx)(_react.Slide, {
        direction: "left",
        in: isModalVisible,
        style: {
          zIndex: 'var(--chakra-zIndices-overlay)'
        },
        children: (0, _jsxRuntime.jsx)(_react.ModalContent, _objectSpread(_objectSpread({}, modalContentStyle), {}, {
          "data-testid": "modalSideBar",
          children: renderSideNav(selectedSideNav)
        }))
      })]
    })]
  });
  function renderSideNav(selectedSideNav) {
    switch (selectedSideNav) {
      case 'default':
        {
          return renderDefaultSideBar();
        }
      case 'languageSelector':
        {
          var languageSelectorLabels = {
            language: labels.language
          };
          return (0, _jsxRuntime.jsx)(_LanguageSideNav.default, {
            currentLanguage: currentLanguage,
            languagesList: languagesList,
            onClickHeaderTitle: () => toggleSideNav('default'),
            tickIcon: tickIcon,
            area: area,
            labels: languageSelectorLabels
          });
        }
      case 'auth':
        {
          var _data$contactDetail, _data$contactDetail2;
          return (0, _jsxRuntime.jsx)(_AuthSideNav.default, {
            onClickHeaderTitle: () => toggleSideNav('default'),
            header: "".concat(data === null || data === void 0 || (_data$contactDetail = data.contactDetail) === null || _data$contactDetail === void 0 ? void 0 : _data$contactDetail.firstName, " ").concat(data === null || data === void 0 || (_data$contactDetail2 = data.contactDetail) === null || _data$contactDetail2 === void 0 ? void 0 : _data$contactDetail2.lastName),
            logout: logout,
            labels: labels
          });
        }
      case 'business':
        {
          return (0, _jsxRuntime.jsx)(_BusinessSideNav.default, {
            labels: labels,
            onClickHeaderTitle: () => toggleSideNav('default')
          });
        }
      case 'discover':
        {
          return (0, _jsxRuntime.jsx)(_DiscoverSideNav.default, {
            labels: labels,
            onClickHeaderTitle: () => toggleSideNav('default')
          });
        }
    }
  }
  function renderDefaultSideBar() {
    var _data$contactDetail3, _data$contactDetail4;
    return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [(0, _jsxRuntime.jsx)(_HeaderSideNav.default, {}), (0, _jsxRuntime.jsx)(_react.ModalBody, {
        p: "0",
        "data-testid": "defaultSideNav",
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          flexDir: "column",
          children: [(0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": 'Global-TriggerLanguageSideNav',
            onClick: () => toggleSideNav('languageSelector'),
            children: (0, _LanguageSideNav2.renderLanguageItem)({
              languagesList,
              locale: currentLanguage,
              icon: (0, _jsxRuntime.jsx)(_react.Box, {
                pt: "0.37rem",
                pl: "sm",
                children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                  svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
                })
              }),
              withStyleHover: true
            })
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            bgColor: "lightGrey5",
            flexDir: "column",
            children: [isLoginEnabled && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
              children: [!isLoggedIn ? (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
                onClick: toggleLoginModal
              }, customContainerItemStyle), {}, {
                "data-testid": "Global-Login-Mobile",
                children: labels.logIn
              })) : !!(data !== null && data !== void 0 && data.contactDetail) && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
                onClick: () => toggleSideNav('auth'),
                "data-testid": "Global-SideNav-UserName-NavItem-Mobile",
                children: (0, _jsxRuntime.jsx)(_NavigationItem.default, {
                  title: "".concat((_data$contactDetail3 = data.contactDetail) === null || _data$contactDetail3 === void 0 ? void 0 : _data$contactDetail3.firstName, " ").concat((_data$contactDetail4 = data.contactDetail) === null || _data$contactDetail4 === void 0 ? void 0 : _data$contactDetail4.lastName),
                  icon: (0, _jsxRuntime.jsx)(_react.Box, {
                    pt: "0.37rem",
                    pl: "sm",
                    children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                      svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
                    })
                  })
                })
              })), (0, _jsxRuntime.jsx)(_index.AuthContentManagerPIVariant, {
                isLoginModalOpen: isLoginModalOpen,
                toggleLoginModal: toggleLoginModal
              })]
            }), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
              onClick: toggleManageModal,
              "data-testid": 'Global-TriggerManageModal',
              children: [labels.findBooking, (0, _jsxRuntime.jsx)(_molecules.ManageBookingContainer, {
                onClose: toggleManageModal,
                isOpen: isManageBookingModalOpen
              })]
            }))]
          }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
            "data-testid": 'Global-TriggerBusinessSideNav',
            onClick: () => toggleSideNav('business'),
            children: (0, _jsxRuntime.jsx)(_NavigationItem.default, {
              title: labels.business,
              icon: (0, _jsxRuntime.jsx)(_react.Box, {
                pt: "0.37rem",
                pl: "sm",
                children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                  svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
                })
              })
            })
          })), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, customContainerItemStyle), {}, {
            "data-testid": 'Global-TriggerDiscoverSideNav',
            onClick: () => toggleSideNav('discover'),
            children: (0, _jsxRuntime.jsx)(_NavigationItem.default, {
              title: labels.discoverPI,
              icon: (0, _jsxRuntime.jsx)(_react.Box, {
                pt: "0.37rem",
                pl: "sm",
                children: (0, _jsxRuntime.jsx)(_atoms.Icon, {
                  svg: (0, _jsxRuntime.jsx)(_atoms.ChevronRight, {})
                })
              })
            })
          }))]
        })
      })]
    });
  }
}
var customContainerItemStyle = _objectSpread(_objectSpread({}, _NavigationMenuMobile.containerItemStyle), {}, {
  _hover: {
    color: 'primary'
  }
});
var modalContentStyle = {
  borderRadius: 0,
  position: 'absolute',
  zIndex: 'var(--chakra-zIndices-overlay)',
  left: 0,
  display: {
    lg: 'none'
  },
  w: {
    mobile: '72',
    xs: '21.44rem',
    sm: '21.875rem',
    md: '23.25rem'
  }
};
var burgerMenuContainerStyle = {
  pt: '0.5rem',
  flexDir: 'column',
  justifyContent: 'center',
  alignItems: 'center'
};
var burgerMenuTextStyle = {
  pt: '0.375rem',
  fontSize: 'sm',
  lineHeight: '2',
  fontWeight: 'normal'
};