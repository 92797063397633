"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("@chakra-ui/react");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
var PrivacyPolicy = () => {
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var htmlContent = {
    __html: t('precheckin.privacypolicy.checkbox').replace(/<a/g, '<a style="color: var(--chakra-colors-btnSecondaryEnabled); font-weight: bold; cursor: pointer;" target="_blank"')
  };
  return (0, _jsxRuntime.jsx)(_react.Text, {
    "data-testid": "privacy-policy-label",
    ml: "xl",
    dangerouslySetInnerHTML: htmlContent
  });
};
var _default = exports.default = PrivacyPolicy;