"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BookingInfoCard;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = require("react");
var _AgentOverrideModal = _interopRequireDefault(require("./AgentOverrideModal"));
var _BookingInfoCard = _interopRequireDefault(require("./BookingInfoCard.component"));
var _ResendConfirmationModal = require("./ResendConfirmationModal");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BookingInfoCard(_ref) {
  var _firstRoom$roomStay, _firstRoom$roomStay2, _firstRoom$billing, _OVData$cancellationR;
  var {
    basketReference,
    tempBookingReference,
    operaConfNumber,
    bookingReference,
    area,
    updateStatusAfterCancel,
    inputValues,
    bookingType,
    isAmendPage = false,
    isAmendSuccessful = false,
    setInputValuesInSessionStorage
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var shouldShowTypeOfBooking = area === _api.Area.CCUI;
  var baseDataTestId = 'BookingInfoCard';
  var router = (0, _router.useRouter)();
  var [dpaInfo, setDpaInfo] = (0, _react2.useState)({
    dpaPassed: false,
    dpaOverride: false
  });
  var [overridenUserInfo, setOverridenUserInfo] = (0, _react2.useState)({
    reservationOverrideReasons: {
      reasonName: '',
      callerName: '',
      managerName: '',
      reasonCode: ''
    },
    reservationOverridden: false
  });
  var [isAgentOverrideModalVisible, setIsAgentOverrideModalVisible] = (0, _react2.useState)(false);
  var {
    bookingData,
    bookingError,
    bookingIsError,
    bookingIsLoading,
    bookingIsSuccess,
    bookingRefetch
  } = (0, _utils.useBookingConfimationData)(area, basketReference !== null && basketReference !== void 0 ? basketReference : '', bookingReference, language, country);
  var areAllReservationsCancelled = (bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.filter(reservation => (reservation === null || reservation === void 0 ? void 0 : reservation.reservationStatus) === _api.BOOKING_TYPE.CANCELLED).length) === (bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.length);
  var firstRoom = areAllReservationsCancelled ? bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList[0] : bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList.find(reservation => (reservation === null || reservation === void 0 ? void 0 : reservation.reservationStatus) !== _api.BOOKING_TYPE.CANCELLED);
  var basketReferenceValue = basketReference !== null && basketReference !== void 0 ? basketReference : bookingData === null || bookingData === void 0 ? void 0 : bookingData.basketReference;
  var gdsReferenceNumber = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.gdsReferenceNumber;
  var reservationOverriddenStatus = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationOverridden;
  var distBookingChannel = firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay = firstRoom.roomStay) === null || _firstRoom$roomStay === void 0 ? void 0 : _firstRoom$roomStay.bookingChannel;
  var bookingStatus = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationStatus;
  var paymentOption = firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.guaranteeCode;
  var departureDate = firstRoom === null || firstRoom === void 0 || (_firstRoom$roomStay2 = firstRoom.roomStay) === null || _firstRoom$roomStay2 === void 0 ? void 0 : _firstRoom$roomStay2.departureDate;
  var {
    data: OVData,
    error: OVError
  } = (0, _utils.useQueryRequest)(['getOverrideReasons', bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId], _api.GET_OVERRIDE_REASONS, {
    hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId
  }, {
    enabled: !!(bookingData !== null && bookingData !== void 0 && bookingData.hotelId) && isAgentOverrideModalVisible
  });
  var [isModalVisible, setIsModalVisible] = (0, _react2.useState)(false);
  var onModalClose = () => setIsModalVisible(prevState => !prevState);
  (0, _react2.useEffect)(() => {
    if (bookingIsSuccess && reservationOverriddenStatus) {
      setOverridenUserInfo({
        reservationOverrideReasons: firstRoom === null || firstRoom === void 0 ? void 0 : firstRoom.reservationOverrideReasons,
        reservationOverridden: reservationOverriddenStatus
      });
    }
  }, [bookingIsSuccess, setOverridenUserInfo, bookingData === null || bookingData === void 0 ? void 0 : bookingData.reservationByIdList]);
  if (bookingIsError) {
    return (0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorContainer'),
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        status: "error",
        description: String(bookingError),
        variant: "alert",
        maxW: "full",
        svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {})
      })
    });
  }
  if (bookingIsLoading) {
    return (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, loadingStyle), {}, {
      "data-testid": "Loading-BookingInfoCard",
      children: (0, _jsxRuntime.jsx)(_atoms.LoadingSpinner, {
        loadingText: t('booking.loading')
      })
    }));
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: [(0, _jsxRuntime.jsx)(_BookingInfoCard.default, {
      bookingReference: bookingReference,
      basketReference: basketReferenceValue,
      operaConfNumber: operaConfNumber,
      tempBookingReference: tempBookingReference,
      area: area,
      shouldShowTypeOfBooking: shouldShowTypeOfBooking,
      bookingType: bookingType !== null && bookingType !== void 0 ? bookingType : (0, _utils.mappingBookingStatus)(bookingStatus, departureDate),
      bookingStatus: bookingStatus,
      getBookingStatus: () => {
        bookingRefetch();
        area === 'ccui' && updateStatusAfterCancel && updateStatusAfterCancel(bookingReference);
      },
      paymentOption: paymentOption,
      dpaInfo: dpaInfo,
      setDpaInfo: setDpaInfo,
      setIsAgentOverrideModalVisible: setIsAgentOverrideModalVisible,
      overridenUserInfo: overridenUserInfo,
      inputValues: inputValues,
      gdsReferenceNumber: gdsReferenceNumber,
      distBookingChannel: distBookingChannel,
      isAmendPage: isAmendPage,
      isAmendSuccessful: isAmendSuccessful,
      handleResendConfirmationAction: onModalClose,
      arrivalDate: firstRoom.roomStay.arrivalDate,
      bookingSurname: firstRoom === null || firstRoom === void 0 || (_firstRoom$billing = firstRoom.billing) === null || _firstRoom$billing === void 0 ? void 0 : _firstRoom$billing.lastName,
      handleRepeatBookingAction: () => _handleRepeatBookingAction(setInputValuesInSessionStorage)
    }), area === _api.Area.PI && basketReference && (0, _jsxRuntime.jsx)(_ResendConfirmationModal.ResendConfirmationModal, {
      isModalVisible: isModalVisible,
      onModalClose: onModalClose,
      basketReference: basketReference,
      hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId
    }), area === _api.Area.CCUI && (0, _jsxRuntime.jsxs)(_react.Box, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'AgentOverrideInfo'),
      children: [bookingData && OVData && (0, _jsxRuntime.jsx)(_atoms.ErrorBoundary, {
        children: (0, _jsxRuntime.jsx)(_AgentOverrideModal.default, {
          basketReference: basketReferenceValue,
          hotelId: bookingData === null || bookingData === void 0 ? void 0 : bookingData.hotelId,
          reasons: OVData === null || OVData === void 0 || (_OVData$cancellationR = OVData.cancellationReasons) === null || _OVData$cancellationR === void 0 ? void 0 : _OVData$cancellationR.cancellationReasons,
          error: OVError,
          isVisible: isAgentOverrideModalVisible,
          onClose: handleAgentOverrideModalClose,
          getBookingInfo: () => {
            bookingRefetch();
          },
          overridenUserInfo: overridenUserInfo
        })
      }), bookingType === _api.BOOKING_TYPE.UPCOMING && reservationOverriddenStatus && (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "2xl",
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'OverridenNotification'),
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: '',
          maxWidth: "full",
          description: t('ccui.manageBooking.options.agentOverrideModal.notification.message'),
          status: 'success',
          variant: 'success',
          svg: (0, _jsxRuntime.jsx)(_atoms.Success, {})
        })
      })]
    })]
  });
  function handleAgentOverrideModalClose() {
    setIsAgentOverrideModalVisible(false);
  }
  function _handleRepeatBookingAction(setInputValuesInSessionStorage) {
    if (setInputValuesInSessionStorage) {
      setInputValuesInSessionStorage(inputValues, bookingReference);
    }
    router.push("/".concat(country, "/").concat(language, "/repeat-booking?reservationId=").concat(basketReferenceValue));
  }
}
var loadingStyle = {
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  backgroundColor: 'baseWhite',
  textAlign: 'center',
  lineHeight: 3,
  justifyContent: 'center',
  color: 'btnSecondaryEnabled',
  fontSize: 'xl',
  flex: 'none',
  flexGrow: 0,
  order: 1,
  alignSelf: 'stretch'
};