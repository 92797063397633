"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = BusinessAllowancesCCUI;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _uuid = require("uuid");
var _jsxRuntime = require("react/jsx-runtime");
var _templateObject;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function BusinessAllowancesCCUI(_ref) {
  var _businessAllowances$t;
  var {
    toggleDinnerAllowance,
    dinnerAllowance,
    businessAllowances,
    setBusinessAllowances,
    currency,
    language,
    setHasError,
    mealPackagesSelected,
    availableMealsIds = []
  } = _ref;
  var activateMealSelectedRestrictions = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_MEAL_RESTRICTIONS,
    fallbackValue: false
  });
  var activateMealAvailabilityRestrictions = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_MEAL_AVAILABILITY_RESTRICTIONS,
    fallbackValue: false
  });
  var mealAvailable = id => {
    if (activateMealSelectedRestrictions && mealPackagesSelected) {
      return false;
    }
    if (activateMealAvailabilityRestrictions && !!id) {
      return availableMealsIds.includes(id);
    }
    return true;
  };
  var otherAllowancesArray = Object.values(_api.OTHER_ALLOWANCES_CCUI).map(allowance => {
    return _objectSpread(_objectSpread({}, allowance), {}, {
      type: allowance.type,
      enabled: !!(allowance !== null && allowance !== void 0 && allowance.operaId) === false || mealAvailable(allowance === null || allowance === void 0 ? void 0 : allowance.operaId)
    });
  });
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var baseDataTestId = 'BusinessAllowances';
  var refTextWidth = (0, _react2.useRef)(null);
  var [width, setWidth] = (0, _react2.useState)(0);
  var [errorBudgetInput, setErrorBudgetInput] = (0, _react2.useState)('');
  var totalDinnerBudget = String(businessAllowances.totalDinnerBudgetPersonNight);
  var hasValidValue = totalDinnerBudget !== '' && !isNaN(parseInt(totalDinnerBudget, 10));
  var iseurohotelier = currency !== _api.Currency.GBP && language === 'en';
  (0, _react2.useEffect)(() => {
    if (refTextWidth.current) {
      setWidth(refTextWidth.current ? refTextWidth.current.offsetWidth : 0);
    }
  }, []);
  function onBudgetInputChange(totalDinnerBudgetPersonNight) {
    validateBudgetInput(totalDinnerBudgetPersonNight, t('ccui.businessAllowances.inputTitle.invalidMessage'), setErrorBudgetInput);
    setBusinessAllowances(prevState => _objectSpread(_objectSpread({}, prevState), {}, {
      totalDinnerBudgetPersonNight
    }));
  }
  function validateBudgetInput(budget, invalidMessage, handleError) {
    if (!_api.TOTAL_DINNER_BUDGET_REGEX.test(String(budget))) {
      handleError(invalidMessage);
      setHasError(true);
    } else {
      handleError('');
      setHasError(false);
    }
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
    mt: 16,
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, businessAllowancesWrapperStyle), {}, {
    children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
    }, titleStyle), {}, {
      children: t('ccui.businessAllowances.title')
    })), (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, boxStyle), {}, {
      children: [(0, _jsxRuntime.jsx)(_atoms.Checkbox, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleDinnerAllowance'),
        ml: "md",
        onChange: toggleDinnerAllowance,
        isChecked: dinnerAllowance,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleDinnerAllowance-Label')
        }, checkboxLabelStyle), {}, {
          children: t('ccui.businessAllowances.primaryAllowances.dinner')
        }))
      }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsx)(_atoms.Checkbox, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleAlcoholAllowance'),
        ml: "md",
        onChange: () => setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
          isAlcoholDinner: !businessAllowances.isAlcoholDinner
        })),
        isChecked: businessAllowances.isAlcoholDinner,
        isDisabled: !dinnerAllowance,
        children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ToggleAlcoholAllowance-Label')
        }, checkboxLabelStyle), {}, {
          children: t('ccui.businessAllowances.primaryAllowances.alcohol')
        }))
      })]
    })), (0, _jsxRuntime.jsx)(CustomBox, _objectSpread(_objectSpread({}, customBoxStyle), {}, {
      innertextwidth: width,
      currency: currency,
      hasvalue: hasValidValue ? 'true' : 'false',
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "budget-".concat(currency)),
      iseurohotelier: iseurohotelier ? 1 : 0,
      children: (0, _jsxRuntime.jsx)(_atoms.Input, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'DinnerBudget'),
        label: t('ccui.businessAllowances.inputTitle'),
        placeholderText: t('ccui.businessAllowances.inputTitle.placeholder'),
        name: "totalDinnerBudgetPersonNight",
        value: (_businessAllowances$t = businessAllowances.totalDinnerBudgetPersonNight) === null || _businessAllowances$t === void 0 ? void 0 : _businessAllowances$t.toString(),
        isDisabled: !dinnerAllowance,
        error: errorBudgetInput,
        onChange: onBudgetInputChange
      })
    })), (0, _jsxRuntime.jsx)(_react.Text, {
      mb: 1,
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'OtherAllowances'),
      children: t('businessAllowances.otherAllowances')
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "OtherAllowances_Box")
    }, boxStyle), {}, {
      children: otherAllowancesArray.map((allowances, index) => (0, _jsxRuntime.jsxs)(_react.Box, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Checkbox, {
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(allowances.type, "_Checkbox")),
          ml: "md",
          onChange: () => setBusinessAllowances(_objectSpread(_objectSpread({}, businessAllowances), {}, {
            [allowances.type]: !businessAllowances[allowances.type]
          })),
          isChecked: !!businessAllowances[allowances.type],
          isDisabled: !allowances.enabled,
          children: (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "".concat(allowances.type, "_Label"))
          }, checkboxLabelStyle), {}, {
            children: t("ccui.businessAllowances.otherAllowances.".concat(allowances.type))
          }))
        }, "".concat(allowances.type, "_").concat((0, _uuid.v4)())), index < 4 && (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))]
      }, (0, _uuid.v4)()))
    }))]
  }));
}
var customBoxStyle = {
  maxW: '26.25rem',
  my: 'lg',
  mt: 'xl',
  mb: 'xl',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '26.25rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};
var businessAllowancesWrapperStyle = {
  mb: '2xl',
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '25.063rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  }
};
var boxStyle = {
  border: '1px',
  borderColor: 'lightGrey4'
};
var titleStyle = {
  fontSize: 'xl',
  fontWeight: 'semibold',
  mb: 'xl',
  lineHeight: 3
};
var checkboxLabelStyle = {
  fontSize: 'md',
  lineHeight: '3',
  mx: ' sm'
};
var dividerStyles = {
  borderColor: 'lightGrey2',
  my: 'sm'
};
var CustomBox = (0, _styled.default)(_react.Box)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  ", "\n"])), _ref2 => {
  var {
    hasvalue,
    innertextwidth,
    currency,
    iseurohotelier
  } = _ref2;
  return hasvalue === 'true' && "\n  & > div > div:first-of-type {\n    position: relative;\n    &:after {\n      content: '".concat(currency, "';\n      position: absolute;\n      left: ").concat(currency === _api.Currency.GBP || iseurohotelier ? '1.25rem' : "".concat(innertextwidth / 16, "rem"), ";\n      top: 0;\n      height: 100%;\n      padding-left: ").concat(currency === _api.Currency.GBP || iseurohotelier ? 0 : "1.25rem", ";\n      display: flex;\n      align-items: center;\n    },\n  }\n  & > div > div > input {\n    padding-left: 1.875rem;\n  }\n  ");
});