"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Donations;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _image = _interopRequireDefault(require("next/image"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Donations(_ref) {
  var _sortDonations, _donations$donationPa2;
  var {
    data,
    isError,
    error,
    isLoading,
    selectedDonation,
    onDonationChange
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)();
  var donations = data === null || data === void 0 ? void 0 : data.donations;
  var baseDataTestId = 'Donation';
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'LoadingMessage'),
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      children: [(0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Error'),
        children: "Error on getting donations...."
      }), (0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'ErrorMessage'),
        children: error.message
      })]
    });
  }
  if (!donations) {
    return null;
  }
  var getDonationRadio = (donation, index) => (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, radioBtnStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "DonationRadio-".concat(index)),
    onClick: () => onDonationChange(donation),
    children: (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
      type: "DonationRadio-".concat(index),
      isChecked: selectedDonation && selectedDonation.unitPrice === (donation === null || donation === void 0 ? void 0 : donation.unitPrice),
      children: (0, _jsxRuntime.jsx)(_react.Text, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "DonationLabel-".concat(index)),
        children: donation !== null && donation !== void 0 && donation.currency ? t('charityDonation.donate').replace('{amount, number, ::unit-width-iso-code currency/GBP}', "".concat((0, _utils.formatCurrency)(donation.currency)).concat(donation.unitPrice.toFixed(2))) : t('charityDonation.noDonation')
      })
    })
  }), "".concat(donation === null || donation === void 0 ? void 0 : donation.code, "-").concat(donation === null || donation === void 0 ? void 0 : donation.unitPrice));
  function sortDonations() {
    var _donations$donationPa;
    return donations === null || donations === void 0 || (_donations$donationPa = donations.donationPackages) === null || _donations$donationPa === void 0 ? void 0 : _donations$donationPa.sort((a, b) => b.unitPrice - a.unitPrice);
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container'),
    children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, donationCostStyle), {}, {
      children: (0, _jsxRuntime.jsxs)(_react.Flex, {
        direction: "column",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Title')
        }, donationTitleStyle), {}, {
          children: donations === null || donations === void 0 ? void 0 : donations.name
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, contentWrapper), {}, {
          children: [(donations === null || donations === void 0 ? void 0 : donations.imageSrc) && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({
            "data-testid": "image-wrapper"
          }, imageWrapperStyle), {}, {
            children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, imageBoxStyle), {}, {
              children: (0, _jsxRuntime.jsx)(_image.default, {
                "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Image'),
                src: (0, _utils.formatAssetsUrl)(donations === null || donations === void 0 ? void 0 : donations.imageSrc),
                alt: donations === null || donations === void 0 ? void 0 : donations.imageSrc,
                fill: true,
                style: {
                  objectFit: 'cover'
                },
                priority: true,
                loader: (0, _utils.isIVMEnabled)() ? _utils.akamaiImageLoader : undefined
              })
            }), donations === null || donations === void 0 ? void 0 : donations.imageSrc)
          })), (0, _jsxRuntime.jsx)(_react.Box, {
            "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Description'),
            w: "full",
            ml: {
              base: 0,
              md: 'lg'
            },
            dangerouslySetInnerHTML: {
              __html: (0, _utils.formatInnerHTMLAssetUrls)(donations === null || donations === void 0 ? void 0 : donations.description)
            },
            className: "formatLinks"
          })]
        })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
          "data-testid": "donations-wrapper"
        }, donationOptionsStyle), {}, {
          children: [(_sortDonations = sortDonations()) === null || _sortDonations === void 0 ? void 0 : _sortDonations.map((donation, index) => getDonationRadio(donation, index + 1)), !!(donations !== null && donations !== void 0 && (_donations$donationPa2 = donations.donationPackages) !== null && _donations$donationPa2 !== void 0 && _donations$donationPa2.length) && getDonationRadio({
            code: '',
            currency: '',
            unitPrice: 0
          }, 0)]
        }))]
      })
    }))
  });
}
var contentWrapper = {
  w: 'full',
  mb: 'lg',
  direction: {
    mobile: 'column',
    md: 'row'
  }
};
var donationCostStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: 'full'
  },
  mb: '5xl',
  border: '1px solid',
  borderColor: 'lightGrey1',
  borderRadius: 'base',
  p: 'lg'
};
var donationTitleStyle = {
  lineHeight: '4',
  fontSize: '2xl',
  fontWeight: 'semibold',
  mb: 'lg'
};
var radioBtnStyle = {
  w: {
    mobile: 'full',
    md: '9.80rem',
    lg: '11.125rem',
    xl: '12rem'
  },
  mb: {
    mobile: 'md',
    md: 'lg'
  },
  cursor: 'pointer'
};
var donationOptionsStyle = {
  w: 'full',
  justify: {
    mobile: 'start',
    xs: 'space-around',
    sm: 'space-between'
  },
  direction: {
    mobile: 'column',
    md: 'row'
  },
  wrap: 'wrap'
};
var imageWrapperStyle = {
  justify: 'space-between',
  mb: {
    base: 'lg',
    sm: 0
  },
  wrap: {
    mobile: 'nowrap',
    sm: 'wrap'
  },
  direction: {
    mobile: 'row',
    sm: 'column'
  }
};
var imageBoxStyle = {
  pos: 'relative',
  w: {
    mobile: 'full',
    md: '13.75rem'
  },
  h: {
    mobile: '10rem',
    xs: '11.75rem',
    sm: '19.063rem',
    md: '9.25rem'
  },
  _first: {
    mb: {
      mobile: 0,
      sm: 'xl'
    }
  }
};