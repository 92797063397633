"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = PaymentType;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _icons = require("@chakra-ui/icons");
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function PaymentType(_ref) {
  var {
    isLoading,
    isError,
    error,
    data,
    selectedPaymentType,
    selectedPaymentDetail,
    onPaymentTypeClick,
    t,
    disabledOptions,
    hiddenPaymentMethodTypes,
    variant,
    amendPaymentCard,
    initialPaymentType,
    styles,
    isUsedWithTabs,
    onlyShowPayNow
  } = _ref;
  var baseDataTestId = 'PaymentType';
  var FT_ENABLE_PAYPAL = variant === _api.Area.BB ? _api.FT_BB_ENABLE_PAYPAL : _api.FT_PI_ENABLE_PAYPAL;
  var {
    [FT_ENABLE_PAYPAL]: isPayPalEnabled
  } = (0, _utils.useFeatureToggle)();
  var isApplePayGooglePayEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_ENABLE_APPLEPAY_GOOGLEPAY,
    fallbackValue: false
  });
  var [paymentData, setPaymentData] = (0, _react2.useState)(data);
  (0, _react2.useEffect)(() => {
    var firstEnabledMethod = amendPaymentCard !== null && amendPaymentCard !== void 0 ? amendPaymentCard : paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethods.find(pm => pm.enabled);
    if (selectedPaymentType.name === '' && variant === _api.Area.CCUI) {
      firstEnabledMethod && onPaymentTypeClick(firstEnabledMethod);
    }
    if (variant === _api.Area.PI || variant === _api.Area.BB) {
      firstEnabledMethod && onPaymentTypeClick(firstEnabledMethod);
    }
  }, [paymentData]);
  (0, _react2.useEffect)(() => {
    if (isUsedWithTabs && (variant === _api.Area.PI || variant === _api.Area.BB)) {
      handleInitialPaymentType();
    }
  }, [selectedPaymentDetail.type]);
  var handleInitialPaymentType = () => {
    var initialPaymentDetail = selectedPaymentDetail.type === 'default' ? _api.paymentOptions.PAY_ON_ARRIVAL : selectedPaymentDetail.type;
    var selectedPaymentMethod = amendPaymentCard !== null && amendPaymentCard !== void 0 ? amendPaymentCard : paymentData.paymentMethods.find(pm => {
      var _pm$paymentOptions;
      return pm === null || pm === void 0 || (_pm$paymentOptions = pm.paymentOptions) === null || _pm$paymentOptions === void 0 ? void 0 : _pm$paymentOptions.some(option => option.type === initialPaymentDetail && option.enabled);
    });
    selectedPaymentMethod && onPaymentTypeClick(selectedPaymentMethod);
  };
  var isPaymentMethodEnabled = _ref2 => {
    var {
      type,
      enabled
    } = _ref2;
    switch (type) {
      case _api.PAYPAL_PAYMENT:
        return enabled && isPayPalEnabled;
      case _api.APGP_PAYMENT:
        return enabled && isApplePayGooglePayEnabled;
      default:
        return enabled;
    }
  };
  var findAndSelectFirstOptionAvailableOfPaymentType = () => {
    var _selectedPaymentType$, _paymentData$paymentM;
    if (selectedPaymentType !== null && selectedPaymentType !== void 0 && (_selectedPaymentType$ = selectedPaymentType.paymentOptions) !== null && _selectedPaymentType$ !== void 0 && _selectedPaymentType$.some(option => (option === null || option === void 0 ? void 0 : option.enabled) && option.type === (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type))) {
      return;
    }
    var firstOptionAvailable = paymentData === null || paymentData === void 0 || (_paymentData$paymentM = paymentData.paymentMethods) === null || _paymentData$paymentM === void 0 ? void 0 : _paymentData$paymentM.find(method => {
      var _method$paymentOption;
      return (method === null || method === void 0 || (_method$paymentOption = method.paymentOptions) === null || _method$paymentOption === void 0 ? void 0 : _method$paymentOption.some(option => (option === null || option === void 0 ? void 0 : option.enabled) && option.type === (selectedPaymentDetail === null || selectedPaymentDetail === void 0 ? void 0 : selectedPaymentDetail.type))) && isPaymentMethodVisible(method);
    });
    if ((firstOptionAvailable === null || firstOptionAvailable === void 0 ? void 0 : firstOptionAvailable.name) === (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.name) || !firstOptionAvailable && (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.name) === '') return;
    if (firstOptionAvailable && (firstOptionAvailable === null || firstOptionAvailable === void 0 ? void 0 : firstOptionAvailable.name) !== (selectedPaymentType === null || selectedPaymentType === void 0 ? void 0 : selectedPaymentType.name)) {
      onPaymentTypeClick(firstOptionAvailable);
    }
  };
  (0, _react2.useEffect)(() => {
    if (variant === _api.Area.CCUI) {
      findAndSelectFirstOptionAvailableOfPaymentType();
    }
  }, [selectedPaymentDetail]);
  (0, _react2.useEffect)(() => {
    if (initialPaymentType && paymentData) {
      var getInitialPaymentType = paymentData.paymentMethods.find(pm => pm.type === initialPaymentType);
      getInitialPaymentType && onPaymentTypeClick(getInitialPaymentType);
    }
  }, [initialPaymentType]);
  function removeApplePayGoolgePay() {
    var values = [_api.APPLE, _api.GOOGLE];
    var updateData = paymentData.paymentMethods.filter(value => !values.includes(value.name));
    return updateData;
  }
  function getPaymentObject(applePay, googlePay, acceptedCardTypesAPGP) {
    var params = ['order', 'enabled', 'cnpPreSelected', 'cnpOptionAvailable', 'card', 'paymentOptions'];
    var typeOfPayment = applePay !== null && applePay !== void 0 ? applePay : googlePay;
    var paymentParams = params.reduce((acc, param) => {
      acc[param] = typeOfPayment[param];
      return acc;
    }, {});
    return _objectSpread({
      name: t('cc.APGP'),
      type: _api.APGP_PAYMENT,
      subType: '',
      logoSrc: '',
      acceptedCardTypes: acceptedCardTypesAPGP,
      reasons: []
    }, paymentParams);
  }
  (0, _react2.useEffect)(() => {
    var applePay = paymentData.paymentMethods.find(method => (method === null || method === void 0 ? void 0 : method.name) === _api.APPLE);
    var googlePay = paymentData.paymentMethods.find(method => (method === null || method === void 0 ? void 0 : method.name) === _api.GOOGLE);
    var reserveWithoutCard = paymentData.paymentMethods.find(method => {
      var _method$paymentOption2;
      return method === null || method === void 0 || (_method$paymentOption2 = method.paymentOptions) === null || _method$paymentOption2 === void 0 ? void 0 : _method$paymentOption2.some(option => option.type === _api.paymentOptions.RESERVE_WITHOUT_CARD && option.enabled);
    });
    if (applePay || googlePay) {
      var pageUrl = window.location.href;
      var walletConfigured = !pageUrl.includes('localhost') ? (0, _utils.isApplePayConfigured)() : true;
      var acceptedCardTypesAPGP = [];
      if (walletConfigured && applePay !== null && applePay !== void 0 && applePay.enabled) {
        acceptedCardTypesAPGP.push({
          type: 'AP',
          name: 'Apple Pay',
          logoSrc: applePay === null || applePay === void 0 ? void 0 : applePay.logoSrc
        });
      }
      if (googlePay !== null && googlePay !== void 0 && googlePay.enabled) {
        acceptedCardTypesAPGP.push({
          type: 'GP',
          name: 'Google Pay',
          logoSrc: googlePay === null || googlePay === void 0 ? void 0 : googlePay.logoSrc
        });
      }
      var updatedPayment = removeApplePayGoolgePay();
      setPaymentData(() => ({
        paymentMethods: [...(updatedPayment || []), getPaymentObject(applePay, googlePay, acceptedCardTypesAPGP)]
      }));
    }
    if (isUsedWithTabs && reserveWithoutCard) {
      var _paymentData$paymentM2;
      var reserveWithoutCardObject = {
        name: _api.paymentOptions.RESERVE_WITHOUT_CARD,
        type: _api.paymentOptions.RESERVE_WITHOUT_CARD,
        subType: '',
        order: (paymentData === null || paymentData === void 0 || (_paymentData$paymentM2 = paymentData.paymentMethods) === null || _paymentData$paymentM2 === void 0 ? void 0 : _paymentData$paymentM2.length) + 1,
        logoSrc: '',
        enabled: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.enabled) || false,
        cnpPreSelected: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.cnpPreSelected) || false,
        cnpOptionAvailable: (reserveWithoutCard === null || reserveWithoutCard === void 0 ? void 0 : reserveWithoutCard.cnpOptionAvailable) || false,
        acceptedCardTypes: [],
        card: undefined,
        paymentOptions: [{
          type: _api.paymentOptions.RESERVE_WITHOUT_CARD,
          order: 1,
          enabled: true
        }],
        reasons: []
      };
      setPaymentData(prev => ({
        paymentMethods: [...prev.paymentMethods, reserveWithoutCardObject]
      }));
    }
  }, [data, t]);
  var disabledReasonsArray = (0, _react2.useMemo)(() => getDisabledReasonsArray(data === null || data === void 0 ? void 0 : data.paymentMethods, t, variant), [paymentData.paymentMethods, t]);
  if (isError) {
    return (0, _jsxRuntime.jsx)(_jsxRuntime.Fragment, {
      children: error.message
    });
  }
  var isPaymentMethodVisible = paymentMethod => {
    var isVisible = true;
    hiddenPaymentMethodTypes === null || hiddenPaymentMethodTypes === void 0 || hiddenPaymentMethodTypes.forEach(hiddenPaymentMethodType => {
      if ((hiddenPaymentMethodType === null || hiddenPaymentMethodType === void 0 ? void 0 : hiddenPaymentMethodType.type) === paymentMethod.type) {
        if (hiddenPaymentMethodType !== null && hiddenPaymentMethodType !== void 0 && hiddenPaymentMethodType.name) {
          if ((hiddenPaymentMethodType === null || hiddenPaymentMethodType === void 0 ? void 0 : hiddenPaymentMethodType.name) === paymentMethod.name) {
            isVisible = false;
          }
        } else {
          isVisible = false;
        }
      }
    });
    return isVisible;
  };
  var isPaymentMethodInTab = _ref3 => {
    var {
      paymentOptions: options
    } = _ref3;
    if (isUsedWithTabs) {
      var result = options === null || options === void 0 ? void 0 : options.find(option => {
        if ((option === null || option === void 0 ? void 0 : option.type) === _api.paymentOptions.RESERVE_WITHOUT_CARD && selectedPaymentDetail.type === _api.paymentOptions.PAY_ON_ARRIVAL) {
          return option.enabled;
        }
        return selectedPaymentDetail.type === option.type && option.enabled;
      });
      return result === null || result === void 0 ? void 0 : result.enabled;
    }
    return true;
  };
  var getPIBALabelInfo = subType => {
    var pibaLabelInfoObj = {
      [_api.PiCardSubType.PIBA_UK]: 'cc.NEW_PIBA.info',
      [_api.PiCardSubType.PIBA_EU]: 'cc.NEW_PIBA_EURO.info'
    };
    var pibaLabelText = subType ? pibaLabelInfoObj[subType] : '';
    return pibaLabelText;
  };
  var wrapperStyle = paymentTypeWrapperStyle;
  var title = (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
    "data-testid": "payment-type-method_title",
    children: t('cc.title')
  }));
  if (isUsedWithTabs) {
    title = null;
    wrapperStyle = paymentTypeTabsWrapperStyle;
    if (onlyShowPayNow) {
      title = (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyle), {}, {
        "data-testid": "payment-type-method_title",
        children: t('cc.title.selectPaymentMethod')
      }));
      wrapperStyle = tabsWrapperStylePayNowOnly;
    }
  }
  var subtitle = 'cc.subTitle';
  if (isUsedWithTabs) {
    if (selectedPaymentDetail.type === _api.paymentOptions.PAY_NOW) {
      subtitle = 'paymentOptions.PAY_NOW_DESC';
    }
    if (selectedPaymentDetail.type === _api.paymentOptions.PAY_ON_ARRIVAL) {
      subtitle = 'paymentOptions.PAY_ON_ARRIVAL_DESC_LONG';
    }
  }
  return (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread(_objectSpread({
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'Container')
  }, wrapperStyle), styles === null || styles === void 0 ? void 0 : styles.containerStyles), {}, {
    children: [title, (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
      mb: isUsedWithTabs && !onlyShowPayNow ? 'lg' : 'xl',
      "data-testid": "payment-type-method_description",
      children: t(subtitle)
    })), isError && (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "payment-type-method_error",
      children: error.message
    }), isLoading ? (0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": "loading",
      children: (0, _jsxRuntime.jsx)(_react.Spinner, {})
    }) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
      children: [Boolean(disabledReasonsArray === null || disabledReasonsArray === void 0 ? void 0 : disabledReasonsArray.length) && disabledReasonsArray.map((reason, index) => (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "ReasonNotification-".concat(index)),
        mb: "md",
        children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
          svg: (0, _jsxRuntime.jsx)(_icons.InfoOutlineIcon, {}),
          status: "info",
          description: reason,
          variant: "info",
          "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, "ReasonNotificationModal-".concat(index)),
          className: "assist-no-show"
        })
      }, reason)), (0, _jsxRuntime.jsxs)(_react.Box, {
        "data-testid": "payment-type-method_option-wrapper",
        children: [amendPaymentCard && renderRadioBtn(amendPaymentCard, 0), paymentData === null || paymentData === void 0 ? void 0 : paymentData.paymentMethods.filter(pm => isPaymentMethodEnabled(pm) && isPaymentMethodVisible(pm) && pm.enabled && isPaymentMethodInTab(pm)).map((method, index) => {
          return renderRadioBtn(method, index);
        })]
      })]
    })]
  }));
  function renderRadioBtn(method, index) {
    var _method$paymentOption3, _method$card, _method$card2, _method$card3, _method$card4, _method$acceptedCardT;
    var isDisabled = !((_method$paymentOption3 = method.paymentOptions) !== null && _method$paymentOption3 !== void 0 && _method$paymentOption3.some(m => {
      return m === null || m === void 0 ? void 0 : m.enabled;
    }));
    var visibleAndEnabledPaymentMethods = paymentData.paymentMethods.filter(pm => isPaymentMethodEnabled(pm) && isPaymentMethodVisible(pm) && isPaymentMethodInTab(pm));
    var listIndex = index >= visibleAndEnabledPaymentMethods.length - 1 ? 'last' : index;
    var isRadioDisabled = amendPaymentCard && !(amendPaymentCard !== null && amendPaymentCard !== void 0 && amendPaymentCard.enabled) || amendPaymentCard && method !== amendPaymentCard || (disabledOptions === null || disabledOptions === void 0 ? void 0 : disabledOptions.includes(method.type)) || isDisabled;
    var isRadioChecked = isRadioDisabled ? false : selectedPaymentType && method.order === selectedPaymentType.order;
    return (0, _jsxRuntime.jsx)(_atoms.RadioButton, _objectSpread(_objectSpread({
      type: "payment-type-radio-".concat(index)
    }, radioBtnStyle), {}, {
      listIndex: listIndex,
      onChange: () => {
        onPaymentTypeClick(method);
      },
      isDisabled: isRadioDisabled,
      isChecked: isRadioChecked,
      children: (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": (0, _utils.formatDataTestId)('payment-type-method_option', method.name.replace(/ /g, '_')),
        className: method.type === _api.PiCardType.SAVED_CARD ? 'sessioncamhidetext assist-no-show' : '',
        children: (0, _jsxRuntime.jsxs)(_react.Box, {
          ml: "sm",
          "data-testid": "payment-type-method_option-entire-subdiv-".concat(index),
          children: [(0, _jsxRuntime.jsxs)(_react.Box, {
            py: "3px",
            display: method.card ? 'flex' : 'block',
            "data-testid": "payment-type-method_option-subdiv-with-text",
            children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
              fontWeight: isRadioChecked ? 'semibold' : 'normal',
              "data-testid": "payment-type-method_option-text-".concat(method.name),
              children: [t("".concat((0, _utils.displayMethodType)(method, variant))), (method.type === 'SAVED_CARD' || method.type === 'AMEND_SAVED_CARD') && " (".concat((0, _utils.getCardEnding)(method === null || method === void 0 || (_method$card = method.card) === null || _method$card === void 0 ? void 0 : _method$card.cardNumber), ")")]
            })), ((_method$card2 = method.card) === null || _method$card2 === void 0 ? void 0 : _method$card2.logoSrc) && (0, _jsxRuntime.jsx)(_react.Image, _objectSpread(_objectSpread({}, radioImgStyle), {}, {
              src: (0, _utils.formatAssetsUrl)((_method$card3 = method.card) === null || _method$card3 === void 0 ? void 0 : _method$card3.logoSrc),
              alt: (_method$card4 = method.card) === null || _method$card4 === void 0 ? void 0 : _method$card4.cardType
            }))]
          }), method.type === _api.PiCardType.NEW_PIBA && (0, _jsxRuntime.jsx)(_react.Text, {
            children: t(getPIBALabelInfo(method.subType))
          }), method.card && (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
              children: method.card.cardHolderName
            })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, textColor), {}, {
              mb: "sm",
              children: [t('booking.expiryDateShort'), " ", method.card.expiryMonth, "/", method.card.expiryYear]
            }))]
          }), method.type === 'SAVED_CARD' && variant === _api.Area.BB && (0, _jsxRuntime.jsx)(_atoms.Badge, _objectSpread(_objectSpread({}, badgeStyles), {}, {
            variant: "secondary",
            children: t("".concat((0, _utils.displayMethodType)(method, variant)))
          })), method.acceptedCardTypes && (0, _jsxRuntime.jsx)(_react.Flex, {
            dir: "column",
            flexWrap: "wrap",
            py: "1px",
            children: (_method$acceptedCardT = method.acceptedCardTypes) === null || _method$acceptedCardT === void 0 ? void 0 : _method$acceptedCardT.map(el => (0, _react2.createElement)(_react.Image, _objectSpread(_objectSpread({}, radioImgStyle), {}, {
              src: (0, _utils.isUrl)(el === null || el === void 0 ? void 0 : el.logoSrc) ? el === null || el === void 0 ? void 0 : el.logoSrc : (0, _utils.formatAssetsUrl)(el === null || el === void 0 ? void 0 : el.logoSrc),
              alt: el === null || el === void 0 ? void 0 : el.name,
              key: "".concat(method.name, "-").concat(el === null || el === void 0 ? void 0 : el.logoSrc)
            })))
          })]
        })
      }, method.name)
    }));
  }
}
function getDisabledReasonsArray(paymentMethods, t, variant) {
  return paymentMethods.filter(pm => {
    var _pm$reasons;
    return (pm === null || pm === void 0 || (_pm$reasons = pm.reasons) === null || _pm$reasons === void 0 ? void 0 : _pm$reasons.length) && pm.type !== _api.PiCardType.NEW_PIBA && pm.type !== _api.PiCardType.NEW_CARD;
  }).map(pm => {
    var _pm$reasons2;
    return pm && [(_pm$reasons2 = pm.reasons) === null || _pm$reasons2 === void 0 ? void 0 : _pm$reasons2[0], pm === null || pm === void 0 ? void 0 : pm.card, pm === null || pm === void 0 ? void 0 : pm.reasons];
  }).reduce((acc, curr) => {
    var updated;
    var reasons = [...curr[2]];
    if (t("cc.na.".concat(curr[0])).includes('cardEnding')) {
      var _curr$;
      if (curr[0] === 'CARD_EXPIRED_BEFORE_DEPARTURE' && ((_curr$ = curr[1]) === null || _curr$ === void 0 ? void 0 : _curr$.cardType) === _api.BusinessCardType.BUSINESS_CENTRALLY_STORED_CARD) {
        var _getCardEnding, _curr$2;
        updated = t("cc.na.".concat(curr[0], ".bb")).replace('{cardEnding}', (_getCardEnding = (0, _utils.getCardEnding)((_curr$2 = curr[1]) === null || _curr$2 === void 0 ? void 0 : _curr$2.cardNumber)) !== null && _getCardEnding !== void 0 ? _getCardEnding : '');
      } else if (isPibaNotAllowedForHotel(reasons, variant)) {
        updated = getPibaNotificationText(curr, reasons, t);
      } else {
        var _getCardEnding2, _curr$3;
        updated = t("cc.na.".concat(curr[0])).replace('{cardEnding}', (_getCardEnding2 = (0, _utils.getCardEnding)((_curr$3 = curr[1]) === null || _curr$3 === void 0 ? void 0 : _curr$3.cardNumber)) !== null && _getCardEnding2 !== void 0 ? _getCardEnding2 : '');
      }
    } else {
      updated = t("cc.na.".concat(curr[0]));
    }
    acc.push(updated);
    return acc;
  }, []).filter((x, i, a) => x && a.indexOf(x) == i);
}
function isPibaNotAllowedForHotel(reasons, variant) {
  var pibaNotAllowedReasons = [_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_EU_ALLOWED_ONLY_IN_EU, _api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_UK_ALLOWED_ONLY_IN_UK];
  return variant === _api.Area.BB && reasons.some(reason => pibaNotAllowedReasons.includes(reason));
}
function getPibaNotificationText(curr, reasons, t) {
  var pibaNotAllowedTextObj = {
    PIBA_EU_ALLOWED_ONLY_IN_EU: t("cc.na.".concat(_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_EU_ALLOWED_ONLY_IN_EU)),
    PIBA_UK_ALLOWED_ONLY_IN_UK: t("cc.na.".concat(_api.PIBA_NOT_ALLOWED_HOTEL_KEYS.PIBA_UK_ALLOWED_ONLY_IN_UK))
  };
  var updatedValue;
  reasons.forEach(reason => {
    var _pibaNotAllowedTextOb, _getCardEnding3, _curr$4;
    updatedValue = (_pibaNotAllowedTextOb = pibaNotAllowedTextObj[reason]) === null || _pibaNotAllowedTextOb === void 0 ? void 0 : _pibaNotAllowedTextOb.replace('{cardEnding}', (_getCardEnding3 = (0, _utils.getCardEnding)((_curr$4 = curr[1]) === null || _curr$4 === void 0 ? void 0 : _curr$4.cardNumber)) !== null && _getCardEnding3 !== void 0 ? _getCardEnding3 : '');
  });
  return updatedValue;
}
var titleStyle = {
  fontSize: '2xl',
  fontWeight: 'semibold',
  mb: 'md',
  color: 'darkGrey1',
  lineHeight: '2rem'
};
var radioBtnStyle = {
  h: 'fit-content',
  display: 'flex'
};
var radioImgStyle = {
  w: '10',
  h: '6',
  mr: 'xs',
  mb: 'xs'
};
var paymentTypeWrapperStyle = {
  w: {
    mobile: 'full',
    xs: 'full',
    sm: '26.3rem',
    md: '27.563rem',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  mb: '5xl'
};
var paymentTypeTabsWrapperStyle = {
  w: '100%'
};
var badgeStyles = {
  lineHeight: '2',
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  borderColor: 'maroon',
  color: 'maroon'
};
var textColor = {
  color: 'darkGrey1'
};
var tabsWrapperStylePayNowOnly = {
  mb: '4rem',
  width: {
    mobile: '18rem',
    xs: '21.5rem',
    sm: '33.75rem',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  }
};