"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgVisaLogo = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 40,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M37.998 24H2.002C.902 24 0 23.146 0 22.103V1.897C0 .853.901 0 2.002 0h35.995C39.1 0 40 .853 40 1.897v20.206C40 23.146 39.099 24 37.998 24Z",
    fill: "#1A1F70"
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "m16.866 8.142-3.414 7.737h-2.227l-1.68-6.175c-.102-.38-.19-.52-.5-.68-.507-.26-1.343-.506-2.08-.658l.051-.224h3.585a.97.97 0 0 1 .972.788l.887 4.478 2.192-5.266h2.214Zm8.727 5.21c.01-2.042-2.973-2.154-2.952-3.066.006-.278.285-.573.894-.649.302-.037 1.134-.066 2.078.347l.37-1.64A5.92 5.92 0 0 0 24.01 8c-2.082 0-3.548 1.052-3.56 2.558-.013 1.114 1.046 1.735 1.845 2.106.82.379 1.096.622 1.092.96-.005.52-.654.749-1.26.758-1.06.016-1.674-.272-2.164-.489l-.382 1.696c.492.215 1.4.401 2.343.411 2.213 0 3.661-1.04 3.668-2.647Zm5.495 2.527h1.949l-1.701-7.737h-1.799a.961.961 0 0 0-.897.567l-3.16 7.17h2.212l.439-1.155h2.703l.254 1.155Zm-2.347-2.742 1.109-2.905.638 2.905h-1.747Zm-8.864-4.995-1.742 7.737h-2.107l1.743-7.737h2.106Z",
    fill: "#fff"
  })]
}));
var _default = exports.default = SvgVisaLogo;