"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _hotelAvailability = require("./hotelAvailability");
Object.keys(_hotelAvailability).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _hotelAvailability[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _hotelAvailability[key];
    }
  });
});
var _stayRules = require("./stayRules");
Object.keys(_stayRules).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _stayRules[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _stayRules[key];
    }
  });
});
var _roomOccupancyLimitations = require("./roomOccupancyLimitations");
Object.keys(_roomOccupancyLimitations).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roomOccupancyLimitations[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _roomOccupancyLimitations[key];
    }
  });
});
var _rules = require("./rules");
Object.keys(_rules).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _rules[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _rules[key];
    }
  });
});
var _company = require("./company");
Object.keys(_company).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _company[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _company[key];
    }
  });
});