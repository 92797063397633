"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelRestaurantMenuDescription;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelRestaurantMenuDescription(_ref) {
  var {
    menuDescription
  } = _ref;
  var [height, setHeight] = (0, _react2.useState)(0);
  var [isCollapsibleOpen, setIsCollapsibleOpen] = (0, _react2.useState)(false);
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var linkName = isCollapsibleOpen ? t('hoteldetails.readless') : t('hoteldetails.readmore');
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, menuDescriptionBoxStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Collapse, {
        startingHeight: height ? Math.min(height, 95) : 95,
        in: isCollapsibleOpen,
        "data-testid": "menu-description-collapse",
        children: (0, _jsxRuntime.jsx)(_react.Box, {
          ref: node => (0, _utils.handleRefHeightChange)(node, setHeight),
          dangerouslySetInnerHTML: {
            __html: (0, _utils.formatInnerHTMLAssetUrls)(menuDescription)
          },
          className: "formatLinks"
        })
      }), !isCollapsibleOpen && height >= 95 && (0, _jsxRuntime.jsx)(_react.Box, _objectSpread({}, fadeOutBoxStyles))]
    })), height >= 95 && (0, _jsxRuntime.jsx)(_react.Link, {
      color: "btnSecondaryEnabled",
      sx: {
        ':hover': {
          textDecoration: 'none'
        }
      },
      onClick: () => setIsCollapsibleOpen(!isCollapsibleOpen),
      children: linkName
    })]
  });
}
var fadeOutBoxStyles = {
  position: 'absolute',
  bottom: 0,
  h: '3.125rem',
  w: 'full',
  background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, var(--chakra-colors-baseWhite) 90%)'
};
var menuDescriptionBoxStyles = {
  position: 'relative',
  color: 'baseBlack'
};