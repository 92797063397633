"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Basket;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _icons = require("@chakra-ui/icons");
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _router = require("next/router");
var _react2 = _interopRequireWildcard(require("react"));
var _reactDom = _interopRequireDefault(require("react-dom"));
var _BasketQueryWrapper = require("./BasketQueryWrapper");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Basket(_ref) {
  var {
    isCityTaxExempt,
    isLastFewRooms,
    isHDPBasket,
    hasAccessibleRoom,
    hasTwinRoomChoice,
    shouldDisplayMobileBasket,
    numberOfUnits,
    onBookReservation,
    bookRsvIsLoading,
    bookRsvIsError,
    bookRsvError,
    numberOfNights,
    totalReservationAmount,
    currencyCode,
    dailyPricesPerRoom,
    rateName,
    roomCodes: reservationRoomTypes,
    roomTypeInformationResponse,
    isLessThanLg,
    brand,
    channel,
    isDisabledContinueBtn,
    twinroomSelections
  } = _ref;
  var [isBreakdownVisible, setIsBreakdownVisible] = (0, _react2.useState)(false);
  var [domReady, setDomReady] = (0, _react2.useState)(false);
  var [isDisabledChooseRoomBtn, setIsDisabledChooseRoomBtn] = (0, _react2.useState)(false);
  var dateFormat = 'EEE dd MMM y';
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var router = (0, _router.useRouter)();
  var {
    language,
    country
  } = (0, _utils.useCustomLocale)();
  var twinRoomLabels = [t('twinroom.improvedTwin.title'), t('twinroom.standardTwin.title')];
  var isTwinRoomChoiceEnabled = (0, _utils.useFeatureSwitch)({
    featureSwitchKey: _api.FS_DISPLAY_CHOOSE_TWIN_ROOM,
    fallbackValue: false
  });
  (0, _react2.useEffect)(() => {
    setDomReady(true);
    setIsDisabledChooseRoomBtn(false);
  }, []);
  if (bookRsvIsError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: bookRsvError.message
    });
  }
  if (roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.isLoadingRoomTypeInformation) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      "data-testid": "room-types-loading-message",
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (roomTypeInformationResponse !== null && roomTypeInformationResponse !== void 0 && roomTypeInformationResponse.isErrorRoomTypeInformation) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: roomTypeInformationResponse.errorRoomTypeInformation.message
    });
  }
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [!isLessThanLg && isHDPBasket && renderDesktopBasket(), isLessThanLg && shouldDisplayMobileBasket && isHDPBasket && renderMobileBasket(), !shouldDisplayMobileBasket && !isHDPBasket && renderDesktopBasket(), shouldDisplayMobileBasket && !isHDPBasket && renderMobileBasket(), bookRsvIsError && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "lg",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        svg: (0, _jsxRuntime.jsx)(_atoms.Error, {}),
        status: "error",
        description: t('hoteldetails.booking.error'),
        variant: "error"
      })
    }), isCityTaxExempt && (0, _jsxRuntime.jsx)(_react.Box, {
      mt: "lg",
      children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
        svg: (0, _jsxRuntime.jsx)(_icons.InfoOutlineIcon, {}),
        status: "info",
        description: t('config.policyOfCityTaxGlobalMessage.globalMessage'),
        prefixDataTestId: "hdp_basketCityTax",
        variant: "info"
      })
    })]
  });
  function renderDesktopBasket() {
    var _dailyPricesPerRoom$;
    return (0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": "basket",
      w: "19.3125rem",
      children: (0, _jsxRuntime.jsx)(_atoms.Card, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          w: "full",
          children: [isLastFewRooms && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cardHeaderStyles), {}, {
            "data-testid": "hdp_basketLastFewRooms",
            children: t('hoteldetails.lastfewrooms')
          })), numberOfUnits > 1 ? (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, cardRateStyles), {}, {
            "data-testid": "hdp_basketNrOfRooms",
            children: [t('hoteldetails.nrOfRooms', {
              count: numberOfUnits
            }), ",", ' ', "".concat(brand.toLowerCase() === 'hub' ? 'hub ' : '').concat(rateName)]
          })) : (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
            children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cardTitleStyles), {}, {
              "data-testid": "hdp_basketRoomAndRatePlan",
              children: (0, _BasketQueryWrapper.getStandardRoomLabel)(roomTypeInformationResponse, reservationRoomTypes === null || reservationRoomTypes === void 0 ? void 0 : reservationRoomTypes[0], twinRoomLabels, twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[0])
            })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, cardRateStyles), {}, {
              children: "".concat(brand.toLowerCase() === 'hub' ? 'hub ' : '').concat(rateName)
            }))]
          }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), (0, _jsxRuntime.jsxs)(_react.Flex, {
            justify: "space-between",
            "data-testid": "hdp_basketStayPrice",
            children: [(0, _jsxRuntime.jsxs)(_react.Box, {
              "data-testid": "hdp_basketStay",
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, stayPriceStyles), {}, {
                "data-testid": "hdp_basketStayText",
                children: t('pihotelinfo.stay')
              })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, nightsNrStyles), {}, {
                "data-testid": "hdp_basketNrOfNightsStay",
                children: t('hoteldetails.nrOfNights', {
                  count: numberOfNights
                })
              }))]
            }), (0, _jsxRuntime.jsxs)(_react.Box, {
              textAlign: "right",
              "data-testid": "hdp_basketPrice",
              children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, stayPriceStyles), {}, {
                "data-testid": "hdp_basketPriceText",
                children: t('account.dashboard.booking.price')
              })), (0, _jsxRuntime.jsxs)(_react.Flex, {
                children: [!isBreakdownVisible && (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                  href: "#"
                }, isBreakdownVisibleStyles), {}, {
                  "data-testid": "hdp_basketSeeBreakdownLink",
                  onClick: e => handleShowBreakdownClick(e),
                  children: t('pihotelinfo.breakdownShow')
                })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
                  "data-testid": "total-cost-for-nights"
                }, nightsNrStyles), {}, {
                  children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(totalReservationAmount), language)
                }))]
              })]
            })]
          }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles)), isBreakdownVisible && numberOfUnits === 1 && (0, _jsxRuntime.jsxs)(_react.Box, {
            children: [(0, _jsxRuntime.jsx)(_react.Box, {
              children: dailyPricesPerRoom === null || dailyPricesPerRoom === void 0 || (_dailyPricesPerRoom$ = dailyPricesPerRoom[0]) === null || _dailyPricesPerRoom$ === void 0 || (_dailyPricesPerRoom$ = _dailyPricesPerRoom$.slice(0, 14)) === null || _dailyPricesPerRoom$ === void 0 ? void 0 : _dailyPricesPerRoom$.map(item => (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, breakdownRowStyles), {}, {
                "data-testid": "hdp_basketBreakdownRow",
                children: [(0, _jsxRuntime.jsx)("span", {
                  children: (0, _utils.formatDate)(item.date, dateFormat, language)
                }), (0, _jsxRuntime.jsx)("span", {
                  children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(item.netPrice), language)
                })]
              }), item.date))
            }), (0, _jsxRuntime.jsx)(_react.Box, {
              textAlign: "right",
              "data-testid": "hdp_basketHideBreakdown",
              children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                href: "#"
              }, closeBreakdownStyles), {}, {
                "data-testid": "hdp_basketHideBreakdownLink",
                onClick: e => handleCloseBreakdownClick(e),
                children: t('pihotelinfo.breakdownHide')
              }))
            }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))]
          }), isBreakdownVisible && numberOfUnits > 1 && Array.from({
            length: numberOfUnits
          }, (_, i) => i + 1).map(index => {
            var _dailyPricesPerRoom;
            return (0, _jsxRuntime.jsxs)(_react.Box, {
              children: [(0, _jsxRuntime.jsxs)(_react.Text, {
                "data-testid": "breakdown-room-number",
                fontSize: "sm",
                mb: "2",
                children: [(0, _jsxRuntime.jsx)("strong", {
                  children: t('booking.hotel.summary.room').replace('[roomNumber]', String(index))
                }), ' ', "(", (0, _BasketQueryWrapper.getStandardRoomLabel)(roomTypeInformationResponse, reservationRoomTypes === null || reservationRoomTypes === void 0 ? void 0 : reservationRoomTypes[index - 1], twinRoomLabels, twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[index - 1]), ")"]
              }), (0, _jsxRuntime.jsx)(_react.Box, {
                children: dailyPricesPerRoom === null || dailyPricesPerRoom === void 0 || (_dailyPricesPerRoom = dailyPricesPerRoom[index - 1]) === null || _dailyPricesPerRoom === void 0 || (_dailyPricesPerRoom = _dailyPricesPerRoom.slice(0, 14)) === null || _dailyPricesPerRoom === void 0 ? void 0 : _dailyPricesPerRoom.map(item => (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, breakdownRowStyles), {}, {
                  children: [(0, _jsxRuntime.jsx)("span", {
                    children: (0, _utils.formatDate)(item.date, dateFormat, language)
                  }), (0, _jsxRuntime.jsx)("span", {
                    children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(item.netPrice), language)
                  })]
                }), item.date))
              }), index === numberOfUnits && (0, _jsxRuntime.jsx)(_react.Box, {
                textAlign: "right",
                children: (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                  href: "#"
                }, closeBreakdownStyles), {}, {
                  onClick: e => handleCloseBreakdownClick(e),
                  children: t('pihotelinfo.breakdownHide')
                }))
              }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, dividerStyles))]
            }, "rate-breakdown-".concat(index));
          }), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
            wrap: "wrap"
          }, totalCostWrapperStyles), {}, {
            "data-testid": "hdp_basketTotal",
            children: [(0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": "total-cost-title",
              children: t('hoteldetails.rates.total')
            }), (0, _jsxRuntime.jsx)(_react.Text, {
              "data-testid": "total-cost",
              children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(totalReservationAmount), language)
            }), isCityTaxExempt && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({
              align: "right"
            }, cityTaxExemptStyles), {}, {
              "data-testid": "hdp_cityTaxExemptText",
              children: t('hoteldetails.rates.cityTax')
            }))]
          })), renderButton()]
        })
      })
    });
  }
  function renderMobileBasket() {
    var _dailyPricesPerRoom$2;
    return domReady ? _reactDom.default.createPortal((0, _jsxRuntime.jsx)(_react.Box, {
      "data-testid": "mobile-basket",
      w: "full",
      children: (0, _jsxRuntime.jsx)(_atoms.Card, {
        children: (0, _jsxRuntime.jsxs)(_react.Flex, {
          direction: "column",
          w: "full",
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "column",
            children: [isBreakdownVisible && numberOfUnits === 1 && (0, _jsxRuntime.jsxs)(_react.Box, {
              children: [(0, _jsxRuntime.jsx)(_react.Box, {
                children: dailyPricesPerRoom === null || dailyPricesPerRoom === void 0 || (_dailyPricesPerRoom$2 = dailyPricesPerRoom[0]) === null || _dailyPricesPerRoom$2 === void 0 || (_dailyPricesPerRoom$2 = _dailyPricesPerRoom$2.slice(0, 14)) === null || _dailyPricesPerRoom$2 === void 0 ? void 0 : _dailyPricesPerRoom$2.map(item => (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, breakdownRowStyles), {}, {
                  "data-testid": "hdp_mobileBasketBreakdownRow",
                  children: [(0, _jsxRuntime.jsx)("span", {
                    children: (0, _utils.formatDate)(item.date, dateFormat, language)
                  }), (0, _jsxRuntime.jsx)("span", {
                    children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(item.netPrice), language)
                  })]
                }), item.date))
              }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, mobileDividerStyles))]
            }), isBreakdownVisible && numberOfUnits > 1 && Array.from({
              length: numberOfUnits
            }, (_, i) => i + 1).map(index => {
              var _dailyPricesPerRoom2;
              return (0, _jsxRuntime.jsxs)(_react.Box, {
                children: [(0, _jsxRuntime.jsxs)(_react.Text, {
                  "data-testid": "breakdown-room-number",
                  fontSize: "sm",
                  mb: "2",
                  children: [(0, _jsxRuntime.jsx)("strong", {
                    children: t('booking.hotel.summary.room').replace('[roomNumber]', String(index))
                  }), ' ', "(", (0, _BasketQueryWrapper.getStandardRoomLabel)(roomTypeInformationResponse, reservationRoomTypes === null || reservationRoomTypes === void 0 ? void 0 : reservationRoomTypes[index - 1], twinRoomLabels, twinroomSelections === null || twinroomSelections === void 0 ? void 0 : twinroomSelections[index - 1]), ")"]
                }), (0, _jsxRuntime.jsx)(_react.Box, {
                  children: dailyPricesPerRoom === null || dailyPricesPerRoom === void 0 || (_dailyPricesPerRoom2 = dailyPricesPerRoom[index - 1]) === null || _dailyPricesPerRoom2 === void 0 || (_dailyPricesPerRoom2 = _dailyPricesPerRoom2.slice(0, 14)) === null || _dailyPricesPerRoom2 === void 0 ? void 0 : _dailyPricesPerRoom2.map(item => (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, breakdownRowStyles), {}, {
                    children: [(0, _jsxRuntime.jsx)("span", {
                      children: (0, _utils.formatDate)(item.date, dateFormat, language)
                    }), (0, _jsxRuntime.jsx)("span", {
                      children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(item.netPrice), language)
                    })]
                  }), item.date))
                }), (0, _jsxRuntime.jsx)(_react.Divider, _objectSpread({}, mobileDividerStyles))]
              }, "rate-breakdown-".concat(index));
            })]
          }), (0, _jsxRuntime.jsxs)(_react.Flex, {
            children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
              direction: "column",
              w: "full",
              children: [isLastFewRooms && (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, mobileHeaderStyles), {}, {
                "data-testid": "hdp_mobileBasketLastFewRooms",
                children: t('hoteldetails.lastfewrooms')
              })), (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, mobileTotalCostStyles), {}, {
                children: [router.locale === 'gb' ? (0, _jsxRuntime.jsx)(_react.Text, {
                  "data-testid": "mobile-total-cost-title",
                  mr: "1",
                  children: t('hoteldetails.rates.total')
                }) : null, (0, _jsxRuntime.jsx)(_react.Text, {
                  "data-testid": "mobile-total-cost",
                  children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(totalReservationAmount), language)
                })]
              })), !isBreakdownVisible ? (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                href: "#"
              }, isBreakdownVisibleStyles), {}, {
                "data-testid": "hdp_mobileBasketSeeBreakdownLink",
                onClick: e => handleShowBreakdownClick(e),
                children: t('pihotelinfo.breakdownShow')
              })) : (0, _jsxRuntime.jsx)(_react.Link, _objectSpread(_objectSpread({
                href: "#"
              }, mobileCloseBreakdownStyles), {}, {
                "data-testid": "hdp_mobileBasketHideBreakdownLink",
                onClick: e => handleCloseBreakdownClick(e),
                children: t('pihotelinfo.breakdownHide')
              }))]
            }), (0, _jsxRuntime.jsx)(_react.Flex, {
              direction: "column",
              justify: "flex-end",
              align: "center",
              w: "48",
              children: renderButton()
            })]
          })]
        })
      })
    }), document.getElementById('hotel-details-mobile-basket')) : null;
  }
  function renderButton() {
    if (isChooseAccessibleRoom() || isChooseTwinRoom()) {
      return (0, _jsxRuntime.jsx)(_atoms.Button, {
        id: isChooseAccessibleRoom() ? 'chooseBathroomCTA' : 'chooseTwinroomCTA',
        variant: "primary",
        size: shouldDisplayMobileBasket ? 'sm' : 'md',
        w: "full",
        onClick: isChooseAccessibleRoom() ? redirectToChooseBathroomPage : redirectToTwinroomPage,
        isDisabled: isDisabledChooseRoomBtn,
        "data-testid": shouldDisplayMobileBasket ? 'hdp_mobileBasketChooseRoomTypeButton' : 'hdp_basketChooseRoomTypeButton',
        children: t('hoteldetails.rates.chooseroomtype')
      });
    }
    var buttonTextHDP = bookRsvIsLoading || isDisabledContinueBtn ? t('hoteldetails.booking') : t('hoteldetails.booknowtext');
    return (0, _jsxRuntime.jsx)(_atoms.Button, {
      variant: "primary",
      size: shouldDisplayMobileBasket ? 'sm' : 'md',
      w: "full",
      onClick: onBookReservation,
      isDisabled: bookRsvIsLoading || isDisabledContinueBtn,
      "data-testid": shouldDisplayMobileBasket ? 'hdp_mobileBasketBookNowButton' : 'hdp_basketBookNowButton',
      children: isHDPBasket ? buttonTextHDP : t('booking.summary.continue')
    });
  }
  function isChooseAccessibleRoom() {
    return hasAccessibleRoom && isHDPBasket && !isGermanHotel() && !isHubOrZipHotel();
  }
  function isChooseTwinRoom() {
    return hasTwinRoomChoice && !hasAccessibleRoom && isHDPBasket && !isHubOrZipHotel() && isTwinRoomChoiceEnabled && !isGermanHotel();
  }
  function formatRatePrice(price) {
    return price - Math.floor(price) !== 0 ? price === null || price === void 0 ? void 0 : price.toFixed(2) : price;
  }
  function handleShowBreakdownClick(e) {
    e.preventDefault();
    setIsBreakdownVisible(true);
  }
  function handleCloseBreakdownClick(e) {
    e.preventDefault();
    setIsBreakdownVisible(false);
  }
  function redirectToChooseBathroomPage() {
    setIsDisabledChooseRoomBtn(true);
    router.push("/".concat(country, "/").concat(language).concat(channel === 'BB' ? '/business-booker' : '', "/hotels/choose-bathroom"));
  }
  function redirectToTwinroomPage() {
    setIsDisabledChooseRoomBtn(true);
    router.push("/".concat(country, "/").concat(language).concat(channel === 'BB' ? '/business-booker' : '', "/hotels/choose-twinroom"));
  }
  function isGermanHotel() {
    var urlSlug = ['/hotels', ...router.query.slug].join('/');
    return urlSlug.includes('/hotels/germany') || urlSlug.includes('/hotels/deutschland');
  }
  function isHubOrZipHotel() {
    return ['hub', 'zip'].includes(brand === null || brand === void 0 ? void 0 : brand.toLowerCase());
  }
}
var mobileHeaderStyles = {
  lineHeight: '2',
  fontSize: 'sm',
  color: 'alert',
  fontWeight: 'semibold'
};
var cardHeaderStyles = _objectSpread(_objectSpread({}, mobileHeaderStyles), {}, {
  mb: 'sm'
});
var cardTitleStyles = {
  lineHeight: '3',
  fontSize: 'md',
  fontWeight: 'semibold',
  mb: '0.5'
};
var stayPriceStyles = {
  lineHeight: '3',
  fontSize: 'md',
  fontWeight: 'semibold',
  w: 'full',
  mb: '1',
  color: 'darkGrey1'
};
var nightsNrStyles = {
  fontSize: 'md',
  color: 'darkGrey1'
};
var isBreakdownVisibleStyles = {
  fontSize: 'sm',
  mr: 'sm',
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  cursor: 'pointer'
};
var dividerStyles = {
  mt: 'md',
  mb: 'lg'
};
var mobileDividerStyles = {
  my: 'md'
};
var breakdownRowStyles = {
  display: 'flex',
  justifyContent: 'space-between'
};
var mobileCloseBreakdownStyles = {
  textDecoration: 'underline',
  color: 'btnSecondaryEnabled',
  cursor: 'pointer',
  fontSize: 'sm'
};
var closeBreakdownStyles = _objectSpread(_objectSpread({}, mobileCloseBreakdownStyles), {}, {
  mt: 'sm'
});
var totalCostWrapperStyles = {
  justify: 'space-between',
  fontSize: 'xl',
  fontWeight: 'bold',
  color: 'darkGrey1',
  mb: 'lg'
};
var mobileTotalCostStyles = {
  fontSize: 'xl',
  fontWeight: 'bold',
  color: 'darkGrey1'
};
var cityTaxExemptStyles = {
  width: '100%',
  fontSize: 'xs',
  fontWeight: 'normal'
};
var cardRateStyles = {
  lineHeight: '3',
  fontSize: 'md',
  color: 'primary',
  fontWeight: 'semibold',
  mb: '0.5'
};