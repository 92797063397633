"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SEARCH_COMPANY_BY_ID = exports.SEARCH_COMPANIES = void 0;
var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));
var _graphqlRequest = require("graphql-request");
var _templateObject, _templateObject2;
var SEARCH_COMPANIES = exports.SEARCH_COMPANIES = (0, _graphqlRequest.gql)(_templateObject || (_templateObject = (0, _taggedTemplateLiteral2.default)(["\n  query searchCompanies(\n    $searchTerm: String!\n    $negotiatedRateCompanies: Boolean!\n    $limit: Int!\n    $offset: Int!\n  ) {\n    searchCompanies(\n      searchCompaniesCriteria: {\n        companyName: $searchTerm\n        negotiatedRateCompanies: $negotiatedRateCompanies\n        limit: $limit\n        offset: $offset\n      }\n    ) {\n      companies {\n        name\n        address {\n          addressLine1\n          addressLine2\n          addressLine3\n          addressLine4\n          country\n          postalCode\n        }\n        telephoneNumber\n        corpId\n        companyId\n        profileType\n        language\n        active\n        negotiatedRateEnabled\n      }\n    }\n  }\n"])));
var SEARCH_COMPANY_BY_ID = exports.SEARCH_COMPANY_BY_ID = (0, _graphqlRequest.gql)(_templateObject2 || (_templateObject2 = (0, _taggedTemplateLiteral2.default)(["\n  query searchCompanyById($corpId: String!) {\n    companyProfile(companyId: $corpId) {\n      name\n      address {\n        addressLine1\n        addressLine2\n        addressLine3\n        addressLine4\n        country\n        postalCode\n      }\n      telephoneNumber\n      corpId\n      companyId\n      profileType\n      language\n      active\n      negotiatedRateEnabled\n    }\n  }\n"])));