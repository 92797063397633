"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgIron = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 19,
  height: 22,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M7 0c.097 0 .227.042.383.125l.164.097.181.125c.063.046.128.097.196.152l.209.18.22.209.116.114.237.25c.08.087.162.18.245.276l.253.305.13.163.261.346.266.373a19.729 19.729 0 0 1 1.604 2.82l.256.567c1.266 2.9 2.279 6.83 2.279 11.78-.358.107-.711.206-1.06.298L12.333 20H14c1.314 0 1.948-.699 1.997-2.296L16 17.5c0-2.048.86-3.407 2.796-3.495L19 14v1c-1.362 0-1.95.798-1.997 2.3l-.003.2c0 2.18-.965 3.413-2.8 3.496L14 21h-2c-.299.667-.632 1-1 1H3c-.368 0-.702-.333-1-1l-.939-2.82C.712 18.088.36 17.99 0 17.882c0-4.95 1.013-8.88 2.279-11.78l.256-.567a19.733 19.733 0 0 1 1.604-2.82l.266-.373.262-.346.129-.163.253-.305c.083-.097.165-.189.245-.277l.237-.25.115-.113.221-.208.21-.18c.067-.056.132-.107.195-.153l.18-.125.165-.097C6.773.042 6.903 0 7 0ZM2.148 18.443 3 21h8l.853-2.557c-3.352.743-6.353.743-9.705 0ZM7.182 1.188 7 1.057l-.067.047a5.414 5.414 0 0 0-.794.708c-.729.767-1.497 1.847-2.196 3.147-1.699 3.162-2.775 7.15-2.925 11.796l-.009.374.259.071c4.037 1.067 7.427 1.067 11.464 0l.258-.071-.008-.374c-.144-4.46-1.141-8.314-2.724-11.413l-.201-.383c-.699-1.3-1.467-2.38-2.196-3.147a6 6 0 0 0-.679-.624ZM3.005 14.88a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm8 0a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm-7.505-4a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm7.005 0a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm-6-4a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm5 0a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Zm-2.5-4a.625.625 0 1 1 0 1.25.625.625 0 0 1 0-1.25Z",
    fill: props.color || '#58595B'
  })
}));
var _default = exports.default = SvgIron;