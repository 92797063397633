"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  useBookingConfimationData: true,
  useBookingSpinner: true,
  useCompanyDetails: true,
  useCustomLocale: true,
  useDebounce: true,
  useElementVisited: true,
  useFeatureSwitch: true,
  useFeatureToggle: true,
  useHotelAvailability: true,
  useHotelAvailabilityBB: true,
  useHotelRatesInformationBB: true,
  useHotelAvailabilityCCUI: true,
  useHotelRatesInformationCCUI: true,
  useHotelBrands: true,
  useIsExternalSearch: true,
  useSetOrientation: true,
  usePackages: true,
  usePaymentPaypal: true,
  usePollBasketStatus: true,
  useSetScreenSize: true,
  useScreenSize: true,
  useScrolledPast: true,
  useSilentRoomsMatch: true,
  useStaticHotelInformation: true,
  useUserDetails: true
};
Object.defineProperty(exports, "useBookingConfimationData", {
  enumerable: true,
  get: function get() {
    return _useBookingConfirmationData.default;
  }
});
Object.defineProperty(exports, "useBookingSpinner", {
  enumerable: true,
  get: function get() {
    return _useBookingSpinner.default;
  }
});
Object.defineProperty(exports, "useCompanyDetails", {
  enumerable: true,
  get: function get() {
    return _useCompanyDetails.default;
  }
});
Object.defineProperty(exports, "useCustomLocale", {
  enumerable: true,
  get: function get() {
    return _useCustomLocale.default;
  }
});
Object.defineProperty(exports, "useDebounce", {
  enumerable: true,
  get: function get() {
    return _useDebounce.default;
  }
});
Object.defineProperty(exports, "useElementVisited", {
  enumerable: true,
  get: function get() {
    return _useElementVisited.default;
  }
});
Object.defineProperty(exports, "useFeatureSwitch", {
  enumerable: true,
  get: function get() {
    return _useFeatureSwitch.default;
  }
});
Object.defineProperty(exports, "useFeatureToggle", {
  enumerable: true,
  get: function get() {
    return _useFeatureToggle.default;
  }
});
Object.defineProperty(exports, "useHotelAvailability", {
  enumerable: true,
  get: function get() {
    return _useHotelAvailability.useHotelAvailability;
  }
});
Object.defineProperty(exports, "useHotelAvailabilityBB", {
  enumerable: true,
  get: function get() {
    return _useHotelAvailability.useHotelAvailabilityBB;
  }
});
Object.defineProperty(exports, "useHotelAvailabilityCCUI", {
  enumerable: true,
  get: function get() {
    return _useHotelAvailability.useHotelAvailabilityCCUI;
  }
});
Object.defineProperty(exports, "useHotelBrands", {
  enumerable: true,
  get: function get() {
    return _useHotelBrand.default;
  }
});
Object.defineProperty(exports, "useHotelRatesInformationBB", {
  enumerable: true,
  get: function get() {
    return _useHotelAvailability.useHotelRatesInformationBB;
  }
});
Object.defineProperty(exports, "useHotelRatesInformationCCUI", {
  enumerable: true,
  get: function get() {
    return _useHotelAvailability.useHotelRatesInformationCCUI;
  }
});
Object.defineProperty(exports, "useIsExternalSearch", {
  enumerable: true,
  get: function get() {
    return _useIsExternalSearch.default;
  }
});
Object.defineProperty(exports, "usePackages", {
  enumerable: true,
  get: function get() {
    return _usePackages.default;
  }
});
Object.defineProperty(exports, "usePaymentPaypal", {
  enumerable: true,
  get: function get() {
    return _usePaymentPaypal.usePaymentPaypal;
  }
});
Object.defineProperty(exports, "usePollBasketStatus", {
  enumerable: true,
  get: function get() {
    return _usePollBasketStatus.default;
  }
});
Object.defineProperty(exports, "useScreenSize", {
  enumerable: true,
  get: function get() {
    return _useScreensize.useScreenSize;
  }
});
Object.defineProperty(exports, "useScrolledPast", {
  enumerable: true,
  get: function get() {
    return _useScrolledPast.default;
  }
});
Object.defineProperty(exports, "useSetOrientation", {
  enumerable: true,
  get: function get() {
    return _useOrientation.default;
  }
});
Object.defineProperty(exports, "useSetScreenSize", {
  enumerable: true,
  get: function get() {
    return _useScreensize.default;
  }
});
Object.defineProperty(exports, "useSilentRoomsMatch", {
  enumerable: true,
  get: function get() {
    return _useSilentRoomsMatch.default;
  }
});
Object.defineProperty(exports, "useStaticHotelInformation", {
  enumerable: true,
  get: function get() {
    return _useStaticHotelInformation.default;
  }
});
Object.defineProperty(exports, "useUserDetails", {
  enumerable: true,
  get: function get() {
    return _useUserDetails.default;
  }
});
var _useBookingConfirmationData = _interopRequireDefault(require("./use-booking-confirmation-data"));
var _useBookingSpinner = _interopRequireDefault(require("./use-booking-spinner"));
var _useCompanyDetails = _interopRequireDefault(require("./use-company-details"));
var _useCustomLocale = _interopRequireDefault(require("./use-custom-locale"));
var _useDebounce = _interopRequireDefault(require("./use-debounce"));
var _useElementVisited = _interopRequireDefault(require("./use-element-visited"));
var _useFeatureSwitch = _interopRequireDefault(require("./use-feature-switch"));
var _useFeatureToggle = _interopRequireDefault(require("./use-feature-toggle"));
var _useHotelAvailability = require("./use-hotel-availability");
var _useHotelBrand = _interopRequireDefault(require("./use-hotelBrand"));
var _useIsExternalSearch = _interopRequireDefault(require("./use-is-external-search"));
var _useOrientation = _interopRequireWildcard(require("./use-orientation"));
Object.keys(_useOrientation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useOrientation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useOrientation[key];
    }
  });
});
var _usePackages = _interopRequireDefault(require("./use-packages"));
var _usePaymentPaypal = require("./use-payment-paypal");
var _usePollBasketStatus = _interopRequireDefault(require("./use-poll-basket-status"));
var _useScreensize = _interopRequireWildcard(require("./use-screensize"));
var _useScrolledPast = _interopRequireDefault(require("./use-scrolled-past"));
var _useSilentRoomsMatch = _interopRequireDefault(require("./use-silent-rooms-match"));
var _useStaticHotelInformation = _interopRequireDefault(require("./use-static-hotel-information"));
var _useUserDetails = _interopRequireDefault(require("./use-user-details"));
var _useIpageSubmission = require("./use-ipage-submission");
Object.keys(_useIpageSubmission).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useIpageSubmission[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useIpageSubmission[key];
    }
  });
});
var _useLocalStorage = require("./use-local-storage");
Object.keys(_useLocalStorage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useLocalStorage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useLocalStorage[key];
    }
  });
});
var _useRequest = require("./use-request");
Object.keys(_useRequest).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useRequest[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useRequest[key];
    }
  });
});
var _useSessionStorage = require("./use-session-storage");
Object.keys(_useSessionStorage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  if (key in exports && exports[key] === _useSessionStorage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _useSessionStorage[key];
    }
  });
});
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }