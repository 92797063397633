"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FS_TRIGGER_DOUBLE_OPT_IN = exports.FS_SILENT_SUBSTITUTION = exports.FS_SHOW_AMEND_PAYMENT_PAGE = exports.FS_RE_USE_GUEST = exports.FS_MEAL_RESTRICTIONS = exports.FS_MEAL_AVAILABILITY_RESTRICTIONS = exports.FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES_AMEND = exports.FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES = exports.FS_HDP_KEY_HOTEL_FACTS_CCUI = exports.FS_HDP_HOTEL_FAQ = exports.FS_HDP_BREADCRUMBS = exports.FS_GDP_SINGLE_BOOKING_REDESIGN = exports.FS_GDP_MULTI_BOOKING_REDESIGN = exports.FS_GDP_CAPTURE_BILLING_ADDRESS = exports.FS_ENHANCED_SEARCH_CRITERIA = exports.FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = exports.FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = exports.FS_ENABLE_OPENSEARCH_LOGS = exports.FS_ENABLE_APPLEPAY_GOOGLEPAY = exports.FS_ENABLE_AMEND_PAY_NOW_PI_BB = exports.FS_DISPLAY_REGISTER_GDP = exports.FS_DISPLAY_REGISTER_CTA = exports.FS_DISPLAY_MEALS_AND_COMPANY_NOTIFICATION = exports.FS_DISPLAY_LOGIN_CTA = exports.FS_DISPLAY_GUEST_ACCOUNT = exports.FS_DISPLAY_CHOOSE_TWIN_ROOM = exports.FS_DISPLAY_AMEND_EDIT_BOOKER_EMAIL_ADDRESS = exports.FS_CONTRACT_RATE_CODE = exports.FS_CONFIRM_EMAIL_ADDRESS_CCUI = exports.FS_CHANGE_LOG = exports.FS_ACCOUNT_TO_COMPANY = void 0;
var FS_DISPLAY_MEALS_AND_COMPANY_NOTIFICATION = exports.FS_DISPLAY_MEALS_AND_COMPANY_NOTIFICATION = 'NEXT_FS_DISPLAY_MEALS_AND_COMPANY_NOTIFICATION';
var FS_CONTRACT_RATE_CODE = exports.FS_CONTRACT_RATE_CODE = 'NEXT_FS_CONTRACT_RATE_CODE';
var FS_ENHANCED_SEARCH_CRITERIA = exports.FS_ENHANCED_SEARCH_CRITERIA = 'NEXT_FS_ENHANCED_SEARCH_CRITERIA';
var FS_ACCOUNT_TO_COMPANY = exports.FS_ACCOUNT_TO_COMPANY = 'NEXT_FS_ACCOUNT_TO_COMPANY';
var FS_MEAL_RESTRICTIONS = exports.FS_MEAL_RESTRICTIONS = 'NEXT_FS_MEAL_RESTRICTIONS';
var FS_MEAL_AVAILABILITY_RESTRICTIONS = exports.FS_MEAL_AVAILABILITY_RESTRICTIONS = 'NEXT_FS_MEAL_AVAILABILITY_RESTRICTIONS';
var FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = exports.FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES = 'NEXT_FS_ENABLE_SEARCH_ACCOUNT_SAVE_CHANGES';
var FS_DISPLAY_GUEST_ACCOUNT = exports.FS_DISPLAY_GUEST_ACCOUNT = 'NEXT_FS_DISPLAY_GUEST_ACCOUNT';
var FS_RE_USE_GUEST = exports.FS_RE_USE_GUEST = 'NEXT_FS_RE_USE_GUEST';
var FS_DISPLAY_AMEND_EDIT_BOOKER_EMAIL_ADDRESS = exports.FS_DISPLAY_AMEND_EDIT_BOOKER_EMAIL_ADDRESS = 'NEXT_FS_DISPLAY_AMEND_EDIT_BOOKER_EMAIL_ADDRESS';
var FS_CONFIRM_EMAIL_ADDRESS_CCUI = exports.FS_CONFIRM_EMAIL_ADDRESS_CCUI = 'NEXT_FS_CONFIRM_EMAIL_ADDRESS_CCUI';
var FS_HDP_KEY_HOTEL_FACTS_CCUI = exports.FS_HDP_KEY_HOTEL_FACTS_CCUI = 'NEXT_FS_HDP_KEY_HOTEL_FACTS_CCUI';
var FS_CHANGE_LOG = exports.FS_CHANGE_LOG = 'NEXT_FS_CHANGE_LOG';
var FS_SHOW_AMEND_PAYMENT_PAGE = exports.FS_SHOW_AMEND_PAYMENT_PAGE = 'NEXT_FS_SHOW_AMEND_PAYMENT_PAGE';
var FS_DISPLAY_REGISTER_CTA = exports.FS_DISPLAY_REGISTER_CTA = 'NEXT_PUBLIC_DISPLAY_REGISTER_CTA';
var FS_DISPLAY_LOGIN_CTA = exports.FS_DISPLAY_LOGIN_CTA = 'NEXT_PUBLIC_DISPLAY_LOGIN_CTA';
var FS_DISPLAY_REGISTER_GDP = exports.FS_DISPLAY_REGISTER_GDP = 'NEXT_PUBLIC_DISPLAY_REGISTER_GDP';
var FS_TRIGGER_DOUBLE_OPT_IN = exports.FS_TRIGGER_DOUBLE_OPT_IN = 'NEXT_PUBLIC_TRIGGER_DOUBLE_OPT_IN';
var FS_DISPLAY_CHOOSE_TWIN_ROOM = exports.FS_DISPLAY_CHOOSE_TWIN_ROOM = 'NEXT_PUBLIC_DISPLAY_CHOOSE_TWIN_ROOM';
var FS_ENABLE_AMEND_PAY_NOW_PI_BB = exports.FS_ENABLE_AMEND_PAY_NOW_PI_BB = 'NEXT_ENABLE_AMEND_PAY_NOW_PI_BB';
var FS_ENABLE_APPLEPAY_GOOGLEPAY = exports.FS_ENABLE_APPLEPAY_GOOGLEPAY = 'NEXT_PUBLIC_APPLEPAY_GOOGLEPAY_ENABLE';
var FS_HDP_HOTEL_FAQ = exports.FS_HDP_HOTEL_FAQ = 'NEXT_FS_HDP_HOTEL_FAQ';
var FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES = exports.FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES = 'NEXT_FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES';
var FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES_AMEND = exports.FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES_AMEND = 'NEXT_FS_HIDE_FOOD_AND_BEVERAGE_PACKAGES_AMEND';
var FS_SILENT_SUBSTITUTION = exports.FS_SILENT_SUBSTITUTION = 'NEXT_FS_SILENT_SUBSTITUTION';
var FS_HDP_BREADCRUMBS = exports.FS_HDP_BREADCRUMBS = 'NEXT_FS_HDP_BREADCRUMBS';
var FS_GDP_SINGLE_BOOKING_REDESIGN = exports.FS_GDP_SINGLE_BOOKING_REDESIGN = 'NEXT_FS_GDP_SINGLE_BOOKING_REDESIGN';
var FS_GDP_MULTI_BOOKING_REDESIGN = exports.FS_GDP_MULTI_BOOKING_REDESIGN = 'NEXT_FS_GDP_MULTI_BOOKING_REDESIGN';
var FS_GDP_CAPTURE_BILLING_ADDRESS = exports.FS_GDP_CAPTURE_BILLING_ADDRESS = 'NEXT_FS_GDP_CAPTURE_BILLING_ADDRESS';
var FS_ENABLE_OPENSEARCH_LOGS = exports.FS_ENABLE_OPENSEARCH_LOGS = 'NEXT_OPENSEARCH_LOGS';
var FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = exports.FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS = 'NEXT_FS_ENABLE_SCRIPTS_EMBED_APP_DYNAMICS';