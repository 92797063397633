"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBartRoomTypeLabels = getBartRoomTypeLabels;
exports.getBookingDetailsForCard = getBookingDetailsForCard;
exports.getCardEnding = getCardEnding;
exports.getDistanceUnitBasedOnLocale = getDistanceUnitBasedOnLocale;
exports.getDistanceUnitBasedOnLocaleSb = getDistanceUnitBasedOnLocaleSb;
exports.getIDVPassedStatus = void 0;
exports.getMaxValueFromHDPRoomTypes = getMaxValueFromHDPRoomTypes;
exports.getMaxValueFromRoomStays = getMaxValueFromRoomStays;
exports.getMaxValueFromRoomStaysBC = getMaxValueFromRoomStaysBC;
exports.getMealForCard = getMealForCard;
exports.getMealQuantity = void 0;
exports.getNightsNumber = getNightsNumber;
exports.getNoOfDaysInYear = getNoOfDaysInYear;
exports.getObjRoomLabelForRoomCodes = getObjRoomLabelForRoomCodes;
exports.getPlaceholderString = getPlaceholderString;
exports.getRoomDetailsForBartCard = getRoomDetailsForBartCard;
exports.getRoomDetailsForCard = getRoomDetailsForCard;
exports.getRoomTypeLabelsBySourceSystem = getRoomTypeLabelsBySourceSystem;
exports.getRoomsPlaceholder = getRoomsPlaceholder;
exports.getSecureTwoURL = getSecureTwoURL;
exports.getSelectedDonationPackage = getSelectedDonationPackage;
exports.getSessionStorageValuesForBookings = getSessionStorageValuesForBookings;
exports.getSortedCountries = getSortedCountries;
exports.getSortedCountriesByCurrentLang = getSortedCountriesByCurrentLang;
exports.getSortedCountriesByCurrentLangCcui = getSortedCountriesByCurrentLangCcui;
exports.getTotalPeople = getTotalPeople;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _api = require("@whitbread-eos/api");
var _differenceInDays = _interopRequireDefault(require("date-fns/differenceInDays"));
var _config = _interopRequireDefault(require("next/config"));
var _helpers = require("../helpers");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function getNightsNumber(arrivalDate, departureDate) {
  var arrival = new Date("".concat(arrivalDate, "T00:00:00"));
  var departure = new Date("".concat(departureDate, "T23:59:59"));
  return (0, _differenceInDays.default)(departure, arrival);
}
function getNoOfDaysInYear(year) {
  return year % 4 === 0 && year % 100 > 0 || year % 400 == 0 ? 366 : 365;
}
function getDistanceUnitBasedOnLocale(locale) {
  return locale === _api.LanguageEnum.ENGLISH ? 'MILES' : 'KILOMETERS';
}
function getDistanceUnitBasedOnLocaleSb(locale) {
  return locale === _api.LanguageEnum.ENGLISH ? 'mi' : 'km';
}
function getCardEnding(cardToken) {
  return cardToken === null || cardToken === void 0 ? void 0 : cardToken.substring((cardToken === null || cardToken === void 0 ? void 0 : cardToken.length) - 4);
}
function getSecureTwoURL() {
  var {
    publicRuntimeConfig = {}
  } = (0, _config.default)() || {};
  if (Object.keys(publicRuntimeConfig).includes('NEXT_PUBLIC_SECURE2_URL')) {
    return publicRuntimeConfig.NEXT_PUBLIC_SECURE2_URL;
  } else {
    return typeof window !== 'undefined' ? window.location.hostname.replace(/^www/gim, 'https://secure2') : '';
  }
}
function getSortedCountries(data) {
  if (!data || (data === null || data === void 0 ? void 0 : data.countries.length) === 0) {
    return;
  }
  var sortByCountryLegend = (a, b) => a.countryLegend > b.countryLegend ? 1 : -1;
  var sortCountries = countries => countries === null || countries === void 0 ? void 0 : countries.sort(sortByCountryLegend);
  var sortedCountries = sortCountries(data.countries);
  return sortedCountries.filter((value, index, self) => {
    var currentIndex = self.findIndex(c => c['countryCodeISO'] === value['countryCodeISO']);
    return index === currentIndex;
  });
}
function getSortedCountriesByCurrentLang(data, currentLang) {
  if (!data || (data === null || data === void 0 ? void 0 : data.length) === 0) {
    return [];
  }
  var currentCountrySite = currentLang === _api.LanguageEnum.GERMAN ? _api.ShortCountry.DE : _api.ShortCountry.GB;
  var sortCountriesByCurrentLang = (a, b) => {
    if (a.countryCode === currentCountrySite) {
      return -1;
    }
    if (b.countryCode === currentCountrySite) {
      return 1;
    }
    if (a.countryName < b.countryName) {
      return -1;
    }
    if (a.countryName > b.countryName) {
      return 1;
    }
    return 0;
  };
  return data.sort(sortCountriesByCurrentLang);
}
function getSortedCountriesByCurrentLangCcui(data, currentLang) {
  if (!data || (data === null || data === void 0 ? void 0 : data.length) === 0) {
    return [];
  }
  var currentCountrySite = currentLang === _api.LanguageEnum.GERMAN ? _api.ShortCountry.DE : _api.ShortCountry.GB;
  var sortCountriesByCurrentLang = (a, b) => {
    if (a.countryCodeISO === currentCountrySite) {
      return -1;
    }
    if (b.countryCodeISO === currentCountrySite) {
      return 1;
    }
    if (a.countryLegend < b.countryLegend) {
      return -1;
    }
    if (a.countryLegend > b.countryLegend) {
      return 1;
    }
    return 0;
  };
  return data.sort(sortCountriesByCurrentLang);
}
function getMaxValueFromRoomStays(data, field) {
  return data === null || data === void 0 ? void 0 : data.reduce((cur, val) => {
    if (!(val !== null && val !== void 0 && val.roomStay)) return cur;
    var fieldValue = val.roomStay[field];
    if (fieldValue !== undefined && fieldValue !== null) {
      return Math.max(cur, fieldValue);
    } else {
      return cur;
    }
  }, 0);
}
function getMaxValueFromRoomStaysBC(data, field) {
  return data === null || data === void 0 ? void 0 : data.reduce((cur, val) => {
    return cur < val.roomStay[field] ? val.roomStay[field] : cur;
  }, 0);
}
function getMaxValueFromHDPRoomTypes(data, field) {
  return data === null || data === void 0 ? void 0 : data.reduce((cur, val) => {
    return cur < val[field] ? val[field] : cur;
  }, 0);
}
function getTotalPeople(rooms) {
  return rooms.reduce((acc, cur) => {
    acc.adults += +cur.adults;
    acc.children += +cur.children;
    return acc;
  }, {
    adults: 0,
    children: 0
  });
}
function getPlaceholderString(obj, labels) {
  var {
    adult,
    adults,
    child,
    children,
    room,
    rooms
  } = labels;
  var adultsLabel = obj.adults === 1 ? adult : adults;
  var childrenObj = obj.children === 1 ? child : children;
  var childrenLabel = obj.children === 0 ? '' : childrenObj;
  var roomsLabel = obj.rooms === 1 ? room : rooms;
  return obj.children === 0 ? "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel) : "".concat(obj.adults, " ").concat(adultsLabel, ", ").concat(obj.children, " ").concat(childrenLabel, ", ").concat(obj.rooms, " ").concat(roomsLabel);
}
function getRoomsPlaceholder(rooms, labels) {
  if (!(rooms !== null && rooms !== void 0 && rooms.length)) return;
  var totalRooms = rooms === null || rooms === void 0 ? void 0 : rooms.length;
  var totalPeople = getTotalPeople(rooms);
  var placeholderStr = getPlaceholderString(_objectSpread(_objectSpread({}, totalPeople), {}, {
    rooms: totalRooms
  }), labels);
  return placeholderStr;
}
function getSelectedDonationPackage(donationPackages, roomSelection) {
  return donationPackages.find(donationPack => {
    var _roomSelection$packag;
    return ((_roomSelection$packag = roomSelection === null || roomSelection === void 0 ? void 0 : roomSelection.packagesSelection) !== null && _roomSelection$packag !== void 0 ? _roomSelection$packag : []).find(pkg => pkg.id === donationPack.code);
  });
}
var getIDVPassedStatus = dpaInfoParam => {
  var idvPassedStatus = false;
  if (dpaInfoParam.dpaPassed && !dpaInfoParam.dpaOverride || !dpaInfoParam.dpaPassed && dpaInfoParam.dpaOverride) {
    idvPassedStatus = true;
  }
  return idvPassedStatus;
};
exports.getIDVPassedStatus = getIDVPassedStatus;
function getBartRoomTypeLabels(BART_ROOM_TYPE_LABELS_OBJ, t) {
  var bartRoomTypeLabels = _objectSpread({}, BART_ROOM_TYPE_LABELS_OBJ);
  for (var key in bartRoomTypeLabels) {
    bartRoomTypeLabels[key] = t(bartRoomTypeLabels[key]);
  }
  return bartRoomTypeLabels;
}
function getObjRoomLabelForRoomCodes(key) {
  var objRoomLabelForRoomCodes = {};
  key.roomTypeCode.forEach(code => {
    objRoomLabelForRoomCodes[code] = key.roomLabel;
  });
  return objRoomLabelForRoomCodes;
}
function getRoomTypeLabelsBySourceSystem(roomTypeLabels, sourceSystem, t) {
  var roomTypeLabelsBySourceSystem;
  if (sourceSystem === _api.SOURCE_SYSTEM.BART) {
    roomTypeLabelsBySourceSystem = getBartRoomTypeLabels(_api.BART_ROOM_TYPE_LABELS_OBJ, t);
  } else {
    roomTypeLabelsBySourceSystem = roomTypeLabels.reduce((obj, key) => Object.assign(obj, getObjRoomLabelForRoomCodes(key)), {});
  }
  return roomTypeLabelsBySourceSystem;
}
function getMealForCard(meal) {
  var _meal$totalPrice, _meal$noSelections;
  return {
    title: meal === null || meal === void 0 ? void 0 : meal.description,
    id: meal === null || meal === void 0 ? void 0 : meal.packageCode,
    price: meal === null || meal === void 0 || (_meal$totalPrice = meal.totalPrice) === null || _meal$totalPrice === void 0 ? void 0 : _meal$totalPrice.amount,
    noSelections: (_meal$noSelections = meal === null || meal === void 0 ? void 0 : meal.noSelections) !== null && _meal$noSelections !== void 0 ? _meal$noSelections : 0
  };
}
function getRoomDetailsForCard(reservationDetails, room, bookedRoomType) {
  var _room$guest, _room$guest2, _room$roomCost$amount, _room$roomCost, _room$adultsMeal$map, _room$adultsMeal, _room$kidsMeal$map, _room$kidsMeal, _room$cot, _room$adults, _room$children, _reservationDetails$n;
  return {
    leadGuestName: "".concat(room === null || room === void 0 || (_room$guest = room.guest) === null || _room$guest === void 0 ? void 0 : _room$guest.firstName, " ").concat(room === null || room === void 0 || (_room$guest2 = room.guest) === null || _room$guest2 === void 0 ? void 0 : _room$guest2.lastName),
    roomType: bookedRoomType,
    roomPrice: (_room$roomCost$amount = room === null || room === void 0 || (_room$roomCost = room.roomCost) === null || _room$roomCost === void 0 ? void 0 : _room$roomCost.amount) !== null && _room$roomCost$amount !== void 0 ? _room$roomCost$amount : 0,
    adultMealDescription: (_room$adultsMeal$map = room === null || room === void 0 || (_room$adultsMeal = room.adultsMeal) === null || _room$adultsMeal === void 0 ? void 0 : _room$adultsMeal.map(meal => {
      return getMealForCard(meal);
    })) !== null && _room$adultsMeal$map !== void 0 ? _room$adultsMeal$map : [],
    childrenMealDescription: (_room$kidsMeal$map = room === null || room === void 0 || (_room$kidsMeal = room.kidsMeal) === null || _room$kidsMeal === void 0 ? void 0 : _room$kidsMeal.map(meal => {
      return getMealForCard(meal);
    })) !== null && _room$kidsMeal$map !== void 0 ? _room$kidsMeal$map : [],
    mealPrice: 0,
    cot: (_room$cot = room === null || room === void 0 ? void 0 : room.cot) !== null && _room$cot !== void 0 ? _room$cot : false,
    noAdults: (_room$adults = room === null || room === void 0 ? void 0 : room.adults) !== null && _room$adults !== void 0 ? _room$adults : 1,
    noChildren: (_room$children = room === null || room === void 0 ? void 0 : room.children) !== null && _room$children !== void 0 ? _room$children : 0,
    noNights: (_reservationDetails$n = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.nights) !== null && _reservationDetails$n !== void 0 ? _reservationDetails$n : 1
  };
}
function getRoomDetailsForBartCard(room, bookedRoomType) {
  var _room$reservationGues, _room$reservationGues2, _room$totalRoomCost;
  return {
    adultsNumber: room === null || room === void 0 ? void 0 : room.adultsNumber,
    childrenNumber: room === null || room === void 0 ? void 0 : room.childrenNumber,
    arrivalDate: room === null || room === void 0 ? void 0 : room.arrivalDate,
    departureDate: room === null || room === void 0 ? void 0 : room.departureDate,
    packages: room === null || room === void 0 ? void 0 : room.packages,
    leadGuestName: "".concat(room === null || room === void 0 || (_room$reservationGues = room.reservationGuest) === null || _room$reservationGues === void 0 ? void 0 : _room$reservationGues.givenName, " ").concat(room === null || room === void 0 || (_room$reservationGues2 = room.reservationGuest) === null || _room$reservationGues2 === void 0 ? void 0 : _room$reservationGues2.surName),
    roomType: bookedRoomType,
    totalRoomCost: room === null || room === void 0 || (_room$totalRoomCost = room.totalRoomCost) === null || _room$totalRoomCost === void 0 ? void 0 : _room$totalRoomCost.amount
  };
}
function getBookingDetailsForCard(reservationDetails, hotelName, bookedBy, guestSurname, roomTypeLabels, t) {
  var _reservationDetails$h, _reservationDetails$h2, _reservationDetails$p, _reservationDetails$t, _reservationDetails$t2, _reservationDetails$t3, _reservationDetails$t4, _reservationDetails$p2, _reservationDetails$p3, _reservationDetails$o, _reservationDetails$o2, _reservationDetails$n2, _reservationDetails$n3, _reservationDetails$d, _reservationDetails$d2, _reservationDetails$d3, _reservationDetails$d4, _reservationDetails$d5, _reservationDetails$r, _reservationDetails$r2, _reservationDetails$r3, _reservationDetails$r4, _reservationDetails$r5, _reservationDetails$p4;
  var roomTypeLabelsBySourceSystem = getRoomTypeLabelsBySourceSystem(roomTypeLabels, reservationDetails.sourceSystem, t);
  return {
    ammendOrCancelLabel: "<p>".concat(reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.rateDescription, "</p>\n"),
    shouldDisplayCityTaxMessage: (_reservationDetails$h = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.hotelHasCityTaxForLeisure) !== null && _reservationDetails$h !== void 0 ? _reservationDetails$h : false,
    hotelId: (_reservationDetails$h2 = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.hotelCode) !== null && _reservationDetails$h2 !== void 0 ? _reservationDetails$h2 : '',
    paymentOption: (_reservationDetails$p = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.paymentOption) !== null && _reservationDetails$p !== void 0 ? _reservationDetails$p : '',
    currencyCode: (_reservationDetails$t = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$t2 = reservationDetails.totalCost) === null || _reservationDetails$t2 === void 0 ? void 0 : _reservationDetails$t2.currency) !== null && _reservationDetails$t !== void 0 ? _reservationDetails$t : '',
    totalCost: (_reservationDetails$t3 = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$t4 = reservationDetails.totalCost) === null || _reservationDetails$t4 === void 0 ? void 0 : _reservationDetails$t4.amount.toString()) !== null && _reservationDetails$t3 !== void 0 ? _reservationDetails$t3 : '',
    previousTotal: (_reservationDetails$p2 = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$p3 = reservationDetails.previousTotal) === null || _reservationDetails$p3 === void 0 ? void 0 : _reservationDetails$p3.amount.toString()) !== null && _reservationDetails$p2 !== void 0 ? _reservationDetails$p2 : '',
    balanceOutstanding: (_reservationDetails$o = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$o2 = reservationDetails.outstandingAmount) === null || _reservationDetails$o2 === void 0 ? void 0 : _reservationDetails$o2.amount.toString()) !== null && _reservationDetails$o !== void 0 ? _reservationDetails$o : '',
    newTotal: (_reservationDetails$n2 = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$n3 = reservationDetails.newTotal) === null || _reservationDetails$n3 === void 0 ? void 0 : _reservationDetails$n3.amount.toString()) !== null && _reservationDetails$n2 !== void 0 ? _reservationDetails$n2 : '',
    donationPkg: reservationDetails !== null && reservationDetails !== void 0 && (_reservationDetails$d = reservationDetails.donationsPackage) !== null && _reservationDetails$d !== void 0 && _reservationDetails$d.packageCode ? {
      code: reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$d2 = reservationDetails.donationsPackage) === null || _reservationDetails$d2 === void 0 ? void 0 : _reservationDetails$d2.packageCode,
      currency: reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$d3 = reservationDetails.donationsPackage) === null || _reservationDetails$d3 === void 0 || (_reservationDetails$d3 = _reservationDetails$d3.totalPrice) === null || _reservationDetails$d3 === void 0 ? void 0 : _reservationDetails$d3.currency,
      unitPrice: (_reservationDetails$d4 = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$d5 = reservationDetails.donationsPackage) === null || _reservationDetails$d5 === void 0 || (_reservationDetails$d5 = _reservationDetails$d5.totalPrice) === null || _reservationDetails$d5 === void 0 ? void 0 : _reservationDetails$d5.amount) !== null && _reservationDetails$d4 !== void 0 ? _reservationDetails$d4 : 0
    } : undefined,
    rateType: (_reservationDetails$r = reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.rateType) !== null && _reservationDetails$r !== void 0 ? _reservationDetails$r : '',
    roomDetails: (_reservationDetails$r2 = reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$r3 = reservationDetails.rooms) === null || _reservationDetails$r3 === void 0 ? void 0 : _reservationDetails$r3.map(room => {
      var _room$roomType;
      return getRoomDetailsForCard(reservationDetails, room, roomTypeLabelsBySourceSystem[(_room$roomType = room === null || room === void 0 ? void 0 : room.roomType) !== null && _room$roomType !== void 0 ? _room$roomType : '']);
    })) !== null && _reservationDetails$r2 !== void 0 ? _reservationDetails$r2 : [],
    cancellationInfoResponse: reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.cancellationInfoResponse,
    bookedFor: "".concat(reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$r4 = reservationDetails.rooms) === null || _reservationDetails$r4 === void 0 || (_reservationDetails$r4 = _reservationDetails$r4[0]) === null || _reservationDetails$r4 === void 0 || (_reservationDetails$r4 = _reservationDetails$r4.guest) === null || _reservationDetails$r4 === void 0 ? void 0 : _reservationDetails$r4.firstName, " ").concat(reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$r5 = reservationDetails.rooms) === null || _reservationDetails$r5 === void 0 || (_reservationDetails$r5 = _reservationDetails$r5[0]) === null || _reservationDetails$r5 === void 0 || (_reservationDetails$r5 = _reservationDetails$r5.guest) === null || _reservationDetails$r5 === void 0 ? void 0 : _reservationDetails$r5.lastName),
    arrivalDate: reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.arrivalDate,
    noNights: reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.nights,
    hotelName: hotelName,
    cardType: reservationDetails === null || reservationDetails === void 0 || (_reservationDetails$p4 = reservationDetails.payment) === null || _reservationDetails$p4 === void 0 ? void 0 : _reservationDetails$p4.cardType,
    bookedBy: bookedBy,
    guestSurname: guestSurname,
    dinnerAllowance: reservationDetails === null || reservationDetails === void 0 ? void 0 : reservationDetails.dinnerAllowance
  };
}
function getSessionStorageValuesForBookings() {
  if (typeof window !== 'undefined') {
    var previousSearchValues = window.sessionStorage.getItem('ccuiPrevSearchCriteria');
    if (previousSearchValues) {
      var previousValuesDecoded = (0, _helpers.decodeFromBase64)(previousSearchValues);
      var previousValues = JSON.parse(String(previousValuesDecoded));
      return _objectSpread({}, previousValues);
    }
  }
  return {};
}
var getMealQuantity = (packages, mealId, roomIndex) => {
  var _packages$roomSelecti, _item$;
  var item = packages !== null && packages !== void 0 && packages.roomSelection ? (_packages$roomSelecti = packages.roomSelection[roomIndex].packagesSelection) === null || _packages$roomSelecti === void 0 ? void 0 : _packages$roomSelecti.filter(pck => pck.id === mealId) : [];
  return item && item.length > 0 ? Number((_item$ = item[0]) === null || _item$ === void 0 ? void 0 : _item$.noOfSelections) : 0;
};
exports.getMealQuantity = getMealQuantity;