"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = DonationsInfoBox;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _utils = require("@whitbread-eos/utils");
var _react2 = _interopRequireDefault(require("react"));
var _icons = require("../../assets/icons");
var _Icon = _interopRequireDefault(require("../Icon"));
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function DonationsInfoBox(_ref) {
  var {
    informationBox
  } = _ref;
  var baseDataTestId = 'Donation';
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, donationInfoBoxStyle), {}, {
    "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'InfoBox'),
    children: [(0, _jsxRuntime.jsx)(_react.Box, {
      pr: "sm",
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'InfoBoxIcon'),
      children: (0, _jsxRuntime.jsx)(_Icon.default, {
        svg: (0, _jsxRuntime.jsx)(_icons.Info, {})
      })
    }), (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, donationInfoBoxTextStyle), {}, {
      "data-testid": (0, _utils.formatDataTestId)(baseDataTestId, 'InfoBoxText'),
      dangerouslySetInnerHTML: {
        __html: (0, _utils.formatInnerHTMLAssetUrls)(informationBox)
      },
      className: "formatLinks"
    }))]
  }));
}
var donationInfoBoxStyle = {
  border: '1px solid var(--chakra-colors-lightGrey2)',
  borderRadius: 'var(--chakra-space-xs)',
  p: 'md',
  w: {
    mobile: 'full',
    md: '45rem',
    lg: '50.5rem',
    xl: '54rem'
  },
  mt: 'xl'
};
var donationInfoBoxTextStyle = {
  fontSize: 'sm',
  fontWeight: 'normal'
};