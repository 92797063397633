"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = RateItem;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function RateItem(_ref) {
  var _roomRate$roomTypes;
  var {
    brand,
    value,
    roomRate,
    rateClassification,
    selectedRoomClassAndRate,
    totalReservationAmount,
    setSelectedRoomClassAndRate,
    numberOfNights,
    numberOfUnits
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    rateName,
    rateDescription,
    ratePlanCode
  } = rateClassification || {};
  var {
    currencyCode
  } = (roomRate === null || roomRate === void 0 || (_roomRate$roomTypes = roomRate.roomTypes) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes[0]) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes.rooms) === null || _roomRate$roomTypes === void 0 || (_roomRate$roomTypes = _roomRate$roomTypes[0]) === null || _roomRate$roomTypes === void 0 ? void 0 : _roomRate$roomTypes.roomPriceBreakdown) || {};
  var {
    language
  } = (0, _utils.useCustomLocale)();
  function formatRatePrice(price) {
    return price - Math.floor(price) !== 0 ? price.toFixed(2) : price;
  }
  var RATE_PLAN_BUSINESS_FLEX = 'BUSIFLEX';
  return (0, _jsxRuntime.jsx)(_react.Box, {
    w: "full",
    children: (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, rateItemCardStyle), {}, {
      "data-testid": "hdp_rateItemCard",
      children: [rateName && rateDescription && (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
        onChange: ev => {
          setSelectedRoomClassAndRate(ev.target.value);
        },
        value: value,
        isChecked: value === selectedRoomClassAndRate,
        variant: "borderless",
        width: "full",
        children: (0, _jsxRuntime.jsxs)(_react.Box, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            fontSize: "lg",
            as: "b",
            "data-testid": "hdp_ratePlanName",
            children: "".concat(brand.toLowerCase() === 'hub' ? "".concat(t('booking.rates.hub.prefix'), " ") : '').concat(rateName)
          }), roomRate !== null && roomRate !== void 0 && roomRate.cellCode ? (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, employeeOfferStyle), {}, {
            "data-testid": "hdp_employeeOffer",
            children: t('promotion.EMP01.available')
          })) : null, (0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "hdp_ratePlanDescription",
            children: rateDescription
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_react.Box, {
        textAlign: "end",
        maxW: "32",
        w: "full",
        children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, rateItemTotalPriceStyles), {}, {
          "data-testid": "hdp_rateItemTotalPrice",
          children: (0, _utils.formatPrice)((0, _utils.formatCurrency)(currencyCode), formatRatePrice(totalReservationAmount), language)
        })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, rateItemTotalPriceTitleStyles), {}, {
          "data-testid": "hdp_rateItemTotalPriceTitle",
          children: ratePlanCode === RATE_PLAN_BUSINESS_FLEX ? t('hoteldetails.rates.afterPrice') : t('hoteldetails.rates.total.price')
        })), !!numberOfNights && !!numberOfUnits && (0, _jsxRuntime.jsxs)(_react.Box, {
          display: {
            mobile: 'inline-grid',
            sm: 'block'
          },
          children: [(0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, rateItemNrOfRoomsNightStyle), {}, {
            "data-testid": "hdp_rateItemNrOfRooms",
            children: [t('hoteldetails.nrOfRooms', {
              count: numberOfUnits
            }), ","]
          })), (0, _jsxRuntime.jsxs)(_react.Text, _objectSpread(_objectSpread({}, rateItemNrOfRoomsNightStyle), {}, {
            "data-testid": "hdp_rateItemNrOfNights",
            children: ["\xA0", t('hoteldetails.nrOfNights', {
              count: numberOfNights
            })]
          }))]
        })]
      })]
    }))
  });
}
var rateItemCardStyle = {
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 'full'
};
var rateItemNrOfRoomsNightStyle = {
  display: 'inline-block',
  fontSize: 'xs',
  lineHeight: '2',
  color: 'lightGrey1'
};
var rateItemTotalPriceStyles = {
  fontSize: {
    base: 'md',
    sm: 'lg'
  },
  lineHeight: '3',
  fontWeight: 'semibold'
};
var rateItemTotalPriceTitleStyles = {
  fontSize: 'xs',
  lineHeight: '2'
};
var employeeOfferStyle = {
  fontWeight: 'semibold',
  color: 'success'
};