"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = LoadingSpinner;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function LoadingSpinner(_ref) {
  var {
    loadingText,
    svgProps,
    wrapperStyle
  } = _ref;
  return (0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({
    "data-testid": 'loading-spinner'
  }, _objectSpread(_objectSpread({}, currentWrapperStyle), wrapperStyle)), {}, {
    children: [(0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
      xmlns: "http://www.w3.org/2000/svg",
      width: "80px",
      height: "80px",
      viewBox: "0 0 100 100",
      preserveAspectRatio: "xMidYMid",
      className: "uil-spin"
    }, svgProps), {}, {
      children: [(0, _jsxRuntime.jsx)("rect", {
        x: "0",
        y: "0",
        width: "100",
        height: "100",
        fill: "none",
        className: "bk"
      }), (0, _jsxRuntime.jsxs)("g", {
        transform: "translate(50 50)",
        children: [(0, _jsxRuntime.jsx)("g", {
          transform: "rotate(0) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(45) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.12s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.12s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(90) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.25s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.25s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(135) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.37s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.37s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(180) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.5s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.5s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(225) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.62s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.62s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(270) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.75s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.75s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        }), (0, _jsxRuntime.jsx)("g", {
          transform: "rotate(315) translate(34 0)",
          children: (0, _jsxRuntime.jsxs)("circle", {
            cx: "0",
            cy: "0",
            r: "8",
            fill: "#511e62",
            children: [(0, _jsxRuntime.jsx)("animate", {
              attributeName: "opacity",
              from: "1",
              to: "0.1",
              begin: "0.87s",
              dur: "1s",
              repeatCount: "indefinite"
            }), (0, _jsxRuntime.jsx)("animateTransform", {
              attributeName: "transform",
              type: "scale",
              from: "1.4",
              to: "1",
              begin: "0.87s",
              dur: "1s",
              repeatCount: "indefinite"
            })]
          })
        })]
      })]
    })), loadingText && (0, _jsxRuntime.jsx)(_react.Text, {
      mt: "xl",
      children: loadingText
    })]
  }));
}
var currentWrapperStyle = {
  margin: {
    mobile: '2xl',
    sm: '0'
  },
  justifyContent: 'center',
  alignItems: 'center',
  direction: 'column'
};