"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccessibleRoomNotificationComponent;
var _react = require("@chakra-ui/react");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _jsxRuntime = require("react/jsx-runtime");
function AccessibleRoomNotificationComponent(_ref) {
  var {
    data
  } = _ref;
  if (!data) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "hdp_accessibleRoomNotification",
    children: (0, _jsxRuntime.jsx)(_atoms.Notification, {
      status: "info",
      variant: "accessible",
      svg: (0, _jsxRuntime.jsx)(_atoms.Accessible24, {}),
      maxW: "full",
      lineHeight: "2",
      prefixDataTestId: "hdp_accessibleRoomNotification",
      description: (0, _jsxRuntime.jsx)(_react.Box, {
        ml: "7",
        "data-testid": "hdp_accessibleRoomNotificationList",
        dangerouslySetInnerHTML: {
          __html: (0, _utils.formatInnerHTMLAssetUrls)(data)
        },
        className: "formatLinks"
      })
    })
  });
}