"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _api = require("@whitbread-eos/api");
var _config = _interopRequireDefault(require("next/config"));
var _publicRuntimeConfig$;
var allLinks = [{
  title: 'ccui.manageBooking.options.resendConfirmation',
  key: 'resendConfirmation',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'dashboard.bookings.sendInvoice',
  key: 'resendInvoice',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.otherOptions',
  key: 'otherOptions',
  type: 'header'
}, {
  title: 'ccui.manageBooking.options.changeLog',
  key: 'changeLog',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.agentNotes',
  key: 'agentNotes',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.splitBooking',
  key: 'splitBooking',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.repeatBooking',
  key: 'repeatBooking',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.cancelRebook',
  key: 'cancelRebook',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.overridePolicies',
  key: 'overridePolicies',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.changePIPBACNPStatus',
  key: 'changePiba',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.updateDiscountAmount',
  key: 'updateDiscountAmount',
  action: handleGenericAction,
  type: 'link'
}, {
  title: 'ccui.manageBooking.options.updateBookerDetails',
  key: 'bookerDetails',
  action: handleGenericAction,
  type: 'link'
}];
var getActionsByCriteria = (criteria, basketReference, isChangeLogFeatureEnabled, setIsAgentOverrideModalVisible, handleAgentMemo, handleResendInvoiceAction, handleResendConfirmationAction, handleRepeatBooking, handleChangeLogClicked) => {
  return allLinks.filter(link => isAvailable(link.key, criteria)).map(link => {
    switch (link.key) {
      case 'overridePolicies':
        link.action = () => setIsAgentOverrideModalVisible && setIsAgentOverrideModalVisible(true);
        break;
      case 'agentNotes':
        link.action = () => handleAgentMemo && handleAgentMemo({
          reservationId: basketReference,
          variant: _api.MemoModalVariants.CARD
        });
        break;
      case 'resendInvoice':
        link.action = () => handleResendInvoiceAction && handleResendInvoiceAction();
        break;
      case 'resendConfirmation':
        link.action = () => handleResendConfirmationAction && handleResendConfirmationAction();
        break;
      case 'repeatBooking':
        link.action = () => handleRepeatBooking && handleRepeatBooking();
        break;
      case 'changeLog':
        link.action = () => handleChangeLogClicked === null || handleChangeLogClicked === void 0 ? void 0 : handleChangeLogClicked();
        link.isFeatureEnabled = isChangeLogFeatureEnabled;
        break;
      default:
        link.action = handleGenericAction;
    }
    return link;
  });
};
var linksForManager = ['resendConfirmation', 'resendInvoice', 'otherOptions', 'changeLog', 'agentNotes', 'splitBooking', 'repeatBooking', 'cancelRebook', 'overridePolicies', 'changePiba', 'updateDiscountAmount', 'bookerDetails'];
var linksForNormalUser = ['resendConfirmation', 'resendInvoice', 'otherOptions', 'changeLog', 'agentNotes', 'splitBooking', 'repeatBooking', 'cancelRebook', 'overridePolicies', 'changePiba', 'updateDiscountAmount', 'bookerDetails'];
var linksForCCUI = ['otherOptions', 'changeLog', 'agentNotes', 'splitBooking', 'repeatBooking', 'cancelRebook', 'overridePolicies', 'changePiba', 'updateDiscountAmount', 'bookerDetails'];
var linksForUpcomingBookings = ['resendConfirmation', 'otherOptions', 'changeLog', 'agentNotes', 'splitBooking', 'repeatBooking', 'cancelRebook', 'overridePolicies', 'changePiba', 'updateDiscountAmount', 'bookerDetails'];
var linksForPastBookings = ['resendInvoice', 'otherOptions', 'changeLog', 'agentNotes', 'repeatBooking'];
var linksForCancelledBookings = ['otherOptions', 'changeLog', 'agentNotes', 'repeatBooking'];
var {
  publicRuntimeConfig = {}
} = (0, _config.default)() || {};
var linksForPIByStatus = {
  [_api.BOOKING_TYPE.UPCOMING]: [],
  [_api.BOOKING_TYPE.PAST]: []
};
if ((publicRuntimeConfig === null || publicRuntimeConfig === void 0 || (_publicRuntimeConfig$ = publicRuntimeConfig.NEXT_PUBLIC_DISPLAY_BIC_RESEND_CONFIRMATION_INVOICE) === null || _publicRuntimeConfig$ === void 0 ? void 0 : _publicRuntimeConfig$.toLowerCase()) === 'true') {
  linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING].push('resendConfirmation');
  linksForPIByStatus[_api.BOOKING_TYPE.PAST].push('resendInvoice');
}
function isAvailable(key, criteria) {
  return isAvailableByRole(key, criteria.role) && isAvailableByArea(key, criteria.area) && (criteria.area === _api.Area.CCUI || [_api.Area.PI, _api.Area.BB].includes(criteria.area) && criteria.bookingType ? isAvailableByBookingType(key, criteria.bookingType) : isAvailableByStatus(key, criteria.bookingStatus));
}
function isAvailableByRole(key, role) {
  switch (role) {
    case 'manager':
      return linksForManager.includes(key);
    case 'all':
      return linksForNormalUser.includes(key);
    default:
      return true;
  }
}
function isAvailableByArea(key, area) {
  var mergedPILinksByStatus = [...linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING], ...linksForPIByStatus[_api.BOOKING_TYPE.PAST]];
  switch (area) {
    case _api.Area.CCUI:
      return linksForCCUI.includes(key);
    case _api.Area.PI:
      return mergedPILinksByStatus.includes(key);
    case _api.Area.BB:
      return mergedPILinksByStatus.includes(key);
    default:
      return true;
  }
}
function isAvailableByStatus(key, bookingStatus) {
  switch (bookingStatus) {
    case _api.BASKET_STATUS.CANCELLED:
      return linksForCancelledBookings.includes(key);
    case _api.BASKET_STATUS.COMPLETED:
      return linksForPastBookings.includes(key);
    default:
      return true;
  }
}
function isAvailableByBookingType(key, bookingType) {
  switch (bookingType) {
    case _api.BOOKING_TYPE.UPCOMING:
      return linksForUpcomingBookings.includes(key) || linksForPIByStatus[_api.BOOKING_TYPE.UPCOMING].includes(key);
    case _api.BOOKING_TYPE.PAST:
      return linksForPastBookings.includes(key) || linksForPIByStatus[_api.BOOKING_TYPE.PAST].includes(key);
    case _api.BOOKING_TYPE.CANCELLED:
      return linksForCancelledBookings.includes(key);
    default:
      return true;
  }
}
function handleGenericAction() {
  console.log("Handle action");
}
var _default = exports.default = getActionsByCriteria;