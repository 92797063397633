"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hotelInformationSelector = exports.enhanceCheckoutStepList = exports.bookingGuestCount = void 0;
var _api = require("@whitbread-eos/api");
var _validators = require("../validators");
var hotelInformationSelector = hotelInformation => {
  var _hotelInformation$add;
  if (!hotelInformation) {
    return null;
  }
  return {
    hotelName: hotelInformation.name,
    hotelCountry: (_hotelInformation$add = hotelInformation.address) === null || _hotelInformation$add === void 0 ? void 0 : _hotelInformation$add.country,
    hotelAddress: hotelInformation.address && hotelInformation.brand !== 'PID' ? Object.keys(hotelInformation.address).filter(key => key !== 'country').reduce((prev, current) => {
      var _hotelInformation$add2, _hotelInformation$add3;
      if ((0, _validators.isStringValid)((_hotelInformation$add2 = hotelInformation.address[current]) !== null && _hotelInformation$add2 !== void 0 ? _hotelInformation$add2 : '')) prev.push((_hotelInformation$add3 = hotelInformation.address[current]) !== null && _hotelInformation$add3 !== void 0 ? _hotelInformation$add3 : '');
      return prev;
    }, []) : formatDEAddress(hotelInformation.address),
    hotelBrand: hotelInformation.brand
  };
};
exports.hotelInformationSelector = hotelInformationSelector;
var enhanceCheckoutStepList = (listSteps, activePage) => {
  var lastStep = listSteps.slice(-1)[0];
  var filteredSteps = listSteps.slice(0);
  return {
    steps: filteredSteps.slice(0, -1).map(step => ({
      id: Number(step.step)
    })),
    activeStep: activePage === lastStep.id ? filteredSteps.length : filteredSteps.findIndex(step => {
      return _api.BOOKING_STEPS_MAPPING[step.id] === activePage;
    }) + 1
  };
};
exports.enhanceCheckoutStepList = enhanceCheckoutStepList;
var bookingGuestCount = reservationList => reservationList.reduce((acc, current) => {
  var _current$roomStay$adu, _current$roomStay, _current$roomStay$chi, _current$roomStay2;
  acc.adultsNumber.push((_current$roomStay$adu = current === null || current === void 0 || (_current$roomStay = current.roomStay) === null || _current$roomStay === void 0 ? void 0 : _current$roomStay.adultsNumber) !== null && _current$roomStay$adu !== void 0 ? _current$roomStay$adu : 0);
  acc.childrenNumber.push((_current$roomStay$chi = current === null || current === void 0 || (_current$roomStay2 = current.roomStay) === null || _current$roomStay2 === void 0 ? void 0 : _current$roomStay2.childrenNumber) !== null && _current$roomStay$chi !== void 0 ? _current$roomStay$chi : 0);
  return acc;
}, {
  adultsNumber: [],
  childrenNumber: []
});
exports.bookingGuestCount = bookingGuestCount;
var formatDEAddress = address => {
  if (!address) {
    return [];
  }
  var formattedAddress = [];
  if ((0, _validators.isStringValid)(address['addressLine1'])) {
    formattedAddress.push(address['addressLine1']);
  }
  if ((0, _validators.isStringValid)(address['postalCode']) && (0, _validators.isStringValid)(address['addressLine2'])) {
    formattedAddress.push(address['postalCode'] + ' ' + address['addressLine2']);
  } else if ((0, _validators.isStringValid)(address['postalCode'])) {
    formattedAddress.push(address['postalCode']);
  } else if ((0, _validators.isStringValid)(address['addressLine2'])) {
    formattedAddress.push(address['addressLine2']);
  }
  if ((0, _validators.isStringValid)(address['addressLine3'])) {
    formattedAddress.push(address['addressLine3']);
  }
  return formattedAddress;
};