"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccessibleBathroomOptionsComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _uuid = require("uuid");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function AccessibleBathroomOptionsComponent(_ref) {
  var {
    rooms,
    onRoomTypeSelection,
    onBathroomSelection,
    bathroomSelections,
    roomTypeSelections,
    hotelInventoryResponse,
    selectedPMSRoomTypes
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var {
    language
  } = (0, _utils.useCustomLocale)();
  var availableBathroomsPerRoom = (0, _react2.useMemo)(getAvailableBathroomsPerRoom, [rooms, hotelInventoryResponse, selectedPMSRoomTypes]);
  (0, _react2.useEffect)(() => {
    rooms.forEach((room, roomIndex) => {
      var selectionRequired = room.roomTypes.some(roomType => roomType.startsWith('Accessible') || roomType.startsWith('Barrierefreies'));
      var bathroomSelected = bathroomSelections[roomIndex] === _api.ROOM_TYPE.LOWERED || bathroomSelections[roomIndex] === _api.ROOM_TYPE.WET;
      if (selectionRequired) {
        var hasLoweredBathroom = room.bathroomTypes.includes(_api.ROOM_TYPE.LOWERED);
        var hasWetRoom = room.bathroomTypes.includes(_api.ROOM_TYPE.WET);
        if (hasLoweredBathroom && !bathroomSelected) {
          onBathroomSelection(roomIndex, _api.ROOM_TYPE.LOWERED);
        } else if (hasWetRoom && !bathroomSelected) {
          onBathroomSelection(roomIndex, _api.ROOM_TYPE.WET);
        }
        onRoomTypeSelection(roomIndex, room.roomTypes[0]);
      }
    });
  }, [rooms, onBathroomSelection, onRoomTypeSelection, bathroomSelections]);
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
      as: "h1",
      "data-testid": "accessible-title"
    }, headingStyles), {}, {
      children: t('accessible.chooseBathRoomTitle')
    })), rooms.map((room, roomIndex) => {
      var selectionRequired = room.roomTypes.some(roomType => roomType.startsWith('Accessible') || roomType.startsWith('Barrierefreies'));
      return (0, _jsxRuntime.jsxs)(_react.Box, {
        mt: {
          base: 'xl',
          xs: '3xl'
        },
        children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, roomAndGuestsFlexStyles), {}, {
          children: [(0, _jsxRuntime.jsxs)(_react.Flex, {
            direction: "column",
            mr: "md",
            children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
              as: "h3",
              "data-testid": "accessible-room-number"
            }, roomHeadingStyles), {}, {
              children: t('booking.hotel.summary.room').replace('[roomNumber]', selectionRequired ? (roomIndex + 1).toString() : "".concat(roomIndex + 1, " ").concat(room.roomTypes[0]))
            })), renderGuestNumbers(room)]
          }), selectionRequired && renderRoomTypeSelection(room.roomTypes, roomIndex)]
        })), selectionRequired ? renderSelectionOptions(roomIndex) : (0, _jsxRuntime.jsx)(_react.Text, {
          mt: {
            base: '1',
            xs: '0'
          },
          "data-testid": "accessible-no-selection-required",
          children: t('accessible.no.selection.required')
        })]
      }, (0, _uuid.v4)());
    })]
  });
  function renderGuestNumbers(room) {
    var adultsLabel = "".concat(room.adults, " ").concat(room.adults > 1 ? t('dashboard.bookings.adults') : t('dashboard.bookings.adult'));
    var childrenLabel = ", ".concat(room.children, " ").concat(room.children > 1 ? t('dashboard.bookings.children') : t('dashboard.bookings.child'));
    return (0, _jsxRuntime.jsxs)(_react.Text, {
      mt: "1",
      lineHeight: "3",
      "data-testid": "accessible-room-guest-numbers",
      children: [adultsLabel, room.children ? childrenLabel : '']
    });
  }
  function renderRoomTypeSelection(roomTypes, roomIndex) {
    var unavailableRoomType = '';
    if (roomTypes.length === 1) {
      unavailableRoomType = roomTypes.includes('Accessible Twin') || roomTypes.includes('Barrierefreies Zweibettzimmer') ? 'accessible.double.unavailable' : 'accessible.twin.unavailable';
    }
    var unavailableBoxStyle = !unavailableRoomType ? 'initial' : language === 'en' ? '3xl' : '5xl';
    var roomTypeSelectionBoxStyles = {
      mb: unavailableBoxStyle,
      mt: {
        base: '4',
        xs: '0'
      },
      flexGrow: 1
    };
    return (0, _jsxRuntime.jsx)(_atoms.Tooltip, {
      isOpen: !!unavailableRoomType,
      description: t(unavailableRoomType),
      variant: "accessible",
      svg: (0, _jsxRuntime.jsx)(_atoms.Info, {}),
      modifiers: [{
        name: 'flip',
        enabled: false
      }],
      children: (0, _jsxRuntime.jsx)(_react.Box, _objectSpread(_objectSpread({}, roomTypeSelectionBoxStyles), {}, {
        children: (0, _jsxRuntime.jsx)(_atoms.Dropdown, {
          onChange: option => {
            onRoomTypeSelection(roomIndex, option.label);
          },
          options: roomTypes.map((room, index) => ({
            id: index,
            label: room,
            icon: (0, _jsxRuntime.jsx)(_atoms.Icon, {
              svg: (0, _jsxRuntime.jsx)(_atoms.Accessible24, {})
            })
          })),
          selectedId: roomTypes.indexOf(roomTypeSelections[roomIndex]).toString(),
          disabled: roomTypes.length <= 1
        })
      }))
    });
  }
  function renderSelectionOptions(roomIndex) {
    var {
      hasLoweredBathroom,
      hasWetRoom
    } = availableBathroomsPerRoom[roomIndex];
    return (0, _jsxRuntime.jsxs)(_react.Box, {
      maxW: "3xl",
      mt: "lg",
      "data-testid": "accessible-bathroom-dropdown",
      children: [(0, _jsxRuntime.jsx)(_atoms.RadioButton, {
        type: "low-bath",
        value: "low-bath",
        onChange: () => onBathroomSelection(roomIndex, _api.ROOM_TYPE.LOWERED),
        isChecked: bathroomSelections[roomIndex] === _api.ROOM_TYPE.LOWERED,
        isDisabled: !hasLoweredBathroom,
        children: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            fontWeight: "bold",
            "data-testid": "accessible-low-bathroom",
            children: t('accessible.lowBath')
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            dangerouslySetInnerHTML: {
              __html: (0, _utils.formatInnerHTMLAssetUrls)(t('accessible.loweredBath.text'))
            },
            className: "formatLinks"
          })]
        })
      }), (0, _jsxRuntime.jsx)(_react.Box, {
        mt: "1em"
      }), (0, _jsxRuntime.jsx)(_atoms.RadioButton, {
        type: "wet-room",
        value: "wet-room",
        onChange: () => onBathroomSelection(roomIndex, _api.ROOM_TYPE.WET),
        isChecked: bathroomSelections[roomIndex] === _api.ROOM_TYPE.WET,
        isDisabled: !hasWetRoom,
        children: (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
          children: [(0, _jsxRuntime.jsx)(_react.Text, {
            "data-testid": "accessible-wet-room",
            children: t('accessible.wetRoom')
          }), (0, _jsxRuntime.jsx)(_react.Box, {
            dangerouslySetInnerHTML: {
              __html: (0, _utils.formatInnerHTMLAssetUrls)(t('accessible.wetRoom.text'))
            },
            className: "formatLinks"
          })]
        })
      }), (0, _jsxRuntime.jsxs)(_react.Box, {
        mt: "md",
        children: [(0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: t('accessible.wetRoom'),
          description: t('accessible.wetRoom.alertText'),
          prefixDataTestId: "accessible-wetRoom",
          status: "warning",
          variant: "alert",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
          isClosed: hasWetRoom,
          style: {
            maxWidth: 'none'
          },
          isInnerHTML: true
        }), (0, _jsxRuntime.jsx)(_atoms.Notification, {
          title: t('accessible.lowBath'),
          description: t('accessible.loweredBath.alertText'),
          prefixDataTestId: "accessible-lowBath",
          status: "warning",
          variant: "alert",
          svg: (0, _jsxRuntime.jsx)(_atoms.Alert, {}),
          isClosed: hasLoweredBathroom,
          style: {
            maxWidth: 'none'
          },
          isInnerHTML: true
        })]
      })]
    });
  }
  function getAvailableCountPerPMSRoomType() {
    var availableCountPerPMSRoomType = {
      WETDBL: 0,
      LOWDBL: 0,
      WETTWN: 0,
      LOWTWN: 0
    };
    if (!hotelInventoryResponse.isLoadingHotelInventory && !hotelInventoryResponse.isErrorHotelInventory && hotelInventoryResponse.dataHotelInventory) {
      var _hotelInventoryRespon;
      availableCountPerPMSRoomType = (_hotelInventoryRespon = hotelInventoryResponse.dataHotelInventory) === null || _hotelInventoryRespon === void 0 || (_hotelInventoryRespon = _hotelInventoryRespon.hotelInventory) === null || _hotelInventoryRespon === void 0 || (_hotelInventoryRespon = _hotelInventoryRespon.roomTypeInventories) === null || _hotelInventoryRespon === void 0 ? void 0 : _hotelInventoryRespon.reduce((current, roomTypeInventory) => {
        if (!Number.isInteger(current[roomTypeInventory === null || roomTypeInventory === void 0 ? void 0 : roomTypeInventory.code])) return current;
        return _objectSpread(_objectSpread({}, current), {}, {
          [roomTypeInventory === null || roomTypeInventory === void 0 ? void 0 : roomTypeInventory.code]: roomTypeInventory === null || roomTypeInventory === void 0 ? void 0 : roomTypeInventory.availableCount
        });
      }, availableCountPerPMSRoomType);
    }
    return availableCountPerPMSRoomType;
  }
  function getAvailableBathroomsPerRoom() {
    var roomCountPerPMSRoomType = selectedPMSRoomTypes.reduce((current, pmsRoomType) => {
      if (!Number.isInteger(current[pmsRoomType])) return current;
      return _objectSpread(_objectSpread({}, current), {}, {
        [pmsRoomType]: current[pmsRoomType] + 1
      });
    }, {
      WETDBL: 0,
      LOWDBL: 0,
      WETTWN: 0,
      LOWTWN: 0
    });
    var availableCountPerPMSRoomType = getAvailableCountPerPMSRoomType();
    return rooms.map((room, roomIndex) => {
      var response = {
        hasLoweredBathroom: false,
        hasWetRoom: false
      };
      var selectionRequired = room.roomTypes.some(roomType => roomType.startsWith('Accessible') || roomType.startsWith('Barrierefreies'));
      if (!selectionRequired) {
        return response;
      }
      switch (selectedPMSRoomTypes[roomIndex]) {
        case _api.ROOM_TYPE.LOWERED_DOUBLE:
          return {
            hasLoweredBathroom: room.bathroomTypes.includes(_api.ROOM_TYPE.LOWERED) && availableCountPerPMSRoomType.LOWDBL > 0,
            hasWetRoom: room.bathroomTypes.includes(_api.ROOM_TYPE.WET) && availableCountPerPMSRoomType.WETDBL - roomCountPerPMSRoomType.WETDBL > 0
          };
        case _api.ROOM_TYPE.LOWERED_TWIN:
          return {
            hasLoweredBathroom: room.bathroomTypes.includes(_api.ROOM_TYPE.LOWERED) && availableCountPerPMSRoomType.LOWTWN > 0,
            hasWetRoom: room.bathroomTypes.includes(_api.ROOM_TYPE.WET) && availableCountPerPMSRoomType.WETTWN - roomCountPerPMSRoomType.WETTWN > 0
          };
        case _api.ROOM_TYPE.WET_DOUBLE:
          return {
            hasLoweredBathroom: room.bathroomTypes.includes(_api.ROOM_TYPE.LOWERED) && availableCountPerPMSRoomType.LOWDBL - roomCountPerPMSRoomType.LOWDBL > 0,
            hasWetRoom: room.bathroomTypes.includes(_api.ROOM_TYPE.WET) && availableCountPerPMSRoomType.WETDBL > 0
          };
        case _api.ROOM_TYPE.WET_TWIN:
          return {
            hasLoweredBathroom: room.bathroomTypes.includes(_api.ROOM_TYPE.LOWERED) && availableCountPerPMSRoomType.LOWTWN - roomCountPerPMSRoomType.LOWTWN > 0,
            hasWetRoom: room.bathroomTypes.includes(_api.ROOM_TYPE.WET) && availableCountPerPMSRoomType.WETTWN > 0
          };
      }
      return response;
    });
  }
}
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: {
    base: '3xl',
    sm: '3xxl'
  },
  lineHeight: {
    base: '4',
    sm: '5'
  }
};
var roomAndGuestsFlexStyles = {
  mb: 'sm',
  flexDirection: {
    base: 'column',
    xs: 'row'
  },
  w: {
    base: '100%',
    sm: '64%',
    md: '50%'
  }
};
var roomHeadingStyles = {
  fontWeight: 'semibold',
  fontSize: '2xl',
  lineHeight: '4'
};