"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgLogoHubSimple = props => (0, _jsxRuntime.jsxs)("svg", _objectSpread(_objectSpread({
  width: 58,
  height: 64,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: [(0, _jsxRuntime.jsx)("mask", {
    id: "logo-hub-simple_svg__a",
    style: {
      maskType: 'alpha'
    },
    maskUnits: "userSpaceOnUse",
    x: 0,
    y: 0,
    width: 58,
    height: 64,
    children: (0, _jsxRuntime.jsx)("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M0 0h58v64H0V0Z",
      fill: "#fff"
    })
  }), (0, _jsxRuntime.jsx)("g", {
    mask: "url(#logo-hub-simple_svg__a)",
    children: (0, _jsxRuntime.jsx)("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M29 0C12.983 0 0 13.026 0 29.094c0 13.221 8.792 24.38 20.83 27.92a7.241 7.241 0 0 1 2.99 1.802L29 64l5.181-5.184a7.23 7.23 0 0 1 2.989-1.802C49.208 53.474 58 42.315 58 29.094 58 13.026 45.017 0 29 0Z",
      fill: "#BBD531"
    })
  }), (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M46.14 25.737c-1.088-1.062-2.566-1.67-4.053-1.67-1.477 0-2.776.537-3.874 1.598v-4.982c0-.65-.49-1.14-1.14-1.14-.636 0-1.118.49-1.118 1.14v14.679c0 .639.501 1.14 1.14 1.14.638 0 1.118-.49 1.118-1.14v-.222c1.081 1.017 2.381 1.532 3.874 1.532 2.9 0 5.898-2.358 5.898-6.303v-.043c0-1.797-.655-3.428-1.844-4.589Zm-.5 4.675c0 2.418-1.47 4.045-3.66 4.045-2.365 0-3.83-2.111-3.83-4.067v-.042c0-2.511 1.988-4.066 3.83-4.066 2.087 0 3.66 1.758 3.66 4.087v.043ZM19.554 29.515v6.016c0 .65.49 1.14 1.14 1.14.637 0 1.118-.49 1.118-1.14v-6.208c0-3.09-1.946-5.087-4.959-5.087-1.292 0-2.426.406-3.319 1.182v-4.564c0-.639-.5-1.14-1.14-1.14-.638 0-1.118.49-1.118 1.14V35.53c0 .65.49 1.14 1.14 1.14.637 0 1.118-.49 1.118-1.14v-5.844c0-1.9 1.333-3.277 3.17-3.277 1.81 0 2.85 1.131 2.85 3.105ZM34.123 25.377c0-.65-.482-1.14-1.119-1.14-.65 0-1.14.49-1.14 1.14v6.017c0 1.973-1.038 3.104-2.85 3.104h-.244c-1.81 0-2.848-1.131-2.848-3.104v-6.017c0-.65-.49-1.14-1.14-1.14-.638 0-1.119.49-1.119 1.14v6.208c0 3.09 1.952 5.086 4.973 5.086h.529c3.012 0 4.958-1.996 4.958-5.086v-6.208Z",
    fill: "#fff"
  })]
}));
var _default = exports.default = SvgLogoHubSimple;