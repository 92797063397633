"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = Form;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _yup = require("@hookform/resolvers/yup");
var _react2 = require("react");
var _reactHookForm = require("react-hook-form");
var _FormButtons = _interopRequireDefault(require("./FormButtons"));
var _FormField = _interopRequireDefault(require("./FormField"));
var _formConstants = require("./formConstants");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function Form(_ref) {
  var _ref2, _ref3, _submitButton$action;
  var {
    id,
    elements: {
      formStyles,
      fieldsContainerStyles,
      buttonsContainerStyles,
      fields,
      buttons,
      bottomFields,
      onSubmitAction
    },
    onChange = () => {},
    errorsOrder,
    defaultValues,
    validationSchema,
    getFormState,
    defaultErrors,
    resetForm,
    testid,
    autoComplete = 'on',
    fieldsetDisabled = false
  } = _ref;
  var {
    control,
    handleSubmit,
    getValues,
    formState: {
      errors
    },
    setError,
    clearErrors,
    setValue,
    reset,
    resetField,
    watch,
    trigger
  } = (0, _reactHookForm.useForm)({
    mode: 'onBlur',
    defaultValues,
    resolver: validationSchema ? (0, _yup.yupResolver)(validationSchema) : undefined
  });
  var watchValues = (0, _reactHookForm.useWatch)({
    control
  });
  var errorsRef = (0, _react2.useRef)({});
  var submitButton = buttons === null || buttons === void 0 ? void 0 : buttons.find(button => (button === null || button === void 0 ? void 0 : button.type) === _formConstants.FORM_BUTTON_TYPES.SUBMIT);
  var customSubmitButton = fields === null || fields === void 0 ? void 0 : fields.find(field => {
    var _field$props;
    return (field === null || field === void 0 || (_field$props = field.props) === null || _field$props === void 0 ? void 0 : _field$props.type) === _formConstants.FORM_BUTTON_TYPES.SUBMIT;
  });
  var noSubmitAction = () => console.error('Form does not have a submit button with an action set');
  var errorFn = errors => {
    var _firstErrorElement$sc;
    var firstElementName;
    if (errorsOrder) {
      firstElementName = errorsOrder.find(el => !!errors[el]);
    }
    if (!firstElementName) {
      firstElementName = Object.keys(errors)[0];
    }
    var firstErrorElement = document.querySelector("[data-fieldname=\"".concat(firstElementName, "\"]"));
    firstErrorElement === null || firstErrorElement === void 0 || (_firstErrorElement$sc = firstErrorElement.scrollIntoView) === null || _firstErrorElement$sc === void 0 || _firstErrorElement$sc.call(firstErrorElement, {
      behavior: 'smooth'
    });
  };
  (0, _react2.useEffect)(() => {
    var subscription = watch(() => {
      if (onChange) {
        onChange(getValues());
      }
    });
    return () => subscription.unsubscribe();
  }, [watchValues]);
  (0, _react2.useEffect)(() => {
    if (defaultErrors) {
      for (var fieldName in defaultErrors) {
        setError(fieldName, defaultErrors[fieldName]);
      }
    }
  }, [defaultErrors]);
  (0, _react2.useEffect)(() => {
    return () => {
      getFormState === null || getFormState === void 0 || getFormState(getValues(), errorsRef.current);
    };
  }, [getFormState, getValues]);
  (0, _react2.useEffect)(() => {
    errorsRef.current = errors;
  }, [errors]);
  (0, _react2.useEffect)(() => {
    if (resetForm) {
      var resetValues = {};
      for (var _key in defaultValues) {
        resetValues[_key] = '';
      }
      reset(resetValues);
    }
  }, [resetForm]);
  var handleTriggerValidation = (0, _react2.useCallback)(fieldsName => {
    trigger(fieldsName);
  }, [trigger]);
  var handleSetValue = (0, _react2.useCallback)((fieldName, value) => {
    setValue(fieldName, value);
  }, [setValue]);
  var handleResetField = (0, _react2.useCallback)((fieldName, options) => {
    resetField(fieldName, options);
  }, [resetField]);
  var handleSetError = (0, _react2.useCallback)((fieldName, error, config) => {
    setError(fieldName, error, config);
  }, [setError]);
  var handleClearErrors = (0, _react2.useCallback)(fieldName => {
    clearErrors(fieldName);
  }, [clearErrors]);
  var submitFn = (_ref2 = (_ref3 = (_submitButton$action = submitButton === null || submitButton === void 0 ? void 0 : submitButton.action) !== null && _submitButton$action !== void 0 ? _submitButton$action : customSubmitButton === null || customSubmitButton === void 0 ? void 0 : customSubmitButton.action) !== null && _ref3 !== void 0 ? _ref3 : onSubmitAction) !== null && _ref2 !== void 0 ? _ref2 : noSubmitAction;
  return (0, _jsxRuntime.jsx)("form", {
    id: id,
    onSubmit: handleSubmit(submitFn, errorFn),
    "data-testid": testid,
    autoComplete: autoComplete,
    children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({}, formStyles), {}, {
      children: [(0, _jsxRuntime.jsx)("fieldset", {
        disabled: fieldsetDisabled,
        style: styleFieldset(fieldsetDisabled),
        children: (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, defaultFieldsContainerStyles), fieldsContainerStyles)), {}, {
          children: fields.map(formField => {
            var _formField$id;
            return (0, _jsxRuntime.jsx)(_FormField.default, {
              control: control,
              formField: formField,
              errors: errors,
              getValues: getValues,
              handleSetValue: handleSetValue,
              handleResetField: handleResetField,
              handleSetError: handleSetError,
              handleClearErrors: handleClearErrors,
              handleTriggerValidation: handleTriggerValidation,
              reset: reset
            }, "".concat(formField.name, "-").concat((_formField$id = formField.id) !== null && _formField$id !== void 0 ? _formField$id : ''));
          })
        }))
      }), buttons && (0, _jsxRuntime.jsx)(_FormButtons.default, {
        buttonsContainerStyles: buttonsContainerStyles,
        buttons: buttons
      }), bottomFields && (0, _jsxRuntime.jsx)(_react.Flex, _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, defaultFieldsContainerStyles), fieldsContainerStyles)), {}, {
        children: bottomFields.map(formField => {
          var _formField$id2;
          return (0, _jsxRuntime.jsx)(_FormField.default, {
            control: control,
            formField: formField,
            errors: errors,
            getValues: getValues,
            handleSetValue: handleSetValue,
            handleResetField: handleResetField,
            reset: reset
          }, "".concat(formField.name, "-").concat((_formField$id2 = formField.id) !== null && _formField$id2 !== void 0 ? _formField$id2 : ''));
        })
      }))]
    }))
  });
}
var defaultFieldsContainerStyles = {
  direction: 'column',
  height: 'auto',
  justifyContent: 'flex-start',
  marginBottom: 'var(--chakra-space-lg)'
};
var defaultFieldsetStyle = {
  border: '0',
  padding: '0.01em 0 0 0',
  margin: '0',
  minWidth: '0'
};
var styleFieldset = isDisabled => {
  return isDisabled ? _objectSpread(_objectSpread({}, {
    opacity: '0.4',
    pointerEvents: 'none'
  }), defaultFieldsetStyle) : defaultFieldsetStyle;
};