"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = AccountToCompanyDetails;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = _interopRequireWildcard(require("react"));
var _yup = require("yup");
var _CompanySelection = _interopRequireDefault(require("../../../../common/CompanySelection"));
var _modalType = require("../../../../common/CompanySelection/modalType.enum");
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var FieldType = function (FieldType) {
  FieldType["ACCOUNT_NUMBER"] = "accountNumber";
  FieldType["COMPANY_NAME"] = "companyName";
  return FieldType;
}(FieldType || {});
var ACCOUNT_NUMBAR_VALIDATIONS = {
  MATCHES: /^[a-zA-Z0-9~-]+$/g,
  MIN: 5,
  MAX: 20
};
var COMPANY_NAME_VALIDATIONS = {
  MATCHES: /^[a-zA-Z0-9\s.,:;_!?"*%=+£$€¥&@#()\-'À-ÖØ-ʒͰ-ͳͶ-ͷͻ-ͽvΑ-Ͽἀ-ῼЀ-ӿễấ]+$/g,
  MIN: 2,
  MAX: 50
};
function AccountToCompanyDetails(_ref) {
  var {
    hotelId,
    selectedPaymentDetail,
    setVerifiedCompany
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [accountNumber, setAccountNumber] = (0, _react2.useState)('');
  var [companyName, setCompanyName] = (0, _react2.useState)('');
  var [accountNumberError, setAccountNumberError] = (0, _react2.useState)('');
  var [companyNameError, setCompanyNameError] = (0, _react2.useState)('');
  var [searchError, setSearchError] = (0, _react2.useState)('');
  var [queryEnabled, setQueryEnabled] = (0, _react2.useState)(false);
  var [showCompanySelectionModal, setShowCompanySelectionModal] = (0, _react2.useState)(false);
  var [resultsData, setResultsData] = (0, _react2.useState)({
    tooManyResults: false,
    companies: []
  });
  var companyNameInputRef = (0, _react2.useRef)();
  var baseTestId = 'AccountToCompanyDetails';
  var isSearchDisabled = selectedPaymentDetail !== _api.paymentOptions.PAY_ON_ARRIVAL;
  var inputChangeValidationSchema = fieldType => {
    var validations = fieldType === FieldType.ACCOUNT_NUMBER ? ACCOUNT_NUMBAR_VALIDATIONS : COMPANY_NAME_VALIDATIONS;
    return (0, _yup.string)().when('$value', _ref2 => {
      var [value] = _ref2;
      return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(validations.MATCHES, t("ccui.accountToCompany.".concat(fieldType, ".invalidCharactersError"))).max(validations.MAX, t("ccui.accountToCompany.".concat(fieldType, ".maxCharactersError"))) : (0, _yup.string)().notRequired();
    });
  };
  var submitValidationSchema = fieldType => {
    var validations = fieldType === FieldType.ACCOUNT_NUMBER ? ACCOUNT_NUMBAR_VALIDATIONS : COMPANY_NAME_VALIDATIONS;
    return (0, _yup.string)().when('$value', _ref3 => {
      var [value] = _ref3;
      return (value === null || value === void 0 ? void 0 : value.length) > 0 ? (0, _yup.string)().matches(validations.MATCHES, t("ccui.accountToCompany.".concat(fieldType, ".invalidCharactersError"))).max(validations.MAX, t("ccui.accountToCompany.".concat(fieldType, ".maxCharactersError"))).min(validations.MIN, t("ccui.accountToCompany.".concat(fieldType, ".minCharactersError"))) : (0, _yup.string)().notRequired();
    });
  };
  var {
    data: companiesData,
    isFetching: companiesRequestFetching,
    isError: companiesRequestError
  } = (0, _utils.useQueryRequest)(['searchA2CPaymentCompanies', companyName, accountNumber, hotelId, 50], _api.SEARCH_A2C_COMPANIES, {
    companyName,
    arNumber: accountNumber,
    hotelId,
    limit: 50
  }, {
    enabled: queryEnabled
  }, undefined, true);
  (0, _react2.useEffect)(() => {
    if (!companiesRequestFetching && queryEnabled) {
      if (companiesRequestError) {
        setResultsData({
          tooManyResults: false,
          companies: []
        });
        setSearchError(t('ccui.accountToCompany.search.technicalError'));
      } else {
        var _companiesData$search;
        var {
          companies,
          hasMore
        } = (_companiesData$search = companiesData === null || companiesData === void 0 ? void 0 : companiesData.searchCompanies) !== null && _companiesData$search !== void 0 ? _companiesData$search : {};
        validateCompanies(companies, hasMore);
      }
      setQueryEnabled(false);
    }
  }, [companiesRequestFetching, companiesData, companiesRequestError, queryEnabled]);
  var validateCompanies = (companies, tooManyResults) => {
    if (companies && companies.length > 0) {
      setResultsData({
        tooManyResults,
        companies
      });
      setShowCompanySelectionModal(true);
    } else {
      setResultsData({
        tooManyResults: false,
        companies: []
      });
      setSearchError(t('ccui.accountToCompany.search.noResults'));
    }
  };
  var handleInputErrors = function () {
    var _ref4 = (0, _asyncToGenerator2.default)(function* (value, validationSchema) {
      var _validationResult$err;
      var validationResult = yield validationSchema.validate(value.toUpperCase(), {
        context: {
          value
        }
      }).catch(err => {
        return err;
      });
      var hasValidationError = (validationResult === null || validationResult === void 0 ? void 0 : validationResult.errors) && validationResult.errors.length > 0;
      return {
        hasError: hasValidationError,
        error: (_validationResult$err = validationResult.errors) === null || _validationResult$err === void 0 ? void 0 : _validationResult$err[0]
      };
    });
    return function handleInputErrors(_x, _x2) {
      return _ref4.apply(this, arguments);
    };
  }();
  var onChange = function () {
    var _ref5 = (0, _asyncToGenerator2.default)(function* (val, fieldType) {
      setSearchError('');
      fieldType === FieldType.ACCOUNT_NUMBER ? setAccountNumber(val) : setCompanyName(val);
      var validationSchema = inputChangeValidationSchema(fieldType);
      var {
        hasError,
        error
      } = yield handleInputErrors(val, validationSchema);
      var errorSetter = fieldType === FieldType.ACCOUNT_NUMBER ? setAccountNumberError : setCompanyNameError;
      hasError ? errorSetter(error) : errorSetter('');
    });
    return function onChange(_x3, _x4) {
      return _ref5.apply(this, arguments);
    };
  }();
  var onAccountNumberChange = val => {
    onChange(val, FieldType.ACCOUNT_NUMBER);
  };
  var onCompanyNameChange = val => {
    onChange(val, FieldType.COMPANY_NAME);
  };
  var onSearchCompany = function () {
    var _ref6 = (0, _asyncToGenerator2.default)(function* () {
      setSearchError('');
      if (accountNumber === '' && companyName === '') {
        setSearchError(t('ccui.accountToCompany.fieldsEmptyError'));
        return;
      }
      var accountNumberValidationSchema = submitValidationSchema(FieldType.ACCOUNT_NUMBER);
      var {
        hasError: accountNumberHasError,
        error: accountNumberError
      } = yield handleInputErrors(accountNumber, accountNumberValidationSchema);
      accountNumberHasError ? setAccountNumberError(accountNumberError) : setAccountNumberError('');
      var companyNameValidationSchema = submitValidationSchema(FieldType.COMPANY_NAME);
      var {
        hasError: companyNameHasError,
        error: companyNameError
      } = yield handleInputErrors(companyName, companyNameValidationSchema);
      companyNameHasError ? setCompanyNameError(companyNameError) : setCompanyNameError('');
      if (accountNumberHasError || companyNameHasError) {
        return;
      }
      setQueryEnabled(true);
    });
    return function onSearchCompany() {
      return _ref6.apply(this, arguments);
    };
  }();
  var handleCompanySelectionModalClosed = () => {
    setShowCompanySelectionModal(false);
  };
  var handleCompanyVerified = company => {
    setShowCompanySelectionModal(false);
    setVerifiedCompany(company);
  };
  return (0, _jsxRuntime.jsxs)(_jsxRuntime.Fragment, {
    children: [(0, _jsxRuntime.jsxs)(_react.Flex, _objectSpread(_objectSpread({}, wrapperStyles), {}, {
      "data-testid": baseTestId,
      children: [(0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, titleStyles), {}, {
        children: t('ccui.accountToCompany.title')
      })), (0, _jsxRuntime.jsx)(_react.Text, _objectSpread(_objectSpread({}, descriptionStyles), {}, {
        children: t('ccui.accountToCompany.description')
      })), (0, _jsxRuntime.jsxs)(_react.VStack, _objectSpread(_objectSpread({
        spacing: 4,
        align: "stretch"
      }, inputWrapperStyles), {}, {
        children: [(0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "companyName",
          value: companyName,
          onChange: onCompanyNameChange,
          label: t('ccui.accountToCompany.companyName.placeholder'),
          placeholderText: t('ccui.accountToCompany.companyName.placeholder'),
          error: companyNameError,
          inputRef: companyNameInputRef,
          styles: {
            inputElementStyles
          },
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'CompanyName')
        }), (0, _jsxRuntime.jsx)(_atoms.Input, {
          name: "accountNumber",
          value: accountNumber,
          onChange: onAccountNumberChange,
          label: t('ccui.accountToCompany.accountNumber.placeholder'),
          placeholderText: t('ccui.accountToCompany.accountNumber.placeholder'),
          error: accountNumberError,
          styles: {
            inputElementStyles
          },
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'AccountNumber')
        }), searchError && (0, _jsxRuntime.jsx)(_atoms.InfoMessage, {
          infoMessage: searchError,
          otherStyles: errorMessageStyles,
          "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Error')
        })]
      })), (0, _jsxRuntime.jsx)(_atoms.Button, {
        type: "button",
        variant: "tertiary",
        size: "full",
        maxW: {
          mobile: '18rem',
          lg: '18rem',
          xl: '19.375rem'
        },
        mt: 4,
        onClick: onSearchCompany,
        isDisabled: isSearchDisabled,
        "data-testid": (0, _utils.formatDataTestId)(baseTestId, 'Search'),
        children: t('ccui.accountToCompany.search')
      })]
    })), showCompanySelectionModal && (0, _jsxRuntime.jsx)(_CompanySelection.default, {
      showCompanySelectionModal: showCompanySelectionModal,
      companyModalType: _modalType.COMPANY_MODAL_TYPE.ACCOUNT_TO_COMPANY,
      companies: resultsData.companies,
      tooManyResults: resultsData.tooManyResults,
      finalFocusRef: companyNameInputRef,
      onClose: handleCompanySelectionModalClosed,
      onCompanyVerified: handleCompanyVerified
    })]
  });
}
var titleStyles = {
  color: 'darkGrey1',
  fontSize: 'xl',
  lineHeight: 3,
  fontWeight: 'semibold',
  mb: 'var(--chakra-space-4)'
};
var descriptionStyles = {
  color: 'darkGrey1',
  fontSize: 'md',
  lineHeight: 3,
  fontWeight: 'normal',
  mb: 'var(--chakra-space-8)'
};
var wrapperStyles = {
  flexDir: 'column',
  mb: '5xl'
};
var inputWrapperStyles = {
  w: {
    mobile: 'full',
    lg: '24.5rem',
    xl: '26.25rem'
  },
  maxW: '26.25rem',
  mb: '4'
};
var inputElementStyles = {
  color: 'darkGrey1'
};
var errorMessageStyles = {
  position: 'relative',
  color: 'darkGrey1',
  w: 'full',
  mb: '4'
};