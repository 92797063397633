"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "DEFAULT_TRACING_COOKIE_NAME", {
  enumerable: true,
  get: function get() {
    return _globalConstants.DEFAULT_TRACING_COOKIE_NAME;
  }
});
Object.defineProperty(exports, "ID_TOKEN_COOKIE", {
  enumerable: true,
  get: function get() {
    return _globalConstants.ID_TOKEN_COOKIE;
  }
});
Object.defineProperty(exports, "RolesRequired", {
  enumerable: true,
  get: function get() {
    return _index.RolesRequired;
  }
});
Object.defineProperty(exports, "cn", {
  enumerable: true,
  get: function get() {
    return _tailwind.cn;
  }
});
exports.formatIBAssetsUrl = formatIBAssetsUrl;
exports.getActivePath = getActivePath;
exports.getUserDetails = exports.getServerUnleashToggles = exports.getRandomTracingId = exports.getInnBusinessHeaderLabels = exports.getInitials = exports.getCompanyDetails = void 0;
Object.defineProperty(exports, "useTranslation", {
  enumerable: true,
  get: function get() {
    return _i18n.useTranslation;
  }
});
var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));
var _api = require("@whitbread-eos/api");
var _nanoid = require("nanoid");
var _config = _interopRequireDefault(require("next/config"));
var _headers = require("next/headers");
var _globalConstants = require("../global-constants");
var _index = require("../hoc/index");
var _decodeIdToken = _interopRequireDefault(require("../utils/decodeIdToken"));
var _i18n = require("../utils/i18n");
var _tailwind = require("../utils/tailwind");
var _unleash = require("../utils/unleash");
var getUserDetails = exports.getUserDetails = function () {
  var _ref = (0, _asyncToGenerator2.default)(function* (cookieStore) {
    var _token$value;
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var token = cookieStore.get(_globalConstants.ID_TOKEN_COOKIE);
    var {
      email
    } = (0, _decodeIdToken.default)((_token$value = token === null || token === void 0 ? void 0 : token.value) !== null && _token$value !== void 0 ? _token$value : '');
    if (!email) {
      return null;
    }
    try {
      var _token$value2;
      var res = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/customers/hotels/").concat(email, "?business=true"), {
        method: 'GET',
        headers: {
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat((_token$value2 = token === null || token === void 0 ? void 0 : token.value) !== null && _token$value2 !== void 0 ? _token$value2 : '')
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getUserDetails(_x) {
    return _ref.apply(this, arguments);
  };
}();
var getCompanyDetails = exports.getCompanyDetails = function () {
  var _ref2 = (0, _asyncToGenerator2.default)(function* (companyId, sessionId, employeeId, cookieStore) {
    var _token$value3;
    var {
      publicRuntimeConfig = {}
    } = (0, _config.default)() || {};
    var token = cookieStore.get(_globalConstants.ID_TOKEN_COOKIE);
    var {
      email
    } = (0, _decodeIdToken.default)((_token$value3 = token === null || token === void 0 ? void 0 : token.value) !== null && _token$value3 !== void 0 ? _token$value3 : '');
    if (!email || !companyId) {
      return null;
    }
    try {
      var _token$value4;
      var res = yield fetch("".concat(publicRuntimeConfig.NEXT_PUBLIC_REST_API, "/company/").concat(companyId), {
        method: 'GET',
        headers: {
          'session-id': sessionId,
          'employee-id': employeeId,
          'company-id': companyId,
          Origin: publicRuntimeConfig.NEXT_PUBLIC_ASSETS_URL_WITHOUT_BASIC,
          Authorization: "Bearer ".concat((_token$value4 = token === null || token === void 0 ? void 0 : token.value) !== null && _token$value4 !== void 0 ? _token$value4 : '')
        },
        cache: 'no-cache'
      });
      if (!res.ok) {
        return null;
      }
      return yield res.json();
    } catch (error) {
      return null;
    }
  });
  return function getCompanyDetails(_x2, _x3, _x4, _x5) {
    return _ref2.apply(this, arguments);
  };
}();
var getInnBusinessHeaderLabels = exports.getInnBusinessHeaderLabels = function () {
  var _ref3 = (0, _asyncToGenerator2.default)(function* (language) {
    var _process$env$NEXT_APP, _process, _process$env$NEXT_PUB, _process2;
    var query = (0, _api.getInnBusinessHeader)();
    var expirationTime = (_process$env$NEXT_APP = (_process = process) === null || _process === void 0 || (_process = _process.env) === null || _process === void 0 ? void 0 : _process.NEXT_APP_STATIC_CONTENT_CACHE_TTL) !== null && _process$env$NEXT_APP !== void 0 ? _process$env$NEXT_APP : 0;
    var endpoint = (_process$env$NEXT_PUB = (_process2 = process) === null || _process2 === void 0 || (_process2 = _process2.env) === null || _process2 === void 0 ? void 0 : _process2.NEXT_PUBLIC_GRAPHQL_ENDPOINT) !== null && _process$env$NEXT_PUB !== void 0 ? _process$env$NEXT_PUB : '';
    try {
      var _result$errors, _result$data;
      var res = yield fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          query: query,
          variables: {
            language,
            country: language === 'en' ? 'gb' : 'de',
            businessBooker: true
          }
        }),
        next: {
          revalidate: Number(expirationTime)
        }
      });
      if (!res.ok) {
        return null;
      }
      var result = yield res.json();
      if (!(result !== null && result !== void 0 && result.data) || (result === null || result === void 0 || (_result$errors = result.errors) === null || _result$errors === void 0 ? void 0 : _result$errors.length) > 0) {
        return null;
      }
      return result === null || result === void 0 || (_result$data = result.data) === null || _result$data === void 0 || (_result$data = _result$data.headerInformation) === null || _result$data === void 0 ? void 0 : _result$data.innBusinessHeader;
    } catch (error) {
      return null;
    }
  });
  return function getInnBusinessHeaderLabels(_x6) {
    return _ref3.apply(this, arguments);
  };
}();
var getServerUnleashToggles = exports.getServerUnleashToggles = function () {
  var _ref4 = (0, _asyncToGenerator2.default)(function* (label, fallbackFlags, url, query) {
    var _tracingCookie$name;
    var cookieStore = (0, _headers.cookies)();
    var tracingCookie = cookieStore.get(_globalConstants.DEFAULT_TRACING_COOKIE_NAME);
    return (0, _unleash.getUnleashTogglesServerOrClient)(cookieStore, label, fallbackFlags, url, query, {
      [_globalConstants.DEFAULT_TRACING_COOKIE_NAME]: (_tracingCookie$name = tracingCookie === null || tracingCookie === void 0 ? void 0 : tracingCookie.name) !== null && _tracingCookie$name !== void 0 ? _tracingCookie$name : ''
    });
  });
  return function getServerUnleashToggles(_x7, _x8, _x9, _x10) {
    return _ref4.apply(this, arguments);
  };
}();
var getRandomTracingId = () => {
  return (0, _nanoid.nanoid)();
};
exports.getRandomTracingId = getRandomTracingId;
var getInitials = (firstName, lastName) => {
  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
};
exports.getInitials = getInitials;
function formatIBAssetsUrl() {
  var path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return "https://".concat(process.env.NEXT_PUBLIC_ASSETS_URL).concat(path);
}
function getActivePath(currentPath, navObj) {
  var _Object$values$find$l, _Object$values;
  return (_Object$values$find$l = (_Object$values = Object.values(navObj)) === null || _Object$values === void 0 || (_Object$values = _Object$values.find(value => currentPath === null || currentPath === void 0 ? void 0 : currentPath.includes(value === null || value === void 0 ? void 0 : value.path))) === null || _Object$values === void 0 ? void 0 : _Object$values.label) !== null && _Object$values$find$l !== void 0 ? _Object$values$find$l : '';
}