"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = HotelFaqComponent;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = require("@chakra-ui/react");
var _api = require("@whitbread-eos/api");
var _atoms = require("@whitbread-eos/atoms");
var _utils = require("@whitbread-eos/utils");
var _nextI18next = require("next-i18next");
var _react2 = require("react");
var _jsxRuntime = require("react/jsx-runtime");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function HotelFaqComponent(_ref) {
  var {
    faq,
    brand,
    isLoading,
    isError,
    error
  } = _ref;
  var {
    t
  } = (0, _nextI18next.useTranslation)(['common']);
  var [faqItemList, setFaqItemList] = (0, _react2.useState)([]);
  (0, _react2.useEffect)(() => {
    var _faq$faqItems;
    var faqItems = [];
    (faq === null || faq === void 0 ? void 0 : faq.faqItems) && (faq === null || faq === void 0 || (_faq$faqItems = faq.faqItems) === null || _faq$faqItems === void 0 ? void 0 : _faq$faqItems.map(faqData => {
      var _faqData$answer;
      var contentText = (0, _jsxRuntime.jsx)(_react.Text, {
        dangerouslySetInnerHTML: {
          __html: (0, _utils.formatInnerHTMLAssetUrls)((_faqData$answer = faqData === null || faqData === void 0 ? void 0 : faqData.answer) !== null && _faqData$answer !== void 0 ? _faqData$answer : '')
        }
      });
      faqItems.push({
        title: faqData === null || faqData === void 0 ? void 0 : faqData.question,
        content: contentText
      });
    }));
    setFaqItemList(faqItems);
  }, [faq]);
  if (isLoading) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: t('searchresults.list.hotel.loading')
    });
  }
  if (isError) {
    return (0, _jsxRuntime.jsx)(_react.Text, {
      children: error.message
    });
  }
  function isHubOrPiHotel() {
    return [_api.HotelBrand.HUB, _api.HotelBrand.PI, _api.HotelBrand.PID].includes(brand === null || brand === void 0 ? void 0 : brand.toUpperCase());
  }
  if (!isHubOrPiHotel()) {
    return null;
  }
  return (0, _jsxRuntime.jsx)(_react.Box, {
    "data-testid": "hdp_faqs",
    children: (0, _jsxRuntime.jsxs)(_react.Box, _objectSpread(_objectSpread({
      maxW: "full"
    }, containerStyles), {}, {
      children: [(0, _jsxRuntime.jsx)(_react.Heading, _objectSpread(_objectSpread({
        "data-testid": "hdp-faqs-title",
        as: "h3"
      }, headingStyles), {}, {
        children: faq !== null && faq !== void 0 && faq.title ? faq.title : t('hoteldetails.faqs.title')
      })), (0, _jsxRuntime.jsx)(_react.Box, {
        "data-testid": "hdp-faqs-list",
        mt: {
          base: '2',
          sm: '1'
        },
        className: "formatLinks",
        children: (0, _jsxRuntime.jsx)(_atoms.Accordion, {
          allowMultiple: false,
          accordionItems: faqItemList,
          bgColor: "var(--chakra-colors-lightGrey5)"
        })
      })]
    }))
  });
}
var headingStyles = {
  fontWeight: 'semibold',
  fontSize: {
    base: 'xl',
    sm: '2xl'
  },
  lineHeight: {
    base: '3',
    sm: '4'
  }
};
var containerStyles = {
  mt: {
    base: 'lg',
    sm: '3xl'
  },
  mb: {
    base: 'lg',
    sm: '2xl'
  }
};