"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React = _interopRequireWildcard(require("react"));
var _jsxRuntime = require("react/jsx-runtime");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var SvgBell = props => (0, _jsxRuntime.jsx)("svg", _objectSpread(_objectSpread({
  width: 24,
  height: 24,
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}, props), {}, {
  children: (0, _jsxRuntime.jsx)("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12 3a5.52 5.52 0 0 0-3.96 1.688A5.852 5.852 0 0 0 6.4 8.765c0 2.76-.574 4.475-1.102 5.473-.266.5-.524.83-.704 1.025a2.457 2.457 0 0 1-.245.233l-.002.002a.836.836 0 0 0-.313.917A.803.803 0 0 0 4.8 17h14.4a.803.803 0 0 0 .766-.585.836.836 0 0 0-.313-.917l-.002-.002a2.454 2.454 0 0 1-.245-.233 4.89 4.89 0 0 1-.704-1.025c-.528-.998-1.102-2.712-1.102-5.473 0-1.53-.59-2.995-1.64-4.077A5.52 5.52 0 0 0 12 3Zm5.48 12.353H6.52a7.76 7.76 0 0 0 .182-.326C7.374 13.76 8 11.768 8 8.765a4.18 4.18 0 0 1 1.172-2.912A3.943 3.943 0 0 1 12 4.647c1.06 0 2.078.434 2.828 1.206A4.18 4.18 0 0 1 16 8.765c0 3.003.626 4.995 1.298 6.262.06.115.122.223.183.326Zm-6.114 3.98c-.203-.32-.65-.428-1-.243s-.47.594-.267.912c.193.304.47.556.804.73.334.176.712.268 1.097.268s.763-.092 1.097-.267c.334-.175.61-.427.804-.73.203-.32.084-.728-.266-.913-.35-.185-.798-.076-1.001.243a.704.704 0 0 1-.268.243.79.79 0 0 1-.732 0 .704.704 0 0 1-.268-.243Z",
    fill: "#511E62"
  })
}));
var _default = exports.default = SvgBell;